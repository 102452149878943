export default class CXoneNameValuePair 
{
	public Name: string;
	public Value: Object;
	
	constructor (name: string,  value: Object)
	{
		this.Name =name;
		this.Value =value;
	}
}
