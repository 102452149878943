import IResultSet from "../../Interfaces/IResultSet";
import { SqlParser } from "../../Parsers/SQL/SqlParser";
import StringUtils from "../../Utils/StringUtils";
import TextUtils from "../../Utils/TextUtils";

export default class XoneTestResulset implements IResultSet {

    private m_stringData = ['Valor prueba texto', 'Texto corto', '0002346', 'Otro texto', 'Un texto mas largo de prueba', 'Mas texto'];
    private m_nIterate: number;
    private m_parsed: SqlParser;
    private m_maxRows = 100;

    /**
     *
     */
    constructor(strCmd: string) {
        this.m_nIterate = 0;
        this.m_parsed = new SqlParser("ROWID");
        this.m_parsed.ParseSqlString(strCmd)
    }

    isResultset: boolean = true;

    getBytes(fld: string): number[] {
        return null;
    }
    getColumnCount(): number {
        return this.m_parsed.getQueryFields().length;
    }
    getColumnTypes(): number[] {
        return [1, 2, 3, 4, 5, 6, 7, 8];
    }
    getColumnName(ordinal: number): string {
        if (ordinal < this.m_parsed.getQueryFields().length) {
            let name = this.m_parsed.getQueryFields()[ordinal].getResolvedName();
            if (name.endsWith('*') || TextUtils.isEmpty(name))
                name = "Column" + ordinal;
            return name;
        }
        return "Column" + ordinal;
    }
    close(): void {
        this.m_nIterate = 0;
    }
    getValue(fld: string | number, nType: number) {
        switch (nType) {
            case 0: // Numero
                return Math.floor(Math.random() * (1000 - 1)) + 1;
            case 2:
                return Math.random() * (1000 - 1) + 1;
            case 3:
                return new Date();
            default:
                return this.m_stringData[Math.floor(Math.random() * 6)];
        }
        return "Test Value";
    }
    getString(fld: string | number): string {
        return "test string";
    }
    getInt(fld: string): number {
        return Math.floor(Math.random() * (1000 - 1)) + 1;
    }
    getDate(fld: string): Date {
        return new Date();
    }
    public async next(): Promise<boolean> {
        return this.m_nIterate++ != this.m_maxRows;
    }
    public async EOF(): Promise<boolean> {
        return this.m_nIterate == this.m_maxRows;
    }
    public async moveTo(position: number): Promise<boolean> {
        return position == this.m_maxRows || (this.m_nIterate = position) == this.m_maxRows;
    }

}