// @ts-nocheck
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Container from "./components/Container.vue";
import "./composables/helperFunctions/DateHelper";
import { XoneDataObject } from "./composables/appData/core/XoneDataObject";

// Create Vue App
createApp(App)
  .use(router)
  .component("Container", Container)
  .mount("#app");

// Disable navigator forward button
window.addEventListener(
  "popstate",
  (event) => {
    if (typeof event.state == "object" && event.state.obsolete !== true) {
      history.replaceState({ obsolete: true }, "");
      history.pushState(event.state, "");
    }

    if (typeof event.state == "object" && event.state.obsolete === true) {
      history.back();
    }
  },
  false
);

// Lock scale
const viewportmeta = document.querySelector('meta[name="viewport"]');
if (viewportmeta) {
  viewportmeta.content =
    "width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0";
  document.body.addEventListener(
    "gesturestart",
    function() {
      viewportmeta.content =
        "width=device-width, minimum-scale=0.25, maximum-scale=1.6";
    },
    false
  );
}

// Add changeModelValue to prototype
if (!XoneDataObject.prototype.changeModelValue)
  XoneDataObject.prototype.changeModelValue = function(propName, newValue) {
    this[propName] = newValue;
  };

// SW
// if (navigator.serviceWorker) navigator.serviceWorker.register("./sw.js");
