<template>
  <div
    v-if="!attributes.imgChecked && !attributes.imgUnchecked"
    :style="{
      padding: '0',
      height: 'var(--label-height)',
    }"
  >
    <input
      :disabled="isDisabled"
      v-model="propValue"
      type="checkbox"
      xchange="onChange()"
      xfocus="onFocusChanged(true)"
      xblur="onFocusChanged(false)"
      xkeydown="onKeyPress($event)"
    />
  </div>
  <button
    v-else
    :disabled="isDisabled"
    @click="propValue = !propValue"
    :style="{
      width: imageSize.width
        ? `${imageSize.width}px`
        : (attributes.width !== undefined && '100%') || attributes.fieldSize,
      height: imageSize.height
        ? `${imageSize.height}px`
        : (controlHeight && `${controlHeight - 11}px`) || '30px',
      backgroundImage: `url(${
        propValue == 1 ? backgroundImageChecked : backgroundImageUnchecked
      })`,
      opacity:
        attributes.disableEdit && attributes.disableEdit !== '' && isDisabled
          ? 0.4
          : 1,
    }"
  ></button>
</template>

<script>
import {
  Ref,
  ComputedRef,
  PropType,
  computed,
  watch,
  ref,
  reactive,
  inject,
  onMounted,
} from "vue";

import {
  xoneAttributesHandler,
  PropAttributes,
} from "../../../composables/XoneAttributesHandler";
import AppDataHandler from "../../../composables/AppDataHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { getImagePath } from "../../../composables/helperFunctions/ImageHelper";

export default {
  props: {
    /** xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },

    /** attributes
     @type {PropType<PropAttributes>} 
     */
    attributes: { type: Object, required: true },

    isDisabled: { type: Boolean, required: true },

    controlWidth: { type: Number, default: 0 },

    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    /**
     * prop model value
     * @type {Ref<any>}
     */
    const propValue = ref(props.xoneDataObject.model[props.attributes.name]);

    watch(
      () => propValue.value,
      async (newValue, oldValue) => {
        try {
          // Update data model
          props.xoneDataObject.changeModelValue(
            props.attributes.name,
            newValue === true ? 1 : 0
          );
          onChange();
        } catch (ex) {
          console.error(ex);
        }
      }
    );

    /**
     * Get editInRow="false" if prop is in contents
     * @type {boolean}
     */
    const objectInfo = inject("objectInfo");

    /**
     * on value change
     */
    const onChange = async () => {
      try {
        if (objectInfo?.editInRow && objectInfo.autosave) {
          await props.xoneDataObject.save();
        }
      } catch (ex) {
        xoneUI.showSnackbar({
          text: ex,
          color: "red",
        });
      }
    };

    /**
     * Scale Factor
     * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
     */
    const { widthFactor, heightFactor } = inject("scaleFactor");

    const imageSize = reactive({
      width: computed(() =>
        xoneAttributesHandler.getControlScaled(
          props.attributes.imgWidth,
          props.containerWidth,
          widthFactor.value
        )
      ),
      height: computed(() =>
        xoneAttributesHandler.getControlScaled(
          props.attributes.imgHeight, // offset height
          props.containerHeight,
          heightFactor.value
        )
      ),
    });

    const backgroundImageChecked = ref();
    const backgroundImageUnchecked = ref();

    onMounted(async () => {
      backgroundImageChecked.value = await getImagePath(
        props.attributes.imgChecked,
        "icons"
      );
      backgroundImageUnchecked.value = await getImagePath(
        props.attributes.imgUnchecked,
        "icons"
      );
    });

    return {
      propValue,
      imageSize,
      backgroundImageChecked,
      backgroundImageUnchecked,
      onChange,
    };
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  margin: 0 5px 0 5px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 20px;
  align-self: flex-start;
}

button {
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  background-color: transparent;
  animation: fadeIn 0.3s;
  transition: all 0.3s;
}
</style>