<template>
  <teleport to="body">
    <div class="xone-linked-container" @click="onHide">
      <div
        ref="divElement"
        :style="{
          '--linked-search-max-height': `calc(${containerHeight}px - 10%)`,
          '--linked-search-max-width': maxWidthPosition,
          '--linked-search-min-width': linkedSearchPosition.width + 'px',
          '--linked-search-min-height': heightPosition,
          '--linked-search-right-position': rightPosition,
          '--linked-search-top-position': topPosition,
        }"
      >
        <!-- <p>{{ attributes.title ? attributes.title : "" }}</p> -->
        <table v-show="rows.length !== 0">
          <tbody>
            <template
              class="xone-linked-row"
              v-for="(rowItem, iRows) in rows"
              :key="`rowItem${iRows}`"
            >
              <tr>
                <td
                  v-for="(column, iColumns) in rowItem"
                  :key="`columnItem${iColumns}`"
                  @click="onSelectedItem(column)"
                >
                  {{ column.value }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </teleport>
</template>

<script>
import {
  inject,
  Ref,
  ComputedRef,
  PropType,
  computed,
  ref,
  onMounted,
  nextTick,
  watch,
  onUnmounted,
} from "vue";

import {
  xoneAttributesHandler,
  PropAttributes,
} from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../composables/appData/core/XoneDataCollection";
import AppDataHandler from "../../composables/AppDataHandler";

export default {
  name: "LinkedSearch",
  emits: ["onHide", "onSelectedItem"],
  props: {
    /** xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },
    /** attributes
     @type {PropType<PropAttributes>} 
     */
    attributes: { type: Object, required: true },

    linkedSearchPosition: { type: Object, required: true },
  },
  setup(props, context) {
    /**
     * Window Size
     * @type {{containerWidth: Ref<number>, containerHeight: Ref<number>}}
     */
    const { containerWidth, containerHeight } = inject("containerSize");

    // Close modal
    const onHide = () => context.emit("onHide");

    // Select an item
    const onSelectedItem = (item) => context.emit("onSelectedItem", item);
    //
    // Map attributes
    const mapColValues = props.xoneDataObject.getFieldPropertyValue(
      props.attributes.linkedTo,
      "mapcol-values"
    );
    const mapCol = props.xoneDataObject.getFieldPropertyValue(
      props.attributes.linkedTo,
      "mapcol"
    );
    let mapFld = props.xoneDataObject.getFieldPropertyValue(
      props.attributes.linkedTo,
      "mapfld"
    );

    // calculate rows to display on grid
    const rows = ref([]);

    onMounted(async () => {
      try {
        await nextTick();
        setPosition();
        //
        // mapcol-values

        if (mapColValues !== "") {
          setTimeout(async () => {
            await nextTick();
            setPosition();
          });
          return (rows.value = mapColValues
            .split(",")
            .map((e) => [{ mapValue: e, value: e }]));
        }

        //
        // mapcol && mapfld

        /**
         * Create XoneDataObject
         * @type {XoneDataCollection}
         */
        const xoneDataCollection = await AppDataHandler.getAppData().getCollection(
          mapCol
        );

        // Layout
        const props = [];

        const getControlProps = (control) => {
          control.controls.forEach((e) => {
            const propAttributes = xoneAttributesHandler.getPropAttributes(
              e.attributes
            );
            if (
              propAttributes.node === "group" ||
              propAttributes.node === "frame"
            )
              getControlProps(e);
            if (propAttributes.node !== "prop") return;
            if (
              propAttributes.type.startsWith("T") ||
              propAttributes.type.startsWith("N")
            )
              props.push(propAttributes.name);
          });
        };

        getControlProps(xoneDataCollection.getLayout(8));

        // Data
        await xoneDataCollection.startBrowse();

        rows.value = [];
        while (xoneDataCollection.getCurrentItem()) {
          const obj = xoneDataCollection.getCurrentItem();
          rows.value.push(
            props.map((e) => {
              return { mapValue: obj[mapFld], value: obj[e] };
            })
          );

          await xoneDataCollection.moveNext();
        }
        xoneDataCollection.clear();
        await nextTick();
        setPosition();
      } catch (ex) {
        console.error(ex);
      }
    });

    //
    // Manage dialog position
    /**
     * @type {Ref<HTMLElement>}
     */
    const divElement = ref();

    const rightPosition = ref(
      (window.innerWidth - containerWidth.value) / 2 +
        containerWidth.value -
        props.linkedSearchPosition.left
    );
    const topPosition = ref(props.linkedSearchPosition.top + "px");
    const maxWidthPosition = ref(props.linkedSearchPosition.width + "px");
    const heightPosition = ref(props.linkedSearchPosition.height + "px");

    const setPosition = async () => {
      if (!divElement.value) return;
      // Right
      const right =
        (window.innerWidth - containerWidth.value) / 2 +
        containerWidth.value -
        props.linkedSearchPosition.left;
      if (window.innerWidth - divElement.value.offsetWidth > right)
        rightPosition.value = `${right}px`;
      else rightPosition.value = "15px";
      // Top
      if (
        divElement.value.offsetHeight <=
        containerHeight.value - props.linkedSearchPosition.top
      )
        topPosition.value = `${props.linkedSearchPosition.top}px`;
      else
        topPosition.value = `calc(100vh - 5% - ${divElement.value.offsetHeight}px)`;
      if (rows.value.length == 0) return;
      await nextTick();
      // Height
      heightPosition.value = divElement.value.offsetHeight + "px";
      maxWidthPosition.value = `${containerWidth.value - right - 5}px`;
    };

    onMounted(() => window.addEventListener("resize", setPosition));
    onUnmounted(() => window.removeEventListener("resize", setPosition));
    watch(
      () => containerWidth.value,
      () => onHide()
    );
    watch(
      () => containerHeight.value,
      () => onHide()
    );

    return {
      containerWidth,
      containerHeight,
      onHide,
      onSelectedItem,
      rows,
      divElement,
      rightPosition,
      topPosition,
      heightPosition,
      maxWidthPosition,
    };
  },
};
</script>

<style scoped>
.xone-linked-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.12);
  animation: fadeIn 0.3s;

  overflow: hidden;
}

.xone-linked-container > div {
  position: absolute;
  border: 1px gray solid;
  background-color: white;
  border-radius: 3px;
  overflow-y: auto;
  overflow-x: auto;
  max-width: var(--linked-search-max-width);
  max-height: var(--linked-search-max-height);
  min-width: var(--linked-search-min-width);
  min-height: var(--linked-search-min-height);
  right: var(--linked-search-right-position);
  top: var(--linked-search-top-position);
  transition: all 0.2s;
}

p {
  margin: 10px;
  font-weight: bold;
  font-size: 1rem;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  max-width: var(--linked-search-max-width);
  max-height: var(--linked-search-max-height);
  min-width: var(--linked-search-min-width);
  min-height: var(--linked-search-min-height);
  animation: fadeIn 0.3s;
  width: auto;
}

td {
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;
}

tr {
  /* border: 1px rgba(0, 0, 0, 0.05) solid; */
  white-space: nowrap;
}

tr:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.14);
}
</style>