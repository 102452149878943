<template>
  <teleport to="body">
    <div v-if="state.visible" class="xone-msgbox">
      <div>
        <div class="xone-msgbox-title">{{ state.title }}</div>
        <div class="xone-msgbox-text">{{ state.msg }}</div>
        <div class="xone-msgbox-options">
          <button @click="onClick(true)">{{ state.btn1 }}</button>
          <button v-if="state.btn2 !== ''" @click="onClick(false)">
            {{ state.btn2 }}
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { reactive } from "vue";

import xoneUI from "../../composables/XoneUI";

export default {
  setup() {
    const state = reactive({
      title: "",
      msg: "",
      flags: 0,
      btn1: "",
      btn2: "",
      response: null,
      visible: false,
    });

    xoneUI.setMsgBoxCallback(async (message = "", title = "", flags = 0) => {
      state.title = title;
      state.msg = message;
      state.flags = flags;
      switch (flags) {
        case 0:
          state.btn1 = "Ok";
          state.btn2 = "";
          break;
        case 1:
          state.btn1 = "Sí";
          state.btn2 = "No";
          break;
        case 4:
          state.btn1 = "Ok";
          state.btn2 = "Cancel";
          break;
      }
      state.response = null;
      state.visible = true;
      while (!state.response)
        await (() =>
          new Promise((resolve) => setTimeout(() => resolve(), 100)))();

      state.visible = false;

      return state.response;
    });

    const onClick = (isAccepted) => {
      switch (state.flags) {
        case 0:
          return (state.response = 10);
        case 1:
          return (state.response = isAccepted ? 1 : 2);
        case 4:
          return (state.response = isAccepted ? 6 : 7);
        default:
          return (state.response = 0);
      }
    };
    return { state, onClick };
  },
};
</script>

<style>
.xone-msgbox {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  pointer-events: all;
  animation: fadeIn 0.3s;
  z-index: 999;
}

.xone-msgbox > div {
  max-width: 768px;
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  border: 1px rgba(0, 0, 0, 0.14) solid;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
  animation: zoomIn 0.3s;
}

.xone-msgbox-title {
  margin-bottom: 30px;
  width: 100%;
  font-size: 21px;
  text-align: justify;
}

.xone-msgbox-text {
  width: 100%;
  font-size: 18px;
  text-align: justify;
}

.xone-msgbox-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.xone-msgbox-options button {
  margin-top: 30px;
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  color: #3273dc;
  border: 1px #3273dc solid;
  border-radius: 5px;
  background-color: transparent;
  font-size: 16px;
}

.xone-msgbox-options button:hover {
  cursor: pointer;
}
</style>