<template>
  <div
    v-show="!isDisableVisible"
    class="xone-prop"
    :name="attributes.name"
    :style="{
      marginTop: margins.top + 'px',
      marginRight: margins.right + 'px',
      marginBottom: margins.bottom + 'px',
      marginLeft: margins.left + 'px',
      width:
        (controlWidth && `${controlWidth}px`) ||
        (imgSize && `${imgSize.width}px`),
      height:
        (controlHeight && `${controlHeight}px`) ||
        (imgSize && `${imgSize.height}px`) ||
        (attributes.height ?? 'auto'),
      position: attributes.floating.floating && 'absolute',
      top: attributes.floating.floating && floatingTop && floatingTop,
      left: attributes.floating.floating && floatingLeft && floatingLeft,
      '--label-height':
        (controlHeight && `${controlHeight}px`) || attributes.lines || '100%',
      '--text-weight': attributes.fontBold ? 'bold' : 'normal',
      '--input-bgcolor': attributes.textBgColor,
      '--input-forecolor': attributes.textForeColor,

      backgroundColor: displayScreenThresholds && '#ff000025',
      border: displayScreenThresholds && '1px solid #ff0000',
    }"
  >
    <InputProp
      v-if="inputType"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth"
      :controlHeight="controlHeight"
      :inputType="inputType"
    ></InputProp>
    <ButtonProp
      v-if="attributes.type === 'B'"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
    ></ButtonProp>
    <ImageProp
      v-if="attributes.type === 'IMG' || attributes.type === 'PH'"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth"
      :controlHeight="controlHeight"
    ></ImageProp>
    <Contents
      v-if="attributes.type === 'Z'"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth || containerWidth"
      :controlHeight="controlHeight || containerHeight"
    ></Contents>
    <WebProp
      v-if="attributes.type === 'WEB'"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth"
      :controlHeight="controlHeight"
    ></WebProp>
    <VideoProp
      v-if="attributes.type === 'VD'"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth"
      :controlHeight="controlHeight"
    ></VideoProp>
  </div>
</template>

<script>
// vue
import {
  computed,
  inject,
  ref,
  Ref,
  ComputedRef,
  watchEffect,
  onMounted,
  watch,
} from "vue";
// components
import ButtonProp from "@/components/propComponents/Button";
import InputProp from "@/components/propComponents/Input";
import ImageProp from "@/components/propComponents/Image";
import Contents from "@/components/propComponents/Contents";
import WebProp from "@/components/propComponents/Web";
import VideoProp from "@/components/propComponents/Video";
// composables
import {
  xoneAttributesHandler,
  PropAttributes,
  Margins,
} from "../composables/XoneAttributesHandler";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";

export default {
  name: "Prop",
  props: {
    /** xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },
    control: { type: Object, default: undefined, required: true },
    containerWidth: { type: Number, default: 0 },
    containerHeight: { type: Number, default: 0 },
  },
  components: {
    ButtonProp,
    InputProp,
    ImageProp,
    Contents,
    WebProp,
    VideoProp,
  },
  setup(props) {
    const displayScreenThresholds = inject("displayScreenThresholds");

    /**
     * Attributes model
     * @type {ComputedRef<PropAttributes>}
     */
    const attributesModel = computed(() =>
      xoneAttributesHandler.getPropAttributes(props.control.attributes)
    );

    /**
     * Reactive attributes
     * @type {Ref<PropAttributes>}
     */
    const attributes = ref({});

    // Observe changes in attributes model and data model with ##FLD_ fields and fetch them to attributes
    xoneAttributesHandler.watchAttributes(
      attributes,
      attributesModel,
      props.xoneDataObject?.model
    );

    /**
     * Check if disablevisible
     * @type {ComputedRef<boolean>}
     */
    const isDisableVisible = computed(() => {
      if ((attributes.value.disableVisible ?? "") === "") return false;
      return xoneAttributesHandler.evalFormula(
        attributes.value.disableVisible,
        props.xoneDataObject?.model
      );
    });

    /**
     * Image size
     * @type {Ref<number|null>}
     */
    let imgSize = ref(null);

    const calculateImgSize = () => {
      if (!attributes.value.image) return;
      const img = new Image();
      img.src = `/icons/${attributes.value.image}`;
      img.onload = function () {
        imgSize.value = {
          width: xoneAttributesHandler.getControlScaled(
            this.width + "px",
            props.containerWidth,
            widthFactor.value
          ),
          height: xoneAttributesHandler.getControlScaled(
            this.height + "px", // offset height
            props.containerHeight,
            heightFactor.value
          ),
        };
      };
    };

    // Recalculate image size if attributes.image change
    onMounted(() => calculateImgSize());

    /**
     * Window Size
     * @type {{containerHeight: Ref<number>}}
     */
    const { containerHeight } = inject("containerSize");
    watch(
      () => containerHeight.value,
      () => calculateImgSize()
    );

    /**
     * Input type depending on prop type
     * @type {ComputedRef<string>}
     */
    const inputType = computed(() => {
      switch (attributes.value.type) {
        case "T":
          return "text";
        case "X":
          return "password";
        case "N":
          return "number";
        case "NC":
          return "checkbox";
        case "D":
          return "date";
        case "TT":
          return "time";
        case "DT":
          return "datetime-local";
        case "AT":
          return "file";
        case "TL":
          return "text";
        default:
          if (
            attributes.value.type.length != 0 &&
            attributes.value.type[0] === "N"
          )
            return "number";
          return null;
      }
    });

    /**
     * Scale Factor
     * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
     */
    const { widthFactor, heightFactor } = inject("scaleFactor");

    /**
     * Calculate width of the current prop
     * @type {ComputedRef<number>}
     */
    const controlWidth = computed(() =>
      xoneAttributesHandler.getControlScaled(
        attributes.value.width,
        props.containerWidth,
        widthFactor.value
      )
    );

    /**
     * Calculate height of the current prop
     * @type {ComputedRef<number>}
     */
    const controlHeight = computed(() =>
      xoneAttributesHandler.getControlScaled(
        attributes.value.height,
        props.containerHeight,
        heightFactor.value
      )
    );

    /**
     * Margins of the current prop
     * @type {ComputedRef<Margins>}
     */
    const margins = computed(() => ({
      top: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.top,
        props.containerHeight,
        heightFactor.value
      ),
      left: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.left,
        props.containerWidth,
        widthFactor.value
      ),
      bottom: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.bottom,
        props.containerHeight,
        heightFactor.value
      ),
      right: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.right,
        props.containerWidth,
        widthFactor.value
      ),
    }));

    /**
     * calculate floating top
     * @type {ComputedRef<string>}
     */
    const floatingTop = computed(() =>
      xoneAttributesHandler.getScaledPosition(
        attributes.value.floating.top,
        heightFactor.value
      )
    );

    /**
     * calculate floating leeft
     * @type {ComputedRef<string>}
     */
    const floatingLeft = computed(() =>
      xoneAttributesHandler.getScaledPosition(
        attributes.value.floating.left,
        widthFactor.value
      )
    );

    return {
      displayScreenThresholds,
      attributes,
      inputType,
      controlWidth,
      controlHeight,
      margins,
      isDisableVisible,
      imgSize,
      floatingTop,
      floatingLeft,
    };
  },
};
</script>

<style scoped >
.xone-prop {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  /* default height */
  height: 30px;
  box-sizing: border-box;
}
</style>
