import Hashtable from "../../Collections/HashMap/Hashtable";
import XoneGenericException from "../../Exceptions/XoneGenericException";
import { XoneMessageKeys } from "../../Exceptions/XoneMessageKeys";
import IConnection from "../../Interfaces/IConnection";
import IResultSet from "../../Interfaces/IResultSet";
import IXoneObject from "../../Interfaces/IXoneObject";
import DataUtils from "../../Utils/DataUtils";
import StringUtils from "../../Utils/StringUtils";
import { Utils } from "../../Utils/Utils";
import XmlUtils from "../../Utils/XmlUtils";
import XmlNode from "../../Xml/JSONImpl/XmlNode";
import XmlNodeList from "../../Xml/JSONImpl/XmlNodeList";
import { XoneApplication } from "../XoneApplication";
import { XoneDataCollection } from "../XoneDataCollection";
import { XoneDataObject } from "../XoneDataObject";

export default class MacrosEvaluator {

    private m_lstMacros: Hashtable<string, Object>;
    private m_ownerApp: XoneApplication;
    private m_ownerColl: XoneDataCollection;

    constructor(ownerApp: XoneApplication, ownerColl: XoneDataCollection) {
        this.m_lstMacros = new Hashtable<string, Object>();
        this.m_ownerApp = ownerApp;
        this.m_ownerColl = ownerColl;
    }
    /**
     * Evalúa todas las macros incluidas dentro de la sentencia que se pasa como parámetro
     *
     * M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
     * Hace falta que esta función sea pública para usarla desde fuera
     *
     * @param Sentence				Sentencia SQL o cadena en la que se quieren evaluar las macros.
     * @param EvalLookupMacro		TRUE si se va a evaluar la macro de acceso a datos (macro de búsqueda)
     * @return						Devuelve la cadena con todas las macros evaluadas.
     * @throws Exception
     */
    public async EvaluateAllMacros(Sentence: string, EvalLookupMacro: boolean = false): Promise<string> {
        if (Sentence == null) {
            return null;
        }
        let strLookupMacroName = this.m_ownerColl.getLookupMacroName();
        let str = Sentence;
        let k1: number, k2: number;

        for (k1 = 0; k1 < str.length;) {// Teóricamente debería sustituir todo...
            k1 = str.indexOf("##", k1);

            //TODO ADD TAG Juan Carlos Cambio return por breaks para que evalúe OK macros.
            if (k1 == -1)
                break;
            k2 = str.indexOf("##", k1 + 2);
            if (k2 == -1)
                break;

            //
            // Buscar la cadenilla entre los dos kases...
            // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
            let strMacro = str.substring(k1, k2 + 2);
            // Buscar en el XML si está definida esta macro
            let bEval = true;
            if (!EvalLookupMacro) {// Hay que saltarla
                if (!StringUtils.IsEmptyString(strLookupMacroName)) {// Tiene macro de búsqueda
                    if (strLookupMacroName.equals(strMacro))
                        bEval = false;
                }// Tiene macro de búsqueda
            }// Hay que saltarla
            // Si hay que evaluar se evalúa, de lo contrario se deja tal cual
            let strValue: string;
            if (bEval)
                strValue = await this.EvaluateMacro(strMacro);
            else
                strValue = strMacro;
            //
            // Comprobar si el valor de la macro es el mismo que tenía antes...
            if (!strMacro.equals(strValue))
                //
                // Ahora sustituir esta cosa
                str = StringUtils.Replace(str, strMacro, strValue);
            else
                k1 = k2 + 2;
            //
            // Seguimos buscando aquí, por si las macros son recursivas
            // así que k1 se queda donde venía...
        }// Teóricamente debería sustituir todo...
        // M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
        if (str.contains("##"))
            str = this.m_ownerApp.PrepareSqlString(str);
        // Completo
        return str;
    }

    /// Evalúa la macro cuyo nombre se pasa como parámetro.
    /// <param name="MacroName">Nombre de la macro que se quiere evaluar.</param>
    private async EvaluateMacro(MacroName: string): Promise<string> {
        const FunctionName = "CXoneDataCollection::EvaluateMacro";
        let strTmp: string;
        let bIsFilter = false;
        let strValue: string;
        let node = this.m_ownerColl.getNode("macro", "name", MacroName);
        //
        // Comprobar si esta macro es un filtro
        if (node != null) {// Tiene nodo
            strTmp = XmlUtils.getNodeAttr(node, "filter");
            bIsFilter = StringUtils.ParseBoolValue(strTmp, false);
        }// Tiene nodo
        // Primero obtener el valor real de la macro antes de ir al mappings
        if (this.m_lstMacros.containsKey(MacroName)) {// Procesar este valor
            // F13022202: Las macros pueden contener valores de cualquier tipo, no solo cadenas.
            // Las macros pueden contener cualquier cosa, no solo cadenillas
            let value = this.m_lstMacros.get(MacroName);
            if (bIsFilter)
                strValue = this.m_ownerColl.DevelopObjectValue(value);
            else
                strValue = StringUtils.SafeToString(value);
            if (!StringUtils.IsEmptyString(strValue)) {// Preparar el valor
                //
                // Si es un filtro, habrá que prepararlo antes
                strTmp = this.m_ownerColl.getConnection().PrepareSqlString(strValue);
                if (bIsFilter)
                    strTmp =await  this.m_ownerColl.BrowseData.PrepareFilter(strTmp);
                return strTmp;
            }// Preparar el valor
        }// Procesar este valor
        //
        // Si no existe el nodo simplemente se devuelve el nombre de lo que se ha pedido
        if (node == null)
            return MacroName;		// Nada más que hacer con esta...
        //
        // Buscar en este nodo el valor
        strValue = XmlUtils.getNodeAttr(node, "value");
        let strSQL = "";
        let ent: XoneDataObject = null;
        if (StringUtils.IsEmptyString(strValue)) {// Puede ser que el valor venga en algún nodo
            strValue = "";
            let actions = node.SelectNodes("Node");
            for (let i = 0; i < actions.count(); i++) {// Revisa las acciones
                let action = actions.get(i);
                if (this.EvaluateNodeRules(action)) {// Esta vale
                    if ("id-list".equals(XmlUtils.getNodeAttr(action, "name"))) {// Lista de IDs
                        strValue = XmlUtils.getNodeAttr(action, "mask");
                        strSQL = XmlUtils.getNodeAttr(action, "sql");
                        if (null != (ent = this.m_ownerApp.getCurrentCompany()))
                            strSQL = ent.PrepareSqlString(strSQL);
                        let rs: IResultSet = null;
                        let conn: IConnection = null;
                        try {
                            if (null == (conn = this.m_ownerColl.getConnection().GetNewConnection(true))) {// Error
                                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                                ////throw new Exception("Error evaluando la macro '" + MacroName + "' No se puede crear una nueva conexión de acceso a datos.");
                                let sb = this.m_ownerColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_EVALMACROFAIL_01, "{0} failed. Error evaluating macro '{1}'. Cannot create new data connection.");
                                sb = sb.replace("{0}", FunctionName);
                                sb = sb.replace("{1}", MacroName);
                                throw new XoneGenericException(-3998, sb);
                            }// Error
                            // Obtener una conexión
                            // A12042503: Mecanismo para registrar un modo debug global en la aplicación.
                            // if (this.getIsDebugging() || this.m_ownerApp.isDebugMode())
                            // 	Utils.DebugLog(Utils.TAG_DATABASE_LOG,"EvaluateMacros (id-list): "+strSQL);
                            if (null == (rs = await this.m_ownerColl.getConnection().CreateRecordsetAsync(conn, strSQL))) {// Error
                                conn = null;
                                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                                ////throw new Exception("Error evaluando la macro '" + MacroName + "'");
                                let sb = this.m_ownerColl.GetMessage(XoneMessageKeys.SYS_MSG_COLL_EVALMACROFAIL_02, "{0} failed. Error evaluating macro '{1}'. Error executing SQL query.");
                                sb = sb.replace("{0}", FunctionName);
                                sb = sb.replace("{1}", MacroName);
                                throw new XoneGenericException(-3999, sb);
                            }// Error
                            strTmp = "";
                            while (await rs.next()) {// Cada uno de los elementos
                                let str1 = StringUtils.SafeToString(DataUtils.RsReadLong(rs, "N"));
                                if (!StringUtils.IsEmptyString(strTmp))
                                    strTmp += ",";
                                strTmp += str1;
                            }// Cada uno de los elementos
                            rs.close();
                            rs = null;
                            // Cerrar la conexión
                            //////conn.close();
                            conn = null;
                        }
                        catch (e) {
                            if (rs != null) {// Cerrar
                                try {
                                    rs.close();
                                }
                                catch (se) {
                                    // Ignorar esta excepción
                                }
                                rs = null;
                            }// Cerrar
                            /*
                            if (conn != null)
                            {// Cerrar
                                try
                                {
                                    conn.close();
                                }
                                catch (SQLException se)
                                {
                                    // Ignorar esta excepción
                                }
                                conn = null;
                            }// Cerrar
                            */
                            throw e;
                        }
                        //
                        // Si ha encontrado alguna lista
                        if (StringUtils.IsEmptyString(strTmp))
                            strTmp = "NULL";
                        // Sustituir los valores
                        strValue = StringUtils.Replace(strValue, "##LIST##", strTmp);
                        break;
                    }// Lista de IDs
                }// Esta vale
            }// Revisa las acciones
        }// Puede ser que el valor venga en algún nodo
        //
        // Si quedan cosas por solucionar, intentar reemplazar operadores custom
        // No se retorna directamente, sino que se analiza si quedan cosas
        // por sustituir
        strSQL = this.m_ownerColl.getConnection().PrepareSqlString(strValue);
        if (strSQL.contains("##")) {// Llamar la función
            strSQL = this.m_ownerColl.getConnection().ReplaceCustomOper(this.m_ownerColl, strSQL, "##BIT##");
        }// Llamar la función
        //
        if (bIsFilter)
            strSQL = this.m_ownerColl.getConnection().PrepareFilter(strSQL);
        //
        // Habrá que mirar otros...
        return strSQL;
    }

    /**
     * Evalúa todas las reglas que contiene el nodo que se pasa como parámetro.
     * En esta implementación solamente se evalúan las reglas para el scope current-enterprise.
     * @param Node			Nodo XML cuyas reglas se quieren evaluar.
     * @return				Devuelve TRUE si todas las reglas son exitosas.
     * @throws Exception
     */
    private EvaluateNodeRules(Node: XmlNode): boolean {
        let rules: XmlNodeList;
        let strScope: string;
        let ent: IXoneObject = null;

        rules = Node.SelectNodes("rule");
        for (let i = 0; i < rules.count(); i++) {// Verificar cada regla
            let rule = rules.get(i);
            strScope = XmlUtils.getNodeAttr(rule, "scope");
            if (strScope.equals("current-enterprise")) {// Empresa
                if (ent == null)
                    ent = this.m_ownerApp.getCompany();
                if (ent == null)
                    return false;		// No tiene empresa
                if (!ent.EvaluateRule(rule)) {// Limpiar el error y retornar
                    return false;
                }// Limpiar el error y retornar
                continue;
            }// Empresa
        }// Verificar cada regla
        return true;
    }
}