<template>
  <teleport v-if="showCamera" to="body">
    <div class="xone-camera">
      <div
        class="xone-camera-container"
        :style="{
          width: `${size - 50}px`,
        }"
      >
        <!-- Camera -->
        <video
          v-if="!isNotSupported && !isVideo && !isPhoto"
          :style="{
            width: `${size - 50}px`,
          }"
          ref="videoElement"
          autoplay
        ></video>
        <!-- Video preview -->
        <video
          v-show="isVideo"
          ref="videoPreviewElement"
          :style="{
            width: `${size - 50}px`,
          }"
          controls
        ></video>
        <!-- Photo preview -->
        <div
          v-show="isPhoto"
          ref="photoPreviewElement"
          :style="{
            width: `${size - 50}px`,
          }"
        ></div>
        <!-- Not supported message -->
        <div style="padding: 50px" v-if="isNotSupported">
          CAMERA UNSUPPORTED IN YOUR BROWSER
        </div>
        <div>
          <div v-if="!isNotSupported && !isVideo && !isPhoto">
            <!-- Photo button -->
            <button class="xone-photo" @click="takePhoto">
              <div><div></div></div>
              <br />
              Photo
            </button>
            <!-- Video button -->
            <button class="xone-video" @click="recordVideo">
              <div><div></div></div>
              <br />
              Video (5 segundos)
            </button>
          </div>
          <div v-if="isVideo || isPhoto">PREVIEW</div>
        </div>
        <!-- Cancel button -->
        <button class="xone-cancel" @click="showCamera = false">Cancel</button>
      </div>
    </div>
  </teleport>
</template>
<script>
import { computed, inject, ref, Ref, watch } from "vue";

import xoneUI from "../../composables/XoneUI";

export default {
  setup() {
    const showCamera = ref(false);

    /**
     * video element
     * @type {Ref<HTMLElement>}
     */
    const videoElement = ref();

    const isPhoto = ref(false);
    const isVideo = ref(false);

    /**
     * photo preview element
     * @type {Ref<HTMLElement>}
     */
    const photoPreviewElement = ref();

    /**
     * video preview element
     * @type {Ref<HTMLElement>}
     */
    const videoPreviewElement = ref();

    /**
     * is not supported
     * @type {Ref<boolean>}
     */
    const isNotSupported = ref(false);

    /**
     * stream
     * @type {MediaStream}
     */
    let stream;

    xoneUI.setStartCameraCallback(() => {
      showCamera.value = true;
      // TODO: devolver la captura, habrá que ver cómo lo vamos a implementar para subir el fichero al servidor
    });

    /**
     * Show / Hide camera
     */
    watch(
      () => showCamera.value,
      async (newValue) => {
        // reset booleans
        isNotSupported.value = false;
        isPhoto.value = false;
        isVideo.value = false;
        // Start camera
        if (newValue) {
          if (!navigator.mediaDevices) {
            isNotSupported.value = true;
            return;
          }
          stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true,
          });
          videoElement.value.srcObject = stream;
        } else {
          // Stop camera
          if (stream) stream.getTracks().forEach((track) => track.stop());
        }
      }
    );

    /**
     * Window Size
     * @type {{containerWidth: Ref<number>, containerHeight: Ref<number>}}
     */
    const { containerWidth, containerHeight } = inject("containerSize");

    const size = computed(() =>
      containerWidth.value < containerHeight.value
        ? containerWidth.value
        : containerHeight.value
    );

    const takePhoto = () => {
      let canvas = document.createElement("canvas");

      canvas.attributes.height = videoElement.value.offsetHeight;
      canvas.attributes.width = videoElement.value.offsetWidth;

      let context = canvas.getContext("2d");

      context.drawImage(videoElement.value, 0, 0, canvas.width, canvas.height);

      context.canvas.toBlob((blob) => {
        blobValue = blob;

        const newImg = document.createElement("img"),
          url = URL.createObjectURL(blob);

        newImg.onload = () => URL.revokeObjectURL(url);

        newImg.src = url;
        newImg.height = videoElement.value.offsetHeight;
        newImg.width = videoElement.value.offsetWidth;

        photoPreviewElement.value.innerHTML = "";
        photoPreviewElement.value.appendChild(newImg);

        canvas = null;
        context = null;

        isVideo.value = false;
        isPhoto.value = true;
      });
    };

    // media recorder
    let mediaRecorder;

    /**
     * video chunks
     * @type {Array}
     */
    let chunks;

    /**
     * blob  (video)
     * @type {Blob}
     */
    let blobValue;

    const recordVideo = () => {
      if (!MediaRecorder) {
        isNotSupported.value = true;
        return;
      }
      chunks = [];

      const options = {
        mimeType: "video/webm;codecs=h264",
      };

      if (!MediaRecorder.isTypeSupported(options.mimeType))
        options.mimeType = "video/webm;codecs=vp8";

      mediaRecorder = new MediaRecorder(stream, options);

      mediaRecorder.start();

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);

      mediaRecorder.onstop = (e) => {
        blobValue = new Blob(chunks, { type: "video/webm" });

        const url = URL.createObjectURL(blobValue);

        videoPreviewElement.value.onload = () => URL.revokeObjectURL(url);

        videoPreviewElement.value.src = url;
        videoPreviewElement.value.autoplay = true;

        isVideo.value = true;
        isPhoto.value = false;
      };

      setTimeout(() => mediaRecorder.stop(), 5000);
    };
    return {
      showCamera,
      videoElement,
      size,
      isNotSupported,
      takePhoto,
      recordVideo,
      photoPreviewElement,
      videoPreviewElement,
      isPhoto,
      isVideo,
    };
  },
};
</script>

<style scoped>
.xone-camera {
  display: flex;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.xone-camera-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  border: 1px solid black;
  animation: zoomIn 0.3s;
}

.xone-video,
.xone-photo {
  margin: 20px;
  background-color: transparent;
  border: none;
}

.xone-photo > div,
.xone-video > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 90px;
  background: darkred;
}

.xone-photo > div > div {
  width: 54px;
  height: 54px;
  border-radius: 90px;
  background: white;
}

.xone-video > div > div {
  width: 54px;
  height: 54px;
  border-radius: 90px;
  background: red;
}

.xone-cancel {
  align-self: flex-end;
  margin: 10px 10px 10px 0;
  padding: 5px 10px;
  border: none;
  color: #3273dc;
  border: 1px #3273dc solid;
  border-radius: 5px;
  background-color: transparent;
  font-size: 16px;
}
</style>