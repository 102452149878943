import { Exception } from "./Exception";

export class XoneValidationException extends Exception {
    public  code: number;

    /**
     *
     */
    constructor(code:number, message: string) {
        super(message);
        this.code=code;
    }
}