// import { type } from "os";
// import {format as ft} from "util";
import {sprintf as pr} from "sprintf-js";

declare global {
  interface String {
      equals(text: string): boolean;
      equalsIgnoreCase(text: string): boolean;
      contains(text: string): boolean;
      compareToIgnoreCase(text:string):number;
      compareTo(text:string):number;
  }

  interface StringConstructor {
    format(...args):string;
  }

  interface Array<T> {
    add(index:number, item: T): void;
    remove(index: T | number):void;
    contains(item:T):boolean;
  }

}

 
 String.prototype.equals = function (text: string): boolean {
   return this.localeCompare(text)===0;
 };

 String.prototype.equalsIgnoreCase = function (text: string): boolean {
  return this.compareToIgnoreCase(text)===0;
};

 String.prototype.contains = function (text: string): boolean {
  return this.indexOf(text)>=0;
};

String.prototype.compareToIgnoreCase = function (text: string): number {
  return this.toLowerCase().localeCompare(text.toLocaleLowerCase());
};

String.prototype.compareTo = function (text: string): number {
  return this.localeCompare(text);
};

String.format = function (text:string,...args): string {
  return pr(text,...args);
  //return ft(text,...args);
};

// function add<T>(this: T[],index:number, item: T) {
//   this.splice(index, 0, item);
// }

// function remove<T>(this: T[], index:number)  {
//      this.splice(index, 1);
// };

// function contains<T>(this: T[], item: T):boolean {
//     return this.indexOf(item)>=0;
// }

Array.prototype.add= function<T>(this: T[],index:number, item: T) {
  this.splice(index, 0, item);
};

Array.prototype.contains= function<T>(this: T[], item: T):boolean {
     return this.indexOf(item)>=0;
};

Array.prototype.remove=function<T>(this: T[], index: T | number)  {
  if (typeof index =='number')
    this.splice(index, 1);
  else
  this.splice(this.indexOf(index as T), 1);
};

// Object.defineProperty(Array.prototype,"contains",{
//     value: function<T>(this: T[],index:number,item:T) {
//         return this.splice(index, 0, item);
//       }
// });


