<template>
  <div
    ref="contentsElement"
    class="xone-contents"
    :class="{ 'xone-gridview': isGridView, 'xone-slideview': isSlideView }"
    :style="{
      height:
        attributes.height === 'auto'
          ? 'auto'
          : (controlHeight && `${controlHeight}px`) || 'auto',
      backgroundColor: attributes.bgColor,
      'grid-template-columns': isGridView && gridTemplateColumns,
      maxHeight: `${fitHeight ? fitHeight : controlHeight}px`,
      maxWidth: attributes.viewMode === 'picturemap' && `${controlWidth}px`,
      overflow: attributes.viewMode === 'picturemap' && 'auto',
    }"
    @scroll="onScroll"
  >
    <!-- Map View -->
    <Map
      v-if="isMapView"
      :xoneDataObject="xoneDataObject"
      :controlHeight="fitHeight ? fitHeight : controlHeight"
      :controlWidth="controlWidth"
      :attributes="attributes"
    ></Map>
    <!-- Calendar View -->
    <Calendar
      v-if="isCalendarView"
      :xoneDataObject="xoneDataObject"
      :controlHeight="fitHeight ? fitHeight : controlHeight"
      :controlWidth="controlWidth"
      :attributes="attributes"
    ></Calendar>
    <!-- Calendar View -->
    <PictureMap
      v-if="isPictureMapView"
      :xoneDataObject="xoneDataObject"
      :controlHeight="fitHeight ? fitHeight : controlHeight"
      :controlWidth="controlWidth"
      :attributes="attributes"
    ></PictureMap>
    <!-- Chart View -->
    <template v-else-if="isChartView">
      <ChartBar
        v-if="
          attributes.viewMode === 'barchart' ||
          attributes.viewMode === '3dbarchart' ||
          attributes.viewMode === 'stackedbarchart' ||
          attributes.viewMode === 'linechart'
        "
        :xoneDataObject="xoneDataObject"
        :controlHeight="fitHeight ? fitHeight : controlHeight"
        :controlWidth="controlWidth"
        :attributes="attributes"
      >
      </ChartBar>
      <ChartPie
        v-if="
          attributes.viewMode === 'piechart' ||
          attributes.viewMode === 'piechart2'
        "
        :xoneDataObject="xoneDataObject"
        :controlHeight="fitHeight ? fitHeight : controlHeight"
        :controlWidth="controlWidth"
        :attributes="attributes"
      >
      </ChartPie>
    </template>
    <!-- Other Contents -->
    <template
      v-else
      v-for="rowInfo in contentsRowsInfo"
      :key="`${breadcumbId}-${attributes.name}-${rowInfo.id}`"
    >
      <ContentsRow
        :rowInfo="rowInfo"
        :controlHeight="fitHeight ? fitHeight : controlHeight"
        :controlWidth="
          isGridView ? controlWidth / attributes.galleryColumns : controlWidth
        "
        :attributes="attributes"
        :isSlideView="isSlideView"
      ></ContentsRow>
    </template>
    <div v-if="isLoaderVisible" class="xone-loader">
      <div></div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  inject,
  onBeforeMount,
  onMounted,
  provide,
  reactive,
  ref,
  Ref,
  ComputedRef,
  watchEffect,
  PropType,
  onUnmounted,
  watch,
  nextTick,
} from "vue";

import ContentsRow from "@/components/propComponents/contentsComponents/ContentsRow";
import Map from "@/components/propComponents/contentsComponents/Map";
import Calendar from "@/components/propComponents/contentsComponents/Calendar";
import ChartBar from "@/components/propComponents/contentsComponents/ChartBar";
import ChartPie from "@/components/propComponents/contentsComponents/ChartPie";
import PictureMap from "@/components/propComponents/contentsComponents/PictureMap";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../composables/appData/core/XoneDataCollection";
import {
  PropAttributes,
  xoneAttributesHandler,
} from "../../composables/XoneAttributesHandler";
import { XoneControl, XoneView } from "../../composables/XoneViewsHandler";

export default {
  name: "Contents",
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     * */
    xoneDataObject: { type: Object, required: true },
    /**
     * attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
    controlHeight: { type: Number, default: 0 },
    controlWidth: { type: Number, default: 0 },
  },
  components: {
    ContentsRow,
    Map,
    ChartBar,
    Calendar,
    ChartPie,
    PictureMap,
  },
  setup(props) {
    /**
     * Show Loader spinner
     * @type {Ref<boolean>}
     */
    const isLoaderVisible = ref(false);

    /**
     * Contents items info (index, size, visibility)...
     * @type {Ref<Array>}
     */
    const contentsRowsInfo = ref([]);

    /**
     * Contents
     * @type {Ref<XoneDataCollection>}
     */
    const contents = ref();
    // provide contents to child components
    provide("contents", contents);

    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");

    // provide item to load to child components
    const nextItemToLoad = ref(null);
    provide("nextItemToLoad", nextItemToLoad);

    /**
     * breadcumbId
     * @type {string}
     */
    const breadcumbId = inject("breadcumbId");

    /**
     * lastBreadcumb
     * @type {ComputedRef<string>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    /**
     * contentsElement
     * @type {Ref<HTMLElement>}
     */
    const contentsElement = ref();

    /**
     * Window Size
     * @type {{containerHeight: Ref<number>}}
     */
    const { containerHeight } = inject("containerSize");

    /**
     * fit height
     * @type {Ref<number>}
     */
    const fitHeight = ref();

    /**
     * Calculate and adjust height to parent container when height attribute is null or auto
     */
    const fitHeightToContainer = async () => {
      if (props.attributes.viewMode === "picturemap") return;
      try {
        await nextTick();
        setTimeout(() => {
          if (
            !contentsElement.value ||
            (props.attributes.height && props.attributes.height !== "auto")
          ) {
            if (fitHeight.value) fitHeight.value = null;
            return;
          }

          let top = contentsElement.value.parentElement.offsetTop;

          fitHeight.value = props.controlHeight - top;
        }, 500);
      } catch {}
    };

    onMounted(() => fitHeightToContainer());

    watch(
      () => containerHeight.value,
      () => {
        fitHeightToContainer();
      }
    );

    /**
     * selectedItem
     * @type {Ref<number>}
     */
    const selectedItem = ref(-1);
    provide("selectedItem", selectedItem);

    //
    // Data

    // rows per page
    const rowsPerPage = 20;

    let isLoadingData = false;
    let isLoadingRowsInfo = false;
    let isCheckingRowVisibility = false;
    let checkRowVisibilityWaiting = false;
    let rowsCount = 0;

    /**
     * Load Rows to show in contents
     */
    const loadRowsInfo = () => {
      if (isLoadingData) return;

      if (contentsRowsInfo.value.length < rowsCount)
        isLoaderVisible.value = true;

      isLoadingRowsInfo = true;
      const init =
        contentsRowsInfo.value.length === 0
          ? 0
          : contentsRowsInfo.value[contentsRowsInfo.value.length - 1].id + 1;
      const end = init + 20;
      for (let i = init; i < end; i++) {
        if (i < rowsCount) {
          contentsRowsInfo.value.push({
            id: i,
            visible: true,
            height: "auto",
            width: "auto",
          });
        }
      }
      isLoadingRowsInfo = false;
      isLoaderVisible.value = false;
    };

    /**
     * Show / Hide elements depending on scroll
     */
    const checkScrollElementsVisibility = (e) => {
      isCheckingRowVisibility = true;
      setTimeout(() => {
        contentsRowsInfo.value.forEach((element) => {
          if (isLoadingData) return;

          /**
           * divElement
           * @type {HTMLElement}
           */
          const divElement = document.getElementById(
            `${props.attributes.name.replace("@", "")}${
              element.id
            }${breadcumbId}`
          );
          if (!divElement) return;

          // Hide elements above scroll
          if (
            divElement.offsetTop +
              divElement.clientHeight +
              window.outerHeight <
            e.target.scrollTop
          ) {
            // set height
            if (element.height === "auto")
              element.height = divElement.clientHeight
                ? divElement.clientHeight + "px"
                : "auto";
            // set width
            if (element.width === "auto")
              element.width = divElement.clientWidth
                ? divElement.clientWidth + "px"
                : "auto";
            // set visibility
            element.visible = false;

            // Hide elements below scroll
          } else if (
            divElement.offsetTop + divElement.clientHeight >
            e.target.scrollTop + e.target.clientHeight + window.outerHeight
          ) {
            // set height
            if (element.height === "auto")
              element.height = divElement.clientHeight
                ? divElement.clientHeight + "px"
                : "auto";
            // set width
            if (element.width === "auto")
              element.width = divElement.clientWidth
                ? divElement.clientWidth + "px"
                : "auto";
            // set visibility
            element.visible = false;

            // Show elements on scroll
          } else if (!element.visible) {
            element.height = "auto";
            element.width = "auto";
            element.visible = true;
          }
          setTimeout(() => {
            isCheckingRowVisibility = false;
            if (checkRowVisibilityWaiting) {
              checkRowVisibilityWaiting = false;
              checkScrollElementsVisibility(e);
            }
          }, 250);
        });
      }, 250);
    };

    /**
     * Check if load rows
     */
    const checkLoadRowsInfo = (e) => {
      if (
        e.target.scrollHeight - e.target.clientHeight - window.outerHeight >=
          e.target.scrollTop ||
        isLoadingData
      )
        return;

      isLoadingRowsInfo = true;

      setTimeout(() => {
        // load rows info
        if (!isLoadingData) loadRowsInfo();

        setTimeout(() => {
          if (!isLoadingRowsInfo) checkLoadRowsInfo(e);
        }, 250);
      }, 250);
    };

    /**
     * On contents scroll
     */
    const onScroll = (e) => {
      if (isLoadingData) return;
      // Check elements visibility in scroll
      if (!isCheckingRowVisibility) checkScrollElementsVisibility(e);
      // Check again when checkScrollElementsVisibility ends
      else if (!checkRowVisibilityWaiting) checkRowVisibilityWaiting = true;
      // Check if we have to load more child components
      if (!isLoadingRowsInfo) checkLoadRowsInfo(e);
    };

    /**
     * LoadAll contents async
     */
    const refresh = async () => {
      isLoadingData = true;
      nextItemToLoad.value = -1;

      // Is not map
      try {
        // load contents
        if (!contents.value)
          contents.value = await props.xoneDataObject.getContents(
            props.attributes.contents
          );

        // Show loader div
        isLoaderVisible.value = true;

        // Reset contents row info
        contentsRowsInfo.value = [];

        // LoadAll
        await contents.value.loadAll(true, { start: 0, length: 200 }); // TODO: cargar datos según scroll

        rowsCount = contents.value.length;

        isLoadingData = false;

        loadRowsInfo();
        nextItemToLoad.value = 0;
      } catch (ex) {
        console.error(ex);
      }
    };

    /**
     * Group Id
     * @type {string}
     */
    const groupId = inject("groupId");

    /**
     * Group active
     * @type {Ref<string>}
     */
    const { activeGroup } = inject("groupHandler");
    let firstGroup;

    onMounted(() => {
      if (
        isMapView.value ||
        isChartView.value ||
        isCalendarView.value ||
        isPictureMapView.value
      )
        return;
      // Add control to view
      const xoneControl = new XoneControl(props.attributes.name, true);
      xoneControl.refresh = refresh;
      xoneView.addControl(xoneControl);
      watchEffect(async () => {
        if (!firstGroup) firstGroup = activeGroup.value;

        if (contents.value || groupId !== activeGroup.value) return;

        await nextTick();

        setTimeout(
          () =>
            // refresh contents
            refresh(),
          firstGroup !== groupId ? 200 : 0 // Vamos a dejar tiempo para que se haga la transicion del grupo antes de empezar a cargar el contents, asi ira mucho mas fluido
        );
      });
    });

    // Clear Contents
    onUnmounted(() => {
      if (contents.value) contents.value.clear();
    });

    //
    // viewmode gridview
    const isGridView = computed(
      () =>
        props.attributes.viewMode === "gridview" &&
        !isNaN(props.attributes.galleryColumns)
    );

    const gridTemplateColumns = computed(() => {
      if (!isGridView.value) return;

      return `repeat(${props.attributes.galleryColumns}, ${
        props.controlWidth / props.attributes.galleryColumns
      }px)`;
    });

    //
    // viewmode mapview

    const isMapView = computed(
      () =>
        props.attributes.viewMode === "mapview" ||
        props.attributes.viewMode === "openstreetmap"
    );

    //
    // viewmode slideview

    const isSlideView = computed(
      () => props.attributes.viewMode === "slideview"
    );

    //
    // viewmode calendar

    const isCalendarView = computed(
      () => props.attributes.viewMode === "calendarview"
    );

    // autoslide
    let autoslideInterval;

    onMounted(() => {
      if (isSlideView.value) {
        if (props.attributes.autoslideDelay) {
          let currentIndex = 0;

          // create interval
          autoslideInterval = setInterval(() => {
            if (rowsCount === 0) return;

            currentIndex += 1;
            if (currentIndex >= rowsCount) currentIndex = 0;

            /**
             * Get element
             * @type {HTMLElement}
             */
            const element = document.getElementById(
              `${props.attributes.name.replace(
                "@",
                ""
              )}${currentIndex}${breadcumbId}`
            );
            if (!element) return;

            // Scroll to element
            contentsElement.value.scrollTo({
              left: element.offsetLeft,
              top: 0,
              behavior: "smooth",
            });
          }, props.attributes.autoslideDelay * 1000);
        }
      }
    });

    // clear autoslide interval
    onUnmounted(() => {
      if (autoslideInterval) clearInterval(autoslideInterval);
    });

    //
    // viewmode chart
    const isChartView = computed(
      () =>
        props.attributes.viewMode &&
        props.attributes.viewMode.toString().contains("chart")
    );

    //
    // viewmode picturemap

    const isPictureMapView = computed(
      () => props.attributes.viewMode === "picturemap"
    );

    return {
      contentsElement,
      fitHeight,
      contentsRowsInfo,
      onScroll,
      breadcumbId,
      isLoaderVisible,
      isGridView,
      gridTemplateColumns,
      isMapView,
      isSlideView,
      isCalendarView,
      isChartView,
      isPictureMapView,
    };
  },
};
</script>

<style scoped>
.xone-contents {
  /* box-sizing: border-box; */
  /* box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12); */
  /* margin: 5px; */
  box-sizing: border-box;
  width: calc(100%);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s;
  animation: fadeIn 0.3s;
  /* scroll-snap-type: y none; */
  /* border: 1px lightgray solid; */
}
.xone-contents div {
  scroll-snap-align: start;
}

.xone-gridview {
  display: grid;
}

.xone-slideview {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.xone-loader {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  grid-column: 1 / -1;
}

.xone-loader div:first-child {
  border-radius: 50%;
  border: 6px solid gray;
  border-left: 6px solid transparent;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>