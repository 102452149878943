export const XoneMessageKeys = {
    SYS_MSG_GENERALFAIL : "SYS_MSG_GENERALFAIL",
    SYS_MSG_DATASRCFAIL : "SYS_MSG_DATASRCFAIL",
    SYS_MSG_FILENOTFOUND : "SYS_MSG_FILENOTFOUND",
    SYS_MSG_FILENOTFOUND_01 : "SYS_MSG_FILENOTFOUND_01",
    SYS_MSG_FORMULAERROR : "SYS_MSG_FORMULAERROR",
    SYS_MSG_COLL_CIRCULARCOLLDEF : "SYS_MSG_COLL_CIRCULARCOLLDEF",
    SYS_MSG_COLL_UNDEFINEDFIELD : "SYS_MSG_COLL_UNDEFINEDFIELD",
    SYS_MSG_COLL_NONAME : "SYS_MSG_COLL_NONAME",
    SYS_MSG_COLL_CANNOTCREATEOBJ : "SYS_MSG_COLL_CANNOTCREATEOBJ",
    SYS_MSG_COLL_SQLPARSEERROR : "SYS_MSG_COLL_SQLPARSEERROR",
    SYS_MSG_COLL_SQLPARSEERROR_01 : "SYS_MSG_COLL_SQLPARSEERROR_01",
    SYS_MSG_COLL_CANNOTQUALIFY : "SYS_MSG_COLL_CANNOTQUALIFY",
    SYS_MSG_COLL_XPROPVALUEFAIL : "SYS_MSG_COLL_XPROPVALUEFAIL",
    SYS_MSG_COLL_PREPARESQLFAIL : "SYS_MSG_COLL_PREPARESQLFAIL",
    SYS_MSG_COLL_COLLCONNOPENERR : "SYS_MSG_COLL_COLLCONNOPENERR",
    SYS_MSG_COLL_LOADALLFAIL_02 : "SYS_MSG_COLL_LOADALLFAIL_02",
    SYS_MSG_COLL_LOADALLFAIL_03 : "SYS_MSG_COLL_LOADALLFAIL_03",
    SYS_MSG_COLL_CREATECLONEFAIL : "SYS_MSG_COLL_CREATECLONEFAIL",
    SYS_MSG_COLL_GETROWCOUNTFAIL : "SYS_MSG_COLL_GETROWCOUNTFAIL",
    SYS_MSG_COLL_EXECUTENODEFAIL : "SYS_MSG_COLL_EXECUTENODEFAIL",
    SYS_MSG_COLL_EMBEDFILTERFAIL : "SYS_MSG_COLL_EMBEDFILTERFAIL",
    SYS_MSG_COLL_LOOKUPOBJFAIL_02 : "SYS_MSG_COLL_LOOKUPOBJFAIL_02",
    SYS_MSG_COLL_LOOKUPOBJFAIL_03 : "SYS_MSG_COLL_LOOKUPOBJFAIL_03",
    SYS_MSG_COLL_EVALMACROFAIL_01 : "SYS_MSG_COLL_EVALMACROFAIL_01",
    SYS_MSG_COLL_EVALMACROFAIL_02 : "SYS_MSG_COLL_EVALMACROFAIL_02",
    SYS_MSG_COLL_FIELDEXISTSFAIL : "SYS_MSG_COLL_FIELDEXISTSFAIL",
    SYS_MSG_COLL_LOADCURRITEMFAIL_01 : "SYS_MSG_COLL_LOADCURRITEMFAIL_01",
    SYS_MSG_OBJ_GENERATEFLDFAIL : "SYS_MSG_OBJ_GENERATEFLDFAIL",
    SYS_MSG_OBJ_SAVEFAIL : "SYS_MSG_OBJ_SAVEFAIL",
    SYS_MSG_OBJ_SAVEFAIL_02 : "SYS_MSG_OBJ_SAVEFAIL_02",
    SYS_MSG_OBJ_DELETEOBJFAIL_01 : "SYS_MSG_OBJ_DELETEOBJFAIL_01",
    SYS_MSG_OBJ_DELETEOBJFAIL_02 : "SYS_MSG_OBJ_DELETEOBJFAIL_02",
    SYS_MSG_OBJ_CREATEFAIL_01 : "SYS_MSG_OBJ_CREATEFAIL_01",
    SYS_MSG_OBJ_CREATEFAIL_02 : "SYS_MSG_OBJ_CREATEFAIL_02",
    SYS_MSG_OBJ_CREATEFAIL_03 : "SYS_MSG_OBJ_CREATEFAIL_03",
    SYS_MSG_OBJ_COPYPROPFAIL : "SYS_MSG_OBJ_COPYPROPFAIL",
    SYS_MSG_OBJ_LINKFAIL_01 : "SYS_MSG_OBJ_LINKFAIL_01",
    SYS_MSG_OBJ_LINKFAIL_02 : "SYS_MSG_OBJ_LINKFAIL_02",
    SYS_MSG_OBJ_CANNOTDELETE : "SYS_MSG_OBJ_CANNOTDELETE",
    SYS_MSG_OBJ_GETNORMALPROPFAIL : "SYS_MSG_OBJ_GETNORMALPROPFAIL",
    SYS_MSG_OBJ_PROCESSVALFAIL : "SYS_MSG_OBJ_PROCESSVALFAIL",
    SYS_MSG_OBJ_NOXLATTARGET : "SYS_MSG_OBJ_NOXLATTARGET",
    SYS_MSG_OBJ_XLATMISSINGFLD : "SYS_MSG_OBJ_XLATMISSINGFLD",
    SYS_MSG_OBJ_EXECUTEMETHODFAIL_01 : "SYS_MSG_OBJ_EXECUTEMETHODFAIL_01",
    SYS_MSG_OBJ_EXECUTEMETHODFAIL_02 : "SYS_MSG_OBJ_EXECUTEMETHODFAIL_02",
    SYS_MSG_OBJ_RESTOREMAPFLDFAIL_01 : "SYS_MSG_OBJ_RESTOREMAPFLDFAIL_01",
    SYS_MSG_OBJ_RESTOREMAPFLDFAIL_02 : "SYS_MSG_OBJ_RESTOREMAPFLDFAIL_02",
    SYS_MSG_OBJ_RESTOREMAPFLDFAIL_03 : "SYS_MSG_OBJ_RESTOREMAPFLDFAIL_03",
    SYS_MSG_OBJ_LOADCONTENTFAIL_01 : "SYS_MSG_OBJ_LOADCONTENTFAIL_01",
    SYS_MSG_OBJ_LOADCONTENTFAIL_02 : "SYS_MSG_OBJ_LOADCONTENTFAIL_02",
    SYS_MSG_OBJ_REPLFLDVALUEMACROFAIL : "SYS_MSG_OBJ_REPLFLDVALUEMACROFAIL",
    SYS_MSG_OBJ_PUTITEMFAIL : "SYS_MSG_OBJ_PUTITEMFAIL",
    SYS_MSG_OBJ_GETFORMULAPROPFAIL_01 : "SYS_MSG_OBJ_GETFORMULAPROPFAIL_01",
    SYS_MSG_OBJ_GETFORMULAPROPFAIL_02 : "SYS_MSG_OBJ_GETFORMULAPROPFAIL_02",
    SYS_MSG_OBJ_GETEXTFORMULAFAIL : "SYS_MSG_OBJ_GETEXTFORMULAFAIL",
    SYS_MSG_OBJ_CHKUNIQFLDFAIL_01 : "SYS_MSG_OBJ_CHKUNIQFLDFAIL_01",
    SYS_MSG_OBJ_CHKUNIQFLDFAIL_02 : "SYS_MSG_OBJ_CHKUNIQFLDFAIL_02",
    SYS_MSG_OBJ_UPDDEPTHFAIL_01 : "SYS_MSG_OBJ_UPDDEPTHFAIL_01",
    SYS_MSG_OBJ_UPDDEPTHFAIL_02 : "SYS_MSG_OBJ_UPDDEPTHFAIL_02",
    SYS_MSG_OBJ_UPDATECNTFAIL : "SYS_MSG_OBJ_UPDATECNTFAIL",
    SYS_MSG_OBJ_SETVALFAIL : "SYS_MSG_OBJ_SETVALFAIL",
    SYS_MSG_OBJ_DOLINKFAIL : "SYS_MSG_OBJ_DOLINKFAIL",
    SYS_MSG_OBJ_SETFLDVALFAIL : "SYS_MSG_OBJ_SETFLDVALFAIL",
    SYS_MSG_OBJ_MAPVALFAIL : "SYS_MSG_OBJ_MAPVALFAIL",
    SYS_MSG_OBJ_EXECCOMPSQLFAIL_01 : "SYS_MSG_OBJ_EXECCOMPSQLFAIL_01",
    SYS_MSG_OBJ_EXECCOMPSQLFAIL_02 : "SYS_MSG_OBJ_EXECCOMPSQLFAIL_02",
    SYS_MSG_OBJ_RULEFAIL : "SYS_MSG_OBJ_RULEFAIL",
    SYS_MSG_OBJ_RULEFAIL_02 : "SYS_MSG_OBJ_RULEFAIL_02",
    SYS_MSG_OBJ_FAILIFNULLFAIL : "SYS_MSG_OBJ_FAILIFNULLFAIL",
    SYS_MSG_OBJ_EOFRULEFAIL : "SYS_MSG_OBJ_EOFRULEFAIL",
    SYS_MSG_OBJ_COMPARENUMBERFAIL : "SYS_MSG_OBJ_COMPARENUMBERFAIL",
    SYS_MSG_OBJ_INCORRECTVALUE : "SYS_MSG_OBJ_INCORRECTVALUE",
    SYS_MSG_CONN_CONNFACTORY : "SYS_MSG_CONN_CONNFACTORY",
    SYS_MSG_CONN_LOADERROR : "SYS_MSG_CONN_LOADERROR",
    SYS_MSG_CONN_RPLLOADERROR : "SYS_MSG_CONN_RPLLOADERROR",
    SYS_MSG_CONN_INCORRECVALUE : "SYS_MSG_CONN_INCORRECVALUE",
    SYS_MSG_CONN_INNERCMDNULL : "SYS_MSG_CONN_INNERCMDNULL",
    SYS_MSG_CONN_INVALIDACTION : "SYS_MSG_CONN_INVALIDACTION",
    SYS_MSG_CONN_CMDNOTINITIALIZED : "SYS_MSG_CONN_CMDNOTINITIALIZED",
    SYS_MSG_CONN_CONNNOTINITIALIZED : "SYS_MSG_CONN_CONNNOTINITIALIZED",
    SYS_MSG_CONN_CLIENTFILENOTFOUND : "SYS_MSG_CONN_CLIENTFILENOTFOUND",
    SYS_MSG_CONN_SQLSYNTAXERROR : "SYS_MSG_CONN_SQLSYNTAXERROR",
    SYS_MSG_CONN_SQLPARSEERROR : "SYS_MSG_CONN_SQLPARSEERROR",
    SYS_MSG_CONN_INVALIDPARAMVAL : "SYS_MSG_CONN_INVALIDPARAMVAL",
    SYS_MSG_CONN_QUEUEINSERTERROR_01 : "SYS_MSG_CONN_QUEUEINSERTERROR_01",
    SYS_MSG_CONN_QUEUEINSERTERROR_02 : "SYS_MSG_CONN_QUEUEINSERTERROR_02",
    SYS_MSG_CONN_INCORRECTPARAM_01 : "SYS_MSG_CONN_INCORRECTPARAM_01",
    SYS_MSG_CONN_INCORRECTPARAM_02 : "SYS_MSG_CONN_INCORRECTPARAM_02",
    SYS_MSG_CONN_INVALIDCONNSTRING : "SYS_MSG_CONN_INVALIDCONNSTRING",
    SYS_MSG_APP_CANNOTCREATEMAINCONN : "SYS_MSG_APP_CANNOTCREATEMAINCONN",
    SYS_MSG_APP_OPENCONNERROR : "SYS_MSG_APP_OPENCONNERROR",
    SYS_MSG_APP_LOGONNOUSERCOLL : "SYS_MSG_APP_LOGONNOUSERCOLL",
    SYS_MSG_APP_LOGONUSERNOTFOUND : "SYS_MSG_APP_LOGONUSERNOTFOUND",
    SYS_MSG_APP_UNKNOWNSYSMACRO : "SYS_MSG_APP_UNKNOWNSYSMACRO",
    SYS_MSG_APP_ONLOGONFAILED : "SYS_MSG_APP_ONLOGONFAILED",
    SYS_MSG_APP_ENTIDLEVELFAILED_01 : "SYS_MSG_APP_ENTIDLEVELFAILED_01",
    SYS_MSG_APP_NOCOMPANYCOLL : "SYS_MSG_APP_NOCOMPANYCOLL",
    SYS_MSG_APP_NOCOMPANY : "SYS_MSG_APP_NOCOMPANY",
    SYS_MSG_XERR_UNKNOWNMETHOD : "SYS_MSG_XERR_UNKNOWNMETHOD",
    SYS_MSG_CTX_STAKUNDERFLOW : "SYS_MSG_CTX_STAKUNDERFLOW",
    SYS_MSG_USR_READONLYFIELD : "SYS_MSG_USR_READONLYFIELD",
    SYS_MSG_SQL_STRIPFIELDERROR : "SYS_MSG_SQL_STRIPFIELDERROR",
    SYS_MSG_SQL_ADDFIELDERROR : "SYS_MSG_SQL_ADDFIELDERROR",
    SYS_MSG_SQL_EMPTYBUFFERERROR : "SYS_MSG_SQL_EMPTYBUFFERERROR",
    SYS_MSG_SQL_INCOMPLETETOKEN : "SYS_MSG_SQL_INCOMPLETETOKEN",
    SYS_MSG_SQL_SYNTAXERROR : "SYS_MSG_SQL_SYNTAXERROR",
    SYS_MSG_DBG_LINKDISCONNECTED : "SYS_MSG_DBG_LINKDISCONNECTED",
    SYS_MSG_VBS_BADPARAMCOUNT : "SYS_MSG_VBS_BADPARAMCOUNT"
}