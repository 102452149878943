import Hashtable from "../Collections/HashMap/Hashtable";
import { XoneDataCollection } from "../core/XoneDataCollection";
import { XoneDataObject } from "../core/XoneDataObject";
import IXmlNode from "../Interfaces/IXmlNode";
import IXmlNodeList from "../Interfaces/IXmlNodeList";
import CXoneNameValuePair from "../Utils/CXoneNameValuePair";
import StringUtils from "../Utils/StringUtils";
import { Utils } from "../Utils/Utils";

export default class ScriptContext {
    /**
     *  Objeto en cuyo contexto se ejecuta el script
     */
    private m_object: XoneDataObject=null;
    /**
     *  Colección en cuyo contexto se ejecuta el script
     */
    private m_coll: XoneDataCollection=null;
    /**
     * A12081701: Mecanismo para pasar parámetros a un nodo de acción.
     * Los parámetros que lleva un script para ponerlos como argumentos en el scope general.
     * 
     */
    private m_lstParams: Hashtable<String, Object>;

    // K10090801:	Carga de includes locales en los nodos de acciones.
    // En esta lista se almacenan los scripts locales del nodo <action> en cuestión
    private m_lstLocalIncludes: Array<any>;

    /**
     * Construye un contexto de colección.
     * @param HostCollection		Colección en cuyo contexto se ejecuta el script.
     */
    constructor(HostCollection: XoneDataCollection | XoneDataObject) {
        if (HostCollection instanceof XoneDataObject) {
            this.m_object = HostCollection
            this.m_coll = (HostCollection as XoneDataObject).getOwnerCollection();
        } else
            this.m_coll = HostCollection;
        // K10090801:	Carga de includes locales en los nodos de acciones.
        this.m_lstLocalIncludes = new Array<any>();
    }

    /**
     * @return		Devuelve el objeto en el cual se ejecuta el script al que se refiere este contexto.
     */
    public getObjectHost(): any {
        return this.m_object;
    }

    /**
     * @return		Devuelve la colección en la cual se ejecuta el script al que se refiere este contexto.
     */
    public getCollectionHost(): XoneDataCollection {
        return this.m_coll;
    }

    // Lista con los nombres de ficheros include de este contexto
    // Devuelve la lista de includes locales.
    // K10090801:	Carga de includes locales en los nodos de acciones.
    // La lista no es de cadenas
    public getLocalIncludes() {
        return this.m_lstLocalIncludes;
    }

    /**
     * A12081701: Mecanismo para pasar parámetros a un nodo de acción.
     * Setea un parámetro para el script cuyo contexto tenemos. Los parámetros se podrán usar dentro del script
     * como variables dentro del scope global.
     * 
     * @param ParamName			Nombre del parámetro. No puede coincidir con ningún valor reservado.
     * @param ParamValue		Valor del parámetro. Cualquier cosa.
     */
    public SetParam(ParamName: string, ParamValue: Object) {
        if (this.m_lstParams == null)
            this.m_lstParams = new Hashtable<String, Object>();
        if (StringUtils.IsEmptyString(ParamName))
            return;
        if (ParamValue != null)
            this.m_lstParams.put(ParamName, ParamValue);
        else
            this.m_lstParams.delete(ParamName);
    }

    /**
     * A12081701: Mecanismo para pasar parámetros a un nodo de acción.
     * Expone la lista de parámetros para poder sacarlos y meterlos en el contexto.
     * 
     * @return La lista de parámetros o NULL si no hay parámetros.
     */
    public GetParams() {
        return this.m_lstParams;
    }

    /**
     * L02102015: Sobrecargar ExtracArguments para poder usar Actionode null
     * K13080701: La ejecución de scripts con argumentos a nivel de colección falta en algunos casos.
     * Dado un contexto de script y una lista de argumentos saca los nombres de parámetros del nodo y
     * coloca los argumentos en el contexto...
     *
     * @param Arguments
     */
    // public void ExtractArguments(Object[] Arguments) {
    //     ExtractArguments(null,Arguments);
    // }

    public ExtractArguments(ActionNode: IXmlNode, ...args): void {
        if (!args)
            return;
        let xna: IXmlNodeList = null;
        if (ActionNode != null)
            xna = ActionNode.SelectNodes("param");
        args.forEach((value, i) => {// Puede haber más parámetros que valores
            let strName = null;
            if (Array.isArray(value)) {
                this.ExtractArguments(ActionNode, ...value);
            } else {
                if (value instanceof CXoneNameValuePair) {// Vienen los dos
                    //let nvp = (CXoneNameValuePair)value;
                    strName = value.Name;
                    value = value.Value;
                }// Vienen los dos
                else {// Sacar del XML
                    if (xna != null && i < xna.count()) {// Tenemos valor
                        let xarg: IXmlNode = xna.get(i);
                        // Sacar el nombre
                        strName = xarg.getAttrValue(Utils.PROP_ATTR_NAME);
                    }// Tenemos valor
                    if (StringUtils.IsEmptyString(strName))
                        strName = String.format("Param%d", i + 1);
                }// Sacar del XML	
                this.SetParam(strName, value);
            }
        });// Puede haber más parámetros que valores
    }

}