import IUserCredentialValidator from "../Interfaces/IUserCredentialValidator";
import HashUtils from "../Utils/HashUtils";
import StringUtils from "../Utils/StringUtils";
import { XoneDataCollection } from "./XoneDataCollection";
import { XoneDataObject } from "./XoneDataObject";

export default class XoneUser extends XoneDataObject implements IUserCredentialValidator{

    /**
     * A11042901: Modificaciones para adicionar nuevos algoritmos de comprobación de claves. Tipo
     * de hash usado para gestionar la clave.
     */
    protected m_strHashType:string;
    /**
     * K13022601: Modificaciones en el manejo de claves de usuario usando hash. El encode también
     * podemos definirlo
     */
    private m_strEncode:string;

    /**
     * Construye un objeto de datos que representa un usuario de aplicación.
     *
     * @param OwnerCollection Colección propietaria de este objeto.
     */
    constructor(OwnerCollection:XoneDataCollection) {
        super(OwnerCollection);
        // A11042901: Modificaciones para adicionar nuevos algoritmos de comprobación de claves.
        // Obtener el modelo de hash que lleva el campo que nos interesa
        try {
            this.m_strHashType = OwnerCollection.FieldPropertyValue("PWD", "hash-type");
            // K13022601: Modificaciones en el manejo de claves de usuario usando hash.
            // El encoding
            if (StringUtils.IsEmptyString(this.m_strEncode = OwnerCollection.FieldPropertyValue("PWD", "encode"))) {
                // Por defecto base64
                this.m_strEncode = "base64";
            }
        } catch (ex) {
            throw ex;
        }
    }

    public ValidateUserCredentials(UserData: Object[]): boolean {
        // Aquí solamente esperamos un parámetro que será la clave o el hash
        let strPwd = StringUtils.SafeToString(UserData[0]);
        let strObjPwd = StringUtils.SafeToString(this.get("PWD"));
        if (StringUtils.IsEmptyString(strPwd)) {
            // Clave vacía
            return StringUtils.IsEmptyString(strObjPwd);
        }
        // A11042901: Modificaciones para adicionar nuevos algoritmos de comprobación de claves.
        if (!StringUtils.IsEmptyString(this.m_strHashType)) {
            /*
             * K13022601: Modificaciones en el manejo de claves de usuario usando hash. El atributo
             * hash-type y encode debe ser case insensitive
             */
            strPwd=HashUtils.createHash(this.m_strHashType,this.m_strEncode,strPwd);
            // this.MessageDigest md = this.getMessageDigest(this.m_strHashType);
            // if (md != null) {
            //     byte[] source = new byte[strPwd.length()];
            //     StringUtils.CopyAsBytes(strPwd, source, 0);
            //     md.update(source);
            //     byte[] btmp = md.digest();
            //     strPwd = StringUtils.encodeHash(btmp, this.m_strEncode);
            // }
        }
        // Ahora comparamos... clave con clave o hash con hash... lo que sea...
        return strObjPwd.equals(strPwd);
    }
    

    // private getMessageDigest(sHashType:string): MessageDigest  {
    //     switch (sHashType.toLowerCase()) {
    //         case "md5":
    //             return MessageDigest.getInstance("MD5");
    //         case "sha1":
    //             return MessageDigest.getInstance("SHA1");
    //         case "sha2":
    //             return MessageDigest.getInstance("SHA2");
    //         case "sha3":
    //             return MessageDigest.getInstance("SHA3");
    //         case "sha256":
    //             return MessageDigest.getInstance("SHA256");
    //         case "sha384":
    //             return MessageDigest.getInstance("SHA384");
    //         case "sha512":
    //             return MessageDigest.getInstance("SHA512");
    //         default:
    //             return null;
    //     }
    // }
}