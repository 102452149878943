import IXmlDocument from "../../Interfaces/IXmlDocument";
import XmlNode from "../JSONImpl/XmlNode";
//import xml from "sax-parser";
import XmlNodeList from "../JSONImpl/XmlNodeList";
import StringBuilder from "../../Utils/StringBuilder";
import TextUtils from "../../Utils/TextUtils";
import XmlAttributes from "../JSONImpl/XmlAttributes";


export default class SaxDocument implements IXmlDocument {
    
    private m_rootNode: XmlNode;
    private  xml = require("sax-parser");
    // private lastNode: XmlNode;
    // private rootNode: XmlNode;
    // private sText: StringBuilder;

    Deserialize(context: any, sPath: string, Source: any, useTranslation: boolean) {
        this.parser.parseString(Source);
        if (this.parser?.m_hndDoc?.rootNode) {
            this.m_rootNode=this.parser.m_hndDoc.rootNode;
        }
     }

    getRootNode(): XmlNode {
        return this.m_rootNode;
    }

    createNode(node: XmlNode, strName: string) {
        throw new Error("Method not implemented.");
    }

    private parser = new this.xml.SaxParser(function(cb) {
        let lastNode: XmlNode;
        //let rootNode: XmlNode;
        let sText: StringBuilder;
        
        cb.onStartDocument(function() {});
        cb.onEndDocument(function() {});
        cb.onStartElementNS(function(localName, attrs, prefix, uri, namespaces) {
            let bIndex=false;
            let bFirstNode = (lastNode == null);
            let attributes = new XmlAttributes(attrs);
            if (attributes.getIndex("ext-file") >= 0) {
                //lastNode = getIncludeNode(localName, attributes.getValue(attributes.getIndex("ext-file")));
            } else {
                bIndex =attributes && attributes.length>0 && !TextUtils.isEmpty(attributes.getValue("name"));
                if (bIndex) {
                    lastNode = new XmlNode(lastNode, localName, attributes, "name", attributes.getValue("name"), /*getEvents(attributes)*/);
                } else {
                    lastNode = new XmlNode(lastNode, localName, attributes);
                }
            }
            if (bFirstNode) {
                this.rootNode = lastNode;
            }
            sText = new StringBuilder();
        });

        cb.onEndElementNS(function(elem, prefix, uri) {
            if (sText != null) {
                lastNode.setText(sText.toString());
                sText = null;
            }
            lastNode = lastNode.getParentNode();
        });

        cb.onCharacters(function(chars) {
            // if (sText.indexOf("Contrase") > 0) {
            //     sText = sText.replace("&gt;", ">");
            // }
            if (sText != null) {
                sText.append(chars);
            }
        });
        cb.onCdata(function(cdata) {
          console.log("<CDATA>" + cdata + "</CDATA>");
        });
        cb.onComment(function(msg) {
          //console.log("<COMMENT>" + msg + "</COMMENT>");
        });
        cb.onWarning(function(msg) {
          console.log("<WARNING>" + msg + "</WARNING>");
        });
        cb.onError(function(msg) {
          console.log("<ERROR>" + JSON.stringify(msg) + "</ERROR>");
        });
      });
}