<template>
  <canvas
    :id="`chart-${breadcumbId}-${attributes.name}`"
    :height="controlHeight && `${controlHeight}px`"
    :width="controlWidth && `${controlWidth}px`"
  ></canvas>
</template>

<script>
import {
  inject,
  onMounted,
  ref,
  watchEffect,
  Ref,
  PropType,
  onUnmounted,
  nextTick,
} from "vue";

import Chart from "chart.js";

import {
  PropAttributes,
  xoneAttributesHandler,
} from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import { hexToRgbA } from "../../../composables/helperFunctions/ChartsHelper";
import { XoneControl, XoneView } from "../../../composables/XoneViewsHandler";

export default {
  name: "ChartBar",
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     * */
    xoneDataObject: { type: Object, required: true },
    /**
     * attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    /**
     * breadcumbId
     * @type {string}
     */
    const breadcumbId = inject("breadcumbId");

    /**
     * Contents
     * @type {Ref<XoneDataCollection>}
     */
    const contents = ref();

    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");

    let ctx;

    let gridLines = false;

    let refreshTimeOut;

    const refresh = async () => {
      if (!ctx) return;

      if (refreshTimeOut) clearTimeout(refreshTimeOut);

      refreshTimeOut = setTimeout(async () => {
        if (!contents.value)
          contents.value = await props.xoneDataObject.getContents(
            props.attributes.contents
          );

        // Load contents data
        await contents.value.loadAll();

        /**
         * contents data
         * @type {Ref<Array<XoneDataObject>>}
         */
        const data = {
          labels: [],
          datasets: [],
        };

        // Fill chart data
        let chartSerieTitle;
        const categoryNames = [];
        const categoryTitles = [];
        const categoryColors = [];
        const barThickness = [];

        contents.value.m_xmlNode.SelectNodes("prop").forEach((e) => {
          // Chart serie title
          if (e.getAttrValue("chart-serie-title") === "true")
            chartSerieTitle = e.getAttrValue("name");

          // Chart value
          if (e.getAttrValue("chart-category") !== "true") return;
          categoryNames.push(e.getAttrValue("name"));
          categoryTitles.push(e.getAttrValue("title"));

          // Chart category color
          categoryColors.push(e.getAttrValue("chart-category-color"));

          // Bar Thickness
          barThickness.push(e.getAttrValue("chart-bar-thickness") || undefined);
        });

        // Refresh data
        data.datasets = [];
        data.labels = [];

        // Create data
        await categoryNames.forEach(async (value, index) => {
          const dataset = { label: categoryTitles[index] };
          dataset.data = [];
          dataset.backgroundColor = [];
          dataset.borderColor = [];
          dataset.borderWidth = 2;
          dataset.barThickness = barThickness[index];

          for (let i = 0; i < contents.value.length; i++) {
            /**
             * rowDataObject
             * @type {XoneDataObject}
             */
            const rowDataObject = await contents.value.get(i);

            // Chart Serie Title
            if (chartSerieTitle && index === 0)
              data.labels.push(rowDataObject[chartSerieTitle]);

            dataset.data.push(rowDataObject[value]);
            dataset.backgroundColor.push(hexToRgbA(categoryColors[index], 0.7));
            dataset.borderColor.push(categoryColors[index]);
          }
          data.datasets.push(dataset);
          if (index === categoryNames.length - 1) {
            createChart(data);
          }
        });
      }, 100);
    };

    let myChart;
    const createChart = async (data) => {
      if (!myChart)
        myChart = new Chart(ctx, {
          type: props.attributes.viewMode === "linechart" ? "line" : "bar",
          data: data,
          options: {
            // animation: { duration: 1000 },
            legend: { display: false },
            elements: {
              point: {
                radius: 2,
              },
            },
            scales: {
              xAxes: [
                {
                  stacked: props.attributes.viewMode === "stackedbarchart",
                  gridLines: { display: false },
                },
              ],
              yAxes: [
                {
                  stacked: props.attributes.viewMode === "stackedbarchart",
                  gridLines: { display: gridLines },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          },
        });
      else {
        if (myChart.data != data) {
          myChart.data = data;
          myChart.update();
        }
      }
    };

    /**
     * Group Id
     * @type {string}
     */
    const groupId = inject("groupId");

    /**
     * Group active
     * @type {Ref<string>}
     */
    const { activeGroup } = inject("groupHandler");

    // Get chart element
    nextTick(() => {
      ctx = document.getElementById(
        `chart-${breadcumbId}-${props.attributes.name}`
      );

      // Add control to view
      const xoneControl = new XoneControl(props.attributes.name, true);
      xoneControl.refresh = refresh;
      xoneView.addControl(xoneControl);
      watchEffect(async () => {
        if (contents.value || groupId !== activeGroup.value) return;
        refresh();
      });
    });

    // Clear contents
    onUnmounted(() => {
      if (contents.value) contents.value.clear();
    });

    return { breadcumbId };
  },
};
</script>

<style scoped>
canvas {
  position: relative;
}
</style>