import { isNull } from "util";
import Calendar from "./Calendar";
import StringUtils from "./StringUtils";
import TextUtils from "./TextUtils";

export default class ObjUtils {


    static IsNothing(Value: any): boolean {
        return Value == null || Value == undefined || Value == 'undefined';
    }

    static IsNumeric(Value: any): boolean {
        return !isNaN(Value);
    }

    static IsFloat(Value: any): boolean {
        if (ObjUtils.IsNothing(Value))
            return false;
        return ObjUtils.IsNumeric(Value) && !Number.isInteger(Value);
    }

    static IsInt(Value: any): boolean {
        if (ObjUtils.IsNothing(Value))
            return false;
        return ObjUtils.IsNumeric(Value) && Number.isInteger(Value);
    }

    static EqualObj(oldVal: any, Value: any) {
        return oldVal === Value;
    }
    static ZeroCalendarTime(dt: Calendar): Calendar {
        dt.getTime().setHours(0);
        dt.getTime().setMinutes(0);
        dt.getTime().setSeconds(0);
        dt.getTime().setMilliseconds(0);
        return dt;
    }

    public static SafeToCalendar(Value: any): Calendar {
        if (Value instanceof Calendar)
            return Value;
        if (Value instanceof Date) {
            return new Calendar(Value as Date);
        }
        return this.SafeToComplexDate(StringUtils.SafeToString(Value));
    }

    public static SafeToDate(Value: any): Date {
        if (Value instanceof Calendar)
            return Value.getTime();
        if (Value instanceof Date) {
            return Value as Date;
        }
        let dt=this.SafeToComplexDate(StringUtils.SafeToString(Value));
        if (dt)
            return dt.getTime();
        return null;
    }

    public static SafeToDateString(Value: any): string {
        if (Value instanceof Calendar)
            return Value.toString();
        if (Value instanceof Date)
            Value.toDateString();
        return StringUtils.SafeToString(Value);
    }

    public static getOnlyTime(Value: Date):string {
        return Value.getHours().toString().padStart(2,"0")+":"+Value.getMinutes().toString().padStart(2,"0")
    }

    public static SafeToHours(Value?: any): string {
        if (!Value)
            return this.getOnlyTime(new Date());
        if (Value instanceof Calendar)
            return this.getOnlyTime(Value.getTime());
        if (Value instanceof Date) {
            return this.getOnlyTime(Value as Date);
        }
        return this.getOnlyTime(this.SafeToComplexDate(StringUtils.SafeToString(Value)).getTime());
    }

    private static getDateSeparator(str: string): string {
        const sep = ["-", "/"];
        for (var s in
            sep) {
            if (str.indexOf(s) > 0) {
                return s;
            }
        }
        return "/";
    }


    private static SafeToComplexDate(sDate: string): Calendar {
        if (TextUtils.isEmpty(sDate)) {
            return null;
        }
        const regex = /\//g;
        sDate = sDate.trim().replace("T", " ");
        let calendar = Calendar.getInstance();
        let sSeparator = this.getDateSeparator(sDate);
        if (sSeparator == "/")
            sDate = sDate.replace(regex, '-');
        return new Calendar(Date.parse(sDate));
        // let sPattern="";
        // if (sDate.indexOf(sSeparator) == 4) {
        //     // Debe ser el formato de SQLite
        //     if (sDate.length <= 10) {
        //         sPattern = "yyyy" + sSeparator + "MM" + sSeparator + "dd";
        //     } else {
        //         sPattern = "yyyy" + sSeparator + "MM" + sSeparator + "dd HH:mm:ss";
        //     }

        //     return new Calendar(Date.parse(sDate));
        // } else {
        //     sPattern = getDateFormat(Locale.getDefault(), sDate, sSeparator);
        //     if (sDate.length() <= 10) {
        //         sPattern = sPattern.substring(0, sPattern.lastIndexOf("y"));
        //     }
        // }
        // SimpleDateFormat formatter = new SimpleDateFormat(sPattern, Locale.US);
        // Date date = unsafeParseDate(formatter, sDate);
        // calendar.setTime(date);
        // return calendar;
    }
    public static NATIVE_TYPE_LONG = 1;
    public static NATIVE_TYPE_INT = 2;
    public static NATIVE_TYPE_BOOL = 3;
    public static NATIVE_TYPE_DOUBLE = 4;
    public static NATIVE_TYPE_FLOAT = 5;
    public static OBJ_TYPE_STRING = 100;
    public static OBJ_TYPE_DATE = 101;
    public static OBJ_TYPE_CALENDAR = 102;
    public static OBJ_TYPE_NULL = 103;
    public static NOT_NATIVE_TYPE = -1;

    /**
     * Dado un objeto, devuelve el marcador de tipo interno.
     *
     * @param Source Objeto cuyo marcador de tipo interno se quiere obtener.
     * @return Devuelve el marcador de tipo del objeto.
     */
    public static GetTypeTag(Source: any): number {
        if (Source == null) {
            return this.OBJ_TYPE_NULL;
        }
        if (typeof Source === 'number' || Source instanceof Number) {
            if (Number.isInteger(Source))
                return this.NATIVE_TYPE_LONG;
            else
                return this.NATIVE_TYPE_DOUBLE
        }
        if (typeof Source === 'boolean' || Source instanceof Boolean) {
            return this.NATIVE_TYPE_BOOL;
        }
        if (typeof Source === 'string' || Source instanceof String) {
            return this.OBJ_TYPE_STRING;
        }
        if (Source instanceof Date) {
            return this.OBJ_TYPE_DATE;
        }
        if (Source instanceof Calendar) {
            return this.OBJ_TYPE_CALENDAR;
        }
        return this.NOT_NATIVE_TYPE;
    }
}