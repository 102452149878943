import IResultSet from "../Interfaces/IResultSet";
import StringUtils from "./StringUtils";
import { Utils } from "./Utils";

export default class DataUtils {

    /**
     * Lee un  valor de un recordset y devuelve la conversión a entero
     *
     * @param DataSource Origen de datos del que se va a leer.
     * @param FieldName  Nombre del campo a leer.
     * @return Devuelve el valor del campo. Si no es numérico posiblemente devuelva cero.
     */
    public static RsReadLong( DataSource:IResultSet, FieldName:string):number {
        let l = 0;
        try {
            l = DataSource.getInt(FieldName);
        } catch (e) {
            // Ignorar esta excepción... puede ser que el campo no exista o algo así...
        }
        return l;
    }


    /**
     * Lee un  valor de un recordset y devuelve la conversión a entero
     *
     * @param DataSource Origen de datos del que se va a leer.
     * @param FieldName  Nombre del campo a leer.
     * @return Devuelve el valor del campo. Si no es numérico posiblemente devuelva cero.
     */
    public static RsReadInt(DataSource:IResultSet, FieldName:string):number {
        return this.RsReadLong(DataSource,FieldName);
    }

    /**
     * A10031101:	Programación del registro de operaciones en la cola para javoso.
     * Esta función se usa para desdoblar la ejecución de SQLs, así que había que programarla.
     * Leer una cadena de un resultset. Emplea mecanismos de seguridad para evitar que se
     * devuelva un nulo.
     *
     * @param DataSource
     * @param FieldName
     * @return
     */
    public static RsReadString(DataSource:IResultSet, FieldName:string):string {
        let str = Utils.EMPTY_STRING;

        try {
            str = DataSource.getString(FieldName);
            str = StringUtils.SafeToString(str);
        } catch (e) {
            e.printStackTrace();
        }
        return str;
    }
}