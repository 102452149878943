import { Exception } from "./Exception";

export default class XoneGenericException extends Exception {
    m_code: number;
    m_extra: string;
    /**
     *
     */
    constructor(code: number, message: string, extra?:string) {
        super(message);
        this.m_code=code;
        this.m_extra=extra;
    }
} 