<template>
  <div class="xone-row"><slot></slot></div>
</template>

<script>
export default {
  name: "Row",
};
</script>

<style scoped>
.xone-row {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  height: auto;
}
</style>