<template>
  <button
    ref="buttonElement"
    :disabled="
      isExecutingOnClick ||
      (objectInfo.isContents && !objectInfo.editInRow) ||
      isDisableEdit
    "
    :style="{
      backgroundColor: attributes.image ? 'transparent' : attributes.bgColor,
      color: isExecutingOnClick ? 'lightgray' : attributes.foreColor,
      backgroundImage: attributes.image && `url(${imgPath})`,
      borderRadius: attributes.borderCornerRadius,
      fontSize: attributes.fontSize && attributes.fontSize,
      justifyContent:
        (attributes.align.row && attributes.align.row) || 'center',
      alignItems:
        (attributes.align.column && attributes.align.column) || 'center',
      opacity: isExecutingOnClick || isDisableEdit ? 0.4 : 1,
      padding: attributes.labelWidth !== '0' && '5px',
    }"
    :class="{
      'no-border': attributes.image || attributes.bgColor,
      noEditInRow: objectInfo.isContents && !objectInfo.editInRow,
      'keep-aspect-ratio': attributes.keepAspectRatio,
    }"
    @click="!noEditInRow && onClick()"
  >
    {{ attributes.labelWidth !== "0" ? attributes.title : "" }}
  </button>
</template>

<script>
import {
  inject,
  ref,
  Ref,
  PropType,
  onMounted,
  watch,
  watchEffect,
  computed,
} from "vue";

import AppDataHandler from "../../composables/AppDataHandler";
import {
  xoneAttributesHandler,
  PropAttributes,
} from "../../composables/XoneAttributesHandler";
import XoneUI from "../../composables/XoneUI";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import { getImagePath } from "../../composables/helperFunctions/ImageHelper";

export default {
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },
    /**
     * attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    // on click method is being executed
    const isExecutingOnClick = ref(false);

    /**
     * on click button
     */
    const onClick = async () => {
      if (isExecutingOnClick.value) return;
      isExecutingOnClick.value = true;

      let method = props.attributes?.method;
      //
      // Execute method
      if (method)
        await xoneAttributesHandler.executeMethod(method, props.xoneDataObject);

      //
      // Execute onclick
      try {
        if (props.attributes?.onClick)
          await props.xoneDataObject.DoRunScriptAsync(
            `let e = { target: '${props.attributes.name}' };
              ${props.attributes.onClick}`
          );
      } catch (ex) {
        console.error(ex);
      }
      //
      // Execute binded event in script
      await xoneAttributesHandler.executeBindedEvent(
        props.xoneDataObject,
        props.attributes,
        "onclick",
        {}
      );

      isExecutingOnClick.value = false;
    };

    /**
     * Check if disableedit
     * @type {ComputedRef<boolean>}
     */
    const isDisableEdit = computed(() => {
      if ((props.attributes.disableEdit ?? "") === "") return false;
      return xoneAttributesHandler.evalFormula(
        props.attributes.disableEdit,
        props.xoneDataObject.model
      );
    });

    /**
     * Contents Info
     * @type {Object}
     */
    const objectInfo = inject("objectInfo");

    //
    // Offset height when scaled

    /**
     * button element
     * @type {Ref<HTMLElement>}
     */
    const buttonElement = ref();

    const fitSizeToContainer = (timeout = 0) => {
      xoneAttributesHandler.fitHeightToContainer(
        props.attributes,
        buttonElement,
        timeout
      );
      xoneAttributesHandler.fitWidthToContainer(
        props.attributes,
        buttonElement,
        timeout
      );
    };

    onMounted(() => fitSizeToContainer());

    /**
     * Last breadcumb in stack
     * @type {ComputedRef<string>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    // Provide breadcumbId info to child components
    const breadcumbId = inject("breadcumbId");

    watchEffect(() => {
      if (breadcumbId === lastBreadcumb.value?.id) fitSizeToContainer();
    });

    /**
     * Window Size
     * @type {{containerHeight: Ref<number>}}
     */
    const { containerHeight, containerWidth } = inject("containerSize");
    watch(
      () => containerHeight.value,
      () => fitSizeToContainer(500)
    );
    watch(
      () => containerWidth.value,
      () => fitSizeToContainer(500)
    );

    // if selected group is current group fit size to container
    /**
     * groupId
     * @type {number}
     */
    const groupId = inject("groupId");

    /**
     * activeGroup
     * @type {Ref<number>}
     */
    const { activeGroup } = inject("groupHandler");

    watch(
      () => activeGroup.value,
      (newValue) => {
        if (groupId !== newValue) return;
        fitSizeToContainer();
      }
    );

    const imgPath = ref(
      props.attributes.image?.includes("icons") ||
        props.attributes.image?.includes("files")
        ? props.attributes.image
        : `/icons/${props.attributes.image}`
    );

    watchEffect(async () => {
      if (props.attributes.image) {
        imgPath.value = await getImagePath(props.attributes.image);
      }
    });

    return {
      buttonElement,
      onClick,
      isDisableEdit,
      objectInfo,
      isExecutingOnClick,
      imgPath,
    };
  },
};
</script>

<style scoped>
/* button */
button {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  align-self: center;
  transition: all 0.3s;

  /* background-size: 100% 100%; */
}

button:after {
  content: "";
  background: lightgray; /*#90ee90;*/
  display: block;
  position: absolute;
  padding: 100%;
  padding-left: 100%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.3s;
}

button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.keep-aspect-ratio {
  background-size: contain;
}

.no-border {
  border: none;
}

.noEditInRow {
  cursor: pointer;
  pointer-events: none;
}
</style>