import ObjUtils from "./ObjUtils";
import StringUtils from "./StringUtils";

export default class NumberUtils {
    static SafeToLong(value: any): number {
        return this.SafeToInt(value);
    }
    static SafeToDouble(str: any) {
		if (!str || str===null)
			return 0;
        return Number.parseFloat(StringUtils.SafeToString(str));
    }
    static SafeToBool(Value: any): boolean {
		if (Value===null)
			return false;
		return StringUtils.ParseBoolValue(Value.toString());
	}
	
	static SafeToInt(str: any, nDefault:number=0): number {
		if (ObjUtils.IsNothing(str))
			return nDefault;
		try {
			let retValue= Number.parseInt(StringUtils.SafeToString(str));
			return Number.isInteger(retValue)?retValue:nDefault;
		} catch(e) {
			console.error(e);
			return nDefault;
		}
	}

} 