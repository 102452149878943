<template>
  <div
    :id="`map-${breadcumbId}`"
    class="xone-map"
    :style="{
      height: controlHeight && `${controlHeight}px`,
      width: controlWidth && `${controlWidth}px`,
    }"
  >
    <div class="xone-map-markers">
      <div class="xone-marker-button">
        <button @click="showLayers = !showLayers"></button>
      </div>
      <div
        class="xone-markers-list"
        v-if="showLayers"
        :style="{ maxHeight: `${controlHeight - 50}px` }"
      >
        <a
          v-for="(layer, index) in keyLayers"
          :key="`map-${index}`"
          @click="changeMapLayer(layer)"
          >{{ layer }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  Ref,
} from "vue";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.min.js";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

import {
  PropAttributes,
  xoneAttributesHandler,
} from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import { getMapLayers } from "../../../composables/helperFunctions/MapHelper";
import { XoneControl, XoneView } from "../../../composables/XoneViewsHandler";
import { generateUniqueId } from "../../../composables/helperFunctions/StringHelper";
import { normalizeWebColor } from "../../../composables/helperFunctions/ColorHelper";

export default {
  name: "Map",
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     * */
    xoneDataObject: { type: Object, required: true },
    /**
     * attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    L.Icon.Default.imagePath = "./assets/";

    const defaultIconUrl = "/assets/marker-icon.png";
    const defaultShadowUrl = "/assets/marker-shadow.png";

    const breadcumbId = inject("breadcumbId");

    // tiles provider for leaflet
    const mapLayers = getMapLayers(L);

    const keyLayers = computed(() =>
      Object.entries(mapLayers)
        .filter(([key, value]) => !value.onlyLabel)
        .map(([key, value]) => key)
    );

    // Map
    let map;

    // Markers
    let markers = [];

    // Lines
    const polyLines = new Map();

    // Polygons
    const polygons = new Map();

    // Routes
    const routes = new Set();

    // polygons
    const polygon = new Map();

    // Draw line
    const drawLine = (name, color, lineStyle, ...pointList) => {
      const points = [];
      for (let i = 0; i < pointList.length; i += 2) {
        points.push(new L.LatLng(pointList[i], pointList[i + 1]));
      }
      let dashArray = null;
      if (lineStyle === "dashed") dashArray = "10, 15";
      else if (lineStyle === "dotted") dashArray = "1, 10";
      // else if (lineStyle === "mixed") dashArray = "10, 5, 1, 10";
      const firstpolyline = new L.Polyline(points, {
        color: normalizeWebColor(color),
        weight: 5,
        opacity: 0.5,
        smoothFactor: 1,
        dashArray,
      });
      if (polyLines.get(name)) name = generateUniqueId();
      polyLines.set(name, firstpolyline);
      firstpolyline.addTo(map);
    };

    // Draw area
    const drawArea = ({ id, data, color, fillcolor, pattern, width }) => {
      const points = [];
      data.forEach((e) => {
        const dataCoords = e.split(",").map((e) => Number(e));
        points.push(new L.LatLng(dataCoords[0], dataCoords[1]));
      });
      let dashArray = null;
      if (pattern === "dashed") dashArray = "10, 15";
      else if (pattern === "dotted") dashArray = "1, 10";

      const polygon = L.polygon(points, {
        color: normalizeWebColor(color),
        fillColor: normalizeWebColor(fillcolor),
        weight: width,
        dashArray,
      }).addTo(map);

      if (polyLines.get(id)) id = generateUniqueId();
      polygons.set(id, polygon);
      // polygon.bindPopup("I am a polygon.");
      // polygon.bindTooltip("I am a polygon.").openTooltip();
    };

    // Draw route
    const drawRoute = (Args) => {
      const route = L.Routing.control({
        waypoints: [
          L.latLng(Args.sourceLatitude, Args.sourceLongitude),
          L.latLng(Args.destinationLatitude, Args.destinationLongitude),
        ],
        routeWhileDragging: false,
        showAlternatives: false,
      });
      routes.add(route);
      route.addTo(map);
    };

    // Remove markers
    const removeMarkers = () => {
      markers.forEach((e) => map.removeLayer(e));
      markers = [];
    };

    // Remove lines
    const clearLines = () => {
      polyLines.forEach((e) => map.removeLayer(e));
      polyLines.clear();
    };

    // Remove lines
    const clearAreas = () => {
      polygons.forEach((e) => map.removeLayer(e));
      polygons.clear();
    };

    /**
     * Contents
     * @type {Ref<XoneDataCollection>}
     */
    const contents = ref();

    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");

    /**
     * contents data
     * @type {Ref<Array<XoneDataObject>>}
     */
    const data = ref([]);

    /**
     * Refresh map
     */
    const refresh = async () => {
      // Load map
      loadMap();

      // Get contents
      if (!contents.value)
        contents.value = await props.xoneDataObject.getContents(
          props.attributes.contents
        );

      // Get coll attributes
      const contentsAttributes = xoneAttributesHandler.getContainerAttributes(
        contents.value.getLayout().attributes
      );

      // Load contents data
      // LoadAll
      await contents.value.loadAll();

      data.value = [];
      for (let i = 0; i < contents.value.length; i++) {
        /**
         * rowDataObject
         * @type {XoneDataObject}
         */
        const rowDataObject = await contents.value.get(i);
        data.value.push(rowDataObject);
        setTimeout(async () => {
          try {
            await rowDataObject.ExecuteNode("before-edit");
          } catch {}
          try {
            await rowDataObject.ExecuteNode("load");
          } catch {}
          try {
            await rowDataObject.ExecuteNode("after-edit");
          } catch {}
        });
      }

      drawMarkers(true);
    };

    /**
     * Refresh map markers
     */
    const drawMarkers = (withFitBounds = false) => {
      // create bounds array
      const bounds = [];
      data.value.forEach((e) => {
        if (!e.LATITUD || !e.LONGITUD) return;

        // let iconUrl = `/assets/marker-icon.png`;
        // TODO: poner el  icono que viene del mappings

        // const icon = L.icon({
        //   iconUrl,
        //   shadowUrl: "/assets/marker-shadow.png",
        //   iconSize: [25, 41],
        //   iconAnchor: [12, 41],
        // });

        // push coordinates into bounds
        bounds.push([e.LATITUD, e.LONGITUD]);
        // Create marker
        const marker = L.marker([e.LATITUD, e.LONGITUD]) //, { icon }
          .addTo(map)
          .on("click", async () => {
            // on click execute selecteditem node
            try {
              await e.ExecuteNode("selecteditem");
            } catch {}
          });
        markers.push(marker);
      });
      // fit bounds
      if (withFitBounds && bounds.length !== 0) map.fitBounds(bounds);
    };

    // Clear layers
    const clearLayers = () =>
      Object.keys(map._layers).forEach((key) =>
        map.removeLayer(map._layers[key])
      );

    // Clear routes
    const clearRoutes = () => routes.forEach((e) => map.removeControl(e));

    const changeMapLayer = (layer) => {
      // Add new layer
      mapLayers[layer].layer.addTo(map);
      // if (mapLayers[layer].noLabel)
      //   mapLayers["CartoDB.PositronOnlyLabels"].layer.addTo(map);

      // Set zoom
      if (map._zoom > mapLayers[layer].layer.options.maxZoom)
        map.setZoom(mapLayers[layer].layer.options.maxZoom);

      // Refresh Markers
      drawMarkers();

      showLayers.value = false;
    };

    /**
     * Load Map
     */
    const loadMap = () => {
      if (!map)
        map = L.map(`map-${breadcumbId}`).setView([43.454518, -3.851194], 2);
      else {
        clearRoutes();
        clearLayers();
      }
      mapLayers["OpenStreetMap.Mapnik"].layer.addTo(map);
    };

    onMounted(() => {
      //
      // Add control to view
      const xoneControl = new XoneControl(props.attributes.name, true);

      // Refresh
      xoneControl.refresh = refresh;
      // Draw Line
      xoneControl.drawLine = drawLine;
      // Clear Area
      xoneControl.clearArea = (Args) => map.removeLayer(polygons.get(Args));
      // Clear all Areas
      xoneControl.clearAllAreas = clearAreas;
      // Clear line
      xoneControl.clearLine = (Args) => map.removeLayer(polyLines.get(Args));
      // Clear all lines
      xoneControl.clearAllLines = clearLines;
      // CLear all routes
      xoneControl.clearAllRoutes = clearRoutes;
      // CLear route
      xoneControl.clearRoute = clearRoutes;
      // Draw Area
      xoneControl.drawArea = drawArea;
      // Draw Route
      xoneControl.drawRoute = drawRoute;
      xoneControl.routeTo = drawRoute;
      // Zoom to
      xoneControl.zoomTo = (...Args) => {
        console.log(Args);
        console.log(map);
        map.zoomControl.setPosition(Args);
      };
      // Zoom to bounds
      xoneControl.zoomToBounds = (Args) =>
        map.fitBounds(Args.map((e) => e.split(",").map((e) => Number(e))));

      xoneView.addControl(xoneControl);

      //
      // Refresh contents
      refresh();
      // console.log("L.Routing", L.Routing);
      // navigator.geolocation.getCurrentPosition(
      //   (pos) => console.log(pos),
      //   (err) => console.error(err),
      //   { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      // );
    });
    onUnmounted(() => {
      if (contents.value) contents.value.clear();
    });

    const showLayers = ref(false);
    return { keyLayers, breadcumbId, showLayers, changeMapLayer };
  },
};
</script>

<style scoped>
.xone-map {
  position: relative;
  height: 100vh;
  width: 100%;
}
.xone-map-markers {
  display: flex;
  position: absolute;
  right: 20px;
  margin-top: 10px;
  z-index: 999;
  pointer-events: all;
  padding: 5px;
}

.xone-marker-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
}

.xone-marker-button button {
  display: block;
  background: url(/assets/layers.png) no-repeat;
  background-position: center;
  padding: 5px;
  border: none;
  width: 36px;
  height: 36px;
  background-size: auto;
}

.xone-markers-list {
  display: flex;
  flex-direction: column;
  animation: slideLeft 0.15s;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  border-radius: 5px;
  margin-left: 5px;

  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
  pointer-events: all;
}

a {
  cursor: pointer;
  background-color: white;
  color: black;
  padding: 10px;
  text-align: justify;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  pointer-events: all;
}

a:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

a:link {
  text-decoration: none;
}
</style>