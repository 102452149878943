export default class BufferedReader {

    private m_pointer;
    private m_lines;

    /**
     *
     */
    constructor(data: string="") {
        this.m_pointer=0;
        this.m_lines=Array.from(data.replace(/\r/gi,"").split('\n'));    
    }

    readLine(): string {
        if (this.m_pointer>=this.m_lines.length)
            return null;
        return this.m_lines[this.m_pointer++];
    }

}