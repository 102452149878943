<template>
  <div
    v-show="isActive && !isDisableVisible"
    :class="[
      'xone-frame',
      attributes.keepAspectRatio && 'keep-aspect-ratio',
      attributes.framebox && 'xone-frame-borders',
    ]"
    :name="attributes.name"
    :style="{
      marginTop: margins.top + 'px',
      marginRight: margins.right + 'px',
      marginBottom: margins.bottom + 'px',
      marginLeft: margins.left + 'px',
      width: controlWidth && `${controlWidth}px`,
      height:
        controlHeight && attributes.node !== 'group' && `${controlHeight}px`,

      backgroundColor: displayScreenThresholds
        ? '#00ff0025'
        : attributes.bgColor,
      border: displayScreenThresholds && '1px solid #ff0000',

      backgroundImage: attributes.image && `url(/icons/${attributes.image})`,
      justifyContent: attributes.align.column,
      borderRadius: attributes.borderCornerRadius,
      overflowY:
        attributes.scroll || attributes.node === 'group' ? 'auto' : 'hidden',
      position: attributes.floating.floating && 'absolute',
      zIndex: attributes.floating.floating && '1',
      top: attributes.floating.floating && floatingTop && floatingTop,
      left: attributes.floating.floating && floatingLeft && floatingLeft,
      animation: attributes.disableVisible && 'fadeIn 0.5s',
    }"
  >
    <!-- Rows of the container -->
    <template v-if="attributes.node === 'group' || !isDisableVisible">
      <Row
        v-for="(row, irows) in rows"
        :key="`${attributes.name}-${irows}`"
        :style="{
          justifyContent: attributes.align.row,
          alignItems: attributes.align.column,
        }"
      >
        <!-- Columns of the Row -->
        <template
          v-for="(column, iColumns) in row"
          :key="`${column.attributes.name}-${iColumns}`"
        >
          <!-- Container -->
          <template
            v-if="
              column.attributes.node === 'frame' ||
              column.attributes.node === 'group'
            "
          >
            <a
              :href="attributes.href"
              target="_blank"
              v-if="attributes.viewMode === 'link'"
            >
              <Container
                :xoneDataObject="xoneDataObject"
                :control="column"
                :containerWidth="controlWidth ? controlWidth : containerWidth"
                :containerHeight="
                  controlHeight ? controlHeight : containerHeight
                "
              ></Container>
            </a>
            <Container
              v-else
              :xoneDataObject="xoneDataObject"
              :control="column"
              :containerWidth="controlWidth ? controlWidth : containerWidth"
              :containerHeight="controlHeight ? controlHeight : containerHeight"
            ></Container>
          </template>
          <!-- Prop -->
          <template v-if="column.attributes.node === 'prop'">
            <a
              v-if="attributes.viewMode === 'link'"
              :href="attributes.href"
              target="_blank"
            >
              <Prop
                :xoneDataObject="xoneDataObject"
                :control="column"
                :containerWidth="controlWidth ? controlWidth : containerWidth"
                :containerHeight="
                  controlHeight ? controlHeight : containerHeight
                "
              ></Prop>
            </a>
            <Prop
              v-else
              :xoneDataObject="xoneDataObject"
              :control="column"
              :containerWidth="controlWidth ? controlWidth : containerWidth"
              :containerHeight="controlHeight ? controlHeight : containerHeight"
            ></Prop>
          </template>
        </template> </Row
    ></template>
  </div>
</template>

<script>
import {
  computed,
  inject,
  onMounted,
  ref,
  Ref,
  ComputedRef,
  watchEffect,
  watch,
  provide,
  nextTick,
} from "vue";
import Row from "@/components/Row";
import Prop from "@/components/Prop";
import {
  xoneAttributesHandler,
  ContainerAttributes,
  Margins,
} from "../composables/XoneAttributesHandler";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";
import { XoneControl, XoneView } from "../composables/XoneViewsHandler";

export default {
  name: "Container",
  components: {
    Row,
    Prop,
  },
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },
    control: { type: Object, required: true },
    containerWidth: { type: Number, default: 0 },
    containerHeight: { type: Number, default: 0 },
    visibilityBit: { type: Number, default: 1 },
  },
  /**
   * Group / Frame control
   */
  setup(props) {
    const displayScreenThresholds = inject("displayScreenThresholds");

    /**
     * Attributes model
     * @type {ComputedRef<ContainerAttributes>}
     */
    const attributesModel = computed(() =>
      xoneAttributesHandler.getContainerAttributes(props.control.attributes)
    );

    /**
     * Reactive Attributes
     * @type {Ref<ContainerAttributes>}
     */
    const attributes = ref({});

    // Observe changes in attributes model and data model with ##FLD_ fields and fetch them to attributes
    xoneAttributesHandler.watchAttributes(
      attributes,
      attributesModel,
      props.xoneDataObject?.model
    );

    // Provide group id
    if (attributes.value.node === "group")
      provide("groupId", attributes.value.id);

    /**
     * Check if disablevisible
     * @type {ComputedRef<boolean>}
     */
    const isDisableVisible = computed(() => {
      // Frame without children
      if (
        attributes.value.node === "frame" &&
        props.control.controls.length === 0
      )
        return true;
      // No disablevisible attribute
      if ((attributes.value.disableVisible ?? "") === "") return false;
      // Eval disablevisible formula
      return xoneAttributesHandler.evalFormula(
        attributes.value.disableVisible,
        props.xoneDataObject?.model
      );
    });

    /**
     * Group active
     * @type {Ref<string>}
     */
    const { activeGroup } = inject("groupHandler");

    /**
     * Is current group active
     * @type {ComputedRef<boolean>}
     */
    const isActive = computed(() => {
      return (
        attributes.value.node !== "group" ||
        attributes.value.fixed ||
        attributes.value.id === activeGroup.value ||
        attributes.value.drawerOrientation ||
        props.visibilityBit === 4
      );
    });

    // Execute onfocus method
    if (attributes.value.node === "group" && attributes.value.onFocus)
      watch(
        () => activeGroup.value,
        async (newValue) => {
          if (newValue !== attributes.value.id) return;

          await xoneAttributesHandler.executeMethod(
            attributes.value.onFocus,
            props.xoneDataObject
          );
        }
      );

    /**
     * Container rows / columns
     * @type {ComputedRef<Array>}
     */
    const rows = computed(() => {
      let rowsArr = [];
      let row = [];
      props.control.controls?.forEach((e) => {
        // Set width and height = 100% if group has only 1 child and it's a frame
        if (
          attributes.value.node === "group" &&
          props.control.controls.length === 1 &&
          e.attributes.node === "frame" &&
          !attributes.value.fixed &&
          !attributes.value.floating?.floating
        ) {
          e.attributes.height = "100%";
          e.attributes.width = "100%";
        }
        const controlAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        // newline true
        if (controlAttributes.newLine) {
          if (row.length !== 0) {
            rowsArr.push(row);
            row = [];
          }
          row.push(e);
        }
        // newline false
        else {
          row.push(e);
        }
      });
      rowsArr.push(row);
      return rowsArr;
    });

    /**
     * Scale Factor
     * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
     */
    const { widthFactor, heightFactor } = inject("scaleFactor");

    /**
     * Calculate width of the current container
     * @type {ComputedRef<number>}
     */
    const controlWidth = computed(() =>
      xoneAttributesHandler.getControlScaled(
        attributes.value.width,
        props.containerWidth,
        widthFactor.value
      )
    );

    /**
     * Calculate height of the current container
     * @type {ComputedRef<number>}
     */
    const controlHeight = computed(() =>
      xoneAttributesHandler.getControlScaled(
        attributes.value.height,
        props.containerHeight,
        heightFactor.value
      )
    );

    /**
     * Margins of the current container
     * @type {ComputedRef<Margins>}
     */
    const margins = computed(() => ({
      top: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.top,
        props.containerHeight,
        heightFactor.value
      ),
      left: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.left,
        props.containerWidth,
        widthFactor.value
      ),
      bottom: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.bottom,
        props.containerHeight,
        heightFactor.value
      ),
      right: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.right,
        props.containerWidth,
        widthFactor.value
      ),
    }));

    /**
     * calculate floating top
     * @type {ComputedRef<string>}
     */
    const floatingTop = computed(() =>
      xoneAttributesHandler.getScaledPosition(
        attributes.value.floating.top,
        heightFactor.value
      )
    );

    /**
     * calculate floating leeft
     * @type {ComputedRef<string>}
     */
    const floatingLeft = computed(() =>
      xoneAttributesHandler.getScaledPosition(
        attributes.value.floating.left,
        widthFactor.value
      )
    );

    //
    // Handler refresh
    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");

    onMounted(() => {
      // Add control to view
      const xoneControl = new XoneControl(attributes.value.name);
      xoneControl.refresh = () =>
        props.control.controls.forEach((e) => {
          if (xoneView[e.attributes.name]?.refresh)
            xoneView[e.attributes.name].refresh();
        });
      xoneView.addControl(xoneControl);
    });

    return {
      displayScreenThresholds,
      attributes,
      isActive,
      rows,
      controlWidth,
      controlHeight,
      margins,
      isDisableVisible,
      floatingTop,
      floatingLeft,
    };
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

.xone-frame {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: contain; */
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* transition: width 0.3s, height 0.3s; */
  box-sizing: border-box;
}

.xone-frame-borders {
  border: 1px solid gray;
  border-radius: 5px;
}

.keep-aspect-ratio {
  background-size: contain;
}

.tabcontent {
  height: auto;
  width: auto;
  flex-shrink: 1;
  flex-grow: 1;
}
</style>