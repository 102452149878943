import { timeStamp } from "console";

export class XoneError {
	private m_code: number;
	private m_description: string;
	private m_failedSql: string;
	
	clear() {

	}

	getDescription() {
		return this.m_description;
	}

	getFailedSql() {
		return this.m_failedSql;
	}

	setFailedSql(failedSql:string) {
		this.m_failedSql=failedSql;
	}

	getNumber():number {
		return this.m_code;
	}

	setNumber(code:number) {
		this.m_code=code;
	}

	setDescription(description: string) {
		this.m_description=description;
	}


}