import Locale from "../core/Localization/Locale";
import NullPointerException from "../Exceptions/NullPointerException";
import Calendar from "./Calendar";
import NumberUtils from "./NUmberUtils";
import ObjUtils from "./ObjUtils";
import StringUtils from "./StringUtils";

export default class ObjectDeveloper {
    // Field descriptor #55 I
    public static ONESECOND = 1000;

    // Field descriptor #55 I
    public static ONEMINUTE = 60000;

    // Field descriptor #55 I
    public static ONEHOUR = 3600000;

    // Field descriptor #55 I
    public static ONEDAY = 86400000;

    // Field descriptor #55 I
    public static ONEWEEK = 604800000;

    // Field descriptor #47 J
    public static ONEMONTH = 2419200000;

    // Field descriptor #47 J
    public static ONEYEAR = 31536000000;

    /**
     * Devuelve una cadena con el valor de un objeto desarrollado
     *
     * @param Value      Valor que se quiere desarrollar
     * @param Datemask   Máscara de fecha con la que se quiere formatear.
     * @param ForceNulls TRUE para forzar un valor NULL en caso de que el objeto sea nulo
     * @return Devuelve una cadena con el objeto desarrollado listo para poner en un filtro o un SQL.
     */
    public DevelopObjectValue(Value: any, Datemask: string = "ymd", ForceNulls: boolean = true): string {
        let str: string, strDateSep: string, strAMPM: string;
        let strMask = Datemask;
        let dtTime: Calendar;
        let n: number;
        //
        // Buscar la máscara por defecto
        // K10052602:	Poner como máscara de fecha por defecto la de SQLite.
        if (0 >= strMask.length) {
            strMask = "ymd";
        }
        //
        // Convertir el nombre del tipo y desarrollar esto...
        let vt = ObjUtils.GetTypeTag(Value);
        switch (vt) {
            case ObjUtils.OBJ_TYPE_DATE:
            case ObjUtils.OBJ_TYPE_CALENDAR:
                // Fecha
                dtTime = ObjUtils.SafeToCalendar(Value);
                if (dtTime == null) {
                    throw new NullPointerException("DevelopObjectValue(): Date value is null");
                }
                if (strMask.substring(0, 2).equals("##")) {// Separador
                    // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                    strDateSep = strMask.substring(2, 3);
                    strMask = strMask.substring(3, strMask.length);
                }// Separador
                else {
                    strDateSep = "'";
                }
                switch (strMask) {
                    case "mm/dd/yyyy":
                    case "mdy": // MDY
                        // F10052601:	Modificaciones en las funciones con meses (zero based)
                        str = String.format("%s%02d/%02d/%04d %02d:%02d:%02d%s", strDateSep, dtTime.get(Calendar.MONTH) , dtTime.get(Calendar.DAY_OF_MONTH), dtTime.get(Calendar.YEAR), dtTime.get(Calendar.HOUR_OF_DAY), dtTime.get(Calendar.MINUTE), dtTime.get(Calendar.SECOND), strDateSep);
                        break;
                    case "dd/mm/yyyy":
                    case "dmy": // DMY
                        // F10052601:	Modificaciones en las funciones con meses (zero based)
                        str = String.format("%s%02d/%02d/%04d %02d:%02d:%02d%s", strDateSep, dtTime.get(Calendar.DAY_OF_MONTH), dtTime.get(Calendar.MONTH) , dtTime.get(Calendar.YEAR), dtTime.get(Calendar.HOUR_OF_DAY), dtTime.get(Calendar.MINUTE), dtTime.get(Calendar.SECOND), strDateSep);
                        break;
                    case "yyyy/mm/dd":
                    case "ymd": // YMD
                        // F10052601:	Modificaciones en las funciones con meses (zero based)
                        // F11041506: 	Cambiar el campo de calendario usado para formatear fechas y horas.
                        //str = `${strDateSep}${dtTime.get(Calendar.YEAR).toString()}-${(dtTime.get(Calendar.MONTH) ).toString().padStart(2,'0')}-${dtTime.get(Calendar.DAY_OF_MONTH).toString().padStart(2,'0')} ${dtTime.get(Calendar.HOUR_OF_DAY).toString().padStart(2,'0')}:${dtTime.get(Calendar.MINUTE).toString().padStart(2,'0')}:${dtTime.get(Calendar.SECOND).toString().padStart(2,'0')}${strDateSep}`;  String.format("%s%04d-%02d-%02d %02d:%02d:%02d%s", strDateSep, dtTime.get(Calendar.YEAR), dtTime.get(Calendar.MONTH) , dtTime.get(Calendar.DAY_OF_MONTH), dtTime.get(Calendar.HOUR_OF_DAY), dtTime.get(Calendar.MINUTE), dtTime.get(Calendar.SECOND), strDateSep);
                        str = String.format("%s%04d-%02d-%02d %02d:%02d:%02d%s", strDateSep, dtTime.get(Calendar.YEAR), dtTime.get(Calendar.MONTH) , dtTime.get(Calendar.DAY_OF_MONTH), dtTime.get(Calendar.HOUR_OF_DAY), dtTime.get(Calendar.MINUTE), dtTime.get(Calendar.SECOND), strDateSep);
                        break;
                    default: // Máscara
                        if (strMask.contains("dmy")) {// Orden dmy
                            // F10052601:	Modificaciones en las funciones con meses (zero based)
                            str = String.format("%s%02d/%02d/%04d ##TIME##%s", strDateSep, dtTime.get(Calendar.DAY_OF_MONTH), dtTime.get(Calendar.MONTH) , dtTime.get(Calendar.YEAR), strDateSep);
                            strMask = StringUtils.Replace(strMask, "dmy", str);
                        }// Orden dmy
                        if (strMask.contains("mdy")) {// Orden mdy
                            // F10052601:	Modificaciones en las funciones con meses (zero based)
                            str = String.format("%s%02d/%02d/%04d ##TIME##%s", strDateSep, dtTime.get(Calendar.MONTH) , dtTime.get(Calendar.DAY_OF_MONTH), dtTime.get(Calendar.YEAR), strDateSep);
                            strMask = StringUtils.Replace(strMask, "mdy", str);
                        }// Orden mdy
                        if (strMask.contains("ymd")) {// Orden ymd
                            // F10052601:	Modificaciones en las funciones con meses (zero based)
                            str = String.format("%s%04d-%02d-%02d ##TIME##%s", strDateSep, dtTime.get(Calendar.YEAR), dtTime.get(Calendar.MONTH) , dtTime.get(Calendar.DAY_OF_MONTH), strDateSep);
                            strMask = StringUtils.Replace(strMask, "ymd", str);
                        }// Orden ymd
                        //
                        // Comprobar el tipo de hora que lleva
                        n = dtTime.get(Calendar.HOUR);
                        if (strMask.contains("##PM##")) {// Ajustar las horas y eso
                            switch (n) {// Ver las horas
                                case 0:
                                    n = 12;
                                    strAMPM = "PM";
                                    break;
                                case 13:
                                case 14:
                                case 15:
                                case 16:
                                case 17:
                                case 18:
                                case 19:
                                case 20:
                                case 21:
                                case 22:
                                case 23:
                                case 24:
                                    n -= 12;
                                    strAMPM = "PM";
                                    break;
                                default:
                                    strAMPM = "AM";
                                    break;
                            }// Ver las horas
                            strMask = StringUtils.Replace(strMask, "##PM##", strAMPM);
                        }// Ajustar las horas y eso
                        else {
                            strAMPM = "24";
                        }
                        if (strAMPM.length > 0) {// Formatear
                            str = String.format("%02d:%02d:%02d", n, dtTime.get(Calendar.MINUTE), dtTime.get(Calendar.SECOND));
                            ////str =NumberUtilities.toString(n, 10) +":" + NumberUtilities.toString(dtTime.get(Calendar.MINUTE), 10, 2) + ":";
                            ////str +=NumberUtilities.toString(dtTime.get(Calendar.SECOND), 10, 2);
                        }// Formatear
                        else {
                            str = "";
                        }
                        strMask = StringUtils.Replace(strMask, "##TIME##", str);
                        str = strMask;
                        break;
                }
                break;
            case ObjUtils.NATIVE_TYPE_INT:
            case ObjUtils.NATIVE_TYPE_LONG:
                str = Value.toString();
                break;
            case ObjUtils.NATIVE_TYPE_BOOL:
                str = NumberUtils.SafeToBool(Value) ? "-1" : "0";
                break;
            case ObjUtils.OBJ_TYPE_NULL:
                str = "NULL";
                break;
            case ObjUtils.NATIVE_TYPE_DOUBLE:
            case ObjUtils.NATIVE_TYPE_FLOAT:
                str = Value.toString();
                if (str.contains(",")) {
                    str = StringUtils.Replace(str, ",", ".");
                }
                break;
            case ObjUtils.OBJ_TYPE_STRING:
                // Las cadenas de longitud cero serán nulos, dijo el señor... 	
                str = Value.toString();
                if (StringUtils.IsEmptyString(str) && ForceNulls) {
                    str = "NULL";
                } else {// Quitar las comillas simples
                    if (str.contains("\'")) {
                        str = StringUtils.Replace(str, "'", "''");
                    }
                    str = "'" + str + "'";
                }// Quitar las comillas simples
                break;
            default:
                str = "NULL";
                break;
        }
        return str;
    }
}