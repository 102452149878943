<template>
  <div
    :id="`${attributes.name.replace('@', '')}${rowInfo.id}${breadcumbId}`"
    :class="['xone-contents-row', isSlideView && 'xone-slidesnap']"
  >
    <Container
      v-if="isRowLoaded && control && xoneDataObject && rowInfo.visible"
      :xoneDataObject="xoneDataObject"
      :control="control"
      :containerHeight="controlHeight"
      :containerWidth="controlWidth"
      :visibilityBit="4"
      @click="onContentsRowClick"
      :style="{
        backgroundColor: backgroundColor,
        cursor: !attributes.editInRow && !attributes.locked && 'pointer',
      }"
    ></Container>
    <div
      v-if="!isRowLoaded || !control || !xoneDataObject || !rowInfo.visible"
      :style="{
        width: rowInfo.width && rowInfo.width,
        height: rowInfo.height && rowInfo.height,
      }"
    ></div>
  </div>
</template>

<script>
import {
  computed,
  inject,
  onMounted,
  provide,
  ref,
  Ref,
  PropType,
  watchEffect,
  ComputedRef,
  nextTick,
} from "vue";

import AppDataHandler from "../../../composables/AppDataHandler";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import {
  xoneAttributesHandler,
  PropAttributes,
  ContainerAttributes,
} from "../../../composables/XoneAttributesHandler";

export default {
  name: "ContentsRow",

  props: {
    rowInfo: { type: Object, required: true },
    controlHeight: { type: Number, default: 0 },
    /**
     * attributes
     * @type {PropType<PropAttributes>}
     */
    attributes: { type: Object, required: true },
    controlWidth: { type: Number, default: 0 },
    isSlideView: { type: Boolean, default: false },
  },

  setup(props) {
    const isRowLoaded = ref(false);

    /**
     * Contents
     * @type {Ref<XoneDataCollection>}
     */
    const contents = inject("contents");

    /**
     * XoneDataObject
     * @type {Ref<XoneDataObject>}
     */
    const xoneDataObject = ref(null);

    /**
     * DataModel
     * @type {Ref<Object>}
     */
    let dataModel = ref(null);

    /**
     * Layout
     * @type {Ref<Object>}
     */
    const layout = ref(null);

    /**
     * Control attributes and childs layout
     * @type {ComputedRef<Array>}
     */
    const control = computed(() =>
      layout.value?.controls?.find((e) => {
        const containerAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        return (
          containerAttributes.node === "group" &&
          !containerAttributes.fixed &&
          !containerAttributes.drawerOrientation
        );
      })
    );

    /**
     * Contents coll attributes
     * @type {ComputedRef<ContainerAttributes>}
     */
    const contentsAttributes = computed(() =>
      xoneAttributesHandler.getContainerAttributes(
        contents.value?.getLayout().attributes
      )
    );

    /**
     * selectedItem
     * @type {Ref<number>}
     */
    const selectedItem = inject("selectedItem");

    let isOnContentsRowClickExecuting = false;
    /**
     * On Click contents row
     */
    const onContentsRowClick = () => {
      // is locked
      if (props.attributes.editInRow || props.attributes.locked) return;

      if (isOnContentsRowClickExecuting) return;

      isOnContentsRowClickExecuting = true;
      (async () => {
        // execute selecteditem node
        try {
          if (await xoneDataObject.value.ExecuteNode("selecteditem")) {
            isOnContentsRowClickExecuting = false;
            selectedItem.value = props.rowInfo.id;
            return;
          }
        } catch {}
        // open edit view
        AppDataHandler.pushXoneDataObject(xoneDataObject.value);
        isOnContentsRowClickExecuting = false;
      })();
    };

    /**
     * Load contents row async
     */
    const doLoadAsync = async () => {
      isRowLoaded.value = false;

      xoneDataObject.value = await contents.value.get(props.rowInfo.id);

      if (nextItemToLoad.value !== props.rowInfo.id || !xoneDataObject.value)
        return (nextItemToLoad.value = null);

      nextItemToLoad.value++;

      // DataModel
      dataModel = ref(xoneDataObject.value.model);
      xoneDataObject.value.model = dataModel.value;
      // Layout
      layout.value = xoneDataObject.value.getLayout(4);
      xoneDataObject.value.setLayout(layout.value);

      setTimeout(async () => {
        try {
          await xoneDataObject.value.ExecuteNode("before-edit");
        } catch {}
        try {
          await xoneDataObject.value.ExecuteNode("load");
        } catch {}
        isRowLoaded.value = true;
        try {
          await xoneDataObject.value.ExecuteNode("after-edit");
        } catch {}
      });

      await nextTick();
      isRowLoaded.value = true;
    };

    /**
     * breadcumbId
     * @type {string}
     */
    const breadcumbId = inject("breadcumbId");

    /**
     * lastBreadcumb
     * @type {ComputedRef<string>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    //
    // Load / Refresh Item
    const nextItemToLoad = inject("nextItemToLoad");
    onMounted(() => {
      watchEffect(() => {
        if (nextItemToLoad.value === props.rowInfo.id) doLoadAsync();
      });
    });

    // Provide contents info
    provide("objectInfo", {
      isContents: true,
      editInRow: props.attributes.editInRow,
      autosave: props.attributes.autosave,
    });

    let colorView;
    contents.value.m_xmlNode.SelectNodes("prop").forEach((e) => {
      if (e.getAttrValue("colorview") === "true")
        colorView = e.getAttrValue("name");
      if (e.getAttrValue("cell-selected-bgcolor") === "true")
        colorView = e.getAttrValue("name");
    });

    // Row background color
    const backgroundColor = computed(() => {
      if (colorView && xoneDataObject.value)
        return xoneDataObject.value[colorView];
      else if (
        props.rowInfo.id === selectedItem.value &&
        contentsAttributes.value.cellSelectedBgColor
      )
        return contentsAttributes.value.cellSelectedBgColor;
      else
        return Number(props.rowInfo.id) % 2 === 0
          ? contentsAttributes.value.cellEvenColor
          : contentsAttributes.value.cellOddColor;
    });

    return {
      isRowLoaded,
      control,
      contentsAttributes,
      xoneDataObject,
      onContentsRowClick,
      breadcumbId,
      backgroundColor,
    };
  },
};
</script>

<style>
.xone-contents-row {
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  animation: fadeIn 0.3s;
}

.xone-slidesnap {
  scroll-snap-align: center;
}

.xone-contents-row-basic {
  cursor: pointer;
}

.xone-contents-row-basic:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.12);
}
</style>