import Hashtable from "../../Collections/HashMap/Hashtable";
import { Exception } from "../../Exceptions/Exception";
import IXmlDocument from "../../Interfaces/IXmlDocument";
import IXoneAttributes from "../../Interfaces/IXoneAttributes";
import StringUtils from "../../Utils/StringUtils";
import TextUtils from "../../Utils/TextUtils";
import { Utils } from "../../Utils/Utils";
import XmlAttributes from "./XmlAttributes";
import XmlNodeList from "./XmlNodeList";


export default class XmlNode {

    private serialVersionUID = 5009966220209540301;
    protected m_strName: string;
    protected m_strText: string;
    protected m_children: Array<XmlNode> ;
    protected m_childrenNodes: Hashtable<String, XmlNode> ;
    // Luis: Utilizaremos estas caches para no perder la velocidad qeu tenia que el mapping fuera lineal
    // y tanto los props, frames contents y elementos de primer nivel para encontrarlos pronto
    protected m_cachePropNodes: Hashtable<String, XmlNode>;
    protected m_cacheContainersNodes: Hashtable<String, XmlNode>;
    // Los de primer nivel los pondre mas adelante
    protected m_cacheOtherNodes: Hashtable<String, XmlNode>;
    protected  m_parent:XmlNode;
    protected m_allChildren: XmlNodeList;
    protected m_attrs: IXoneAttributes;
    protected lstEvents: Array<String>;
    //protected m_doc:IXmlDocument;

    

    constructor( parentNode: XmlNode, nodeName: string, attrs: IXoneAttributes, defaultAttr?: string, defaultAttrValue?: string, events?: Array<String> ) {
        //this.m_doc = doc;
        this.m_children = new Array<XmlNode>();
        this.m_childrenNodes = new Hashtable<string,XmlNode>();
        this.m_strName = nodeName;
        this.m_parent = parentNode;
        this.m_attrs= attrs || new XmlAttributes();
        if (parentNode != null) {
            parentNode.addChild(this, nodeName, defaultAttr, defaultAttrValue);
        }
        this.lstEvents = events;
    }
    

    /**
     * A13081301: Funcionalidad para manejar XML a nivel de script y modificar estructuras en runtime.
     * Construye un nodo XML a partir de otro similar. Copia los hijos, los atributos y el texto.
     *
     * @param Source
     * @param ParentNode
     */
    // public XmlNode(IXmlDocument doc, XmlNode Source, parent: XmlNodeNode) {
    //     m_doc = doc;
    //     m_parent = ParentNode;
    //     m_strName = Source.getName();
    //     m_childrenNodes = new Hashtable<string,XmlNode>();
    //     ArrayList<XmlNode> children = Source.getChildren();
    //     if (children != null) {
    //         // Copiar los hijos
    //         m_children = new ArrayList<>();
    //         for (int i = 0; i < children.size(); i++) {
    //             XmlNode n = children.get(i);
    //             XmlNode nc = new XmlNode(doc, n, this);
    //             String name = n.getName();
    //             if (n.attrExists(Utils.PROP_ATTR_NAME) && (Utils.COLL_COLL.equals(name) || Utils.PROP_ATTR_GROUP.equals(name) || Utils.PROP_NAME.equals(name) || Utils.PROP_ATTR_FRAME.equals(name) || Utils.PROP_ATTR_CONTENTNAME.equals(name))) {
    //                 addChild(nc, name, Utils.PROP_ATTR_NAME, n.getAttrValue(Utils.PROP_ATTR_NAME));
    //             } else {
    //                 addChild(nc, name, null, null);
    //             }
    //         }
    //     }
    //     m_attrs = new XoneAttributesImpl((XoneAttributesImpl) Source.getAttrs());
    //     m_strText = Source.getText();
    // }

    // public XmlNode(IXmlDocument doc, parent: XmlNodeNode, String name) {
    //     m_doc = doc;
    //     //m_node =SourceNode;
    //     m_children = new ArrayList<>();
    //     //m_attributes =new Hashtable<String,String> ();
    //     m_childrenNodes = new Hashtable<string,XmlNode>();
    //     if (ParentNode != null) {
    //         ParentNode.addChild(this, name, null, null);
    //     }
    //     m_strName = name;
    //     m_parent = ParentNode;
    // }
    
    private AddPropToRoot(parent: XmlNode, node: XmlNode, nodeName: string, defaultAttr: string, defaultAttrValue: string):void {
        if (parent == null) {
            return;
        }
        if (StringUtils.areEquals(parent.getName(),Utils.COLL_COLL)) {
            parent.addFastChild(node, nodeName, defaultAttr, defaultAttrValue);
        } else {
            const tmp:XmlNode = parent.getParentNode();
            if (tmp != null) {
                this.AddPropToRoot(tmp, node, nodeName, defaultAttr, defaultAttrValue);
            }
        }
    }

    
    
    public get attrs() : any {
        return this.m_attrs;
    }
    
    public addChild(node: XmlNode, nodeName: string, defaultAttr: string, defaultAttrValue: string):void {
        if (!TextUtils.isEmpty(defaultAttr) && !TextUtils.isEmpty(defaultAttrValue)) {
           this. m_childrenNodes.put(Utils.formatKey(nodeName, defaultAttr, defaultAttrValue), node);
            if (nodeName.equals(Utils.PROP_NAME) || nodeName.equals(Utils.PROP_ATTR_FRAME) || nodeName.equals(Utils.PROP_ATTR_CONTENTNAME)) {
                this.AddPropToRoot(this, node, nodeName, defaultAttr, defaultAttrValue);
            }
        } else {
            const name: string = this.getName();
            if (Utils.COLL_COLL.equals(name) || (Utils.PROP_ATTR_GROUP.equals(name) && !(nodeName.equals(Utils.PROP_NAME) || nodeName.equals(Utils.PROP_ATTR_FRAME) || nodeName.equals(Utils.PROP_ATTR_CONTENTNAME)))) {
                this.AddPropToRoot(this, node, nodeName, defaultAttr, defaultAttrValue);
            }
        }
        this.m_children.push(node);
    }

    
    public  addFastChild(node: XmlNode,  nodeName: string, defaultAttr: string, defaultAttrValue: string): void {
        switch (nodeName) {
            case Utils.PROP_NAME:
                if (this.m_cachePropNodes == null) {
                    this.m_cachePropNodes = new Hashtable<string,XmlNode>();
                }
                this.m_cachePropNodes.put(Utils.formatKey(nodeName, defaultAttr, defaultAttrValue), node);
                break;
            case Utils.PROP_ATTR_FRAME:
            case Utils.PROP_ATTR_CONTENTNAME:
                if (this.m_cacheContainersNodes == null) {
                    this.m_cacheContainersNodes = new Hashtable<string,XmlNode>();
                }
                this.m_cacheContainersNodes.put(Utils.formatKey(nodeName, defaultAttr, defaultAttrValue), node);
                break;
            default:
                if (this.m_cacheOtherNodes == null) {
                    this.m_cacheOtherNodes = new Hashtable<string,XmlNode>();
                }
                this.m_cacheOtherNodes.put(nodeName, node);
                break;
        }
    }

    
    public getName(): string {
        return this.m_strName;
    }

    public setName(value): void {
        this.m_strName = value;
    }

    
    public getText(): string {
        return this.m_strText;
    }

    
    
    public getBooleanText(bDefault: boolean):boolean {
        return StringUtils.ParseBoolValue(this.m_strText, bDefault);
    }

    
    public setText(value: string):void {
        this.m_strText = value;
    }

    
    public getAttributes():IXoneAttributes {
        return this.m_attrs;
    }

    // Tag: L0610201502 Si tiene eventos en los atributos se devuelve la lista
    
    public getEvents():Array<String> {
        return this.lstEvents;
    }

    
    public  getChildren(): Array<XmlNode> {
        return this.m_children;
    }

    
    public getDocument():IXmlDocument {
        throw new Exception("Not implement")
    }


    public getAttrValue(sAttrName: string, sDefault: string=""): string {
        const sValue: string = this.m_attrs.getValue(sAttrName);
        if (sValue == null) {
            return sDefault;
        }
        return sValue;
    }

    
    public setAttrValue(AttrName: string, value: string): void {
        this.m_attrs.setValue(AttrName, value);
    }

    
    public deleteAttribute(AttrName: string): void {
        this.m_attrs.deleteAttribute(AttrName);
    }

    // Luis: Funcion comun para detectar condiciones rapidas para encontrar la cache
    private CheckCacheConditions(map: any,  NodeName: string, ...cacheNames: string[]):boolean {
        if (map == null) {
            return false;
        }
        if (map.isEmpty()) {
            return false;
        }
        if (cacheNames.length == 1) {
            return NodeName.equals(cacheNames[0]);
        } else if (cacheNames.length > 1) {
            return cacheNames.indexOf(NodeName) >= 0;
        }
        return false;
    }

    // Luis: Funcion comun para detectar condiciones rapidas para encontrar la cache
    private CheckIfCacheExists(map: any,  sKey: string): boolean {
        if (map == null) {
            return false;
        }
        if (map.isEmpty()) {
            return false;
        }
        return map.containsKey(sKey);
    }

    public getParentNode():XmlNode {
        return this.m_parent;
    }

    
    public setParentNode(parentNode: XmlNode): void {
        this.m_parent = parentNode;
    }

    
    
    public  getChildNodes(): XmlNodeList {
        if (this.m_allChildren != null) {
            return this.m_allChildren;
        }
        // Si no, la creamos ahora
        this.m_allChildren = new XmlNodeList();
        for (let i = 0; i < this.m_children.length; i++) {// Comprobar cada nombre
            let node: XmlNode = this.m_children[i];
            this.m_allChildren.addNode(node);
        }// Comprobar cada nombre
        return this.m_allChildren;
    }

    
    public hasChildNodes():boolean {
        try {
            if (this.m_allChildren != null && this.m_allChildren.count() > 0) {
                return true;
            }
            if (this.m_children != null && this.m_children.length > 0) {
                return true;
            }
            return false;
        } catch (ex) {
            ex.printStackTrace();
            return false;
        }
    }


    /**
     * Devuelve TRUE si el atributo cuyo nombre se pasa como par�metro existe en la lista de atributos del nodo.
     *
     * @param AttrName Nobmre del atributo cuya existencia se quiere comprobar
     * @return Devuelve TRUE si existe el atributo.
     */
    
    public attrExists(AttrName: string):boolean {
        return this.m_attrs.getIndex(AttrName) >= 0;
    }


    /**
     * @param m_attrs the m_attrs to set
     */
    
    public  setAttrs(m_attrs: IXoneAttributes):void {
        this.m_attrs = m_attrs;
    }

    /**
     * @return the m_attrs
     */
    
    public getAttrs():IXoneAttributes {
        return this.m_attrs;
    }

    /**
     * A13081301: Funcionalidad para manejar XML a nivel de script y modificar estructuras en runtime.
     *
     * @return
     */
    public getXml(): string {
        return null;
    }

    /**
     * A13081301: Funcionalidad para manejar XML a nivel de script y modificar estructuras en runtime.
     * Inserta un nodo antes del que se diga como par�metro...
     *
     * @param NewChild Nodo a insertar
     * @param Before   Nodo delante del que se inserta. NULL para agregar al final.
     */
    
    public InsertBefore(NewChild:XmlNode, Before:XmlNode): void {
        this.addToCache(NewChild);
        const idx = this.m_children.indexOf(Before);
        if (idx == -1) {
            this. m_children.push(NewChild);
        } else {
            this.m_children.add(idx, NewChild);
        }
    }

    /**
     * A0206201401: Luis. Funcionalidad para manejar XML a nivel de script y modificar estructuras
     * en runtime. Inserta un nodo antes del que se diga como parámetro...
     *
     * @param NewChild Nodo a insertar
     * @param After    Nodo detras del que se inserta. NULL para agregar al final.
     */
    
    public  InsertAfter( NewChild:XmlNode, After: XmlNode ): void {
        this.addToCache(NewChild);
        let idx = this.m_children.indexOf(After);
        if (idx == -1) {
            this.m_children.push(NewChild);
        } else {
            this.m_children.add(idx + 1, NewChild);
        }
    }

    public addToCache(child:XmlNode): void {
        const nodeName: string = child.getName();
        if (child.attrExists(Utils.PROP_ATTR_NAME)) {
            const defaultAttr: string = Utils.PROP_ATTR_NAME;
            const defaultAttrValue: string = child.getAttrValue(Utils.PROP_ATTR_NAME);
            if ((Utils.COLL_COLL.equals(nodeName) || Utils.PROP_ATTR_GROUP.equals(nodeName) || Utils.PROP_NAME.equals(nodeName) || Utils.PROP_ATTR_FRAME.equals(nodeName) || Utils.PROP_ATTR_CONTENTNAME.equals(nodeName))) {
                if (!TextUtils.isEmpty(defaultAttr) && !TextUtils.isEmpty(defaultAttrValue)) {
                    this.m_childrenNodes.put(Utils.formatKey(nodeName, defaultAttr, defaultAttrValue), child);
                    if (nodeName.equals(Utils.PROP_NAME) || nodeName.equals(Utils.PROP_ATTR_FRAME) || nodeName.equals(Utils.PROP_ATTR_CONTENTNAME)) {
                        this.AddPropToRoot(this, child, nodeName, defaultAttr, defaultAttrValue);
                    }
                } else {
                    const name = this.getName();
                    if (Utils.COLL_COLL.equals(name) || (Utils.PROP_ATTR_GROUP.equals(name) && !(nodeName.equals(Utils.PROP_NAME) || nodeName.equals(Utils.PROP_ATTR_FRAME) || nodeName.equals(Utils.PROP_ATTR_CONTENTNAME)))) {
                        this.AddPropToRoot(this, child, nodeName, defaultAttr, defaultAttrValue);
                    }
                }
            }
        }
    }

    /**
     * A0206201402: Luis. Funcionalidad para manejar XML a nivel de script y modificar estructuras
     * en runtime. Borra un nodo de las listas
     *
     * @param child Nodo a borrar
     */
    
    public Remove(child: XmlNode): boolean{
        try {
            const idx = this.m_children.indexOf(child);
            if (idx > -1) {
                this.m_children.remove(idx);
                this.m_childrenNodes.entrySet().forEach(entry => {
                    if (entry[1]==child) {
                        this.m_childrenNodes.delete(entry[0]);
                    }
                });
                return true;
            }
            return false;
        } catch (e) {
            e.printStackTrace();
            return false;
        }

    }

    /**
     * A11121601: Implementación del picaíto en los XML de las maquinarias modernas. Reemplaza un
     * hijo en la lista de este nodo por el que se pase.
     *
     * @param Original Nodo que se quiere reemplazar.
     * @param NewNode  Nuevo nodo para poner en el lugar del original.
     */
    
    public replaceChild( Original: XmlNode, NewNode:XmlNode ): void {
        if (this.m_children != null) {// Reemplazar
            const nIndex = this.m_children.indexOf(Original);
            if (nIndex >= 0) {// Est�
                this.m_children.add(nIndex, NewNode);
                this.Remove(Original);
            }// Est�
        }// Reemplazar
        if (this.m_childrenNodes != null) {// Buscar y sustituir
            this.m_childrenNodes.clear();
            //
            // TODO PROGRAMAR ESTO
            // Si est� en la lista organizada esta tenemos que quitarlo...
            //
        }// Buscar y sustituir
    }

    public clone(): XmlNode {
        return new XmlNode( this, null,null,null,null);
    }

    public describeContents(): number {
        return 0;
    }

    public selectNodes(NodeName: string, AttrName?: string, AttrValue?: string, Exist?: boolean): XmlNodeList {
        return this.SelectNodes(NodeName,AttrName,AttrValue,Exist);
    }

    public SelectNodes(NodeName: string, AttrName?: string, AttrValue?: string, Exist?: boolean): XmlNodeList {
        // Si tiene valor, es el de toda la vida
        if (!TextUtils.isEmpty(AttrValue)) {
            return this.SelectNodesInt3(NodeName, AttrName, AttrValue);
        }
        if (this.CheckCacheConditions(this.m_cachePropNodes, NodeName, Utils.PROP_NAME)) {
            return this.SelectNodesHashExist(this.m_cachePropNodes, NodeName, AttrName, Exist);
        }
        if (this.CheckCacheConditions(this.m_cacheContainersNodes, NodeName, Utils.PROP_ATTR_CONTENTNAME, Utils.PROP_ATTR_FRAME)) {
            return this.SelectNodesHashExist(this.m_cacheContainersNodes, NodeName, AttrName, Exist);
        }
        //
        // Crear la lista
        const list = new XmlNodeList();
        for (let i = 0; i < this.m_children.length; i++) {// Comprobar cada nombre
            const node: XmlNode = this.m_children[i];
            if (node.getName().equals(NodeName)) {// Al menos el nombre lo tiene
                const strAttr = node.getAttrValue(AttrName);
                if (TextUtils.isEmpty(strAttr)) {// No existe
                    if (Exist) {
                        continue;
                    }
                }// No existe
                else {// Existe
                    if (!Exist) {
                        continue;
                    }
                }// Existe
                // A la lista
                list.addNode(node);
            }// Al menos el nombre lo tiene
        }// Comprobar cada nombre
        // Devolver la lista en cuesti�n
        return list;
    }


    private SelectNodesInt1(NodeName: string): XmlNodeList {
        // Como hemos hecho una cache de props y frames pues lo mejor es buscar ahi
        if (this.CheckCacheConditions(this.m_cachePropNodes, NodeName, Utils.PROP_NAME)) {
            return new XmlNodeList(this.m_cachePropNodes.values());
        }
        if (this.CheckCacheConditions(this.m_cacheContainersNodes, NodeName, Utils.PROP_ATTR_CONTENTNAME, Utils.PROP_ATTR_FRAME)) {
            return this.SelectNodesHash2(this.m_cacheContainersNodes, NodeName);
        }
        const list = new XmlNodeList();
        // Ahora buscar en los hijos
        for (let i = 0; i < this.m_children.length; i++) {// Comprobar cada nombre
            const node: XmlNode = this.m_children[i];
            const localName = node.getName();
            if (TextUtils.equals(localName, NodeName)) {
                list.addNode(node);
            }
        }// Comprobar cada nombre
        return list;
    }

    private  SelectNodesHash2(nodeList: Hashtable<String, XmlNode> , NodeName: string):XmlNodeList {
        const list = new XmlNodeList();
        nodeList.values().forEach(node=> {
            const localName = node.getName();
            if (TextUtils.equals(localName, NodeName)) {
                list.addNode(node);
            }
        });
        return list;
    }

    
    
    private SelectNodesHash4(nodeList:Hashtable<String, XmlNode> , NodeName: string, AttrName: string, AttrValue: string):XmlNodeList {
        const list = new XmlNodeList();
        nodeList.values().forEach(node=> {
            if (node.getName().equals(NodeName)) {// Al menos el nombre lo tiene
                const strAttr = node.getAttrValue(AttrName);
                if (TextUtils.equals(strAttr, AttrValue)) {
                    list.addNode(node);
                }
            }// Al menos el nombre lo tiene
        });// Comprobar cada nombre
        // No aparece
        return list;
    }

    
    
    private SelectNodesInt3(NodeName: string, AttrName: string, AttrValue: string): XmlNodeList {
        if (this.CheckCacheConditions(this.m_cachePropNodes, NodeName, Utils.PROP_NAME)) {
            return this.SelectNodesHash4(this.m_cachePropNodes, NodeName, AttrName, AttrValue);
        }
        if (this.CheckCacheConditions(this.m_cacheContainersNodes, NodeName, Utils.PROP_ATTR_CONTENTNAME, Utils.PROP_ATTR_FRAME)) {
            return this.SelectNodesHash4(this.m_cacheContainersNodes, NodeName, AttrName, AttrValue);
        }
        const list = new XmlNodeList();
        for (let i = 0; i < this.m_children.length; i++) {// Comprobar cada nombre
            const node: XmlNode = this.m_children[i];
            if (node.getName().equals(NodeName)) {// Al menos el nombre lo tiene
                const strAttr = node.getAttrValue(AttrName);
                if (TextUtils.equals(strAttr, AttrValue)) {
                    list.addNode(node);
                }
            }// Al menos el nombre lo tiene
        }// Comprobar cada nombre
        // No aparece
        return list;
    }

    /**
     * Sobrecarga que permite buscar nodos con X atributo independientemente del valor de este.
     *
     * @param NodeName
     * @param AttrName
     * @return
     */
    
    
    private SelectNodesStr2(NodeName: string, AttrName: string): XmlNodeList {
        return this.SelectNodes(NodeName, AttrName, null, true);
    }
    
    /**
     * Equivalente de SelecNodes con la opci�n de poder filtrar por la existencia o no existencia de un atributo determinado
     * pasando NULL como valor del atributo
     *
     * @param NodeName  Nombre de los nodos que van a la lista.
     * @param AttrName  Atributo por el que se quiere comparar o cuya existencia se quiere verificar.
     * @param AttrValue Valor del atributo para comparaci�n. NULL si lo que se quiere es verificar existencia.
     * @param Exist     Si AttrValue es NULL, TRUE para retornar nodos con el atributo, FALSE para retornar nodos sin el atributo.
     * @return Devuelve la lista con los nodos que cumplen la condici�n.
     */
    
    private SelectNodesHashExist(nodeList: Hashtable<String, XmlNode> , NodeName: string, AttrName: string,  Exist: boolean):XmlNodeList {
        //
        // Crear la lista
        const list = new XmlNodeList();
        nodeList.values().forEach(node=> {
            if (node.getName().equals(NodeName)) {// Al menos el nombre lo tiene
                const strAttr = node.getAttrValue(AttrName);
                if (TextUtils.isEmpty(strAttr)) {// No existe
                    if (Exist) {
                        return;
                    }
                }// No existe
                else {// Existe
                    if (!Exist) {
                        return;
                    }
                }// Existe
                // A la lista
                list.addNode(node);
            }// Al menos el nombre lo tiene
        });// Comprobar cada nombre
        // Devolver la lista en cuesti�n
        return list;
    }

    public selectSingleNode(NodeName: string, AttrName?: string, AttrValue?: string | boolean): XmlNode {
        return this.SelectSingleNode(NodeName,AttrName,AttrValue);
    }

    public SelectSingleNode(NodeName: string, AttrName?: string, AttrValue?: string | boolean): XmlNode {
        if (AttrName==null)
            return this.SelectSingleNodeInt1(NodeName);
        if (AttrValue==null)
            return this.SelectSingleNodeIntExist(NodeName,AttrName,true);
        if (typeof AttrValue === 'boolean')
            return this.SelectSingleNodeIntExist(NodeName,AttrName,AttrValue);
        return this.SelectSingleNodeInt3(NodeName,AttrName,AttrValue);
    }
    

    private SelectSingleNodeInt1(NodeName: string): XmlNode {
        // Como hemos hecho una cache de props y frames pues lo mejor es buscar ahi
        if (this.CheckCacheConditions(this.m_cachePropNodes, NodeName, Utils.PROP_NAME)) {
            return this.m_cachePropNodes.values()[0];
        }
        if (this.CheckCacheConditions(this.m_cacheContainersNodes, NodeName, Utils.PROP_ATTR_CONTENTNAME, Utils.PROP_ATTR_FRAME)) {
            return this.m_cacheContainersNodes.values()[0];
        }
        if (this.m_cacheOtherNodes != null) {
            if (!this.m_cacheOtherNodes.isEmpty() && this.m_cacheOtherNodes.containsKey(NodeName)) {
                return this.m_cacheOtherNodes.get(NodeName);
            }
        }
        // Ahora buscar en los hijos
        for (let i = 0; i < this.m_children.length; i++) {// Comprobar cada nombre
            let node: XmlNode = this.m_children[i];
            if (TextUtils.equals(node.getName(), NodeName)) {
                return node;
            }
            if (Utils.PROP_ATTR_GROUP.equals(node.getName())) { // Vamos a buscar al menos en el primer nivel del group porque antes se permitia
                node = node.SelectSingleNode(NodeName);
                if (node != null) {
                    return node;
                }
            }
        }// Comprobar cada nombre
        return null;
    }

    private SelectSingleNodeHash4(nodeList:Hashtable<String, XmlNode> , NodeName: string, AttrName: string, AttrValue:string): XmlNode {
        nodeList.values().forEach(node=> {
            const localName = node.getName();
            if (TextUtils.equals(localName, NodeName)) {
                if (TextUtils.equals(node.getAttrValue(AttrName), AttrValue)) {
                    return node;
                }
            }
        });
        return null;
    }

    private SelectSingleNodeInt3(NodeName: string, AttrName: string, AttrValue: string):XmlNode {
        let node: XmlNode = null;
        if (TextUtils.equals(Utils.PROP_ATTR_NAME, AttrName)) {
            // Como hemos hecho una cache de props y frames pues lo mejor es buscar ahi
            const sKey = Utils.formatKey(NodeName, AttrName, AttrValue);
            if (this.CheckCacheConditions(this.m_cachePropNodes, NodeName, Utils.PROP_NAME) && this.CheckIfCacheExists(this.m_cachePropNodes, sKey)) {
                return this.m_cachePropNodes.get(sKey);
            }
            if (this.CheckCacheConditions(this.m_cacheContainersNodes, NodeName, Utils.PROP_ATTR_CONTENTNAME, Utils.PROP_ATTR_FRAME) && this.CheckIfCacheExists(this.m_cacheContainersNodes, sKey)) {
                return this.m_cacheContainersNodes.get(sKey);
            }
            node = this.m_childrenNodes.get(sKey);
        } else if (this.CheckCacheConditions(this.m_cachePropNodes, NodeName, Utils.PROP_NAME)) { // Agregado soporte para buscar un nodo por un atributo
            node = this.SelectSingleNodeHash4(this.m_cachePropNodes, NodeName, AttrName, AttrValue);
        } else if (this.CheckCacheConditions(this.m_cacheContainersNodes, NodeName, Utils.PROP_ATTR_CONTENTNAME, Utils.PROP_ATTR_FRAME)) {
            node = this.SelectSingleNodeHash4(this.m_cacheContainersNodes, NodeName, AttrName, AttrValue);
        }
        if (node != null) {
            return node;
        }
        this.m_children.forEach( node=> {// Comprobar cada nombre
            const localName = node.getName();
            if (localName.equals(NodeName)) {// Al menos el nombre lo tiene
                const strAttr = node.getAttrValue(AttrName);
                if (TextUtils.equals(strAttr, AttrValue)) {
                    return node;
                }
            }// Al menos el nombre lo tiene
        });// Comprobar cada nombre
        // No aparece
        return null;
    }

    private SelectSingleNodeHashExist(nodeList: Hashtable<String, XmlNode> , NodeName: string, AttrName: string,  Exists: boolean): XmlNode {
        nodeList.values().forEach(node=> {
            const localName = node.getName();
            if (localName.equals(NodeName)) {// Al menos el nombre lo tiene
                const strAttr = node.getAttrValue(AttrName);
                if (Exists && !TextUtils.isEmpty(strAttr)) {
                    return node;
                } else if (!Exists && TextUtils.isEmpty(strAttr)) {
                    return node;
                }
            }// Al menos el nombre lo tiene
        });// Comprobar cada nombre
        // No aparece
        return null;
    }

    // A10100703:	Funci�n para pedir un nodo del fichero XML de la aplicaci�n.
    // Esto hace falta para poner el GetNode a nivel de XoneApplication
    
    
    private SelectSingleNodeIntExist(NodeName: string, AttrName: string, Exists:boolean ):XmlNode {
        if (this.CheckCacheConditions(this.m_cachePropNodes, NodeName, Utils.PROP_NAME)) {
            return this.SelectSingleNodeHashExist(this.m_cachePropNodes, NodeName, AttrName, Exists);
        }
        if (this.CheckCacheConditions(this.m_cacheContainersNodes, NodeName, Utils.PROP_ATTR_CONTENTNAME, Utils.PROP_ATTR_FRAME)) {
            return this.SelectSingleNodeHashExist(this.m_cacheContainersNodes, NodeName, AttrName, Exists);
        }
        this.m_children.forEach(node => {// Comprobar cada nombre
            const localName = node.getName();
            if (localName.equals(NodeName)) {// Al menos el nombre lo tiene
                const strAttr = node.getAttrValue(AttrName);
                if (Exists && !TextUtils.isEmpty(strAttr)) {
                    return node;
                } else if (!Exists && TextUtils.isEmpty(strAttr)) {
                    return node;
                }
            }// Al menos el nombre lo tiene
        });// Comprobar cada nombre
        // No aparece
        return null;
    }

    
    
    public toJSON() {
        const nodesName="childs";
        let result=<any>{};
        result[this.getName()]=<any>{};
        result[this.getName()]["attributes"]=this.m_attrs.toJSON();
        if (this.m_children.length>0) {
            result[this.getName()][nodesName]=[];
            this.m_children.forEach(item=>result[this.getName()][nodesName].push(item.toJSON()));
        }
        return result;
    }

    // protected XmlNode(Parcel parcel) {
    //     m_strName = parcel.readString();
    //     m_strText = parcel.readString();
    //     Field fCreator = WrapReflection.SafeGetField(this.getClass(), "CREATOR");
    //     Creator<XmlNode> creator = WrapReflection.SafeStaticInvokeGetField(fCreator);
    //     m_children = new ArrayList<>();
    //     parcel.readTypedList(m_children, creator);
    //     m_childrenNodes = new Hashtable<string,XmlNode>();
    //     String[] val = parcel.createStringArray();
    //     m_allChildren = null;
    //     int i = 0;
    //     for (node: XmlNode : m_children) {
    //         node.setParentNode(this);
    //         if (val != null && i < val.length && !TextUtils.isEmpty(val[i])) {
    //             String[] spl = val[i].split("##");
    //             if (node.getName().equals(spl[1]) && node.attrExists(spl[2]) && node.getAttrValue(spl[2]).equals(spl[3])) {
    //                 m_childrenNodes.put(val[i], node);
    //                 i++;
    //             }
    //         }
    //     }
    //     m_attrs = parcel.readParcelable(XoneAttributesImpl.class.getClassLoader());
    //     lstEvents = parcel.createStringArrayList();
    // }

    
    

    
    // public void writeToParcel(Parcel parcel, int nFlags) {
    //     parcel.writeString(m_strName);
    //     parcel.writeString(m_strText);
    //     parcel.writeTypedList(m_children);
    //     parcel.writeStringArray(m_childrenNodes.keySet().toArray(new String[0]));
    //     parcel.writeParcelable(m_attrs, nFlags);
    //     if (lstEvents != null) {
    //         parcel.writeStringList(new ArrayList<>(lstEvents));
    //     } else {
    //         parcel.writeStringList(new ArrayList<>());
    //     }
    // }

    // public static final Creator<XmlNode> CREATOR = new Creator<XmlNode>() {

        
    //     public XmlNode createFromParcel(Parcel source) {
    //         return new XmlNode(source);
    //     }

        
    //     public XmlNode[] newArray(int size) {
    //         return new XmlNode[size];
    //     }
    // };

    /**
     * A13081301: Funcionalidad para manejar XML a nivel de script y modificar estructuras en runtime.
     * Copia el nodo y devuelve el nuevo...
     */
    
    
    
}