<template>
  <div class="xone-layout">
    <!-- <div class="xone-navbar"></div>
    <div class="xone-sidebar"></div> -->
    <div
      class="xone-coll"
      :style="{ width: `${containerWidth}px`, height: `${containerHeight}px` }"
    >
      <router-view />
      <SnackBar />
      <Toast />
      <Loader />
      <MsgBox />
      <Camera />
      <Url />
    </div>
    <!-- <div class="xone-footer"></div> -->
  </div>
</template>

<script>
import Toast from "@/components/uiComponents/Toast";
import SnackBar from "@/components/uiComponents/SnackBar";
import Loader from "@/components/uiComponents/Loader";
import MsgBox from "@/components/uiComponents/MsgBox";
import Camera from "@/components/uiComponents/Camera";
import Url from "@/components/uiComponents/Url";

import {
  provide,
  ref,
  Ref,
  ComputedRef,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from "vue";
import { XoneApplication } from "./composables/appData/core/XoneApplication";
import { FileManagerOnline } from "./composables/appData/Manager/FileManager";
import AppDataHandler from "./composables/AppDataHandler";
import XoneAppHandler from "./composables/XoneAppHandler";
import { useRouter } from "vue-router";
import xoneUI from "./composables/XoneUI";
// import { __SCRIPT_WRAPPERASYNC } from "../public/source/__SCRIPT_WRAPPER__";

export default {
  components: {
    Toast,
    SnackBar,
    Loader,
    MsgBox,
    Camera,
    Url,
  },
  setup() {
    console.log(
      `%c XOne Web Framework v1.0.0 `,
      "background: blue; color: white; display: block;"
    );
    const router = useRouter();

    /**
     * Handler mappings App node
     * @type {XoneAppHandler|undefined}
     */
    let xoneAppHandler;

    //
    // Manage Window Size / Scale

    /**
     * appWidth
     * @type {Ref<number>}
     */
    let appWidth = ref(window.innerWidth);

    /**
     * appHeight
     * @type {Ref<number>}
     */
    let appHeight = ref(window.innerHeight);

    /**
     * containerWidth
     * @type {Ref<number>}
     */
    let containerWidth = ref(
      (window.innerWidth > appWidth.value
        ? appWidth.value
        : window.innerWidth) - 2
    );

    /**
     * containerHeight
     * @type {Ref<number>}
     */
    let containerHeight = ref(window.innerHeight - 2);

    // Check if an input is focused in order to handle keyboard in mobile devices
    const isInputFocused = ref(false);
    provide("isInputFocused", isInputFocused);

    watch(
      () => isInputFocused.value,
      (newValue) => {
        if (!newValue) setTimeout(() => onResize(), 250);
      }
    );

    /**
     * on window resize
     */
    const onResize = () => {
      if (isInputFocused.value) return;
      containerWidth.value =
        (window.innerWidth > appWidth.value
          ? appWidth.value
          : window.innerWidth) - 2;
      containerHeight.value = window.innerHeight - 2;
      // Send resolution macros
      xoneAppHandler &&
        xoneAppHandler.setVisualConditionsMacros(
          containerWidth.value,
          containerHeight.value
        );

      sizeConditions.value = xoneAppHandler.sizeConditions;
      orientation.value = xoneAppHandler.orientation;
    };

    onMounted(() => window.addEventListener("resize", onResize));
    onUnmounted(() => window.removeEventListener("resize", onResize));

    provide("containerSize", { containerWidth, containerHeight });

    /**
     * Size Conditions
     * @type <Ref<string>>
     */
    const sizeConditions = ref("");
    provide("sizeConditions", sizeConditions);

    /**
     * Orientation
     * @type <Ref<string>>
     */
    const orientation = ref("");
    provide("orientation", orientation);

    //
    // Scale Factor

    /**
     * widthFactor
     * @type {ComputedRef<number>}
     */
    const widthFactor = computed(() =>
      appWidth.value ? containerWidth.value / appWidth.value : 1
    );

    /**
     * heightFactor
     * @type {ComputedRef<number>}
     */
    const heightFactor = computed(() =>
      appHeight.value ? containerHeight.value / appHeight.value : 1
    );

    // provide scaleFactor to child components
    provide("scaleFactor", { widthFactor, heightFactor });

    // displayScreenThresholds
    const displayScreenThresholds = ref(false);
    provide("displayScreenThresholds", displayScreenThresholds);

    /**
     * Init appData
     */
    onMounted(async () => {
      const appData = new XoneApplication();

      // provide appData
      AppDataHandler.setAppData(appData);

      // set UI
      appData.setUserInterface(xoneUI);

      // TODO: comentar con Luis para que agregue la llamada a exitApp el
      if (!XoneApplication.prototype.exit)
        XoneApplication.prototype.exit = () => xoneUI.exitApp();
      if (!XoneApplication.prototype.exitApp)
        XoneApplication.prototype.exitApp = () => xoneUI.exitApp();
      if (!XoneApplication.prototype.logout)
        XoneApplication.prototype.logout = () => xoneUI.exitApp();

      // Get App Configuration File and Script_Wrapper
      const getConfig = new Function(
        "callbackFunction",
        `(async ()=> { 
            try{
              const config = await import('../configuration.js'); 
              const scriptWrapper = await import('../source/__SCRIPT_WRAPPER__.js');
              callbackFunction({...config.default, scriptWrapperAsync: scriptWrapper.__SCRIPT_WRAPPERASYNC});
            } catch(ex) {
              console.error('Error loading configuration.js and __SCRIPT_WRAPPER__.js files', ex);
            }
          })()`
      );

      getConfig(async (config) => {
        if (config.displayScreenThresholds)
          displayScreenThresholds.value = true;

        //
        // Init App
        await appData.IniciarApp(
          config.sourcePath,
          config.scriptWrapperAsync,
          new FileManagerOnline(),
          `Data Source=${config.apiUrl};xoneuser=${config.clientId};xonepass=${config.clientSecret}`
        );

        xoneAppHandler = new XoneAppHandler(appData);

        // Set app width / height
        const appSize = xoneAppHandler.getAppSize();
        appWidth.value = appSize?.width;
        appHeight.value = appSize?.height;
        onResize();

        // set macros
        xoneAppHandler.setInmutableMacros(appData);

        // get entry-point, logon-login-coll, autologon
        const loginColl = appData.getLoginCollectionName(
          appData.getCurrentVisualConditions()
        );
        const entryPoint = appData.getEntryPointCollection(
          appData.getCurrentVisualConditions()
        );
        const autoLogon = xoneAppHandler.getAutoLogon(); // TODO: crear autologon
        // if (!loginColl) // TODO: crear login integrado
        //   router.push({ name: "Login", params: { id: "integrated" } });

        // router.push("login");
        router.push({ name: "Login" });
      });
    });
    return { containerWidth, containerHeight };
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.xone-layout {
  display: flex;
  flex-direction: column;
}
.xone-navbar {
  flex-shrink: 1;
}

.xone-coll {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn03 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}

@keyframes fadeOut03 {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideLeft {
  from {
    transform: translate(75%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes slideRight {
  from {
    transform: translate(-75%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
