import Hashtable from "../../Collections/HashMap/Hashtable";
import IXoneAttributes from "../../Interfaces/IXoneAttributes";

export default class XmlAttributes implements IXoneAttributes {
    
    private _attributes: Hashtable<string,string>

    /**
     *
     */
    constructor(attrs:[]=null) {
        this._attributes=new Hashtable<string,string>();
        if (attrs) {
            attrs.forEach(element=> this._attributes.put(element[0],element[1]))
        }
    }

    public toJSON() {
        return this._attributes.toJSON();
    }

    
    public get length() : number {
        return this._attributes.length;
    }

    keys(): string[] {
        return this._attributes.keys();
    }
    getIndex(AttrName: string) {
        return this._attributes.keys().indexOf(AttrName);
    }
    getValue(sAttrName: string): string {
        return this._attributes.get(sAttrName);
    }
    setValue(attrName: string, value: string): void {
        this._attributes.put(attrName,value);
        this[attrName]=value;
    }
    deleteAttribute(attrName: string): void {
        this._attributes.delete(attrName);
    }
    clearAll(): void {
        this._attributes.clear();
    }

}