import { Exception } from "../../Exceptions/Exception";

export default class CssParseException extends Exception {

    /**
     *
     */
    constructor(name: string, nLine: number, sLine: string, message: string) {
        super(message);
        
    }
}