import { resolve } from "path";
import Hashtable from "../../Collections/HashMap/Hashtable";
import IConnection from "../../Interfaces/IConnection";
import IDataOptions from "../../Interfaces/IDataOptions";
import IResultSet from "../../Interfaces/IResultSet";
import { SqlParser } from "../../Parsers/SQL/SqlParser";
import ObjUtils from "../../Utils/ObjUtils";
import XoneGPSResulset from "./XoneGPSResulset";

export default class XoneGPSConnection implements IConnection {

    private readonly m_sentence = "Select STATUS,LATITUD,LONGITUD,ALTITUD,RUMBO,FGPS,HGPS,VELOCIDAD,SATELITES from GPS";

    /**
     *
     */
    constructor() {
        let a = 1;

    }

    private getTestResult() {
        return {
            hasError: false,
            data: [
                {
                    ID:1,STATUS:1,LATITUD:38.886524,LONGITUD: -7.003800,ALTITUD:Math.floor(Math.random() * (1000 - 1)) + 1,RUMBO:Math.floor(Math.random() * (360 - 1)) + 1,FGPS: new Date(),HGPS: ObjUtils.SafeToHours(),VELOCIDAD:Math.floor(Math.random() * (200 - 1)) + 1,SATELITES:Math.floor(Math.random() * (8 - 1)) + 1
                }
            ]
        }
    }

    async countAsync(Sentence: any, ...args: any[]): Promise<IResultSet> {
        return await new Promise(resolve => 10);
    }


    /**
     * Este es el lugar donde hay que llenar los datos del GPS, se supone que es lo que lo devuelve asincrono
     * Alejandro debe completar esta funcion
     * @param Sentence 
     * @param data 
     */
    async fetchDataAsync(Sentence?: any, data?: any): Promise<any> {
        return this.getTestResult();
    }

    async executeQueryAsync(Sentence: any, ...args): Promise<IResultSet> {
        // var parser = new SqlParser("ROWID");
        // parser.ParseSqlString(Sentence as string);
        // var data: IDataOptions = {
        //     action: "select",
        //     where: parser.GetWhereSentence(),
        //     sort: parser.getOrderBySentence()
        //     //page: args.length>2?args[2]:{}
        // };
        // data = { ...data, ...args[0] };
        var rs = new XoneGPSResulset(this, Sentence);
        return await rs.populate();
    }

    executeUpdateAsync(Sentence: any): Promise<number> {
        throw new Error("Method not implemented.");
    }

    async executeNonQuery(data?: any): Promise<any> {
        return {
            hasError: false,
            data: [{
                result: 1
            }]
        }
    }

    async executeAsync(Sentence: any, MaxRows: number, ...args): Promise<any> {
        return await this.executeNonQuery();
    }


    executeQuery(Sentence: any): IResultSet {
        return new XoneGPSResulset(this, this.m_sentence);
    }

    createStatement() {
        throw new Error("Method not implemented.");
    }
    isClosed(): boolean {
        throw new Error("Method not implemented.");
    }
    commit() {
        throw new Error("Method not implemented.");
    }
    beginTrans(): void {
        throw new Error("Method not implemented.");
    }
    rollback(): void {
        throw new Error("Method not implemented.");
    }
    execute(parameter: any, MaxRows: number) {
        throw new Error("Method not implemented.");
    }
    executeUpdate(sql: string): number;
    executeUpdate(Sentence: any): number;
    executeUpdate(Sentence: any): number {
        throw new Error("Method not implemented.");
    }
    executeOperation(OperType: number, tableName: string, values: any, whereClause: string): number {
        throw new Error("Method not implemented.");
    }
    getDBMS(): string {
        throw new Error("Method not implemented.");
    }
    outerJoinsSupported(): boolean {
        throw new Error("Method not implemented.");
    }
    acceptsParsedSentences(): boolean {
        throw new Error("Method not implemented.");
    }
    acceptsEmptyQueries(): boolean {
        throw new Error("Method not implemented.");
    }
    LastRowsAffected(): number {
        throw new Error("Method not implemented.");
    }
    LastInsertedRowId(): number {
        throw new Error("Method not implemented.");
    }
    cancelProcesses(flags: number): number {
        throw new Error("Method not implemented.");
    }
    setCryptoData(cryptoData: Hashtable<string, Object>): void {
        throw new Error("Method not implemented.");
    }
    getCryptoData(): Hashtable<string, Object> {
        throw new Error("Method not implemented.");
    }
    cryptoSupported(): boolean {
        throw new Error("Method not implemented.");
    }
    getTokenFromAuth(login: string, pass: string): string {
        throw new Error("Method not implemented.");
    }

}