import { useRouter } from "vue-router";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import { Exception } from "./appData/Exceptions/Exception";
import AppDataHandler from "./AppDataHandler";
import { xoneViewsHandler } from "./XoneViewsHandler";

/**
 * XoneUI class
 */
class XoneUI {
  static _instance;

  constructor() {
    if (XoneUI._instance) return XoneUI._instance;
    XoneUI._instance = this;
  }

  // Add callback function showSnackbar from SnackBar component
  setOpenUrlCallback(openUrlCallback) {
    this.openUrlCallback = openUrlCallback;
  }
  // Add callback function msgBox from current msgbox component
  setMsgBoxCallback(msgBoxCallback) {
    this.msgBoxCallback = msgBoxCallback;
  }

  // Add callback function hideLoader from current loader component
  setHideLoaderCallback(hideLoaderCallback) {
    this.hideLoaderCallback = hideLoaderCallback;
  }

  // Add callback function showGroup from current coll component
  setShowGroupCallback(showGroupCallback) {
    this.showGroupCallback = showGroupCallback;
  }

  // // Set controlsToRefresh from current coll
  // setControlsToRefresh(controlsToRefresh) {
  //   this.controlsToRefresh = controlsToRefresh;
  // }

  // Add callback function showLoader from Loader component
  setShowLoaderCallback(showLoaderCallback) {
    this.showLoaderCallback = showLoaderCallback;
  }

  // Add callback function showToast from Toast component
  setShowToastCallback(showToastCallback) {
    this.showToastCallback = showToastCallback;
  }

  // Add callback function showSnackbar from SnackBar component
  setShowSnackbarCallback(showSnackbarCallback) {
    this.showSnackbarCallback = showSnackbarCallback;
  }

  // Add callback function showSnackbar from SnackBar component
  setStartCameraCallback(startCameraCallback) {
    this.startCameraCallback = startCameraCallback;
  }

  /**
   * setXoneDataObject
   * @param {XoneDataObject} xoneDataObject
   */
  setXoneDataObject(xoneDataObject) {
    this.xoneDataObject = xoneDataObject;
  }

  addCalendarItem(title, description, location, dtStart, dtEnd) {}
  askUserForGPSPermission() {} // Ignorar
  canMakePhoneCall() {} // Ignorar
  captureImage(PropName, CaptureObjectName) {}
  checkGPSStatus() {
    return this.checkGpsStatus();
  }
  checkGpsStatus() {}
  clearDrawing(PropName) {}
  createShortcut(AppName, NoRepeat, Title, IconPath) {} // Ignorar
  deleteShortcut(AppName, Title) {} // Ignorar
  dismissNotification(id) {}
  drawMapRoute(
    PropName,
    DestinationLatitude,
    DestinationLongitude,
    SourceLatitude,
    SourceLongitude,
    Mode,
    StrokeColor
  ) {}
  endPrint() {}
  ensureVisible(Prop, type) {}
  executeActionAfterDelay(NodeName, delay) {}

  /**
   * Go to previus object in stack
   */
  exit() {
    AppDataHandler.deleteLastBreadcumb();
  }
  /**
   * Exit  App
   */
  exitApp() {
    AppDataHandler.clearbreadcumbs();
  }
  getLastKnownLocation() {}
  getLastKnownLocationAccuracy() {}
  getLastKnownLocationAltitude() {}
  getLastKnownLocationBearing() {}
  getLastKnownLocationDateTime() {}
  getLastKnownLocationLatitude() {}
  getLastKnownLocationLongitude() {}
  getLastKnownLocationProvider() {}
  getLastKnownLocationSpeed() {}
  getMaxSoundVolumen() {}
  getSoundVolumen() {}
  /**
   * getView
   * @param {XoneDataObject} DataObject
   */
  getView(DataObject) {
    return xoneViewsHandler?.getView(DataObject);
  }

  /**
   * Hide Group
   * @param {string|number} groupId
   */
  hideGroup(groupId) {
    this.showGroupCallback
      ? this.showGroupCallback(groupId.toString())
      : console.error("hideGroup is not defined", groupId);
  }
  /**
   * Hide loader spinner
   */
  hideLoader() {
    this.hideLoaderCallback
      ? this.hideLoaderCallback()
      : console.error("hideLoader not defined");
  }
  hideNavigationDrawer() {}
  hideSoftwareKeyboard() {}
  hideWaitDialog() {}
  injectJavascript(WebViewPropName, ScriptText) {}
  isApplicationInstalled(packageName) {}
  isOnCall() {}
  isSuperuserAvailable() {}
  isTaskKillerInstalled() {}
  isWifiConnected() {}
  isWifiEnabled() {}
  launchApp(PackageName, ExtrasParam) {}
  launchApplication(PackageName, ExtrasParam) {}

  /**
   * Launch entry point
   */
  launchEntryPoint() {
    this.showLoader();

    setTimeout(() => {
      // Clear current DataObjects and breadcumbs
      AppDataHandler.clearbreadcumbs();

      /**
       * entryPoint
       * @type {string}
       */
      const entryPoint = AppDataHandler.getAppData().getEntryPointCollection(
        AppDataHandler.getAppData().getCurrentVisualConditions()
      );
      // Create XoneDataObject and add it into breadcumbs stack
      (async () => {
        await AppDataHandler.addNewXoneDataObject(entryPoint, "EntryPoint");

        this.hideLoader();
      })();
    });
  }

  lineFeed(Lines) {}
  lockGroup(groupId) {}
  makePhoneCall(PhoneNumber) {}

  /**
   * Show MsgBox
   * @param {string|XoneDataObject} message
   * @param {string} [title]
   * @param {number} [flags]
   * @returns {Promise<number>}
   */
  async msgBox(message, title = "", flags = 0) {
    if (typeof message === "string") {
      let res = 0;

      if (this.msgBoxCallback) {
        res = await this.msgBoxCallback(message, title, flags);
      } else {
        console.error("msgBox not defined");
      }

      return res;
    } else {
      console.log("viene un objeto al msgBox");
    }
  }

  async msgBoxWithSound(Message, Title, type, Sound, Vibrate, NumberRepeat) {
    await this.msgBox(Message, Title, type);
  }

  /**
   * Open new Edit View and push it in Breadcumbs stack
   * @param {XoneDataObject|string} param
   */
  openEditView(param) {
    this.showLoader();
    setTimeout(async () => {
      if (typeof param === "string") {
        // string: create XoneDataObject and push it into stack

        await AppDataHandler.addNewXoneDataObject(param);
        this.hideLoader();
      } else {
        // XoneDataObject: push it into stack

        /**
         * xoneDataObject
         * @type {XoneDataObject}
         */
        const xoneDataObject = param;
        AppDataHandler.pushXoneDataObject(xoneDataObject);
        this.hideLoader();
      }
    });
  }
  openFile(Path) {}
  openMenu(Name, Mask, Mode) {}

  async openUrl(Url) {
    if (this.openUrlCallback) this.openUrlCallback(Url);
    else console.error("Method openUrl not implemented");
  }

  pickFile(PropName, Extensions, PictureOnly, Path, Flag) {}
  playSoundAndVibrate(Sounds, vibrate, NumberRepeat, ContinuePlaying) {}
  playSoundVolumen(Number) {}
  print(Data) {}
  printBIDI(Size, Level, Data) {}
  printBarcode(Type, Data, Width, Height) {}
  printCommand(Data) {}
  printImage(Path, Width, Height, Align, Dither) {}
  printLine(Data) {}
  printPDF(Path, PageNumber) {}
  quitApp() {}
  recognizeSpeech(DataObject, PropName) {}
  /**
   * Refresh controls
   * @param {Array} Props
   */
  refresh(...Props) {
    const xoneView = xoneViewsHandler?.getView(this.xoneDataObject);
    if (!xoneView) return;
    const props = Props.length === 1 ? Props[0].toString().split(",") : Props;
    props.forEach((e) => {
      xoneView[e]?.refresh();
    });
    if (Props.length === 0) xoneView.refresh();
  }
  refreshContentRow(ContentName, Row) {}
  refreshContentSelectedRow(ContentName) {}
  /**
   * refreshValue
   * @param {string} Props
   */
  refreshValue(Props) {
    const xoneView = xoneViewsHandler?.getView(this.xoneDataObject);
    if (!xoneView) return;
    xoneView[Props].refresh();
  }
  relayout() {}
  restartApp() {}
  returnToForeground() {}
  returnToMainMenu() {}
  saveDrawing(PropName, FileName) {}
  sendMail(To, Cc, Subject, Message, Attachments) {}
  sendSMS(Phone, Text) {}
  setFeedMode(FeedMode) {}
  setLanguage(Language) {}
  setMaxWaitDialog(Max) {}
  setNotificationLed(LedColor, LedOn, LedOff) {}
  setSelection(Prop, Position) {}
  shareData(Subject, Text, image) {}
  showConsoleReplica() {}
  showDatePicker(JSONObject) {}

  /**
   * Show Group
   * @param {string|number} groupId
   * @param {*} [animationIn]
   * @param {*} [animationInDuration]
   * @param {*} [animationOut]
   * @param {*} [animationOutDuration]
   */
  showGroup(
    groupId,
    animationIn,
    animationInDuration,
    animationOut,
    animationOutDuration
  ) {
    this.showGroupCallback
      ? this.showGroupCallback(groupId.toString())
      : console.error("showGroup is  not defined", groupId);
  }
  /**
   * Show Spinner loader
   */
  showLoader() {
    this.showLoaderCallback
      ? this.showLoaderCallback()
      : console.error("showLoader is not defined");
  }
  showNavigationDrawer(Orientation) {}
  showNotification(id, Title, Text, TextStatusBar, DataObject, NodeName) {}

  /**
   * Show snackbar
   * @param {string|Object} params
   */
  showSnackbar(params) {
    this.showSnackbarCallback
      ? this.showSnackbarCallback(params)
      : console.error("showSnackbar is  not defined", params);
  }
  showSoftwareKeyboard() {}

  /**
   * Show Toast
   * @param {string} message
   */
  showToast(message) {
    this.showToastCallback
      ? this.showToastCallback(message)
      : console.error("showToast is not defined", message);
  }
  showWaitDialog(Text) {}
  signDataObject(Data, Mask) {}
  sleep(Seconds) {}
  speak(Language, Text) {}
  startAudioRecord(NodeName, Prop, TimeOut) {}
  startCamera(PropName, type) {
    if (this.startCameraCallback) this.startCameraCallback();
  }
  startGps(JSONObject, Interval, Flags) {}
  startGpsV1() {}
  startGpsV2(JSONObject, Interval, Flags) {}
  startKioskMode() {}
  startPrint(PrinterType) {}
  startReplica() {}
  startScanner(NativeObject, Codes, Target) {}
  startSignature(Prop, Width, Height, BackgroundImage, ScreenOrientation) {}
  startWifi() {}
  stopAudioRecord() {}
  stopGps() {}
  stopGpsV1() {}
  stopGpsV2() {}
  stopKioskMode() {}
  stopPlaySoundAndVibrate() {}
  stopReplica() {}
  stopWifi() {}
  /**
   * Take photo
   * @param {*} FileName
   * @param {*} Width
   * @param {*} Height
   */
  takePhoto(FileName, Width, Height) {
    if (this.startCameraCallback) this.startCameraCallback();
  }

  /**
   * Toogle Group
   * @param {string|number} groupId
   */
  toggleGroup(groupId) {
    this.showGroupCallback
      ? this.showGroupCallback(groupId.toString())
      : console.error("toggleGroup is not defined", groupId);
  }
  uninstallApplication(packageName) {}
  unlockGroup(groupId) {}
  updateWaitDialog(message, value) {}
  useLastPrinter(True) {}
  writeString() {}
}

const xoneUI = new XoneUI();

export default xoneUI;
