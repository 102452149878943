import { PrivateKeyInput } from "crypto";
import { ServerStreamFileResponseOptions } from "http2";
import { stringify } from "querystring";
import Hashtable from "../Collections/HashMap/Hashtable";
import Vector from "../Collections/Vector";
import { Exception } from "../Exceptions/Exception";
import XoneGenericException from "../Exceptions/XoneGenericException";
import { XoneMessageKeys } from "../Exceptions/XoneMessageKeys";
import { XoneValidationException } from "../Exceptions/XoneValidationException";
import IMessageHolder from "../Interfaces/IMessageHolder";
import IResultSet from "../Interfaces/IResultSet";
import IXmlNode from "../Interfaces/IXmlNode";
import IXoneObject from "../Interfaces/IXoneObject";
import { SqlParser } from "../Parsers/SQL/SqlParser";
import UITransform from "../Transform/UITransform";
import Calendar from "../Utils/Calendar";
import CXoneNameValuePair from "../Utils/CXoneNameValuePair";
import HashUtils from "../Utils/HashUtils";
import NumberUtils from "../Utils/NUmberUtils";
import ObjUtils from "../Utils/ObjUtils";
import ScriptUtils from "../Utils/ScriptUtils";
import SqlType from "../Utils/SqlType";
import StringBuilder from "../Utils/StringBuilder";
import StringUtils from "../Utils/StringUtils";
import TextUtils from "../Utils/TextUtils";
import { Utils } from "../Utils/Utils";
import XmlUtils from "../Utils/XmlUtils";
import XmlNode from "../Xml/JSONImpl/XmlNode";
import XmlNodeList from "../Xml/JSONImpl/XmlNodeList";
import ScriptContext from "../XoneScripts/ScriptContext";
import { XoneApplication } from "./XoneApplication";
import { XoneDataCollection } from "./XoneDataCollection";

export class XoneDataObject implements IXoneObject {

    // Solo para identificar que una instancia es un objeto
    public get isDataObject():boolean {
        return true;
    }

    // Vamos a identificar nuestro objetos con un id unico
    private UNIQUE_ID: Symbol;
    private static PARSE_NODE_NAME = 0;
    private static PARSE_EXPECTING_OPEN_PAR = 1;
    private static PARSE_PARAM_VALUE = 2;
    private static PARSE_EXPECTING_COMMA = 3;

    private mapEvents: Hashtable<string, any>;

    /**
     * Lista con los valores de los campos normales
     * K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
     * Hacer esta lista concurrente...
     */
    protected m_lstNormalProperties: Hashtable<string, any>;
    /**
     * Lista con las propiedades que se han modificado
     */
    protected m_lstDirtyProperties: Vector<string>;
    /**
     * Colección a la cual pertenece este objeto (al menos la que se usó como plantilla para crearlo)
     */
    protected m_owner: XoneDataCollection;
    /**
     * Cadena clave del objeto. Generada cuando se carga o cuando se pide por primera vez.
     */
    protected m_strObjectId: string;
    /**
     * ID numérico del objeto. -1 si la clave no es numérica. 0 si el objeto es de lectura solamente.
     */
    protected m_lObjectId: number;
    /**
     * TRUE si el objeto no se puede almacenar en base de datos ni fuente de datos ninguna.
     */
    protected m_bReadOnly: boolean;
    /**
     * TRUE si el objeto está "sucio", es decir, alguna de sus propiedades ha cambiado de valor
     */
    protected m_bDirty: boolean;
    /**
     * TRUE si el objeto es el elemento actual de un recorrido SB/EB
     */
    protected m_bIsCurrentItem: boolean;
    /**
     * Lista con las colecciones de contents de este objeto
     */
    protected m_lstContents: Hashtable<string, XoneDataCollection>;
    /**
     * TRUE si ya se han cargado los contents
     */
    protected m_bContentsLoaded: boolean;
    /// TRUE si el objeto está en proceso de carga
    protected m_bIsLoading: boolean;
    /// Lista de valores antiguos para buscar OldValue (x)
    protected m_lstOldValues: Hashtable<string, any>;
    /// TRUE si se han efectuado cambios durante la actualización de una propiedad.
    protected m_bChangesMade: boolean;
    /// Lista con las propiedades que son xlat
    protected m_lstXlatProperties: Vector<string>;
    /// Lista con los nombres de propiedades que se han desarrollado ya para no tener que volver a desarrollarlas.
    protected m_lstDevelopedXlatProperties: Vector<string>;
    /// TRUE si el objeto se está clonando.
    protected m_bIsCloning: boolean;
    /// TRUE si se está efectuando una validación para evitar la reentrada.
    protected m_bIsChecking: boolean;
    /// TRUE para forzar los cambios de monedas a la salida.
    protected m_bForceCurrChanges: boolean;
    /// TRUE si el objeto debe emplear timestamp para sus actualizaciones
    protected m_bUseTimestamp: boolean;
    /// Fecha y hora de la última modificación (asignación de propiedad) de este objeto
    protected m_dtTimeStamp: Calendar;
    /// TRUE si el objeto propaga su estado de modificación hacia sus propietarios
    protected m_bPropagateDirty: boolean;
    /// TRUE si hay que buscar en base de datos para resolver los xlats.
    protected m_bXlatExist: boolean;
    /// TRUE si se está resolviendo un campo xlat
    protected m_bIsRestoring: boolean;
    /// TRUE si se está creando el objeto
    protected m_bIsCreating: boolean;
    /// Aquí se almacena el nombre del campo para el que se está ejecutando Onchange, para permitir reentradas recursivas.
    protected m_strChangeField: string;
    /// TRUE si el objeto es dependiente de su propietario para grabarse (no se graba si su padre no está en base de datos)
    protected m_bDependent: boolean;
    /// TRUE si hay que seguir las reglas de eliminación
    protected m_bFollowRules: boolean;
    /// Lista con los enlaces pendientes de efectuar cuando se grabe el objeto
    protected m_lstLinkItems: Vector<any>;
    /// Lista con los campos xlat que se están desarrollando.
    protected m_lstDevelopingFields: Vector<string>;
    /// TRUE si el objeto es un clon recién creado.
    protected m_bNewClone: boolean;
    /// Lista de variables de la colección
    protected m_lstVariables: Hashtable<string, Object>;
    /**
     * M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
     * Aquí almacenamos una referencia al gestor de mensajes de la aplicación.
     */
    protected m_messages: IMessageHolder;
    /**
     * F11070701: Modificaciones para solucionar los enlaces y grabación con claves no numéricas.
     * Esta banderilla se resetea cuando se carga el objeto de disco, cuando se graba o cuando
     * se hace algo que implique que no es un nuevo objeto.
     */
    protected m_bNewObject: boolean;

    protected m_lstCachedAttributes: Hashtable<string, string>;

    /**
     * TODO Juan Carlos
     * Lista de atributos que se han modificado por script. Los mantenemos en una lista separada
     * para que no se borren al cambiar las condiciones visuales.
     */
    protected m_lstOverridenFieldPropertyValueAttributes: Hashtable<string, string>;

    /**
     * A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
     * Indica en qué nivel de profundidad de actualización de valores estamos. Cero indica nivel raíz
     * y por tanto se pueden reevaluar las fórmulas de visiblidad y tal.
     */
    protected m_nSetValDepth = 0;
    /**
     * K13081201: Cambios en el tratamiento de fórmulas para mejorar la concurrencia.
     * Parece que las fórmulas son un problema coño...
     */
    protected m_formulaLocker: Object;
    /**
     * A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
     * Juegos de valores de visibilidad y habilitación de campos. Contiene las referencias a las fórmulas
     * parseadas y a sus valores para este objeto, se refrescan cada vez que un campo de disparo cambia
     * de valor.
     * A13022106: Mecanismo para abstraer más el modelo de objetos usando interfaces.
     * Esto lo traemos a la clase base.
     */
    protected m_formulas: Hashtable<string, any>;
    private mMapSysOrder: number;

    private m_model = <any>{};

    public get model(): any {
        return this.m_model;
    }


    public set model(v: any) {
        this.m_model = v;
    }


    /**
     * Construye un objeto de datos
     * @param OwnerCollection		Colección propietaria de este objeto.
     */
    constructor(OwnerCollection: XoneDataCollection) {
        //super();
        // Cada objeto Xone le vamos a dar un valor unico consecutivo a partir de 0x10000000
        this.UNIQUE_ID = Symbol("XoneDataObject");
        // F11070701: Modificaciones para solucionar los enlaces y grabación con claves no numéricas.
        // Vale, si es nuevo es nuevo...

        this.m_bNewObject = true;
        // Constriye las cosas que se van a usar en el objeto
        // K13061901: Modificaciones para mejorar el soporte de concurrencia en la maquinaria.
        // Hacer esta lista concurrente.
        this.m_lstNormalProperties = new Hashtable<string, Object>();
        this.m_lstOldValues = new Hashtable<string, Object>();
        this.m_lstDirtyProperties = new Vector<string>();
        this.m_lstXlatProperties = new Vector<string>();
        this.m_lstDevelopedXlatProperties = new Vector<string>();
        this.m_owner = OwnerCollection;
        this.m_bPropagateDirty = true;   // Por defecto propagamos las modificaciones
        this.m_lstLinkItems = new Vector<any>();
        this.m_lstVariables = new Hashtable<string, Object>();
        // F09042302:	La lista de contents se asume creada en muchos lugares del objeto.
        this.m_lstContents = new Hashtable<string, XoneDataCollection>();
        this.m_lstDevelopingFields = new Vector<string>();
        // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
        // Almacenar la referencia al gestor de mensajes
        this.m_messages = this.getOwnerApp().getMessageHolder();
        // M12102401: Gestión de los atributos a nivel de objetos cacheando los valores.
        // Lista de atributos de propiedades cacheados si se usa, claro
        this.m_lstCachedAttributes = new Hashtable<string, string>();
        this.m_lstOverridenFieldPropertyValueAttributes = new Hashtable<string, string>();
        // ADD TAG TODO Inciaicilizar la varibal MAP_SYS_ORDER con -1 para saber que todavia no esta en la coleccion
        this.mMapSysOrder = -1;

        this.m_reactLayout = new Hashtable<string, any>();
        // A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
        // Si la colección tiene fórmulas, nosotros también
        // F13022104: El acceso a las fórmulas de visibilidad de campos debe ser concurrente.
        // La verificación tenemos que hacerla de otra forma... HasFormulas es el restraint para
        // que se nivelen los threads.
        // K13081201: Cambios en el tratamiento de fórmulas para mejorar la concurrencia.
        this.m_formulaLocker = new Object();
        this.addObjectProperties();
        // if (this.m_owner.HasFormulas())
        // {// Copiar
        //     // Una vez aquí dentro ya se han adicionado todas las fórmulas
        //     ////////foreach (KeyValuePair<string, CFormulaParser> kvp in m_owner.Formulas)
        //     // K13081201: Cambios en el tratamiento de fórmulas para mejorar la concurrencia.
        //     // Sacar el lock pafuérida... usar el bloqueador bueno bueno
        //     synchronized (this.m_formulaLocker)
        //     {
        //         // Nos creamos la lista en plan serializado...
        //         this.m_formulas = new Hashtable<string, XoneEvalData>();
        //         synchronized (this.m_formulas)
        //         {
        //         	Hashtable<string, IFormulaParser> ofm =this.m_owner.getFormulas();
        //         	Enumeration<string> enm =ofm.keys();
        //             while (enm.hasMoreElements())
        //             {// Copiar las fórmulas y crear grupos de evaluación
        //             	string key =enm.nextElement();
        //             	IFormulaParser ifp =ofm.get(key);
        //             	FormulaParser formula =(FormulaParser)ifp;
        //                 XoneEvalData ed = new XoneEvalData(formula, this);
        //                 // F13022104: El acceso a las fórmulas de visibilidad de campos debe ser concurrente.
        //                 // No tiene sentido evaluar las fórmulas porque todavía el objeto no tiene valores
        //                 // F13022201: Arreglos en la gestión de fórmulas.
        //                 // Trim a la fórmula que será clave...
        //                 ////////lock (m_formulas)
        //                 this.m_formulas.put(formula.getSourceText().trim(), ed);
        //             }// Copiar las fórmulas y crear grupos de evaluación
        //         }
        //     }
        // }// Copiar
    }

    private addSingleProperty(name: string): void {
        let _this = this;
        let value = _this.getValue(name);
        _this.m_lstNormalProperties.put(name, value);
        _this.m_model[name] = value;
        if (_this.hasOwnProperty(name))
            return;
        Object.defineProperty(_this, name, {
            get: function (): any {
                return _this.getNormalValue(name);
            },
            set: function (v: any) {
                //console.log("LLego");
                // _this.m_model[name]=v;
                // _this.m_lstNormalProperties.put(name,v);
                _this.put(name, v);
            }
        });
    }

    private addObjectProperties(): void {
        let _this = this;
        // Debemos agregar el idFieldname tambien a la reactividad
        if (!this.m_owner.getMultipleKey() && !TextUtils.isEmpty(this.getIdFieldName())) this.addSingleProperty(this.getIdFieldName());
        // Resto de propiedades
        this.m_owner.getNodeList("prop").forEach(node => this.addSingleProperty(node.getAttrValue("name")));
        //  {
        //     let name = node.getAttrValue("name");
        //     let value = _this.getValue(name);
        //     _this.m_lstNormalProperties.put(name, value);
        //     _this.m_model[name] = value;
        //     Object.defineProperty(_this, name, {
        //         get: function (): any {
        //             return _this.getNormalValue(name);
        //         },
        //         set: function (v: any) {
        //             //console.log("LLego");
        //             // _this.m_model[name]=v;
        //             // _this.m_lstNormalProperties.put(name,v);
        //             _this.setNormalValue(name, v);
        //         }
        //     });
        // });
        // let a = 9;
    }

    private getNormalValue(name: string): any {
        return this.m_lstNormalProperties.get(name);

    }

    private setNormalValue(name: string, v: any): any {
        this.m_model[name] = v;
        this.m_lstNormalProperties.put(name, v);
        if (!this.m_lstDirtyProperties.contains(name))
            this.m_lstDirtyProperties.push(name);
        this.m_bDirty = true;
    }

    public PrepareSpecialCollection(arg0: XoneDataCollection) {
        return true;
    }


    EvaluateRule(rule: IXmlNode): boolean {
        if (rule != null) { }
        return true;
    }

    public async OnCreate(CreateNew: boolean): Promise<boolean> {
        let nodeList: XmlNodeList;
        let bAlways: boolean;
        let coll: XoneDataCollection;

        // Indicar que estamos creando el objeto
        this.m_bIsCreating = true;
        try {
            if (this.m_owner.getSpecial())
                this.m_bPropagateDirty = false;
            // Obtener el valor del tag de la colección
            this.m_bPropagateDirty = StringUtils.ParseBoolValue(this.m_owner.CollPropertyValue("propagate-dirty"), this.m_bPropagateDirty);
            if (this.FieldExists("OBJTIMESTAMP"))
                this.m_bUseTimestamp = true;
            this.m_bForceCurrChanges = StringUtils.ParseBoolValue(this.m_owner.CollPropertyValue("forcechanges"), false);
            this.m_bXlatExist = StringUtils.ParseBoolValue(this.m_owner.CollPropertyValue("xlat-exist"), true);
            // Obtener las acciones de creación y ejecutarlas...
            coll = this.m_owner;
            let nCount = 0;
            do {
                nodeList = null;
                let createNode = coll.getNode("create");
                if (createNode != null) {// Existe el nodo padre
                    nodeList = createNode.SelectNodes("action");
                    nCount = nodeList.count();
                }// Existe el nodo padre
                if (null == (coll = coll.getParentCollection()))
                    break;
            } while (nCount == 0);
            // Ejecutar las acciones de creación
            if (nodeList != null) {// Solo si hay lista de acciones
                for (let i = 0; i < nodeList.count(); i++) {// Ejecutar las acciones
                    let node = nodeList.get(i);
                    // Comprueba si las reglas se cumplen
                    bAlways = StringUtils.ParseBoolValue(XmlUtils.getNodeAttr(node, "always"), false);
                    //XmlUtils.GetNodeAttr(node, "name");
                    // Solo si es nuevo
                    if (CreateNew || bAlways) {// Es de nueva creación
                        // Ante todo revisar las reglas
                        if (!this.EvaluateNodeRules(node))
                            continue;
                        // A12081701: Mecanismo para pasar parámetros a un nodo de acción.
                        // Esto no lleva argumentos... de momento
                        if (!await this.ExecuteNodeAction(null, node, null))
                            return false;
                    }// Es de nueva creación
                }// Ejecutar las acciones
            }// Solo si hay lista de acciones
            // Algunas propiedades que deben conocerse
            this.m_bDependent = StringUtils.ParseBoolValue(this.m_owner.CollPropertyValue("dependent"), true);
            // F13030602: Correcciones en la evaluación de fórmulas de visibilidad y habilitaciones.
            // Tenemos que evaluar las fórmulas también aquí si el objeto es nuevo...
            // Evaluar las fórmulas si las hubiere...
            // K13081201: Cambios en el tratamiento de fórmulas para mejorar la concurrencia.
            // synchronized (this.m_formulaLocker)
            // {
            //     if (this.m_formulas != null && CreateNew)
            //     {// Tiene fórmulas
            //     	Enumeration<String> enm =this.m_formulas.keys();
            //         while (enm.hasMoreElements())
            //         {// Evaluar
            //         	String key =enm.nextElement();
            //         	XoneEvalData val =this.m_formulas.get(key);
            //             if (val.getDirty())
            //                 val.Evaluate();
            //         }// Evaluar
            //     }// Tiene fórmulas
            // }
            // Completo
            this.m_bIsCreating = false;
            return true;
        } catch (ex) {
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
            sb = sb.replace("{0}", "CXoneDataObject::OnCreate");
            let sExtraMessage = ex.message;
            if (!TextUtils.isEmpty(sExtraMessage)) {
                sb = sb.concat(sExtraMessage);
            }
            throw new XoneGenericException(-10181, ex, sb);
        }
    }

    // /**
    //  * A12042505: Permitir que se pueda hacer ExecuteNode de un nodo en vez de un nombre.
    //  * Ejecuta todas las acciones de un nodo siempre que se cumplan las reglas que contenga.
    //  *
    //  * A12081701: Mecanismo para pasar parámetros a un nodo de acción.
    //  * Pasar los argumentos de llamada.
    //  *
    //  * @param node				Nodo cuyas acciones se quieren ejecutar.
    //  * @return					Devuelve TRUE si las acciones del nodo se ejecutan correctamente.
    //  * @throws Exception
    //  */
    // public ExecuteNode(node: XmlNode | string, ...Arguments): boolean {
    //     if (node == null)
    //         return false;
    //     if (typeof node == 'string') {
    //         node = this.getOwnerCollection().getNode(node);
    //     }
    //     if (ObjUtils.IsNothing(node)) {
    //         console.error("No se pudo ejecutar el nodo");
    //         throw new Exception("Error ejecutando nodo");
    //     }
    //     // Existe el nodo. Ejecutar las acciones
    //     return this.ExecuteNodeActions(node, "action", null, ...Arguments);
    // }

    // /**
    //  * Ejecuta todas las acciones de un nodo.
    //  * A12081701: Mecanismo para pasar parámetros a un nodo de acción.
    //  * Un parámetro con la lista de argumentos que tiene que traer el nodo en caso de necesitarlos.
    //  *
    //  * @param Node			Nodo XML cuyas acciones se quieren ejecutar.
    //  * @param Prefix		Prefijo de las acciones a ejecutar. NULL para usar 'action'.
    //  * @param Scopes		Lista de ámbitos para resolver los objetos sobre los que operan las acciones del nodo.
    //  * @return				Devuelve TRUE si todas las acciones se ejecutan correctamente.
    //  * @throws Exception
    //  */
    // protected ExecuteNodeActions(Node: XmlNode, Prefix: string, Scopes: Array<any>, ...Arguments): boolean {
    //     let nodeList: XmlNodeList;

    //     if (StringUtils.IsEmptyString(Prefix))
    //         Prefix = "action";

    //     nodeList = Node.SelectNodes(Prefix);
    //     // Ejecuta cada una de las acciones
    //     for (let i = 0; i < nodeList.count(); i++) {// Ejecutar las acciones
    //         let node = nodeList.get(i);
    //         // Pasar la lista de scopes a la evaluación, que para eso se ha pasado aquí
    //         try {
    //             if (!this.EvaluateNodeRules(node, Scopes))
    //                 continue;   // Puede ser que se quiere lanzar el error
    //         }
    //         catch (e) {
    //             continue;   // Ignorar la excepción de validación y seguir
    //         }
    //         // A12081701: Mecanismo para pasar parámetros a un nodo de acción.
    //         // Pasar los argumentos...
    //         if (!this.ExecuteNodeAction(null, node, 0, ...Arguments))
    //             return false;
    //     }// Ejecutar las acciones
    //     return true;
    // }

    // ExecuteNodeAction(FieldName: string, Action: XmlNode, ActionType: number = 0, ...Parameters) {
    //     // TODO:    Aquí hay que poner las acciones y cosas que faltan para que se llame desde todas partes.
    //     let str = XmlUtils.getNodeAttr(Action, "name");
    //     // F11083001: ExecuteNodeAction debería protegerse de acciones sin nombre.
    //     // Nombres vacíos no por favor...
    //     if (StringUtils.IsEmptyString(str)) {
    //         return false;
    //     }
    //     switch (str) {
    //         // case "link":
    //         //     return DoLink(FieldName, Action);
    //         // case "executesql":
    //         //     return DoExecuteSql(Action);
    //         // case "executecompsql":
    //         //     return DoExecuteCompSql(Action);
    //         // case "executenode":
    //         //     return ExecuteNode(XmlUtils.getNodeAttr(Action, "nodename"));
    //         // case "addval":
    //         //     return DoAddVal(Action, null);
    //         // case "mapval":
    //         //     if (ActionType == ActionNodeType.NODETYPE_DELETE) {
    //         //         return DoDelMapVal(Action);
    //         //     } else {
    //         //         return DoMapVal(Action);
    //         //     }
    //         // case "setfldval":
    //         //     return DoSetFldVal(Action);
    //         // case "updateobj":
    //         //     return DoUpdateObj(Action);
    //         // case "updateacc":
    //         //     return DoUpdateAcc(Action);
    //         case "setval":
    //             return this.DoSetVal(Action);
    //         // case "updatecnt":
    //         //     return DoUpdateCnt(FieldName, Action);
    //         // case "generatecnt":
    //         //     return DoGenerateCnt(FieldName, Action);
    //         // case "generatemember":
    //         //     return DoGenerateMember(FieldName, Action);
    //         // case "updatemember":
    //         //     return DoUpdateMember(FieldName, Action);
    //         // case "updatedepth":
    //         //     return DoUpdateDepth(FieldName, Action);
    //         // case "generate":
    //         //     return DoGenerate(Action);
    //         // case "executemethod":
    //         //     return InvokeMethod(Action);
    //         // case "for-each-contents":
    //         //     return DoForEachContents(FieldName, Action);  // Tiene el contents definido
    //         // case "add-content":
    //         //     return DoAddContent(Action);
    //         // case "remove-content":
    //         //     return DoRemoveContent(Action);
    //         // A12081701: Mecanismo para pasar parámetros a un nodo de acción.
    //         // Pasamos los parámetros en cuestión...
    //         case "runscript":
    //             try {
    //                 this.DoRunScript(Action, ...Parameters);
    //                 return true;
    //             } catch (ex) {
    //                 console.error(ex);
    //                 return false;
    //             }
    //         // case "msgbox":
    //         //     return DoMessageBox(Action);
    //         default:
    //             // Método desconocido
    //             // Si tiene funcionalidad propia, llamar a quien lo ejecuta
    //             return true; // ExecuteExternalAction(FieldName, Action);
    //     }
    // }

    /**
     * A12042505: Permitir que se pueda hacer ExecuteNode de un nodo en vez de un nombre.
     * Ejecuta todas las acciones de un nodo siempre que se cumplan las reglas que contenga.
     *
     * A12081701: Mecanismo para pasar parámetros a un nodo de acción.
     * Pasar los argumentos de llamada.
     *
     * @param node				Nodo cuyas acciones se quieren ejecutar.
     * @return					Devuelve TRUE si las acciones del nodo se ejecutan correctamente.
     * @throws Exception
     */
    public async executeNode(node: XmlNode | string, ...Arguments): Promise<boolean> {
        return await this.ExecuteNode(node, ...Arguments);
    }
    public async ExecuteNode(node: XmlNode | string, ...Arguments): Promise<boolean> {
        if (node == null)
            return false;
        if (typeof node == 'string') {
            node = this.getOwnerCollection().getNode(node);
        }
        if (ObjUtils.IsNothing(node)) {
            return false;
            // console.error("No se pudo ejecutar el nodo");
            // throw new Exception("Error ejecutando nodo");
        }
        // Existe el nodo. Ejecutar las acciones
        return await this.ExecuteNodeActions(node, "action", null, ...Arguments);
    }

    /**
     * Ejecuta todas las acciones de un nodo.
     * A12081701: Mecanismo para pasar parámetros a un nodo de acción.
     * Un parámetro con la lista de argumentos que tiene que traer el nodo en caso de necesitarlos.
     *
     * @param Node			Nodo XML cuyas acciones se quieren ejecutar.
     * @param Prefix		Prefijo de las acciones a ejecutar. NULL para usar 'action'.
     * @param Scopes		Lista de ámbitos para resolver los objetos sobre los que operan las acciones del nodo.
     * @return				Devuelve TRUE si todas las acciones se ejecutan correctamente.
     * @throws Exception
     */
    protected async ExecuteNodeActions(Node: XmlNode, Prefix: string, Scopes: Array<any>, ...Arguments): Promise<boolean> {
        let nodeList: XmlNodeList;

        if (StringUtils.IsEmptyString(Prefix))
            Prefix = "action";

        nodeList = Node.SelectNodes(Prefix);
        // Ejecuta cada una de las acciones
        for (let i = 0; i < nodeList.count(); i++) {// Ejecutar las acciones
            let node = nodeList.get(i);
            // Pasar la lista de scopes a la evaluación, que para eso se ha pasado aquí
            try {
                if (!this.EvaluateNodeRules(node, Scopes))
                    continue;   // Puede ser que se quiere lanzar el error
            }
            catch (e) {
                continue;   // Ignorar la excepción de validación y seguir
            }
            // A12081701: Mecanismo para pasar parámetros a un nodo de acción.
            // Pasar los argumentos...
            if (!await this.ExecuteNodeAction(null, node, 0, ...Arguments))
                return false;
        }// Ejecutar las acciones
        return true;
    }

    protected async ExecuteNodeAction(FieldName: string, Action: XmlNode, ActionType: number = 0, ...Parameters): Promise<boolean> {
        // TODO:    Aquí hay que poner las acciones y cosas que faltan para que se llame desde todas partes.
        let str = XmlUtils.getNodeAttr(Action, "name");
        // F11083001: ExecuteNodeAction debería protegerse de acciones sin nombre.
        // Nombres vacíos no por favor...
        if (StringUtils.IsEmptyString(str)) {
            return false;
        }
        switch (str) {
            // case "link":
            //     return DoLink(FieldName, Action);
            // case "executesql":
            //     return DoExecuteSql(Action);
            // case "executecompsql":
            //     return DoExecuteCompSql(Action);
            // case "executenode":
            //     return ExecuteNode(XmlUtils.getNodeAttr(Action, "nodename"));
            // case "addval":
            //     return DoAddVal(Action, null);
            // case "mapval":
            //     if (ActionType == ActionNodeType.NODETYPE_DELETE) {
            //         return DoDelMapVal(Action);
            //     } else {
            //         return DoMapVal(Action);
            //     }
            // case "setfldval":
            //     return DoSetFldVal(Action);
            // case "updateobj":
            //     return DoUpdateObj(Action);
            // case "updateacc":
            //     return DoUpdateAcc(Action);
            case "setval":
                return await this.DoSetVal(Action);
            // case "updatecnt":
            //     return DoUpdateCnt(FieldName, Action);
            // case "generatecnt":
            //     return DoGenerateCnt(FieldName, Action);
            // case "generatemember":
            //     return DoGenerateMember(FieldName, Action);
            // case "updatemember":
            //     return DoUpdateMember(FieldName, Action);
            // case "updatedepth":
            //     return DoUpdateDepth(FieldName, Action);
            // case "generate":
            //     return DoGenerate(Action);
            // case "executemethod":
            //     return InvokeMethod(Action);
            // case "for-each-contents":
            //     return DoForEachContents(FieldName, Action);  // Tiene el contents definido
            // case "add-content":
            //     return DoAddContent(Action);
            // case "remove-content":
            //     return DoRemoveContent(Action);
            // A12081701: Mecanismo para pasar parámetros a un nodo de acción.
            // Pasamos los parámetros en cuestión...
            case "runscript":
                try {
                    return await this.DoRunScriptAsync(Action, ...Parameters);
                } catch (ex) {
                    console.error(ex);
                    return false;
                }
            // case "msgbox":
            //     return DoMessageBox(Action);
            default:
                // Método desconocido
                // Si tiene funcionalidad propia, llamar a quien lo ejecuta
                return true; // ExecuteExternalAction(FieldName, Action);
        }
    }

    EvaluateNodeRules(node: XmlNode, scopesList?: Array<any>) {
        // IXmlNodeList rules;
        // Object vl = null;
        // String strField, str;
        // boolean bRaiseError;
        // XoneDataObject exec;
        // XoneDataObject target;

        // if (Node == null)
        //     return true;
        // // Comprobar si este nodo tiene un atributo "field"
        // // para usar ese valor
        // if (!StringUtils.IsEmptyString(strField = XmlUtils.GetNodeAttr(Node, "field"))) // Obtener el valor que tenga ahora
        //     vl = GetPropertyValue(strField);
        // // Obtener las reglas
        // rules = Node.SelectNodes("rule");
        // for (int i =0;i <rules.count();i++)
        // {// Evalúa cada regla
        // 	IXmlNode rule =rules.get(i);
        //     if (null != (exec = FindScopeObject(rule, ScopeList)))
        //     {// Solo si no se ha evaluado antes
        //         // Buscar si la regla tiene target-scope para los campos y valores de la derecha
        //         if (!StringUtils.IsEmptyString(str = XmlUtils.GetNodeAttr(rule, "target-scope")))
        //             target = FindScopeObject(str, ScopeList, true);
        //         else
        //             target = null;
        //         // Pasarlo como parámetro
        //         try
        //         {
        //         	XoneRuleResult result;
        //         	result =exec.EvaluateRule(strField, vl, rule, target);
        //             if (!result.getRuleResult())
        //                 return false;		// Error
        //         }
        //         catch (Exception e)
        //         {
        //             if (e instanceof XoneValidationException)
        //             {// Hay que dejarla seguir
        //                 str = XmlUtils.GetNodeAttr(rule, "raise");
        //                 bRaiseError = StringUtils.ParseBoolValue(str, false);
        //                 if (bRaiseError)
        //                     throw e;
        //                 else
        //                     return false;   // No lanzar error, directamente devolver que ha fallado la regla
        //             }// Hay que dejarla seguir
        //             // Otra excepción, dejarla seguir
        //             throw e;
        //         }
        //     }// Solo si no se ha evaluado antes
        // }// Evalúa cada regla
        // Todas las reglas han triunfado.
        return true;
    }

    /**
    * Ejecuta un nodo runscript pasando el script y el contexto a la aplicación base.
    * @param ActionNode		Nodo script a ejecutar.
    * @return					Devuelve TRUE si se ejecutan correctamente todos los scripts.
    * @throws Exception
    */
    public async doRunScript(ActionNode: XmlNode | string, ...Arguments): Promise<any> {
        return await this.DoRunScriptAsync(ActionNode, ...Arguments);
    }




    // protected DoRunScript(ActionNode: XmlNode | string, ...Arguments): any {
    //     let data = this.prepareContext(ActionNode, ...Arguments);
    //     let n = data.scripts.length;
    //     for (let i = 0; i < n; i++) {
    //         this.getOwnerApp().RunScript(data.funcNames[i], data.slang, data.scripts[i], data.ctx, data.Arguments);
    //     }
    //     // OK
    //     return true;
    // }

    protected async DoRunScriptAsync(ActionNode: XmlNode | string, ...Arguments): Promise<any> {
        let data = this.m_owner.prepareContext(this, ActionNode, ...Arguments);
        let n = data.scripts.length;
        let promises: Promise<any>[] = new Array<Promise<any>>();
        for (let i = 0; i < n; i++) {
            await this.getOwnerApp().RunScriptAsync(data.funcNames[i], data.slang, data.scripts[i], data.ctx, data.Arguments);
        }
        // OK
        return true;
    }

    /**
     * Asigna valor a un campo de este objeto a partir del valor sacado del nodo XML.
     * @param ActionNode		Nodo XML con los datos del campo y valor que se le va a asignar.
     * @return					Devuelve TRUE si la asignación es correcta.
     * @throws Exception
     */
    private async DoSetVal(ActionNode: XmlNode): Promise<boolean> {
        let strField = "", strValue = "", strType = "";
        let vl: any = null;;

        strField = XmlUtils.GetNodeAttr(ActionNode, "field");
        strValue = XmlUtils.GetNodeAttr(ActionNode, "value");

        // Habría que analizar si es filosóficamente correcto dejar el value
        // vacío e interpretarlo como EMPTY o disparar un error.. por ahora
        // no busquemos más problemas.
        if (StringUtils.IsEmptyString(strValue))
            return true;
        // 06/10/2016 Juan Carlos, check extra
        if (TextUtils.isEmpty(strField)) {
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_SETVALFAIL, "CXoneDataObject::DoSetVal failed. Field attribute is missing.");
            throw new XoneValidationException(-1991, sb);
        }
        // El campo tiene que existir
        if (!this.FieldExists(strField)) {// Error
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_SETVALFAIL, "{0} failed. Field '{1}' is not declared in collection '{2}.");
            sb = sb.replace("{0}", "CXoneDataObject::DoSetVal");
            sb = sb.replace("{1}", strField);
            sb = sb.replace("{2}", this.m_owner.getName());
            throw new XoneValidationException(-1991, sb);
        }// Error
        strType = this.FieldPropertyValue(strField, "type");
        if (StringUtils.IsEmptyString(strType))
            strType = XmlUtils.GetNodeAttr(ActionNode, "type");
        vl = await this.GetValueFromString(strValue, strType);
        // Asignar el valor
        this.put(strField, vl);
        return true;
    }

    FieldExists(FieldName: string) {
        return this.m_owner.FieldExists(FieldName);
    }

    /**
     * F11092606: Cuando se carga un objeto con Load, limpiar los contents.
     * Esta función limpia los contents del objeto.
     */
    public ClearContents(): void {
        if (this.m_lstContents == null) {
            return;
        }
        this.m_lstContents.values().forEach(content => {
            if (!ObjUtils.IsNothing(content)) {
                content.unlock();
                content.clear();
            }
        });
        // let sAllKeys = this.m_lstContents.keys();
        // while (sAllKeys.hasMoreElements()) {
        //     XoneDataCollection content = this.m_lstContents.get(sAllKeys.nextElement());
        //     if (content == null) {
        //         continue;
        //     }
        //     content.Unlock();
        //     content.Clear();
        // }
    }

    protected resolveFieldName(name: string): string {
        /*
         * Comprobamos que comienza como una macro de lo contrario
         * como siempre
         */
        if (!TextUtils.isEmpty(name) && !name.startsWith(Utils.MACRO_TAG))
            return name;
        if (TextUtils.isEmpty(name))
            return name;
        let properties = this.m_owner.getProperties();
        if (properties == null)
            return name;
        let n = name.indexOf(Utils.MACRO_TAG, 2);
        if (n < 0)
            n = name.length;
        let index = name.substring(2, n);
        let nd = properties.SelectSingleNode(Utils.PROP_NAME, Utils.PROP_ATTR_INDEX, index);
        if (nd == null)
            return name;
        return nd.getAttrValue(Utils.PROP_ATTR_NAME);
    }

    protected reverseResolveFieldName(name: string): string {
        /*
         * Comprobamos que comienza como una macro de lo contrario
         * como siempre
         */
        if (!this.m_owner.isIndexed())
            return name;
        if (TextUtils.isEmpty(name))
            return name;
        let properties = this.m_owner.getProperties();
        if (properties == null)
            return name;
        let index = "";
        try {
            index = this.m_owner.FieldPropertyValue(name, Utils.PROP_ATTR_INDEX);
        } catch (e) {
            return name;
        }
        if (TextUtils.isEmpty(index))
            return name;
        else {
            let bld = new StringBuilder(Utils.MACRO_TAG);
            bld.append(index);
            return bld.toString();
        }
    }

    public async Load(Source: IResultSet): Promise<boolean> {
        try {
            // M11092601: Modificaciones para mejorar el soporte de objetos volátiles.
            // Limpiar la lista de valores por si el objeto era volátil y tenía valores...
            this.m_lstNormalProperties.clear();
            // F11092606: Cuando se carga un objeto con Load, limpiar los contents.
            this.ClearContents();
            let n = Source.getColumnCount();
            // Primero leer los valores
            for (let i = 0; i < n; i++) {// Recorrer los campos y leer los valores
                let strFieldName = this.resolveFieldName(Source.getColumnName(i));
                // K11010501:	Modificaciones para la versión 1.5 de Android.
                let objVal = Source.getValue(i, this.m_owner.getTypeFromXml(this.m_owner.PropType(strFieldName)));
                // Almacenar las propiedades normales, siempre que el valor no sea nulo, claro
                if (objVal != null) {// Tiene valor
                    // A11092602: Permitir el uso de resultsets jerárquicos en forma de árbol para cargas múltiples.
                    // El dato podría de ser otro resultset...
                    if (ObjUtils.prototype.hasOwnProperty.call(objVal, "isResultset") && !strFieldName.startsWith(Utils.MACRO_TAG) && !strFieldName.equals("ID")) {// Aquí tenemos que llenar contents
                        let rs = objVal as IResultSet;
                        if (!this.LoadObjectContents(strFieldName, rs)) {// Error
                            return false;
                        }// Error
                    }// Aquí tenemos que llenar contents
                    else
                        // Otra cosa
                        this[strFieldName] = objVal;
                    //this.m_lstNormalProperties.put(strFieldName, objVal);
                }// Tiene valor
                else // F10052505:	Cuando se asigna valor a propiedades nulas en Load, limpiar la lista.
                    this.m_lstNormalProperties.delete(strFieldName);
                // Ver si se trata de la clave
                if (!this.m_owner.getMultipleKey()) {// Una sola clave
                    if (strFieldName.equals(this.getIdFieldName())) {// Es el campo clave
                        if (!this.m_owner.getStringKey()) {// Numérico
                            if (0 == (this.m_lObjectId = NumberUtils.SafeToInt(objVal)))
                                this.m_bReadOnly = true;
                            else
                                this.m_strObjectId = String.format("%d", this.m_lObjectId);
                        }// Numérico
                        else {// Cadena
                            if (objVal != null)
                                this.m_strObjectId = objVal.toString();
                            this.m_lObjectId = -1;
                        }// Cadena
                    }// Es el campo clave
                }// Una sola clave
            }// Recorrer los campos y leer los valores
            // Ahora limpiar la lista de propiedades sucias
            this.m_lstDirtyProperties = new Vector<string>();
            // Si son múltiples claves buscar la lista
            if (this.m_owner.getMultipleKey()) {// Clave múltiple
                this.m_strObjectId = this.GetMultipleKeyString(); // Varias claves
                this.m_lObjectId = -1;	// Marcar que se ha cargado COÑO!!!
            }// Clave múltiple
            this.setDirty(false);
            // F11070701: Modificaciones para solucionar los enlaces y grabación con claves no numéricas.
            // Indicar que el objeto no es nuevo
            this.m_bNewObject = false;
            // Ejecutar los eventos de carga
            return await this.OnLoad();
        }
        catch (e) {
            throw e;
        }
    }

    /**
     * A11092602: Permitir el uso de resultsets jerárquicos en forma de árbol para cargas múltiples.
     * Rellena un contents del objeto basado en un resultset que contiene los objetos.
     *
     * @param ContentsName
     * @param Source
     * @return
     * @throws Exception
     */
    private async LoadObjectContents(ContentsName: string, Source: IResultSet): Promise<boolean> {
        // Lo primero es ver si tenemos contents
        let cnt = await this.getContents(ContentsName);
        if (cnt == null) {// Ver si es una propiedad y buscar contents
            //K11092602: Modificaciones para trabajar con conexiones online puras.
            let ContentsFromProp = this.m_owner.FieldPropertyValue(ContentsName, Utils.PROP_ATTR_CONTENTNAME);
            if (TextUtils.isEmpty(ContentsFromProp)) {
                this.getOwnerApp().RegisterError(-1, "Cannot find contents with name '" + ContentsName + "'");
                return false;
            }
            cnt = await this.getContents(ContentsFromProp);
            if (cnt == null) {// Error
                this.getOwnerApp().RegisterError(-1, "Cannot find contents with name '" + ContentsName + "'");
                return false;
            }
        }// Error
        // A11092602: Permitir el uso de resultsets jerárquicos en forma de árbol para cargas múltiples.
        // Limpiar el contents
        cnt.unlock();
        cnt.clear();
        // Ahora cargar...
        while (await Source.next()) {// Cargar
            let nobj = await cnt.CreateObject(false);
            if (nobj == null) {// Error
                if (this.getOwnerApp().getError().getNumber() != 0)
                    this.getOwnerApp().RegisterError(-1, "Cannot create new object for contents '" + ContentsName + "'");
                return false;
            }// Error
            if (!await nobj.Load(Source)) {// Error
                if (this.getOwnerApp().getError().getNumber() != 0)
                    this.getOwnerApp().RegisterError(-1, "Error loading object data for contents '" + ContentsName + "'");
                return false;
            }// Error
            // Adicionarlo a la colección
            if (!cnt.addItem(nobj)) {// Error
                if (this.getOwnerApp().getError().getNumber() != 0)
                    this.getOwnerApp().RegisterError(-1, "Cannot add new object to contents '" + ContentsName + "'");
                return false;
            }// Error
        }// Cargar
        // A11092602: Permitir el uso de resultsets jerárquicos en forma de árbol para cargas múltiples.
        cnt.setFull(true);
        //cnt.Lock();
        // Completio...
        return true;
    }

    /**
     * Esta función se llama cuando se termina de cargar el objeto. La pueden redefinir los objetos derivados para modificar el comportamiento del objeto al cargarse.
     * @return			Devuelve TRUE si las acciones de carga se ejecutan todas sin problemas.
     * @throws XoneGenericException
     */
    protected async OnLoad(): Promise<boolean> {
        let coll: XoneDataCollection;
        let actions: XmlNodeList;

        try {
            // F13022104: El acceso a las fórmulas de visibilidad de campos debe ser concurrente.
            // Evaluar las fórmulas si las hubiere...
            // K13081201: Cambios en el tratamiento de fórmulas para mejorar la concurrencia.
            // locking.... usando el objeto adecuado...
            // TODO: Luis esto lo resolveremos en el UI
            // (this.m_formulaLocker)
            // {
            //     if (this.m_formulas != null)
            //     {// Tiene fórmulas
            //     	Enumeration<String> enm =this.m_formulas.keys();
            //         while(enm.hasMoreElements())
            //         {// Evaluar
            //         	String key =enm.nextElement();
            //         	XoneEvalData val =this.m_formulas.get(key);
            //             // F13030602: Correcciones en la evaluación de fórmulas de visibilidad y habilitaciones.
            //             // Solo si está sucio...
            //             if (val.getDirty())
            //                 val.Evaluate();
            //         }// Evaluar
            //     }// Tiene fórmulas
            // }
            coll = this.getOwnerCollection();
            do {
                actions = coll.getNodeList("prop", "onload", "true");
                // Ahora bar cada acción y ejecutar los cambios de valor para cada una de estas
                // propiedades
                for (let i = 0; i < actions.count(); i++) {// Acciones
                    let action = actions.get(i);
                    await this.OnChange(XmlUtils.getNodeAttr(action, "name"));
                }// Acciones
                coll = coll.getParentCollection();
            } while (coll != null);
            // Primero obtener los datos de la empresa
            coll = this.getOwnerCollection();
            do {
                // Primero obtener el nodo load
                actions = null;
                let loadNode = coll.getNode("load");
                if (loadNode != null) {// Solo si tiene el nodo padre, claro
                    actions = loadNode.SelectNodes("action");
                    if (actions.count() > 0)
                        break;
                }// Solo si tiene el nodo padre, claro
                coll = coll.getParentCollection();
            } while (coll != null);

            if (actions != null) {// Tiene lista de acciones
                for (let i = 0; i < actions.count(); i++) {// Ejecutar las acciones
                    let action = actions.get(i);
                    //XmlUtils.GetNodeAttr(action, "name");
                    // Ante todo revisar las reglas
                    if (!this.EvaluateNodeRules(action))
                        continue;
                    // Ejecutar las acciones
                    // A12081701: Mecanismo para pasar parámetros a un nodo de acción.
                    // Esto no lleva argumentos... por ahora.
                    if (!await this.ExecuteNodeAction(null, action)) {
                        return false;
                    }
                }// Ejecutar las acciones
            }// Tiene lista de acciones
            return true;
        }
        catch (ex) {
            let sDetails = ex.message;
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
            sb = sb.replace("{0}", "CXoneDataObject::AfterLoad");
            if (!StringUtils.IsEmptyString(sDetails)) {
                sb = sb.concat(sDetails);
            }
            throw new XoneGenericException(-10181, ex, sb);
        }
    }

    /**
     * Este método se ejecuta cuando cambia el valor de un campo. Tiene en cuenta posibles reentradas recursivas.
     * @param FieldName		Nombre del campo que ha cambiado.
     * @throws Exception
     */
    protected async OnChange(FieldName: string): Promise<void> {
        let cnode: XmlNode;
        let strOldChgField: string;

        // Obtener el nodo que contiene las acciones a realizar
        // al ocurrir un cambio
        let onchangeNode = this.getNode("onchange");
        if (onchangeNode == null)
            return;
        // Ahora tendremos que buscar si hay nodos onchange para este campo
        strOldChgField = this.m_strChangeField;
        this.m_strChangeField = FieldName;
        let b = true;
        // A12081701: Mecanismo para pasar parámetros a un nodo de acción.
        // De momento aquí no llevamos argumentos... por ahora.
        let args = [];
        args.push(new CXoneNameValuePair("ChgField", this.m_strChangeField));
        if (null != (cnode = onchangeNode.SelectSingleNode("field", "name", FieldName)))
            b = await this.ExecuteNodeActions(cnode, Utils.EMPTY_STRING, null, ...args);
        if (b) {// Si la vez anterior no ha fallado
            if (null != (cnode = onchangeNode.SelectSingleNode("field", "name", "##ANY##")))
                await this.ExecuteNodeActions(cnode, Utils.EMPTY_STRING, null, ...args);
        }// Si la vez anterior no ha fallado
        this.m_strChangeField = strOldChgField;
    }


    public async ObjectItem(FieldName: string, SearchCollName: string = null): Promise<XoneDataObject> {
        // Buscar el nombre de la colección si no nos la dan
        let strCollName = SearchCollName;

        if (StringUtils.IsEmptyString(strCollName)) {// Buscarla en la propiedad
            if (StringUtils.IsEmptyString(strCollName = this.FieldPropertyValue(FieldName, "mapcol")))
                return null;
        }// Buscarla en la propiedad
        // Ahora tenemos que buscar la propiedad
        let objId = this.get(FieldName);
        if (objId == null)
            return null;    // Na de na...
        let coll = await this.getOwnerApp().getCollection(strCollName);
        if (coll == null)
            return null;
        // Buscar en la colección el objeto cuyo campo clave sea el objeto que tiene nuestro campo ID
        // F12102208: La clave de ObjectItem podría venir vacía y hay que comprobarlo.
        let strId = objId.toString();
        if (StringUtils.IsEmptyString(strId))
            return null;
        return coll.get(strId);
    }

    /**
     * Devuelve una colección contenida dentro de este objeto.
     * @param ContentsName		Indice de la colección dentro del objeto.
     * @return			Devuelve el contents cuyo número de orden se solicita o NULL si el número es mayor que la cantidad de contents.
     * @throws XoneGenericException
     */
    public async getContents(ContentsName: string | number): Promise<XoneDataCollection> {
        // Si no ha cargado los contents, los carga ahora
        if (!this.m_bContentsLoaded)
            if (!await this.LoadContents())
                return null;
        // Si el índice se pasa, na de na...
        if (typeof ContentsName == 'number') {
            if (ContentsName >= this.m_lstContents.length)
                return null;
            ContentsName = this.m_lstContents.keys[ContentsName];
        }

        if (this.m_lstContents == null) {
            this.m_lstContents = new Hashtable<string, XoneDataCollection>();
        }
        if (TextUtils.isEmpty(ContentsName as string)) {
            return null;
        }

        // Si no está en lista, no hay nada que hacer...
        if (!this.m_lstContents.containsKey(ContentsName as string))
            return null;
        return this.m_lstContents.get(ContentsName as string);
        //return coll;
        // Enumeration<String> e =this.m_lstContents.keys();
        // for (let i =0; i <this.m_lstContents.size();i++)
        // {// Recorrer la lista, contar y devolver
        // 	String strKey =e.nextElement();
        //     if (i == Index)
        //         return this.m_lstContents.get(strKey);	// La ha encontrado
        // }// Recorrer la lista, contar y devolver
        // // Completo
        // return null;
    }

    /**
     * Crea las colecciones contents del objeto y las introduce en la lista de contents del objeto.
     * @return		Devuelve TRUE si el contents se carga correctamente.
     * @throws XoneGenericException
     */
    private async LoadContents(): Promise<boolean> {
        let nodeList: XmlNodeList = null;
        let str = "";
        let newColl: XoneDataCollection, appColl: XoneDataCollection;
        let FunctionName = "CXoneDataObject::LoadContents";

        try {
            // Por si las moscas, aunque no debería ocurrir esto...
            // Crear la lista de contents si no existe
            if (this.m_lstContents == null)
                this.m_lstContents = new Hashtable<string, XoneDataCollection>();
            // En cualquier caso, limpiarla...
            this.m_lstContents.clear();
            let coll = this.m_owner;
            nodeList = coll.getNodeList("contents");
            !ObjUtils.IsNothing(coll = coll.getParentCollection())
                && coll.getNodeList("contents").forEach(Node => {
                    if (!nodeList.exist(Node.getName(), Utils.PROP_ATTR_NAME, Node.getAttrValue(Utils.PROP_ATTR_NAME))) {
                        nodeList.addNode(Node);
                    }
                })
            // do
            // {
            //     if (coll == null)
            //         break;
            //     if ((coll = coll.getParentCollection ())!=null) {// Supuestamente tiene padre
            //         try {
            //             let tmpList = coll.getNodeList("contents");
            //             for (IXmlNode Node : tmpList) {
            //                 if (!nodeList.exist(Node.getName(),Utils.PROP_ATTR_NAME,Node.getAttrValue(Utils.PROP_ATTR_NAME))) {
            //                     nodeList.addNode(Node);
            //                 }
            //             }
            //         } catch (e) {
            //             console.log(e.message);
            //         }

            //     }// Supuestamente tiene padre
            // } while (nodeList.count() == 0);

            // Si al final no hay tal lista, nos vamos
            if (nodeList == null)
                return false;
            // Crear los contents de este objeto
            for (let i = 0; i < nodeList.count(); i++) {// Recorre cada nodo de la lista
                let contNode = nodeList.get(i);
                // Buscar la colección y sacarle una copia
                if (null == (appColl = await this.getOwnerApp().getCollection(str = XmlUtils.getNodeAttr(contNode, "src")))) {// Error
                    // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                    let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_LOADCONTENTFAIL_01, "{0} failed. Cannot find collection '{1}' to create contents '{2}'");
                    sb = sb.replace("{0}", FunctionName);
                    sb = sb.replace("{1}", str);
                    sb = sb.replace("{2}", contNode.getAttrValue("name"));
                    throw new XoneGenericException(-9191, sb);
                }// Error

                if (null == (newColl = appColl.CreateClone())) {// Error
                    // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                    let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_LOADCONTENTFAIL_02, "{0} failed. Cannot clone collection '{1}'");
                    sb = sb.replace("{0}", FunctionName);
                    sb = sb.replace("{1}", str);
                    throw new XoneGenericException(-9192, sb);
                }// Error
                // Cambiar los parámetros de la nueva colección de acuerdo
                // a los valores que vienen en el nodo
                str = XmlUtils.getNodeAttr(contNode, "filter");
                newColl.setLinkFilter(str);
                // Ahora debe insertar esta colección en la lista
                str = XmlUtils.getNodeAttr(contNode, "name");
                this.AddContentsColl(str, newColl);
                // Incluir el atributo necesario para comprobar si se busca en BD o no
                //newColl.setXlatExist (StringUtils.ParseBoolValue(XmlUtils.getNodeAttr(contNode, "xlat-exist"), true));
                if (!StringUtils.IsEmptyString(str = XmlUtils.getNodeAttr(contNode, "sort")))
                    newColl.setSort(str);
            }// Recorre cada nodo de la lista
            return (this.m_bContentsLoaded = true);
        }
        catch (ex) {
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            let sMessage = ex.message;
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
            sb = sb.replace("{0}", FunctionName);
            if (!TextUtils.isEmpty(sMessage)) {
                sb = sb.concat(sMessage);
            }
            throw new XoneGenericException(-10131, ex, sb);
        }
    }

    /**
     * Adiciona una colección contents a este objeto
     * @param ContentsName			Nombre del contents dentro de este objeto.
     * @param Collection			Colección que se usará como contents.
     */
    private AddContentsColl(ContentsName: string, Collection: XoneDataCollection): void {
        // Almacenar en la colección
        this.m_lstContents.put(ContentsName, Collection);
        // Asignarle el objeto propietario (este)
        Collection.setOwnerObject(this);
    }

    getId(): any {
        return this.m_lObjectId;
    }
    getIdFieldName(): any {
        return this.m_owner.getIdFieldName();
    }
    getObjectName(): any {
        return this.m_owner.getObjectName();
    }
    PrepareSqlString(Sentence: string, bRemoveQuotes: boolean = false): string {
        // Primeramente intentar sustituir las macros de toda la vida.
        // Si es cadena vacía o no contiene macros, devolverla tal cual
        if (StringUtils.IsEmptyString(Sentence))
            return Sentence;
        if (!Sentence.contains("##"))
            return Sentence;
        // ADD TAG Como esto es mas comun, hacerlo de primero.
        // Sustituir los valores de macros FLD_ que son de este mismo objeto
        if (Sentence.contains("##FLD_")) {// Sustituir macros de campo
            //TODO ADD TAG 13/05/2014 Juan Carlos. Añado sobrecarga a PrepareSqlString, hace falta para PropertyTitle. Buscar esta fecha para el resto de cambios.
            Sentence = this.ReplaceFieldValueMacros(Sentence, "##FLD_", bRemoveQuotes);
            if (!Sentence.contains("##"))
                return Sentence;
        }// Sustituir macros de campo
        // Ahora mandarla a sustituir en la colección
        // F12042601: PrepareSqlString no delega en la aplicación cuando se llama a la colección.
        let str = this.m_owner.PrepareSqlString(Sentence, false, false);
        // Si una vez tratada ya no contiene macros, no hacer nada más
        if (!str.contains("##"))
            return str;
        // De lo contrario procesar aquí...
        let strTmp = str.trim();
        if (StringUtils.IsEmptyString(strTmp))
            return str;
        let strOut = str;
        strOut = StringUtils.Replace(strOut, "\"", "##QUOTES##");
        strOut = StringUtils.Replace(strOut, "##ID##", this.GetObjectIdString(true));
        if (!strOut.contains("##"))
            return strOut;
        // Lista de clientes (si la tiene)
        let str1;
        // Macro de clientes... muy poco usada, pero mantenemos compatibilidad
        if (strOut.contains("##CLIENTIDCOLL##")) {// Evaluar dicha macro
            str1 = this.GetClientIdColl();
            if (StringUtils.IsEmptyString(str1))
                strOut = StringUtils.Replace(strOut, "=##CLIENTIDCOLL##", " IS NOT NULL ");
            else {// Reemplazar la macro
                if (!str1.contains(","))
                    strTmp = "=" + str1;
                else
                    strTmp = " IN (" + str1 + ")";
                strOut = StringUtils.Replace(strOut, "=##CLIENTIDCOLL##", strTmp);
            }// Reemplazar la macro
            if (!strOut.contains("##"))
                return strOut;
        }// Evaluar dicha macro
        // Cadena de claves múltiples en caso de que el objeto las lleve...
        if (strOut.contains("##MULTIKEY##"))
            strOut = StringUtils.Replace(strOut, "##MULTIKEY##", this.GetMultipleKeyString(true));
        if (!strOut.contains("##"))
            return strOut;

        if (strOut.contains("##OWN_")) {// Sustituir las macros de campo del objeto propietario
            let owner = this.m_owner.getOwnerObject();
            if (owner != null) {// Sustituir
                strOut = owner.ReplaceFieldValueMacros(strOut, "##OWN_", false);
                if (!strOut.contains("##"))
                    return strOut;
            }// Sustituir
        }// Sustituir las macros de campo del objeto propietario
        // Sustituir las comillas
        strOut = strOut.replace('\"', '\'');
        // Restablecer las comillas
        strOut = StringUtils.Replace(strOut, "##QUOTES##", "\"");
        if (!strOut.contains("##"))
            return strOut;
        // Operaciones de sustitución con condiciones
        let k = 0, x = 0;
        for (; -1 != (x = strOut.indexOf("##ISNULL("));) {// Existe
            if (-1 == (k = strOut.indexOf(")##", x + 8)))
                break;
            // Ahora buscar el nombre del campo
            // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
            str = strOut.substring(x + 9, k);
            let vl = this.get(str);
            if (typeof vl == 'string') {// Cadena
                strTmp = vl.toString();
                if (StringUtils.IsEmptyString(strTmp))
                    strTmp = "(" + str + "='' OR {0} IS NULL)";
                else
                    strTmp = str + "=" + strTmp;
            }// Cadena
            else {// Ver si es entero
                if (ObjUtils.IsInt(vl)) {// Entero o long
                    let d = NumberUtils.SafeToDouble(vl);
                    if (Math.abs(d) < 0.000001)
                        strTmp = "(" + str + "=0 OR {0} IS NULL)";
                    else {// No es cero
                        vl = this.get(str);
                        strTmp = str + "=" + this.DevelopObjectValue(vl);
                    }// No es cero
                    break;
                }// Entero o long
                else
                    strTmp = str + "=" + this.DevelopObjectValue(vl);
            }// Ver si es entero
            // Ahora sustituir la cadenilla
            // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
            str = strOut.substring(0, x) + strTmp + strOut.substring(k, strOut.length - 3);
            strOut = str;
        }// Existe
        // Devolver lo que quede
        return strOut;
    }

    GetRawPropertyValue(arg0: string): Object {
        throw new Error("Method not implemented.");
    }

    ReplaceFieldValueMacros(Sentence: string, Prefix: string, SuppressQuotes: boolean = false): string {
        let k = 0;
        let FunctionName = "CXoneDataObject::ReplaceFieldValueMacros";

        for (k = Sentence.indexOf(Prefix); k != -1; k = Sentence.indexOf(Prefix)) {// Mientras haya campos por sustituir
            //
            // Puede darse el caso de que se trate de un error de sintaxis en la declaración
            // del filtro. En ese caso hay que señalar el error.
            let x;
            if (-1 == (x = Sentence.indexOf("##", k + 1))) {// Error
                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_REPLFLDVALUEMACROFAIL, "{0} failed. Syntax error in expression '{1}'");
                sb = sb.replace("{0}", FunctionName);
                sb = sb.replace("{1}", Sentence);
                throw new XoneGenericException(-13667, sb);
            }// Error
            // F09042201: Substring no funciona igual en java que en .NET Arreglar Blackberry
            // F13022205: Arreglos varios en ReplaceFildValueMacros.
            // El tamaño del prefijo, no un 6 fijo...
            let strTmp = Sentence.substring(k + Prefix.length, x);
            if (strTmp.length > 1)
                if (strTmp.charAt(0) == '$' && strTmp.charAt(1) == '$') {// Descartar el primer $
                    strTmp = strTmp.substring(1, strTmp.length - 1);
                }// Descartar el primer $
            let vl = this.get(strTmp);
            strTmp = this.DevelopObjectValue(vl);
            // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
            // F13022205: Arreglos varios en ReplaceFildValueMacros.
            // Si hay que suprimir las comillas las suprimimos
            if (SuppressQuotes && !StringUtils.IsEmptyString(strTmp)) {// Pos eso
                if (strTmp.startsWith("'") && strTmp.endsWith("'")) {
                    //TODO ADD TAG 13/05/2014 Juan Carlos strTemp.length() - 2? Eso se come una letra de más...
                    //strTmp = strTmp.substring(1, strTmp.length() - 2);
                    strTmp = strTmp.substring(1, strTmp.length - 1);
                }
            }// Pos eso
            let strRpl = Sentence.substring(k, x + 2);
            Sentence = StringUtils.Replace(Sentence, strRpl, strTmp);
        }// Mientras haya campos por sustituir
        // Completo
        return Sentence;
    }

    /**
     * Devuelve el valor de un atributo de un campo de datos de la colección a la cual pertenece este objeto.
     *
     * M11060901: Permitir que FieldPropertyValue evalúe o no para los frameworks.
     * Un parámetro más para indicar si se evalúa o no.
     *
     * @param FieldName			Nombre de la propiedad (campo) en el cual se quiere buscar un valor de atributo.
     * @param AttrName			Nombre del atributo cuyo valor se quiere obtener para el campo dado.
     * @return					Devuelve el valor del atributo o NULL si el atributo no está definido.
     * @throws Exception
     */
    public FieldPropertyValue(FieldName: string, AttrName: string, bEvaluate: boolean = true): string {
        // Si no tiene propietario, no hacer nada
        if (this.m_owner == null)
            return null;
        // K11051801: Desarrollar macros en el FieldPropertyValue de los objetos.
        // M12102401: Gestión de los atributos a nivel de objetos cacheando los valores.
        // Primero buscar el valor cacheado si lo hubiere...
        let sVal = "";
        let sKey = FieldName + ":" + AttrName;
        if (this.m_lstOverridenFieldPropertyValueAttributes.containsKey(sKey)) {
            sVal = this.m_lstOverridenFieldPropertyValueAttributes.get(sKey);
        } else if (this.m_lstCachedAttributes.containsKey(sKey)) {
            sVal = this.m_lstCachedAttributes.get(sKey);
        } else {
            sVal = this.m_owner.FieldPropertyValue(FieldName, AttrName);
        }
        // Ahora podemos seguir evaluando y tal y tal.
        if (bEvaluate) {
            if (!StringUtils.IsEmptyString(sVal))
                if (sVal.contains("##")) {// Es una macro o la contiene
                    // M11060902: Mejoras en el sistema de evaluación de macros en el framework.
                    if (sVal.equals(Utils.EMPTY_STRING))
                        return null;
                    sVal = this.PrepareSqlString(sVal, false);
                    // M11060902: Mejoras en el sistema de evaluación de macros en el framework.
                    if (sVal.equals("NULL"))
                        return null;
                    if (!StringUtils.IsEmptyString(sVal)) {// Si viene entrecomillado limpiar un poco
                        // F11111104: Los filtros de línea pueden devolver un valor incorrecto.
                        // Esto hay que retocarlo un poquito...
                        // F13061905: Problemas de separadores y delimitadores en GetValueFromString.
                        // Tiene que empezar y terminar con apóstrofes...
                        // TODO ADD TAG 280514 Luis es un and no un or en el inicio y fin de '
                        if (sVal.startsWith("'") && sVal.endsWith("'") && sVal.length >= 2) {// Quitar las comillas
                            // F11111104: Los filtros de línea pueden devolver un valor incorrecto.
                            // Solamente si no es un filtro...
                            // F13061906: Al evaluar FieldPropertyValue hay que excluir filter y linkfilter.
                            // linkfilter también es un filtro, así que también lo excluimos...
                            if (!AttrName.equals("filter") && !AttrName.equals("linkfilter"))
                                sVal = sVal.substring(1, sVal.length - 1);
                        }// Quitar las comillas
                    }// Si viene entrecomillado limpiar un poco
                }// Es una macro o la contiene
        }
        return sVal;
    }

    public GetObjectIdString(Develop: boolean = false): string {
        let str = "";

        if (!StringUtils.IsEmptyString(this.m_strObjectId) && !Develop)
            return this.m_strObjectId;
        // De lo contrario, la desarrollamos aquí
        if (this.m_lObjectId > 0) {// Clave única y numérica
            // O11100301: Si ya el IDString de un objeto tiene valor no hay por qué volver a generarlo.
            if (StringUtils.IsEmptyString(this.m_strObjectId))
                this.m_strObjectId = String.format("%d", this.m_lObjectId);
            return this.m_strObjectId;
        }// Clave única y numérica
        else {// Múltiple o cadena
            if (!this.m_owner.getStringKey()) {// Numérica
                if (Develop)
                    return "NULL";
                // De lo contrario, cadena vacía
                return "";
            }// Numérica
            // Ver si es múltiple
            if (this.m_owner.getMultipleKey()) {// Múltiple
                this.m_strObjectId = this.GetMultipleKeyString(false);
                return this.GetMultipleKeyString(Develop);
            }// Múltiple
            else {// Cadena
                if (Develop)
                    str = this.DevelopObjectValue(this.get(this.getIdFieldName()));
                else
                    str = this.m_strObjectId = this.get(this.getIdFieldName()).toString();
                return str;
            }// Cadena
        }// Múltiple o cadena
    }

    GetRawStringField(fieldName: string): string {
        return "";
    }

    /**
     * Desarrolla el valor de un objeto y lo devuelve como cadena lista para insertar en un SQL o un filtro.
     *
     * K12102201: Modificación de DevelopObjectValue para pasar ForceNulls como parámetro.
     * Un parámetro para indicar el valor de ForceNulls, por si alguien lo necesita.
     *
     * @param Value			Valor que se quiere desarrollar.
     * @return				Devuelve el valor en forma de cadena con sus comillas, formatos de fecha y etc. etc.
     */
    public DevelopObjectValue(Value: Object, ForceNulls: boolean = true): string {
        return this.m_owner.DevelopObjectValue(Value, ForceNulls);
    }

    /**
     * Esta función se llama cuando se termina de cargar el objeto como consecuencia de una búsqueda.
     */
    public OnNormalSearh(): void {
        // En principio esta función de la clase base no hace nada... redefinirla en clases hijas.
    }

    public getOwnerApp(): XoneApplication {
        return this.m_owner.getOwnerApp();
    }

    /**
     * @return		Devuelve TRUE si se trata del elemento actual de un recorrido SB/EB para usar los datos sacados directamente del recordset.
     */
    public getIsCurrentItem(): boolean {
        return this.m_bIsCurrentItem;
    }

    /**
     * Asigna valor a la bandera que indica que este objeto es el CurrentItem de una colección con carga diferida.
     * @param value		TRUE para indicar que es el objeto actual del recorrido. FALSE para un comportamiento normal.
     */
    public async setIsCurrentItem(value: boolean): Promise<void> {
        let bOldVal = this.m_bIsCurrentItem;
        let vl = null;

        if (this.m_bIsCurrentItem = value) {// Es el actual
            vl = this.GetRawPropertyValue(this.getIdFieldName());
            // F11092601: Protegerse a la hora de armar las cadenas de IDs.
            this.m_strObjectId = StringUtils.SafeToString(vl);
            if (this.m_owner.getStringKey()) {// Cadenilla
                ////m_strObjectId = vl.toString();
                this.m_lObjectId = -1;
            }// Cadenilla
            else {// Numérico
                this.m_lObjectId = NumberUtils.SafeToLong(vl);
                // F11092601: Protegerse a la hora de armar las cadenas de IDs.
                ////m_strObjectId = StringUtils.SafeToString(vl);
            }// Numérico
            // Limpiar los contents por si alguien usa LoadAll
            this.ClearContents();
            // Enumeration<String> e =this.m_lstContents.keys();
            // while (e.hasMoreElements())
            // {// Cada colección
            // 	XoneDataCollection cnt =this.m_lstContents.get(e.nextElement());
            //     cnt.Clear();
            //     cnt.setFilter (null);
            // }// Cada colección
            // F10072611: Al asignar un objeto como CurrentItem deben limpiarse sus datos.
            // Limpiar posibles valores y demás coisas
            this.m_lstNormalProperties.clear();
            this.m_lstOldValues.clear();
            this.ClearAllVariables();
            this.setDirty(false);    // Si somos currentitem ahora, entonces estamos limpios :-P
            // F12042506: Cuando se asigna valor TRUE a IsCurrentItem hay que ejecutar el OnLoad.
            try {
                await this.OnLoad();
            }
            catch (e1) {
                console.error(e1);
            }
        }// Es el actual
        else {// Ver si se ha reseteado
            if (bOldVal) {// Era un Deferred
                this.m_lObjectId = 0;
                this.m_strObjectId = null;
            }// Era un Deferred
        }// Ver si se ha reseteado
    }

    /**
     * Limpia todas las variables de este objeto (limpia la lista)
     */
    public ClearAllVariables(): void {
        this.m_lstVariables.clear();
    }

    /**
     * @param Develop	TRUE si se quiere que las parejas campo=valor están separadas por AND en vez de por comas, para facilitar su uso en base de datos.
     * @return			Devuelve una cadena con las parejas campo=valor de la clave múltiple del objeto usado para identificarlo de manera única.
     * @throws Exception
     */
    private GetMultipleKeyString(Develop: boolean = false): string {
        let strKey = "";
        if (this.m_owner.getMultipleKey()) {// Múltiples
            // Leer los valores de los campos claves y obtener una cadena que represente dicha clave
            for (let i = 0; i < this.m_owner.getKeyFields().length; i++) {// Obtener cada valor de campo
                let s = this.m_owner.getKeyFields()[i];
                let value = this[s];
                if (value != null) {// Tiene valor
                    let strTmp = s + "=" + this.DevelopObjectValue(value);
                    if (strKey.length > 0)
                        strKey += Develop ? " AND " : ",";
                    strKey += strTmp;
                }// Tiene valor
            }// Obtener cada valor de campo
        }// Múltiples
        else
            strKey = this.getIdFieldName() + "=" + this.GetObjectIdString(Develop);
        // Completo
        return strKey;
    }

    /**
     * @return		Devuelve la lista de IDs que forman la macro ENTIDCOLL.
     */
    public GetClientIdColl(): string {
        // En la clase base, lo que se devuelve es el ID del objeto
        return String.format("%d", this.m_lObjectId);
    }

    /**
     * Asigna un valor numérico a un campo del objeto
     * @param FieldName		Nombre del campo cuyo valor se quiere asignar.
     * @param value			Valor numérico que se quiere asignar al campo.
     * @throws Exception
     */
    public put(FieldName: string, value: any): void {
        this.PutItem(FieldName, value);
    }

    /**
     * Asigna valor a una propiedad del objeto.
     * @param FieldName		Nombre del campo al que se quiere asignar valor.
     * @param Value			Valor que se quiere asignar al campo.
     * @throws XoneGenericException
     **/
    protected PutItem(FieldName: string, Value: any): void {
        let str = "", strType = "", strKey = "", str1 = "", strProtect = "";
        let bMask = true, bAllow, bUpdate = true, bForce = false, bUpdated = false;
        let oldVal: any;
        let FunctionName = "CXoneDataObject::PutItem";

        // Asignar valor a una propiedad
        this.m_bChangesMade = false;
        try {
            // A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
            // Cada vez que se entre tenemos un nivel mais...
            this.m_nSetValDepth++;
            // Macros y detalles no plis...
            str = FieldName;
            if (StringUtils.IsEmptyString(str))
                return;
            if (str.startsWith("#") || str.startsWith("@"))
                return;
            // Igualmente bitmapped
            if (str.startsWith("%")) {// Bitmapped
                this.PutBitmappedProperty(FieldName, Value);
                return;
            }// Bitmapped
            // Puede que sea un bitmapped
            strType = this.FieldPropertyValue(str, "bit");
            if (!StringUtils.IsEmptyString(strType)) {// Bitmapped
                this.PutBitmappedProperty(FieldName, Value);
                return;
            }// Bitmapped
            // Puede que sea una propiedad calculada sin #
            strType = this.FieldPropertyValue(str, "method");
            if (!StringUtils.IsEmptyString(strType))
                return;
            strType = this.FieldPropertyValue(str, "volatile");
            if (StringUtils.ParseBoolValue(strType, false))
                bForce = true;
            if (str.startsWith("MAP_"))
                bMask = false;
            // Propiedad normal...
            if (this.m_lstNormalProperties.containsKey(str))
                oldVal = this.m_lstNormalProperties.get(str);
            else {// No existe la propiedad, así que ponerla
                oldVal = null;
                // Buscar los atributos de esta propiedad que deben marcarse
                if (!StringUtils.IsEmptyString(strProtect = this.FieldPropertyValue(str, "xlat")))
                    this.m_lstXlatProperties.push(str);
            }// No existe la propiedad, así que ponerla
            // Buscar la propiedad de antiguo valor y si no está crearla
            strKey = str;
            // F10072612: Al asignarse un valor a una propiedad, hay que quitar el valor viejo de los OldValues.
            // Buscar la propiedad de antiguo valor y si no está crearla
            this.m_lstOldValues.delete(strKey);
            if (oldVal != null)
                this.m_lstOldValues.put(strKey, oldVal);
            if (Value instanceof String) {// Cadena
                str = Value.toString();
                // let bVal = StringUtils.ParseBoolValue(this.FieldPropertyValue(FieldName, "validate"), false);
                // // Cuando se está clonando no se valida
                // bVal &= (!this.m_bIsCloning);
                // if (bVal)
                // {// Validar
                //     let testVal = str;
                //     bAllow = false;
                //     let vd =new XoneValidateData (testVal);
                //     if (!ValidateFieldValue(FieldName, vd))
                //     {// Fallar, pero si hay que actualizar, hacerlo
                //         if (!vd.getAllow ())
                //             return;
                //         else
                //         {// No actualiza, pero no falla
                //             bUpdate = false;
                //         }// No actualiza, pero no falla
                //     }// Fallar, pero si hay que actualizar, hacerlo
                //     str = vd.getValue().toString();
                // }// Validar
                strType = this.FieldPropertyValue(FieldName, "type");
                // Si no tiene tipo asumimos el más flexible :-P
                if (StringUtils.IsEmptyString(strType))
                    strType = "T";
                if (strType.equals("X")) {// Password
                    // F11010301:	No codificar a Base64 los passwords vacíos.
                    // Si tenemos valor, lo convertimos
                    if (!StringUtils.IsEmptyString(str)) {// Solo si tiene valor
                        let sHashType = this.FieldPropertyValue(FieldName, "hash-type");
                        let sEncode = this.FieldPropertyValue(FieldName, "encode");
                        // byte[] buf = new byte[str.length()];
                        // StringUtils.CopyAsBytes(str, buf, 0);
                        // // A11042901: Modificaciones para adicionar nuevos algoritmos de comprobación de claves.
                        // // Si tenemos hash, tendremos que usarlo

                        // if (!StringUtils.IsEmptyString(sHashType))
                        // 	str = CalculatePasswordHash(str, MessageDigestAlgorithm.getValue(sHashType), sEncode);
                        // else
                        //     str = Base64.encodeBytes(buf);
                        str = HashUtils.createHash(sHashType, sEncode, str);
                        // F10091609:	Las modificaciones en valores de cadena no actualizan el valor seteado.
                        Value = str;
                    }// Solo si tiene valor
                }// Password
                else if (strType.equals("TD")) {// Fecha cadenilla
                    //  Las fechas al 'A.S.Roiz Style' se preprocesan aquí
                    //  También se arreglan otros casos de datos de tipo cadena.
                    //  Aquí se ha optimizado un poco el proceso de comparación
                    //  porque se hacían todas las comparaciones aunque ya se hubiera
                    //  eocntrado un caso. No es que mejore mucho, pero los tiempos
                    //  sumados de la cantidad de datos de tipo cadena que hay
                    //  puede darle alguna mejorilla a esto...
                    str = this.AdjustDate(str); // Fecha
                    // F10091609:	Las modificaciones en valores de cadena no actualizan el valor seteado.
                    Value = str;
                }// Fecha cadenilla
                else if (strType.equals("TM")) {// Mes/Año
                    str = this.AdjustMonthYear(str);
                    // F10091609:	Las modificaciones en valores de cadena no actualizan el valor seteado.
                    Value = str;
                }// Mes/Año
                else if (strType.equals("TB")) {// Contabilidad
                    str = this.FormatAcc(str);			// Contabilidad
                    // F10091609:	Las modificaciones en valores de cadena no actualizan el valor seteado.
                    Value = str;
                }// Contabilidad
                else if (strType.equals("TN")) {// Por si las moscas
                    str = str.toUpperCase();			// Por si las moscas
                    // F10091609:	Las modificaciones en valores de cadena no actualizan el valor seteado.
                    Value = str;
                }// Por si las moscas
                else if (strType.equals("TC")) {// Cuenta bancaria
                    str = this.FormatCCC(str);
                    str = this.AdjustAccNumber(str);	// Cuenta bancaria
                    // F10091609:	Las modificaciones en valores de cadena no actualizan el valor seteado.
                    Value = str;
                }// Cuenta bancaria
                else if (strType.equals("D")) {// Fecha
                    // F11011203:	Al asignarse cadena vacía a un campo de fecha, ponerle fecha nula.
                    if (StringUtils.IsEmptyString(str))
                        Value = null;
                    else {// Lo de siempre
                        Value = StringUtils.ParseDateString(this.getOwnerApp().currentContext(), str);
                    }// Lo de siempre
                }// Fecha
                else {// Todo lo demás
                    if (strType.startsWith("N")) {// Convertir a lo que sea
                        if (strType.length == 1) {// Entero
                            //let l = NumberUtils.SafeToInt(str);
                            Value = NumberUtils.SafeToInt(str);
                        }// Entero
                        else {// Decimal
                            //let d = NumberUtils.SafeToDouble(str);
                            Value = NumberUtils.SafeToDouble(str);
                        }// Decimal
                    }// Convertir a lo que sea
                }// Todo lo demás
                // Si sigue siendo una cadena entonces hacemos esto, de lo contrario nio nio nio
                if (Value instanceof String) {// Es una cadena
                    // Aplicar la protección si la tiene...
                    strProtect = this.FieldPropertyValue(FieldName, "protect");
                    if (StringUtils.ParseBoolValue(strProtect, false)) {// Encriptar la cadena
                        str1 = this.FieldPropertyValue(FieldName, "size");
                        if (!StringUtils.IsEmptyString(str1)) // Tiene tamaño declarado... imprescindible
                            str = this.ProtectString(str, NumberUtils.SafeToInt(str1));
                    }// Encriptar la cadena
                    // Si se han hecho cambios habrá que indicarlos
                    if (bUpdate) {// Solo actualiza si no hay error
                        //  Si este valor está en los índices, matar el valor actual, porque después
                        //  la búsqueda se puede poner muy mala
                        this.m_lstOldValues.put(FieldName, oldVal);
                        // Solo puede marcar el campo como modificado si no está
                        // cargando de disco, o si está clonando el objeto...
                        // F11011204:	Agregar el campo a la lista de modificados solo si ha cambiado.
                        // En el dirtyproperties solo si ha cambiado
                        if (!FieldName.startsWith("MAP_") && (!this.m_bIsLoading || this.m_bIsCloning)) {// Indicar que está modificada
                            // if (!this.m_lstDirtyProperties.contains(FieldName))
                            //    this.m_lstDirtyProperties.addElement(FieldName);
                        }// Indicar que está modificada
                        else
                            bMask = false;
                        // F12112109: Hay una comparación redundante cuando se asigna un valor de cadena.
                        // Si estamos en instanceof String Value no puede ser NULL nunca.
                        //////if (Value ==null)
                        //////	m_lstNormalProperties.remove(FieldName);
                        //////else
                        this.setNormalValue(FieldName, Value);
                        //this[FieldName] = Value;
                        //this.m_lstNormalProperties.put(FieldName, Value);
                        bUpdated = true;
                    }// Solo actualiza si no hay error
                }// Es una cadena
            }// Cadena
            // Si no es una cadena o ha cambiado de jeta
            if (!(Value instanceof String) && !bUpdated) {
                /*
                 * TODO 17/03/2017 Juan Carlos
                 * Hay campos ID que declara el propio desarrollador de mappings y no tienen nada
                 * que ver. Esto lo cambio y lo pongo igual que iOS (startsWith pasa a ser un
                 * equals).
                 */
                // Comprobar si es un campo ID...
                if (FieldName.equals("ID") && (ObjUtils.IsNumeric(Value))) {// Es un ID algo
                    let l = NumberUtils.SafeToInt(Value);
                    if (l == 0)
                        Value = null;    // Nulo
                }// Es un ID algo
                // Comprobar si es válido

                strProtect = this.FieldPropertyValue(FieldName, "validate");
                if (StringUtils.ParseBoolValue(strProtect, false) && !this.m_bIsCloning) {// Comprobar
                    bAllow = false;
                    let testVal = Value;
                    // Estructura para validar
                    // XoneValidateData vd =new XoneValidateData (testVal);
                    // if (!ValidateFieldValue(FieldName, vd))
                    // {// Falla, pero puede que se permita cambiar valor
                    //     if (!bAllow)
                    //         return;
                    //     else
                    //     {// No actualiza y no falla
                    //         bUpdate = false;
                    //     }// No actualiza y no falla
                    // }// Falla, pero puede que se permita cambiar valor
                    // Value = vd.getValue();
                }// Comprobar
                if (bUpdate || bForce) {// Solo si no hubo error
                    // Antes de almacenar el valor, guardar el anterior
                    if (oldVal != null)
                        this.m_lstOldValues.put(FieldName, oldVal);
                    else
                        this.m_lstOldValues.delete(FieldName);
                    // Ahora asignar el nuevo valor
                    if (Value == null) {
                        this.setNormalValue(FieldName, null);
                        //this[FieldName] = null;
                        this.m_lstNormalProperties.delete(FieldName);
                    } else {
                        this.setNormalValue(FieldName, Value);
                        //this[FieldName] = Value;
                        //this.m_lstNormalProperties.put(FieldName, Value);
                    }
                    // Si es una propiedad modificable...
                    // F11011204:	Agregar el campo a la lista de modificados solo si ha cambiado.
                    // En el dirtyproperties solo si ha cambiado
                    ////if (bMask)
                    ////    m_lstDirtyProperties.addElement(FieldName);
                }// Solo si no hubo error
            }// Comprobar si es un campo ID...
            let bCheck = false;
            // F12112105: No se están haciendo los chequeos correctos al clonar objetos.
            // Verificar más cosas aquí.
            bCheck = (!(ObjUtils.EqualObj(oldVal, Value)) || bForce) && (!this.m_bIsLoading || this.m_bIsCloning) && bUpdate;
            if (bCheck) {// Actualiza la banderilla
                // F11011204:	Agregar el campo a la lista de modificados solo si ha cambiado.
                // En el dirtyproperties solo si ha cambiado
                if (bMask) {// Indicar el dirty
                    this.setDirty(true);
                    // Si no está en lista, la agregamos
                    if (!this.m_lstDirtyProperties.contains(FieldName))
                        this.m_lstDirtyProperties.push(FieldName);
                }// Indicar el dirty
                this.m_bChangesMade = true;	// Ha habido cambios
                // Marcar el timestamp de actualización, solo si se usa
                if (this.m_bUseTimestamp) {// Timestamp de la operación
                    let d = new Date();
                    if (this.m_dtTimeStamp == null)
                        this.m_dtTimeStamp = Calendar.getInstance();
                    else
                        this.m_dtTimeStamp.setTime(d);
                    d = null;
                }// Timestamp de la operación
                // // A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
                // // Si el campo es trigger, ensuciar las fórmulas :-P
                // // K13081201: Cambios en el tratamiento de fórmulas para mejorar la concurrencia.
                // // Podemos tener problemas con esto...
                // synchronized (this.m_formulaLocker)
                // {
                //     if (this.m_formulas != null)
                //     {// Habemus
                //     	Enumeration<String> enm =this.m_formulas.keys();
                //         while (enm.hasMoreElements())
                //         {
                //         	String key =enm.nextElement();
                //         	XoneEvalData val =this.m_formulas.get(key);
                //             if (val.getFormula().IsTrigger(FieldName))
                //                 val.Invalidate();
                //         }
                //     }// Habemus
                // }
            }// Actualiza la banderilla
            //
            // Si el objeto ha quedado sucio, marcar su propietario
            // Comprobar si hay que propagar el estado de modificación
            if (this.m_bDirty && this.m_bPropagateDirty) {// Verificar si tiene objeto propietario
                if (null != (this.m_owner.getOwnerObject()))   // Tiene propietario
                    this.m_owner.getOwnerObject().setDirty(this.m_bDirty);
            }// Verificar si tiene objeto propietario
            // Ahora la idea es buscar si esta propiedad mapea
            // hacia alguna otra y modificarla también
            if (this.m_bChangesMade) {// Como se han hecho cambios hay que mapear
                if (FieldName.equals(this.getIdFieldName())) {// Es el ID
                    if (!this.m_owner.getStringKey()) {// Obtener el valor y ponerlo en el campo
                        this.m_lObjectId = NumberUtils.SafeToInt(Value);
                    }// Obtener el valor y ponerlo en el campo
                }// Es el ID
                if (!StringUtils.IsEmptyString(strKey = this.FieldPropertyValue(FieldName, "updates"))) {// Tiene campo para cambiar
                    let o = this.PreprocessValue(FieldName, strKey, Value);
                    this.SetPropertyValue(strKey, o);
                }// Tiene campo para cambiar
                // Ahora debe verificar si hay que recargar algún mapeo
                // por si las moscas
                if (!this.m_bIsLoading && this.m_owner != null) {// No está cargando
                    this.RestoreMappedFields(FieldName).then(value => {
                        if (!value) {// Error
                            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_PUTITEMFAIL, "{0} failed. Cannot update mapped fields.");
                            sb = sb.replace("{0}", FunctionName);
                            throw new XoneGenericException(-23231, sb);
                        }// Error
                    }).catch(reason => {
                        // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                        let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_PUTITEMFAIL, "{0} failed. Cannot update mapped fields. Reason: {1}");
                        sb = sb.replace("{0}", FunctionName);
                        sb = sb.replace("{1}", reason);
                        throw new XoneGenericException(-23231, sb);
                    });

                    const onChange = async () => {
                        await this.OnChange(FieldName);
                    }
                    onChange();
                    // Algo pudo haber fallado por allí adentro
                }// No está cargando
                // Ver si se actualizan campos con esta cadena
                // Si se está usando el timestamp del objeto, marcar la fecha y hora
                // del cambio que se está realizando
                // F11100304: La comparación del campo OBJTIMESTAMP no se hace con el valor correcto.
                if (this.m_bUseTimestamp && !FieldName.equals("OBJTIMESTAMP")) {// Marcar esta fecha y hora
                    if (!FieldName.startsWith("MAP_") && (!this.m_bIsLoading || this.m_bIsCloning)) {// Solo aquellas que realmente proviquen cambios
                        this.PutItem("OBJTIMESTAMP", this.m_dtTimeStamp);
                    }// Solo aquellas que realmente proviquen cambios
                }// Marcar esta fecha y hora
                // Si estaba a TRUE para entrar a esta condición, lo estará para salir
                // de manera que si lo ha llamado una clase hija, que mantenga el valor
                this.m_bChangesMade = true;
            }// Como se han hecho cambios hay que mapear
            this.postNewValue(FieldName, Value);
        }
        catch (ex) {
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            let sMessage = ex.message;
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
            sb = sb.replace("{0}", FunctionName);
            if (!TextUtils.isEmpty(sMessage)) {
                sb = sb.concat(sMessage);
            }
            console.error(ex);

            throw new XoneGenericException(-10101, ex, sb);
        }
        // finally
        // {
        //     // A12122701: Sistema para gestionar la estructura lógica de vistas en la maquinaria.
        //     // Estamos a nivel cero de evaluación, momento de verificar si evaluamos las fórmulas de
        //     // visibilidad, habilitación y tal y tal...
        //     --this.m_nSetValDepth;
        //     if (this.m_nSetValDepth <= 0)
        //     {// Actualizar las fórmulas
        //         // K13081201: Cambios en el tratamiento de fórmulas para mejorar la concurrencia.
        //         synchronized (this.m_formulaLocker)
        //         {
        //         	this.m_nSetValDepth =0;			// Por si las moscas...
        //             if (this.m_formulas != null)
        //             {// Anda
        //             	Enumeration<String> enm =this.m_formulas.keys();
        //                 while (enm.hasMoreElements())
        //                 {// Evaluar cada una
        //                 	String key =enm.nextElement ();
        //                 	XoneEvalData val =this.m_formulas.get(key);
        //                     if (val.getDirty())
        // 						try {
        // 							val.Evaluate();
        // 						} catch (Exception ex) {
        // 							// M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
        //                             String sMessage = ex.getMessage();
        //                             String sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
        //                             sb = sb.replace("{0}", FunctionName);
        //                             if (!TextUtils.isEmpty(sMessage)) {
        //                                 sb = sb.concat(sMessage);
        //                             }
        //                             throw new XoneGenericException(-10108, ex, sb);
        //                         }
        //                 }// Evaluar cada una
        //             }// Anda
        //         }
        //     }// Actualizar las fórmulas
        //}
    }

    /**
     * Esta función se llama desde get(x) para devolver el valor de una propiedad (campo) del objeto.
     * @param FieldName			Nombre del campo cuyo valor se quiere obtener.
     * @return					Devuelve el valor de la propiedad. Si es NULL se ajustará al nulo de su tipo de datos.
     * @throws Exception
     */
    protected GetItem(FieldName: string): any {
        let FunctionName = "GetItem";
        // Si es una cadena vacía, nos famos...
        if (StringUtils.IsEmptyString(FieldName))
            return null;
        // Primero efectuar algún análisis para ver si tenemos propiedad con nombre especial
        switch (FieldName.charAt(0)) {
            // K11041401: Declarar obsoletas las propiedades tipo method en el mappings.
            //////case '#':
            //////    return GetPropertyMacro(FieldName);
            case '%':
                return this.GetBitmappedProperty(FieldName);
            case '$':
                return this.GetFormulaProperty(FieldName);
        }
        // Puede que sea una propiedad de las anteriores con nombre común
        // O12050702: Optimización al gestionas los campos que son de fórmula o bit.
        // Saber si el campo es de fórmula ahora es un pelín diferente...
        //////String str = FieldPropertyValue(FieldName, "formula");
        //////if (!StringUtils.IsEmptyString(str))
        if (this.m_owner.IsFormulaProperty(FieldName)) {// Meter el pie
            // Ver si no se trata de un campo para generar con "generates"
            //////str = FieldPropertyValue(str, "generated");
            //////if (!StringUtils.ParseBoolValue(str, false))
            // O12050702: Optimización al gestionas los campos que son de fórmula o bit.
            // Esto era deprecated.... ahora es obsolete....
            return this.GetFormulaProperty(FieldName);  // Es una fórmula normal
        }// Meter el pie
        // K11041401: Declarar obsoletas las propiedades tipo method en el mappings.
        /*
        if (!StringUtils.IsEmptyString(str = FieldPropertyValue(FieldName, "method")))
            return GetPropertyMacro(FieldName);  // Método sin caracter de inicio
        */
        // O12050702: Optimización al gestionas los campos que son de fórmula o bit.
        // Esto ahora es un poco diferente
        //////if (!StringUtils.IsEmptyString(str = FieldPropertyValue(FieldName, "bit")))
        if (this.m_owner.IsBitProperty(FieldName))
            return this.GetBitmappedProperty(FieldName);   // Igual que en la anterior
        // A09100501:   Permitir que las propiedades tengan un tipo button.
        // Si es un botón, coger el valor normal y si es nulo, devolver el property-title
        let str = this.FieldPropertyValue(FieldName, "type");
        if (!StringUtils.IsEmptyString(str)) {// Tiene valor
            if (str.equals("B")) {// Botoncillo
                let v1, v2;
                v1 = this.PropertyTitle(FieldName);
                v2 = this.GetNormalProperty(FieldName);
                let str2 = StringUtils.SafeToString(v2);
                if (!StringUtils.IsEmptyString(str2))
                    v1 = str2;
                return v1;
            }// Botoncillo
        }// Tiene valor
        // Si es un current item con carga diferida, buscar el valor en la colección directamente
        let vl = null;
        if (this.m_owner.getDeferredLoad() && this.m_bIsCurrentItem) {// Buscar en el recordset
            // F13012402: Si un valor de un CurrentItem ha variado, devolverlo cuando se lea.
            // Para los valores modificados en un C.I.
            // Si lo que devuelve es NULL, entonces ajustarlo y devolverlo
            if (this.m_lstNormalProperties.containsKey(FieldName))
                vl = this.m_lstNormalProperties.get(FieldName);
            if (vl == null) {
                if (null == (vl = this.m_owner.GetCurrentItemValue(FieldName))) {// Si lo que hay en el recordset es nulo, podría ser un linkedto
                    // Si el campo no existe, tendremos que ver si se trata de un campo xlat o un campo mapeado
                    let strLinkedTo = this.FieldPropertyValue(FieldName, "linkedto");
                    if (!StringUtils.IsEmptyString(strLinkedTo) && !this.m_owner.getConnection().IsOuterJoinSupported()) {// Tiene linkedto
                        if (!StringUtils.IsEmptyString(this.FieldPropertyValue(FieldName, "linkedfield"))) {// Leer valores y devolver lo que sea
                            this.RestoreMappedFields(strLinkedTo).then(value => {
                                if (!value) {// Error
                                    // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                                    let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_PUTITEMFAIL, "{0} failed. Cannot update mapped fields.");
                                    sb = sb.replace("{0}", FunctionName);
                                    throw new XoneGenericException(-23231, sb);
                                }// Error
                            }).catch(reason => {
                                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                                let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_PUTITEMFAIL, "{0} failed. Cannot update mapped fields. Reason: {1}");
                                sb = sb.replace("{0}", FunctionName);
                                sb = sb.replace("{1}", reason);
                                throw new XoneGenericException(-23231, sb);
                            });
                            if (this.m_lstNormalProperties.containsKey(FieldName))
                                return vl = this.m_lstNormalProperties.get(FieldName);
                        }// Leer valores y devolver lo que sea
                    }// Tiene linkedto
                    return this.AdjustNullValue(FieldName);
                }// Si lo que hay en el recordset es nulo, podría ser un linkedto
                // De lo contrario devolverlo tal cual
                if (vl != null) {
                    this[FieldName] = vl;
                    //this.m_lstNormalProperties.put(FieldName, vl);
                }
            }
            // F11041504:	Al devolver un valor que se saca del recordset, procesarlo como siempre.
            // Procesar
            return this.ProcessValue(FieldName, vl);
        }// Buscar en el recordset
        // Finalmente será posiblemente una propiedad normal...
        return this.GetNormalProperty(FieldName);
    }
    GetFormulaProperty(FieldName: string): any {
        throw new Error("Method not implemented.");
    }
    GetBitmappedProperty(FieldName: string): any {
        throw new Error("Method not implemented.");
    }
    PropertyTitle(FieldName: string): any {
        // F11081105: Protegerse en el objeto cuando se trata de un objeto huérfano.
        // F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
        // No es lo mismo cadena vacía que NULL
        // TAG: 0512201601 : Luis He permitido cachear el title para si se cambia por script que se pueda devolver el correcto.
        // Antes solo se buscaba en la colección y cada objeto no podia teenr su porpio titulo personalizado

        let sKey = FieldName + ":" + Utils.PROP_ATTR_TITLE;
        if (this.m_lstOverridenFieldPropertyValueAttributes.containsKey(sKey)) {
            return this.m_lstOverridenFieldPropertyValueAttributes.get(sKey);
        } else if (this.m_lstCachedAttributes.containsKey(sKey)) {
            return this.m_lstCachedAttributes.get(sKey);
        } else if (this.m_owner == null) {
            return this.FieldPropertyValue(FieldName, Utils.PROP_ATTR_TITLE);
        }
        let sVal = this.m_owner.PropertyTitle(FieldName);
        if (!StringUtils.IsEmptyString(sVal)) {
            if (sVal.contains("##")) {// Es una macro o la contiene
                // M11060902: Mejoras en el sistema de evaluación de macros en el framework.
                // F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
                // No es lo mismo cadena vacía que NULL
                if (sVal.equals(StringUtils.XONE_EMPTY_STRING))
                    return Utils.EMPTY_STRING;
                //TODO ADD TAG 13/05/2014 Juan Carlos. Añado sobrecarga a PrepareSqlString, hace falta para PropertyTitle. Buscar esta fecha para el resto de cambios.
                sVal = this.PrepareSqlString(sVal, true);
                if (!StringUtils.IsEmptyString(sVal)) {// Si viene entrecomillado limpiar un poco
                    // M11060902: Mejoras en el sistema de evaluación de macros en el framework.
                    // F13022109: No devolver nulos cuando los atributos no existen o tienen cadenas vacías.
                    // No es lo mismo cadena vacía que NULL
                    if (sVal.equals("NULL"))
                        return Utils.EMPTY_STRING;
                    if (sVal.startsWith("'") || sVal.endsWith("'") && sVal.length >= 2)
                        sVal = sVal.substring(1, sVal.length - 1);
                    ////return sVal.replace("'", "");
                }// Si viene entrecomillado limpiar un poco
            }// Es una macro o la contiene
        }
        //SetFieldPropertyValue(FieldName,Utils.PROP_ATTR_TITLE,sVal,false);
        return sVal;
    }

    /**
     * Devuelve el valor de una propiedad de datos "normal"
     * @param FieldName		Nombre del campo cuyo valor se quiere leer.
     * @return				Devuelve el valor de una propiedad de datos "normal"
     * @throws Exception
     */
    protected GetNormalProperty(FieldName: string): any {
        let vl = null;
        const FunctionName = "GetNormalProperty";
        if (!this.m_lstNormalProperties.containsKey(FieldName)) {// No tiene valor, ver si es xlat
            // TODO: Luis, por ahora el xlat nos lo quitamos
            // if (!StringUtils.IsEmptyString(this.FieldPropertyValue(FieldName, "xlat")))
            // {// Existe la propiedad
            //     if (!this.DevelopXlatProperties(FieldName, true))
            //     {// Error
            //         // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            //         let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_GETNORMALPROPFAIL, "CXoneDataObject::GetNormalProperty failed. Cannot develop slat property '{0}'");
            //         sb =sb.replace("{0}", FieldName);
            //         throw new XoneGenericException(-19199, sb);
            //     }// Error
            //     // Obtener la de la lista
            //     if (this.m_lstNormalProperties.containsKey(FieldName))
            //         vl = this.m_lstNormalProperties.get(FieldName);
            // }// Existe la propiedad
            // else
            {// Ver si es una propiedad mapeada y resolverla ahora
                let strLinkedTo;
                if (!this.m_owner.getConnection().IsOuterJoinSupported() && !StringUtils.IsEmptyString(strLinkedTo = this.FieldPropertyValue(FieldName, "linkedto"))) {// Si está enlazada, buscar dicho valor
                    let strLinkedField = this.FieldPropertyValue(FieldName, "linkedfield");
                    if (!StringUtils.IsEmptyString(strLinkedField)) {// Resolver y leer
                        // Resolver los campos mapeados... esto se puede optimizar después
                        // usando algún mecanismo de carga que no cachee los datos del objeto
                        // sino que use carga diferida y lea directamente del recordset... ya se
                        // verá
                        this.RestoreMappedFields(strLinkedTo).then(value => {
                            if (!value) {// Error
                                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                                let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_PUTITEMFAIL, "{0} failed. Cannot update mapped fields.");
                                sb = sb.replace("{0}", FunctionName);
                                throw new XoneGenericException(-23231, sb);
                            }// Error
                        }).catch(reason => {
                            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_PUTITEMFAIL, "{0} failed. Cannot update mapped fields. Reason: {1}");
                            sb = sb.replace("{0}", FunctionName);
                            sb = sb.replace("{1}", reason);
                            throw new XoneGenericException(-23231, sb);
                        });
                        if (this.m_lstNormalProperties.containsKey(FieldName))
                            vl = this.m_lstNormalProperties.get(FieldName);
                    }// Resolver y leer
                }// Si está enlazada, buscar dicho valor
                else {// No está mapeado
                    // O10052504:	Las propiedades no mapeadas que se sacan de recordset puede cachearse.
                    // F11092602: No ir al resultset si este objeto no es CurrentItem.
                    // Solo busca en el recordset si yo soy current item...
                    if (this.m_bIsCurrentItem) {// Pozi
                        vl = this.m_owner.GetCurrentItemValue(FieldName);
                        if (vl != null) {
                            this[FieldName] = vl;
                            //this.m_lstNormalProperties.put(FieldName, vl);
                        }
                    }// Pozi
                }// No está mapeado
            }// Ver si es una propiedad mapeada y resolverla ahora
        }// No tiene valor, ver si es xlat
        else
            vl = this.m_lstNormalProperties.get(FieldName);

        return this.ProcessValue(FieldName, vl);
    }

    /**
     * Procesa un valor antes de devolverlo a quien lo está leyendo (aquí se decodifican los valores, se ajustan tipos, etc.)
     * @param FieldName			Nombre del campo al que pertenece el valor devuelto.
     * @param Value				Valor como tal.
     * @return					Devuelve el valor ya procesado en función de su tipo de datos.
     * @throws Exception
     */
    private ProcessValue(FieldName: any, Value: any): any {
        if (Value == null && this.m_lstXlatProperties.contains(FieldName) && !this.m_lstDevelopedXlatProperties.contains(FieldName)) {// Desarrollar esta propiedad
            // TODO ADD TAG
            // Solo lectura... si no existe el objeto pos ná...
            if (!this.DevelopXlatProperties(FieldName, true)) {// Error
                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_PROCESSVALFAIL, "CXoneDataObject::ProcessValue failed. Cannot develop xlat property '{0}'");
                sb = sb.replace("{0}", FieldName);
                throw new XoneGenericException(-19991, sb);
            }// Error
            this.m_lstDevelopedXlatProperties.push(FieldName);
        }// Desarrollar esta propiedad
        // O12052901: No llamar a FieldPropertyValue cuando no sea necesario (optimización)
        let str, strType = null;
        if (Value == null)
            return this.AdjustNullValue(FieldName);
        let vl = Value;  // La salida de la función, por defecto lo mismo que viene...
        if (Value instanceof String) {// Cadena
            str = Value.toString();
            strType = this.FieldPropertyValue(FieldName, "type");
            // F11041804: ProcessValue no está analizando los tipos con uno por defecto.
            if (StringUtils.IsEmptyString(strType))
                strType = "T";
            if (strType.equals("TW")) {// Weekday
                let n = NumberUtils.SafeToInt(Value);
                str = this.getOwnerApp().GetWeekDayName(n);
            }// Weekday
            else {// Probar otros
                if (strType.equals("X")) {// Password viejo (sin codificar)
                    if (!StringUtils.IsEmptyString(str)) {// Tiene valor
                        // A11042901: Modificaciones para adicionar nuevos algoritmos de comprobación de claves.
                        // Puede que traiga hash... en ese caso devolvemos lo que hay y punto.
                        // byte[] buffer = Base64.decode(str);
                        // String strTmp =this.FieldPropertyValue (FieldName, "hash-type");
                        // if (StringUtils.IsEmptyString(strTmp))
                        //     str = StringUtils.ReadFromBytes(buffer);
                        let sHashType = this.FieldPropertyValue(FieldName, "hash-type");
                        let sEncode = this.FieldPropertyValue(FieldName, "encode");
                        str = HashUtils.createHash(sHashType, sEncode, str);
                    }// Tiene valor
                }// Password viejo (sin codificar)
                else {// Cualquier otra cosa
                    //
                    // Ver si puede estar encriptado
                    let strProtect = this.FieldPropertyValue(FieldName, "protect");
                    if (!StringUtils.IsEmptyString(strProtect) && !strProtect.equals("0") && !strProtect.equals("false"))
                        str = this.UnprotectString(str, strProtect); // Desproteger la cadenilla
                    //
                    // Seguir con el proceso
                    let nLen = str.length();
                    switch (nLen) {
                        case 8:
                            if (strType.equals("TD"))
                                // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                                str = String.format("%02d/%02d/%04d", NumberUtils.SafeToInt(str.substring(6, 8)), NumberUtils.SafeToInt(str.substring(4, 6)), NumberUtils.SafeToInt(str.substring(0, 4)));   // Transformar la cadenilla
                            break;
                        case 22:
                            if (strType.equals("TC")) {// Ver si la cuenta viene con el formato adecuao
                                // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                                if (str.substring(8, 9).equals("#") && str.substring(11, 12).equals("#")) {// Tiene el formato
                                    // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                                    strType = str.substring(0, 4) + "-" + str.substring(4, 8) + "-" + str.substring(9, 11) + "-" + str.substring(12, 22);
                                    str = strType;
                                }// Tiene el formato
                            }// Ver si la cuenta viene con el formato adecuao
                            break;
                    }
                }// Cualquier otra cosa
            }
            vl = str;
        }// Cadena

        // F10052506:	El procesamiento de valores NC que vienen double es incorrecto.
        if (ObjUtils.IsFloat(Value)) {// Double
            let dbl = NumberUtils.SafeToDouble(Value);
            // O12052901: No llamar a FieldPropertyValue cuando no sea necesario (optimización)
            // Solo se llama FieldPropertyValue si hace falta...
            if (StringUtils.IsEmptyString(strType))
                strType = this.FieldPropertyValue(FieldName, "type");
            if ("N".equals(strType) || "NC".equals(strType))
                return NumberUtils.SafeToInt(dbl);
        }// Double

        // Los campos especiales read-once
        if (FieldName.equals("MAP_MULTIPLE") || FieldName.equals("MAP_REFINE")) // Limpiar esto
            this.m_lstNormalProperties.delete(FieldName);
        // Devolver el valor ya ajustado
        return vl;
    }

    public get ownerCollection() { return this.m_owner; }

    public getOwnerCollection(): XoneDataCollection {
        return this.ownerCollection;
    }

    getValue(FieldName): any {
        return this.GetItem(FieldName);
    }

    DevelopXlatProperties(FieldName: any, arg1: boolean): boolean {
        throw new Error("Method not implemented.");
    }

    protected AdjustNullValue(FieldName: string): Promise<any> {
        let strType = this.FieldPropertyValue(FieldName, "type");
        let Value = null;
        //
        // Comprobar los nulos
        if (StringUtils.IsEmptyString(strType)) {// Puede ser que sea una propiedad mapeada...
            // Esto es supuestamente ineficiente, pero puede
            // llegar a salvar el tipo cuando el usuario sea
            // indisciplinadillo...
            if (!StringUtils.IsEmptyString(strType = this.FieldPropertyValue(FieldName, "linkedto"))) {// Está enlazada a algún campo. Buscar por ahí
                // Buscar la posible colección a que está mapeada la propiedad
                // linkedto de esta propiedad... enredao, pero yo me entiendo
                if (!StringUtils.IsEmptyString(strType = this.FieldPropertyValue(strType, "mapcol"))) {// La propiedad tiene valor
                    // Buscar esta colección
                    // let coll: XoneDataCollection =  await this.m_owner.getOwnerApp().getCollection(strType);
                    // if (null != coll) // Vaya, el huevo en el conejo....
                    //     // Obtener el linkedfield de este campo para buscarlo en aquella colección
                    //     strType = coll.FieldPropertyValue(this.FieldPropertyValue(FieldName, "linkedfield"), "type");
                }// La propiedad tiene valor
            }// Está enlazada a algún campo. Buscar por ahí
        }// Puede ser que sea una propiedad mapeada...
        if (StringUtils.IsEmptyString(strType)) {// Todavía puede ser que no tenga tipo y sea el ID
            if (FieldName.equals(this.getIdFieldName())) {// Es la clave primaria
                if (this.m_owner.getStringKey())
                    Value = Utils.EMPTY_STRING;
                else
                    Value = 0;
            }// Es la clave primaria
            if (FieldName.equals(this.m_owner.getConnection().getRowIdFieldName()))
                Value = "";
        }// Todavía puede ser que no tenga tipo y sea el ID
        else {// Tiene tipo, puede operar :-P
            switch (strType.charAt(0)) {// Posibles tipos
                case 'T':
                case 'X':
                    Value = Utils.EMPTY_STRING;
                    break;
                case 'N':
                    //
                    // Comprobar si el tipo es entero o decimal. Si es decimal, asignar un REAL
                    if (strType.length == 1)
                        Value = 0;
                    else {// Ver si es NC o no
                        // F10112402: AdjustNullValue no tiene en cuenta los campos NC para valores nulos.
                        if (strType.equals("NC"))
                            Value = 0;
                        else
                            Value = 0.0;
                    }// Ver si es NC o no
                    break;
                case 'I':
                    if (strType.equalsIgnoreCase("img"))
                        Value = Utils.EMPTY_STRING;
                    break;
            }// Posibles tipos
        }// Tiene tipo, puede operar :-P
        // Devolver lo que sea
        return Value;
    }

    private m_reactLayout: any;


    //#region Transformaciones para el UI PWA
    public getLayout(visibility: number = 1): any {
        return this.m_owner.getLayout(visibility);
    }

    public setLayout(value: any) {
        this.m_reactLayout = value;
        // const attrs=value?.attributes;
        // if (attrs) {
        //     for (const key in attrs) {
        //         if (Object.prototype.hasOwnProperty.call(attrs, key)) {
        //             this.m_reactLayout.put(attrs.node + ":" + attrs.name+":"+ key,attrs[key]);
        //         }
        //     }
        // }
        // if (value?.controls && Array.isArray(value.controls)) {
        //     value.controls.forEach(element => this.setLayout(element));
        // }
    }




    public getPropAttributes(Value: string | XmlNode, type: string = Utils.PROP_NAME): any {
        return UITransform.getPropAttributes(this, Value, type);
        // let node: XmlNode;
        // let PropName: string
        // if (typeof Value == 'string') {
        //     node=this.m_owner.getNode(type,Utils.PROP_ATTR_NAME,Value);
        //     PropName=Value;
        // } else {
        //     node= Value as XmlNode;
        //     PropName=node.getAttrValue(Utils.PROP_ATTR_NAME);
        //     type=node.getName();
        // }
        // if (ObjUtils.IsNothing(node))
        //     return {};
        // let attrs=node.getAttrs();
        // let result=<any>{ node: type};
        // for (const key in UITransform.UI_ATTRIBUTES[type]) {
        //     if (Object.prototype.hasOwnProperty.call(UITransform.UI_ATTRIBUTES[type], key)) {
        //         const element = UITransform.UI_ATTRIBUTES[type][key];
        //         const value=node.getName().equalsIgnoreCase(Utils.PROP_NAME)?this.FieldPropertyValue(PropName,element):this.m_owner.NodePropertyValue(type,PropName,element);
        //         if (!TextUtils.isEmpty(value)) 
        //         {
        //             result[key]=UITransform.developValue(this,PropName,key,element);
        //         }
        //     }
        // } 
        // return result;
    }
    //#endregion

    public NodePropertyValue(NodeName: string, ItemName: string, AttrName: string): string {
        return this.getOwnerCollection().NodePropertyValue(NodeName, ItemName, AttrName);
    }

    QualifyField(FieldName: any, Sentence?: SqlParser): string {
        return this.m_owner.QualifyField(FieldName, Sentence);
    }


    public getNodeList(NodeName: string, AttrName?: string, AttrValue?: string, Exist?: boolean): XmlNodeList {
        return this.getOwnerCollection().getNodeList(NodeName, AttrName, AttrValue);
    }

    /**
     * @param NodeName		Nombre del nodo que se quiere buscar.
     * @param AttrName		Nombre del atributo que se quiere comparar.
     * @param AttrValue		Valor que tiene que tener el atributo para reconocer un nodo como válido.
     * @return				Devuelve un nodo XML dentro del nodo de esta colección con un atributo cuyo valor se solicita.
     */
    public getNode(NodeName: string, AttrName?: string, AttrValue?: string): XmlNode {
        return this.getOwnerCollection().getNode(NodeName, AttrName, AttrValue);
    }

    UnprotectString(str: any, strProtect: string): any {
        throw new Error("Method not implemented.");
    }

    AdjustDate(str: string): string {
        throw new Error("Method not implemented.");
    }
    AdjustMonthYear(str: string): string {
        throw new Error("Method not implemented.");
    }
    FormatAcc(str: string): string {
        throw new Error("Method not implemented.");
    }
    FormatCCC(str: string): string {
        throw new Error("Method not implemented.");
    }

    AdjustAccNumber(str: string): string {
        throw new Error("Method not implemented.");
    }

    ProtectString(str: string, arg1: number): string {
        throw new Error("Method not implemented.");
    }

    PreprocessValue(FieldName: string, strKey: string, Value: any) {
        // TODO: Luis throw new Error("Method not implemented.");
    }

    SetPropertyValue(FieldName: string, FieldValue: any) {
        // O10052503:	La actualización de MAP_SYS_ORDER se puede puentear.
        if ("MAP_SYS_ORDER".equals(FieldName)) {// Puentear la asignación
            // ADD TAG TODO MAP_SYSY_ORDER no se trata como una prop normal, tendra un put y get propio
            this.mMapSysOrder = NumberUtils.SafeToLong(FieldValue);
            //m_lstNormalProperties.put(FieldName, FieldValue);
            return;
        }// Puentear la asignación
        // De lo contrario comportamiento normal.
        let strField = this.m_owner.MapField(FieldName);
        this.put(strField, FieldValue);
    }

    public GetPropertyValue(FieldName: string): any {
        let strField = this.MapField(FieldName);
        return this.get(strField);
    }

    /**
     * Mapea el nombre de un campo al valor real en la colección o base de datos
     * @param FieldName			Nombre del campo que se quiere mapear.
     * @return					Devuelve el campo mapeado por la colección propietaria.
     */
    private MapField(FieldName: string, DatabaseField: boolean = false): string {
        return this.m_owner.MapField(FieldName, DatabaseField);
    }

    /**
     * Efectúa el mapeo de valores enlazados una vez que se ha actualizado un campo ID mapeado a otra colección.
     * @param Collection		Colección a la cual está enlazado el campo que ha provocado el mapeo.
     * @param FieldName			Nombre del campo ID que ha provocado el mapeo.
     * @param Source			Objeto del cual se sacarán los valores que se van a copiar hacia este.
     * @return					Devuelve TRUE si todas las actualizaciones de campos son correctas.
     * @throws Exception
     */
    private async DoRestoreMapValues(Collection: XoneDataCollection, FieldName: string, Source: XoneDataObject | IResultSet): Promise<boolean> {
        let strFieldFrom = "", strFieldTo = "";
        let nodeList: XmlNodeList;
        let vl: any;
        let bUpdate = true;

        nodeList = this.m_owner.getNodeList("prop", "linkedto", FieldName);
        for (let i = 0; i < nodeList.count(); i++) {// Para cada nodo
            let node = nodeList.get(i);
            if (!StringUtils.IsEmptyString(XmlUtils.getNodeAttr(node, "bit")))
                continue;
            strFieldTo = XmlUtils.getNodeAttr(node, "name");
            strFieldFrom = XmlUtils.getNodeAttr(node, "linkedfield");
            if (Source && Source instanceof XoneDataObject) {// Comprobar si el campo existe en el objeto
                if (!Source.FieldExists(strFieldFrom)) {// Error
                    // Crear nuestro propio error
                    // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                    // TODO ADD TAG Juan Carlos. Añado el nombre del objeto con su colección para que sea
                    // más fácil reparar este error.
                    let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_RESTOREMAPFLDFAIL_03, "{0} failed. Cannot find linked field '{1}' to restore field '{2}' in object {3}");
                    sb = sb.replace("{0}", "CXoneDataObject::DoRestoreMapValues");
                    sb = sb.replace("{1}", strFieldFrom);
                    sb = sb.replace("{2}", strFieldTo);
                    sb = sb.replace("{3}", Source.getName());
                    throw new XoneGenericException(-2444, sb);
                }// Error
                vl = Source.get(strFieldFrom);
            }// Comprobar si el campo existe en el objeto
            else if (Source && (Source as IResultSet).isResultset) {// Comprobar si el campo existe en el objeto
                //int index =-1;
                try {
                    // M10052401:	Modificaciones en la forma de acceso a bases de datos SQLite.
                    // K11010501:	Modificaciones para la versión 1.5 de Android.
                    vl = (Source as IResultSet).getValue(strFieldFrom, this.m_owner.getTypeFromXml(this.m_owner.PropType(strFieldFrom)));
                } catch (ex) {
                    console.error(ex);
                    /*
                     * En principio la colección no permitirá usar un resultset como origen de
                     * restauración de campos mapeados
                     */
                    Collection.setUseObjectsInRestore(true);
                    (Source as IResultSet).close();
                    return await this.RestoreMappedFields(FieldName);
                }
            }// Comprobar si el campo existe en el objeto
            else
                vl = null;
            if (bUpdate)
                this.put(strFieldTo, vl);
        }// Para cada nodo
        return true;
    }

    private async RestoreMappedFields(FieldName: string): Promise<boolean> {
        let strSrch = "", strFieldFrom = "", strFieldTo = "", strType = "";
        let n = 0;
        let bSearchDB = false;
        let bXlatExist = false;
        let str = this.FieldPropertyValue(FieldName, "mapcol");
        if (TextUtils.isEmpty(str))
            return true;
        let coll: XoneDataCollection = null;
        let vl = null, vl2 = null;
        let obj: XoneDataObject;
        let nullObj: XoneDataObject = null;	// F11080903: Limpiar los campos enlazados cuando se pone NULL o cero en el campo ID.
        let FunctionName = "CXoneDataObject::RestoreMappedFields";

        // M09072904: Optimizar el mecanismo de restauración de campos mapeados.
        // Si la lista de campos enlazados a este no tiene ningún elemento, entonces
        // no tenemos nada que hacer
        let nodeList = this.m_owner.getNodeList("prop", "linkedto", FieldName);
        // M11092605: Mecanismo para limpiar colecciones en la resolución de objetos enlazados.
        // Retain o no retain
        let bRetain = StringUtils.ParseBoolValue(this.FieldPropertyValue(FieldName, "retain"), true);
        let bClearColl = false;
        if (nodeList.count() > 0) {
            //Es un linkedto
            // De lo contrario se hace el mismo trabajo de siempre.
            //str = FieldPropertyValue(FieldName, "mapcol");

            //if (!StringUtils.IsEmptyString(str))
            //{// Tiene colección mapeada
            if (str.equals("##OWNERCOLL##")) {// Ownercollection
                if (this.m_owner.getOwnerObject() != null)
                    coll = this.m_owner.getOwnerObject().getOwnerCollection();
                else {// Si no está en un contents, buscar el default-mapcol
                    str = this.FieldPropertyValue(FieldName, "default-mapcol");
                    if (!StringUtils.IsEmptyString(str))
                        coll = await this.getOwnerApp().getCollection(str);
                }// Si no está en un contents, buscar el default-mapcol
            }// Ownercollection
            else {
                try {
                    coll = await this.getOwnerApp().getCollection(str);
                } catch (exx) {
                    console.error(exx);
                    coll = null;
                }

            }
            if (null != (coll)) {// La colección existe
                vl2 = this.GetPropertyValue(FieldName);
                // M09072904:	Optimizar el mecanismo de restauración de campos mapeados.
                // Una pequeña optimización para casos en los que el valor es nulo y por tanto
                // no hay nada que buscar
                if (vl2 == null) {// Resetear los campos a NULL y salir
                    // F11080903: Limpiar los campos enlazados cuando se pone NULL o cero en el campo ID.
                    // Optimizar, sí pero no tanto...
                    return this.DoRestoreMapValues(coll, FieldName, nullObj);
                }// Resetear los campos a NULL y salir
                str = this.FieldPropertyValue(FieldName, "mapfld");
                strType = coll.FieldPropertyValue(str, "type");
                if (StringUtils.IsEmptyString(strType)) {// Tratar de inferir el tipo
                    // La clave de la colección
                    if (str.equals(coll.getIdFieldName()))
                        strType = (coll.getStringKey()) ? "T" : "N";
                }// Tratar de inferir el tipo
                if (strType.startsWith("N")) {// Convertir
                    n = NumberUtils.SafeToInt(vl2);
                    // M09072904:	Optimizar el mecanismo de restauración de campos mapeados.
                    // Una pequeña optimización para casos en los que el valor es nulo y por tanto
                    // no hay nada que buscar
                    if (n <= 0) {// No hay objeto enlazado
                        // F11080903: Limpiar los campos enlazados cuando se pone NULL o cero en el campo ID.
                        // Restaurar con objeto nulo para vaciar los campos
                        return await this.DoRestoreMapValues(coll, FieldName, nullObj);
                    }// No hay objeto enlazado
                }// Convertir
                else {// Cadenilla
                    n = -1;
                    // M09072904:	Optimizar el mecanismo de restauración de campos mapeados.
                    // Una pequeña optimización para casos en los que el valor es nulo y por tanto
                    // no hay nada que buscar
                    // F10052504:	Usar una variable temporal para la conversión en RestoreMappedFields.
                    let strtmp = StringUtils.SafeToString(vl2);
                    if (StringUtils.IsEmptyString(strtmp)) {// No hay objeto
                        // F11080903: Limpiar los campos enlazados cuando se pone NULL o cero en el campo ID.
                        // Asegurarse que se resetean los campos.
                        return await this.DoRestoreMapValues(coll, FieldName, nullObj);
                    }// No hay objeto
                }// Cadenilla

                // Hay un caso particular en esta cosa y es que se está buscando
                // exactamente el objeto propietario...
                obj = null;
                let ownercoll: XoneDataCollection;
                if (null != (obj = this.m_owner.getOwnerObject())) {// Tiene propietario
                    if (null != (ownercoll = obj.getOwnerCollection())) {// Tiene colección propietaria
                        if ((coll.getName()).equals((ownercoll.getName()))) {// Puede ser que busquemos el puro, no?
                            vl = obj.GetPropertyValue(str);
                            if (n != -1) {// Numérico
                                let l = NumberUtils.SafeToLong(vl);
                                if (l != n)
                                    obj = null;
                            }// Numérico
                            else {// Estarán en el mismo tipo que si no...
                                if (!vl.equals(vl2))
                                    obj = null;
                            }// Estarán en el mismo tipo que si no...
                        }// Puede ser que busquemos el puro, no?
                        else
                            obj = null;
                    }// Tiene colección propietaria
                    else
                        obj = null;
                }// Tiene propietario
                // M09072903:	Programar un mecanismo de restauración de campos para SQLs sin unión.
                // Buscar en la colección, solamente si el objeto está en memoria.
                // Si el objeto no está cargado habrá que restaurar con un rowset de toda la vida
                // con lo cual evitamos que la colección enlazada se llene innecesariamente.
                if (obj == null) {// Buscar el objeto para resolver
                    let b = false;
                    if (coll.getUseObjectsInRestore())
                        b = true;
                    // Buscamos el objeto en la colección, en memoria o donde haga falta
                    obj = await coll.getObject(str, vl2, b, b);
                    // M11092605: Mecanismo para limpiar colecciones en la resolución de objetos enlazados.
                    // Ver si hay que limpiar la colección o no
                    bClearColl = !bRetain;
                }// Buscar el objeto para resolver
                // Obtiene una lista con los nodos que son de esta propiedad
                if (obj != null) {// Algo tiene
                    if (!await this.DoRestoreMapValues(coll, FieldName, obj))
                        return false;
                }// Algo tiene
                else {// Habrá que resolver con un rowset
                    if (!coll.getUseObjectsInRestore() && !coll.getSpecial()) {// Si se puede buscar como rowset hacerlo
                        let rs = await coll.GetResultSet(str, vl2);
                        if (rs == null)
                            return false;
                        if (!await this.DoRestoreMapValues(coll, FieldName, rs))
                            return false;
                    }// Si se puede buscar como rowset hacerlo
                }// Habrá que resolver con un rowset
                // Idem para los objetos heredados
                // Add Tag: Luis esto ya no es necesario, porque el nodelist ya lo da
                /*
                XoneDataCollection parent = m_owner.getParentCollection ();
                while (parent != null)
                {// Para cada posible nodo heredado
                    if (!DoRestoreMapValues(parent, FieldName, obj))
                        return false;
                    // Al nivel anterior
                    parent = parent.getParentCollection ();
                }// Para cada posible nodo heredado*/
            }// La colección existe
            else {// Error, la colección no existe
                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_RESTOREMAPFLDFAIL_01, "{0} failed. Cannot get collection '{1}' to restore linked fields for field '{2}'.");
                sb = sb.replace("{0}", FunctionName);
                sb = sb.replace("{1}", str);
                sb = sb.replace("{2}", FieldName);
                throw new XoneGenericException(-3200, sb);
            }// Error, la colección no existe
            //}// Tiene colección mapeada
            // M09072904: Optimizar el mecanismo de restauración de campos mapeados.
            // Nodo del propietario
            // Buscar si hay objeto propietario
            if (null == (obj = this.m_owner.getOwnerObject())) {// No hay objeto propietario
                // M11092605: Mecanismo para limpiar colecciones en la resolución de objetos enlazados.
                if (bClearColl)
                    coll.clear();	// Limpiar...
                //return true;
            }// No hay objeto propietario
            else {//Hay objeto propietario
                if (!this.m_bIsCreating)
                    obj.RestoreTranslatedValues(this, str = this.m_owner.getName(), strFieldTo = FieldName);
                // M11092605: Mecanismo para limpiar colecciones en la resolución de objetos enlazados.
                if (bClearColl)
                    coll.clear();	// Limpiamos....
            }//Hay objeto propietario
        }//Es un linkedto
        //
        // Comprobar si es un campo traducido a columna
        if (!StringUtils.IsEmptyString(str = this.FieldPropertyValue(FieldName, "xlat"))) {// Está traducido
            // Buscar la colección
            coll = await this.getContents(str);
            if (null != coll) {// Tiene colección
                // Ver si hay que buscar en la BD para este contents
                bXlatExist = false; // TODO: coll.getXlatExist();
                strFieldFrom = this.FieldPropertyValue(FieldName, "cmpfield");
                strSrch = this.FieldPropertyValue(FieldName, "cmpvalue");
                if (!StringUtils.IsEmptyString(strFieldFrom) && !StringUtils.IsEmptyString(strSrch)) {// Los valores son correctos
                    if (strFieldFrom.equals("self") && strSrch.equals("self")) {// Es una comparación
                        if (!coll.getFull() && this.m_lObjectId > 0)
                            coll.loadAll();		// Cargar la colección
                        obj = await coll.get(0);
                    }// Es una comparación
                    else {// Solo habrá uno
                        strType = coll.FieldPropertyValue(strFieldFrom, "type");
                        // Ahora buscar el objeto basado en esa macro
                        vl = await this.GetValueFromString(strSrch, strType);
                        // Si el resultado de la evaluación lleva comillas, quitárselas
                        if (vl instanceof String) {// Comprobar la cadenilla obtenida
                            strSrch = vl.toString();
                            if (strType.equals("T") && strSrch.startsWith("\'") && strSrch.endsWith("\'"))
                                // F09042201:	Substring no funciona igual en java que en .NET Arreglar Blackberry
                                strSrch = strSrch.substring(1, strSrch.length - 2);
                            vl = strSrch;
                        }// Comprobar la cadenilla obtenida
                        if (bXlatExist)
                            bSearchDB = !this.m_bXlatExist;
                        else
                            bSearchDB = true;

                        bSearchDB = !this.m_bXlatExist;
                        if (!bSearchDB) {// Si el objeto está grabado sí puede buscar
                            if (this.m_lObjectId > 0)
                                bSearchDB = true;
                        }// Si el objeto está grabado sí puede buscar
                        obj = await coll.getObject(strFieldFrom, vl, true, bSearchDB);
                    }// Solo habrá uno
                    if (obj == null) {// Todavía puede ser que nos permitan crearlo
                        str = this.FieldPropertyValue(FieldName, "create");
                        if (StringUtils.ParseBoolValue(str, false)) {// Crearlo
                            obj = await coll.CreateObject();
                            if (null != obj)// Crear este objeto
                            {// Creado
                                // Poner el valor de comparación
                                this.m_bIsRestoring = true;
                                if (!strFieldFrom.equals("self"))
                                    obj.SetPropertyValue(strFieldFrom, vl);
                                this.m_bIsRestoring = false;
                                // La búsqueda por índices se realiza basada en la propiedad anterior, así
                                // que lo adicionamos después de asignarle valor a la etiqueta
                                // Esta mierda habrá que revisarla más tarde
                                coll.addItem(obj);	// Insertarlo
                            }// Creado
                            else {// Puede que hubiera moña
                                // Por la causa que sea no se ha creado el objeto, así que habrá
                                // que informar al pueblo
                                strFieldFrom = FieldName;
                                vl = this.get(strFieldFrom);	// El valor que acaban de poner
                                strFieldTo = this.DevelopObjectValue(vl);
                                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                                let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_RESTOREMAPFLDFAIL_02, "{0} failed. Field '{1}' Value: {2}");
                                sb = sb.replace("{0}", FunctionName);
                                sb = sb.replace("{1}", strFieldFrom);
                                sb = sb.replace("{2}", strFieldTo);
                                throw new XoneGenericException(-9000, sb);
                            }// Puede que hubiera moña
                        }// Crearlo
                    }// Todavía puede ser que nos permitan crearlo

                    if (obj != null) {// Lo ha encontrado
                        // Asignar
                        str = FieldName;
                        // Comprobar la validez de los campos
                        if (StringUtils.IsEmptyString(strFieldTo = this.FieldPropertyValue(str, "targetfield"))) {// Campo incorrecto
                            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_NOXLATTARGET, "{0} failed. Field '{1}' has no 'targetfield' or it's misspelled.");
                            sb = sb.replace("{0}", FunctionName);
                            sb = sb.replace("{1}", strFieldTo);
                            throw new XoneGenericException(-8888, sb);
                        }// Campo incorrecto
                        // Ver si el campo destino existe en el objeto derivado
                        if (!obj.FieldExists(strFieldTo)) {// Error
                            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_XLATMISSINGFLD, "{0} failed. Field '{1}' is missing in derived object.");
                            sb = sb.replace("{0}", FunctionName);
                            sb = sb.replace("{1}", strFieldTo);
                            throw new XoneGenericException(-8889, sb);
                        }// Error
                        vl = this.get(str);
                        obj.put(strFieldTo, vl);
                        //  Hay un caso particular de actualización de campos traducidos que es cuando el
                        //  campo de búsqueda es el mismo campo que ha cambiado de valor. En este caso es
                        //  necesario invocar la actualización de campos
                        // TODO: Luis
                        // if (strFieldTo.equals(this.FieldPropertyValue(str, "cmpfield")))
                        //     RestoreTranslatedValues(obj, str = this.FieldPropertyValue(str, "xlat"), strFieldTo);
                    }// Lo ha encontrado
                }// Los valores son correctos
            }// Tiene colección
        }// Está traducido
        return true;
    }

    /**
     * Restaura los valores de los campos traducidos (xlat)
     * @param Source			Objeto del cual se traen los valores traducidos.
     * @param Collection		Colección de la cual se han traído los valores.
     * @param TargetField		Nombre del campo que se trae del objeto enlazado.
     * @return					Devuelve TRUE si la actualización de todos los campos es correcta.
     * @throws Exception
     */
    private async RestoreTranslatedValues(Source: XoneDataObject, Collection: string, TargetField: string): Promise<boolean> {
        let propNode: XmlNode;
        let nodeList: XmlNodeList, contList: XmlNodeList;
        let str = "", strFieldTo = "", strFieldFrom = "", strCnt = "", strCmpType = "";
        let vl: any, vl1: any;
        let bUseThis = false;

        // Las propiedades de la colección
        if (Source == null || this.m_bIsRestoring)
            return true;
        // Buscar los contents que usen esta colección
        let xlrNode = this.m_owner.getNode("xlatrefresh");
        if (xlrNode == null)
            return true;		// Al carajo, no hay xlatrefresh
        contList = this.m_owner.getNodeList("contents", "src", Collection);
        // Puede haber más de un contents que use esta colección
        for (let i = 0; i < contList.count(); i++) {// Coger cada una de las colecciones
            let node = contList.get(i);
            // Buscar los nodos que cambian
            strCnt = XmlUtils.getNodeAttr(node, "name");
            // Este es el nombre del contents, que es el que sale aquí
            //////str = string.Format("mp:xlatrefresh/mp:action[@coll='{0}' and @changefld='{1}']", strCnt, TargetField);
            nodeList = xlrNode.SelectNodes("action", "coll", strCnt);
            for (let j = 0; j < nodeList.count(); j++) {// Para cada nodo
                let xrnode = nodeList.get(j);
                str = XmlUtils.getNodeAttr(xrnode, "changefld");
                if (!str.equals(TargetField))
                    continue;			// Este nos lo saltamos
                // Ver si el objeto fuente es el apropiado
                //////str = string.Format("mp:prop[@name='{0}']", strFieldTo = CXmlUtils.GetNodeAttr(xrnode, "targetfld"));
                strFieldTo = XmlUtils.GetNodeAttr(xrnode, "targetfld");
                propNode = this.m_owner.GetNode("prop", "name", strFieldTo);
                XmlUtils.GetNodeAttr(propNode, "type");
                if (StringUtils.IsEmptyString(strFieldFrom = XmlUtils.GetNodeAttr(propNode, "cmpfield")))
                    continue;		// Error
                if (!strFieldFrom.equals("self")) {// Hay que comparar
                    str = XmlUtils.GetNodeAttr(propNode, "cmpvalue");
                    strCmpType = Source.FieldPropertyValue(strFieldFrom, "type");
                    vl = await this.GetValueFromString(str, strCmpType);
                    //  Si el valor es una cadena, debe quitar las comillas porque va a usarlo para
                    //  comparar. Se ve que esto no se había hecho nunca
                    if (vl instanceof String) {// Cadena
                        str = vl.toString();
                        if (str.charAt(0) == '\'' && str.charAt(str.length - 1) == '\'') {// Comprobar
                            if (str.length > 2)
                                str = str.substring(1, str.length - 2);
                            else
                                str = "";
                        }// Comprobar
                        vl = str;
                    }// Cadena
                    // Ver si es este objeto
                    vl1 = Source.GetPropertyValue(strFieldFrom);
                    str = vl1.getClass().getName().toLowerCase();
                    if (str.equals("int") || str.equals("byte"))
                        vl1 = NumberUtils.SafeToLong(vl1);
                    else if (str.equals("single") || str.equals("decimal"))
                        vl1 = NumberUtils.SafeToDouble(vl1);
                    bUseThis = (vl.equals(vl1));
                }// Hay que comparar
                else
                    bUseThis = true;
                // Si tiene que usar este...
                if (bUseThis) {// Lo tiene que usar
                    strFieldFrom = XmlUtils.GetNodeAttr(xrnode, "sourcefld");
                    if (Source != null)
                        vl = Source.get(strFieldFrom);
                    else
                        vl = null;
                    this.put(strFieldTo, vl);
                }// Lo tiene que usar
            }// Para cada nodo
        }// Coger cada una de las colecciones

        return true;
    }

    /**
     * Devuelve un valor a partir de una cadena y un identificador de tipo.
     * @param StringValue		Valor representado en forma de cadena.
     * @param Type				Identificador de tipo XONE usado para convertir el valor.
     * @param UseDatemask		Máscara de fecha a usar para formatear la cadena. NULL para usar el valor por defecto.
     * @return					Devuelve el valor ya convertido según el tipo de datos.
     * @throws XoneGenericException
     */
    public async GetValueFromString(StringValue: string, Type: string, UseDatemask?: string): Promise<string> {
        let dt = Calendar.getInstance();
        let str = "", strSysDateMask = "";
        let vl: any = null;
        let strDay = "", strMonth = "", strYear = "";
        let nFormat = 0;

        try {
            let strVal = StringValue;
            // Cadena vacía, NULL y palca
            if (StringUtils.IsEmptyString(strVal))
                return strVal;
            // Macros primeramente
            if (strVal.equals("##NULL##") || strVal.equals("##EMPTY##"))
                return null;    // Simplemente a NULL o EMPTY
            // En función del tipo
            if (StringUtils.IsEmptyString(Type))
                Type = "T";
            else {// Caso especial
                if (Type.equals("type"))
                    Type = "T";		// Asumo texto
            }// Caso especial
            // F13061905: Problemas de separadores y delimitadores en GetValueFromString.
            // Cambiar este chequeo porque podría estar mal...
            if (strVal.startsWith("\'") && strVal.endsWith("\'") && strVal.length >= 2)
                strVal = strVal.substring(1, strVal.length - 1);
            // Valores especiales para fecha, hora y demás
            if (strVal.equals("##NOW##") || strVal.equals("##NOW_NOTIME##") || strVal.equals("##NOW_TIME##"))
                if (Type.charAt(0) == 'D') {// Fecha normal
                    if (strVal.equals("##NOW##") || strVal.equals("##NOW_NOTIME##")) {// No lleva hora
                        ObjUtils.ZeroCalendarTime(dt);
                    }// No lleva hora
                    vl = dt;
                }// Fecha normal
                else
                    if (Type.equals("TD")) {// Fecha AS
                        vl = String.format("%04d%02d%02d", dt.get(Calendar.YEAR), dt.get(Calendar.MONTH), dt.get(Calendar.DAY_OF_MONTH));
                    }// Fecha AS
            //
            // Macros para partes de fecha
            if (strVal.equals("##DAY##"))
                vl = dt.get(Calendar.DAY_OF_MONTH);
            if (strVal.equals("##MONTH##"))
                vl = dt.get(Calendar.MONTH);
            if (strVal.equals("##YEAR##"))
                vl = dt.get(Calendar.YEAR);
            // Si no hay valor, sustituimos las macros y tratamos de hacer algo con esto
            if (vl == null) {// No ha asignado nada todavía
                // Sustituir macros
                if (strVal.contains("##"))
                    strVal = this.PrepareSqlString(strVal);
                // M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
                // Sustituir macros a nivel de colección
                if (strVal.contains("##"))
                    strVal = await this.m_owner.EvaluateAllMacros(strVal);
                //TODO 31/05/2016 Juan Carlos
                //AT no funcionaba en los setval, es un texto normal, se debe tratar como T.
                //06/06/2016 PH y WEB tambien.
                //10/09/2020 Turno para el type IMG
                if (Type.startsWith("T") || Type.equals("AT") || Type.equals("PH") || Type.equals("WEB") || Type.equals("IMG")) {// Texto
                    if (strVal.equals("''"))
                        strVal = "";
                    else {// Quitar las comillas
                        if (strVal.startsWith("'") && strVal.endsWith("'") && strVal.length > 1) {
                            //TODO ADD TAG Juan Carlos Bugfix, antes no quitaba la comilla inicial.
                            strVal = strVal.substring(1, strVal.length - 1);
                        }
                    }// Quitar las comillas
                    vl = strVal;
                }// Texto
                if (Type.charAt(0) == 'N') {// Numérico
                    // Valor
                    if (strVal.equals("NULL"))
                        vl = null;
                    else {// No Nulo
                        // El NC es un caso especial
                        if (Type.equals("NC")) {// Comprobar posibles casos de cadenas de valores
                            if (strVal.equals("on") || strVal.equals("1") || strVal.equals("yes") || strVal.equals("true"))
                                vl = 1;
                            else
                                vl = 0;
                        }// Comprobar posibles casos de cadenas de valores
                        else {// Numérico normal
                            if (Type.length == 1) {// Los errores de conversión nos los tragamos
                                try {
                                    vl = NumberUtils.SafeToLong(strVal);	// Entero
                                }
                                catch (ex) {
                                    vl = 0;
                                }
                            }// Los errores de conversión nos los tragamos
                            else {// Los errores de conversión nos los tragamos
                                try {
                                    vl = NumberUtils.SafeToDouble(strVal);	// Decimal
                                }
                                catch (ex) {
                                    vl = 0.0;
                                }
                            }// Los errores de conversión nos los tragamos
                        }// Numérico normal
                    }// No Nulo
                }// Numérico
                if (Type.startsWith("D")) {// Fecha
                    str = strVal.trim();
                    if (!StringUtils.IsEmptyString(str)) {// Tiene valor
                        vl = strVal;
                        if (strVal.equals("NULL")) {// NULL
                            vl = null;
                        }// NULL
                        else {// Si es una fecha, ver si el datemask es diferente
                            //
                            // Comprobar si son diferentes
                            // Si el datemask que se pasa como parámetro es nulo, usamos el de la conexión
                            let strDatemask = UseDatemask;
                            if (StringUtils.IsEmptyString(strDatemask))
                                strDatemask = this.m_owner.getConnection().getDatemask();
                            // Ver si está normalizado...
                            if (strDatemask.startsWith("#"))
                                strDatemask = strDatemask.substring(2, strDatemask.length - 2);
                            nFormat = (strDatemask.equals("mdy")) ? 0 : (strDatemask.equals("dmy")) ? 1 : 2;
                            let strComponents = strVal.split('/');
                            switch (nFormat) {// Primer elemento
                                case 0:
                                    strMonth = strComponents[0];
                                    strDay = strComponents[1];
                                    strYear = strComponents[2];
                                    break;
                                case 1:
                                    strDay = strComponents[0];
                                    strMonth = strComponents[1];
                                    strYear = strComponents[2];
                                    break;
                                default:
                                case 2:
                                    strYear = strComponents[0];
                                    strMonth = strComponents[1];
                                    strDay = strComponents[2];
                                    break;
                            }// Primer elemento
                            // Armar la fecha según lo que espera la máquina
                            strSysDateMask = this.getOwnerApp().getSysDatemask();
                            nFormat = (strSysDateMask.equals("mdy")) ? 0 : (strSysDateMask.equals("dmy")) ? 1 : 2;
                            switch (nFormat) {// Según el formato del sistema
                                case 0:
                                    str = strMonth + "/" + strDay + "/" + strYear;
                                    if (strComponents.length > 3)
                                        str += (" " + strComponents[3]);
                                    break;
                                case 1:
                                    str = strDay + "/" + strMonth + "/" + strYear;
                                    break;
                                case 2:
                                    str = strYear + "/" + strMonth + "/" + strDay;
                                    if (strComponents.length > 3)
                                        str += (" " + strComponents[3]);
                                    break;
                            }// Según el formato del sistema
                            vl = StringUtils.ParseDateString(this.getOwnerApp().currentContext(), str);
                        }// Si es una fecha, ver si el datemask es diferente
                    }// Tiene valor
                }// Fecha
            }// No ha asignado nada todavía

            return vl;
        }
        catch (ex) {
            throw new XoneGenericException(-7000, ex);
        }
    }

    private postNewValue(FieldName: string, Value: string) {
        // TODO: Luis

    }

    private postNewFieldProperty(node: string, name: string, AttrName: string, AttrValue: any, addToEvaluated: boolean = false) {
        UITransform.setLayoutFieldPropertyValue(this.m_reactLayout, node, name, AttrName, AttrValue);
    }

    PutBitmappedProperty(FieldName: string, Value: any) {
        // TODO: Luis
    }

    public getParent(): XoneDataCollection {
        return this.m_owner;
    }

    clone() {

    }

    getContentsCount() {
        return this.m_lstContents.length;
    }

    getFieldPropertyValue(propiedad, atributo) {
        return this.FieldPropertyValue(propiedad, atributo);
    }

    getOldItem(propiedad) {

    }

    setFieldPropertyValue(FieldName: string, AttrName: string, Value: string, addToEvaluated: boolean = false) {
        let strKey = FieldName + ":" + AttrName;

        if (Value != null) {
            if (!Value.startsWith("##FLD_")) {
                this.m_lstCachedAttributes.put(strKey, Value);
            }
        }
        else {// Lo quitamos si existe
            if (this.m_lstCachedAttributes.containsKey(strKey))
                this.m_lstCachedAttributes.delete(strKey);
        }// Lo quitamos si existe
        if (addToEvaluated)
            this.m_owner.getOwnerApp().addEvaluatedAttributesList(this.m_owner.getName(), strKey);

        this.postNewFieldProperty(Utils.PROP_NAME, FieldName, AttrName, Value, addToEvaluated);
    }

    public setNodePropertyValue(node: string, name: string, AttrName: string, AttrValue: string) {
        UITransform.setLayoutFieldPropertyValue(this.m_reactLayout, node, name, AttrName, AttrValue);
        // if (m_owner == null) {
        //     return;
        // }
        // m_owner.SetNodePropertyValue(NodeName, ItemName, AttrName, sValue);
    }

    public getName(): string {
        let str = "";

        try {
            // Aquí usamos un StringBuilder para optimizar un poco esto.
            let bld = new StringBuilder(this.getOwnerCollection().getName());
            bld.append('.');
            let sID = this.GetObjectIdString();
            if (TextUtils.isEmpty(sID))
                bld.append(this.getOwnerCollection().getObjectIndex(this));
            else
                bld.append(sID);
            str = bld.toString();
        }
        catch (e) {
            str = "DataObject.0";
        }
        return str;
    }

    setOldItem(propiedad, valor) {

    }

    setOwnerColl() {

    }

    setValue(value) {

    }

    public get(FieldName: string): any {
        return this.GetItem(FieldName);
    }

    getDirty(): boolean {
        return this.m_bDirty;
    }


    setDirty(arg0: boolean) {
        this.m_bDirty = arg0;
    }

    getObjectIndex():number {
        return this.getOwnerCollection().getObjectIndex(this);
    }

    /**
     * @param sVariableName Nombre de la variable cuyo valor se quiere leer.
     * @return Devuelve la variable cuyo nombre se pasa como parámetro. Si la variable no existe devuelve NULL.
     */

    private SafeGetVariableList(): Hashtable<string, Object> {
        if (this.m_lstVariables != null) return this.m_lstVariables;
        return (this.m_lstVariables = new Hashtable<string, Object>());
    }

    public getVariables(sVariableName): any {
        return this.getVariable(sVariableName);
    }

    /**
     * Devuelve la variable cuyo nombre se pasa como parámetro.
     *
     * @param sVariableName Nombre de la variable cuyo valor se quiere conocer.
     * @return Devuelve el valor de la variable o NULL si esta no está definida.
     */

    public getVariable(sVariableName: string): any {
        // A04032903: <onchange> pudiera ser común para todos los campos
        if (sVariableName.equals("##CHGFIELD##")) {
            return this.m_strChangeField;
        }
        // Buscar en lista...
        if (!this.SafeGetVariableList().containsKey(sVariableName)) {
            return null;
        }
        // K11011201:	Modificaciones en el tratamiento de las variables.
        return this.SafeGetVariableList().get(sVariableName);
    }

    /**
     * Asigna valor a la variable cuyo nombre se pasa como parámetro.
     * @param VarName		Nombre de la variable a la que se quiere asignar valor.
     * @param value			Valor que se quiere asignar a la variable.
     */
    public putVariables(sVariableName: string, value: any): void {
        this.setVariable(sVariableName, value);
    }

    /**
     * Asigna valor a la variable cuyo nombre se pasa como parámetro.
     * K11011201:	Modificaciones en el tratamiento de las variables.
     * Poner esto público
     *
     * @param sVariableName Nombre de la variable que se quiere asignar.
     * @param value         Valor que se quiere asignar a la variable.
     */
    public setVariables(sVariableName: string, value: Object): void {
        this.setVariable(sVariableName, value);
    }

    /**
     * Asigna valor a la variable cuyo nombre se pasa como parámetro.
     * K11011201:	Modificaciones en el tratamiento de las variables.
     * Poner esto público
     *
     * @param sVariableName Nombre de la variable que se quiere asignar.
     * @param value         Valor que se quiere asignar a la variable.
     */

    public setVariable(sVariableName: string, value: Object): void {
        // F12042505: Si se pasa NULL a SetVariables hay moña con las listas de valores.
        if (value == null) {
            if (this.SafeGetVariableList().containsKey(sVariableName)) {
                this.SafeGetVariableList().delete(sVariableName);
            }
        } else {
            this.SafeGetVariableList().put(sVariableName, value);
        }
    }

    /**
     * F11070701: Modificaciones para solucionar los enlaces y grabación con claves no numéricas.
     *
     * @return	Devuelve TRUE si el objeto es nuevo (recién creado y no se ha cargado ni grabado)
     */
    public IsNew(): boolean {
        // M11080901: El mecanismo para nuevos objetos se puede flexibilizar un poco.
        // Esto podría ser diferente en función de si es autonumérico o no.
        let bNew = this.m_bNewObject;
        // Vale, si el objeto no es nuevo, entonces no hay nada más que mirar
        if (!bNew)
            return bNew;
        // Si el objeto lleva clave de cadena el funcionamiento también es el de siempre.
        if (this.m_owner.getStringKey())
            return bNew;
        // Ahora estamos en onda numérica, puede ser manual o no.
        try {
            let vl = this.GetPropertyValue(this.m_owner.getIdFieldName());
            let l = NumberUtils.SafeToLong(vl);
            if (l <= 0)
                return bNew;
            // Si el objeto tiene valor en el campo ID en principio tenemos una moña
            // pero si el sistema es autonumérico entonces podemos considerar que el objeto no
            // es nuevo.
            let b = StringUtils.ParseBoolValue(this.m_owner.CollPropertyValue("identity-key"), true);
            // Si nos dicen que el campo de ID no es autonumérico entonces cambiarlo no cambia la condición del objeto
            if (!b)
                return bNew;
            // Si el campo ID tiene valor y es autonumérico entonces el objeto no es nuevo.
            return false;
        }
        catch (e) {
            return bNew;
        }
    }

    isNew(): boolean {
        return this.IsNew();
    }

    loadFromJson(nativeArray) {

    }

    bind(Prop, Evento, Funcion) {

    }

    getObjectItem(indice) {

    }

    getPropertyGroup(propiedad) {

    }

    getPropertyTitle(propiedad) {

    }

    /**
     * @return		Devuelve el nombre del objeto de datos en el cual se ejecutan las acciones de escritura en la fuente de datos.
     */
    public getFixedUpdateObjectName(): string {
        return this.m_owner.getFixedUpdateObjectName();
    }

    /**
     * @return		Devuelve una cadena con el nombre del objeto de datos ya preparado para usarse en una sentencia SQL.
     */
    public getFixedObjectName(): string {
        return this.m_owner.getFixedObjectName();
    }


    //#region Grabar el objeto
    /**
     * Graba el objeto en la fuente de datos de la colección siemrpe que esta permita escritura
     * @return		Devuelve TRUE si el objeto se graba correctamente, así como todos sus contents.
     * @throws Exception
     */
    public async save(): Promise<boolean> {
        let strCmd = "", strFields = "", str = "", strTmp = "", strChkID = "", strPK = "";
        let strRowID = "", strIDString = this.GetObjectIdString(true);
        let rs: IResultSet = null;
        let bUpdate = true, bRegister = false;
        let lPossibleKey = 0;
        let bNeedRowID = true;
        let bNewObj = false;
        if (StringUtils.ParseBoolValue(str = this.m_owner.CollPropertyValue("readonly"), false)) {// Pa otro momento
            this.m_bReadOnly = true;
            return true;	// Read Only no se graba
        }// Pa otro momento
        // A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
        // Comprobar si transaccionamos o no...
        let bTransacted = StringUtils.ParseBoolValue(this.m_owner.CollPropertyValue("transactions"), false);
        // Tenemos que traer esto pacá porque lo necesitamos a nivel de transacciones
        let conn = this.m_owner.getConnection();

        let FunctionName = "CXoneDataObject::Save";

        // Averiguar si este objeto se puede grabar
        // Este chequeo se debe hacer antes de las operaciones que siguen. Es más
        // eficiente y no se pierde tiempo haciendo operaciones por gusto si de entrada
        // el objeto es read-only
        if (this.m_bReadOnly || this.m_owner.getSpecial())
            return true;
        // Si es un objeto derivado, y el propietario no está
        // grabado, no se graba
        if (this.m_owner.getOwnerObject() != null && this.m_bDependent) {// Ver si se ha grabado
            // F11070701: Modificaciones para solucionar los enlaces y grabación con claves no numéricas.
            // Si se trata de un objeto nuevo y el propietario también lo es... no grabamos nico...
            if (this.m_owner.getOwnerObject().IsNew())
                return true;
            /*
            strPK = m_owner.getOwnerObject().GetObjectIdString();
            // F10082402:	Protegerse de los runtimes que no cortocircuitan las expresiones.
            if (StringUtils.IsEmptyString(strPK))
                return true;
            if (strPK.equals("NULL"))
                return true;
            */
        }// Ver si se ha grabado
        strPK = this.getIdFieldName();

        strChkID = strIDString;
        if (strChkID.contains("\'"))
            strChkID = StringUtils.removeChars(strChkID, "'");
        if (strChkID.contains(" "))
            strChkID = StringUtils.removeChars(strChkID, " ");
        if (strChkID.contains(","))
            strChkID = StringUtils.removeChars(strChkID, ",");		// Solo para comprobar si está vacía...

        if (this.m_bDirty) {// Hay que grabar
            // F11110812: No se puede grabar un CurrentItem que no tenga ID.
            // A ver, si el objeto es un current item, tiene que tener ID
            if (this.m_bIsCurrentItem) {// Verificar los IDs
                let bNoId = false;
                if (!this.m_owner.getStringKey()) {// Numérica
                    if (this.m_lObjectId <= 0)
                        bNoId = true;
                }// Numérica
                else {// Cadena
                    if (StringUtils.IsEmptyString(this.m_strObjectId))
                        bNoId = true;
                    else if (this.m_strObjectId.compareToIgnoreCase("null") == 0)
                        bNoId = true;
                }// Cadena
                if (bNoId) {// Error
                    let sb = "{0} failed. Object is current item and has no ID.";
                    sb = sb.replace("{0}", FunctionName);
                    throw new XoneGenericException(-1990, sb);
                }// Error
            }// Verificar los IDs
            // Ejecutar primero las acciones antes de grabar...
            // F11070701: Modificaciones para solucionar los enlaces y grabación con claves no numéricas.
            // Ahora podemos usar nuestra banderilla....
            if (this.IsNew())
                bRegister = true;
            // Verificar si la regla ha fallado pero no hay que devolver hResult
            // TODO: Lui
            // if (!BeforeSave())
            //     return false;    // Fallo de regla
        }// Hay que grabar
        // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
        let sentence = new SqlParser(this.m_owner.getConnection().getRowIdFieldName(), this.m_messages);
        sentence.SetTableName(this.getFixedUpdateObjectName());
        try {
            let bStringKey = this.m_owner.getStringKey();
            let bMultipleKey = this.m_owner.getMultipleKey();
            if (this.m_bDirty) {// Hay que grabar
                // F11010304:	Para grabar hay que recorrer las propiedades modificadas, no las normales.
                // Para grabar hay que recorrer las propiedades modificadas, no las normales, porque allí
                // no están los valores que se han anulado...
                // A11092604: Permitir que la grabación de un objeto pueda dejar de ser incremental.
                let bIncremental = StringUtils.ParseBoolValue(this.m_owner.CollPropertyValue("incremental-save"), true);
                let keys = bIncremental ? this.m_lstDirtyProperties : this.m_lstNormalProperties.keys();
                // Ahora recorremos las propiedades que sean, que a fin de cuentas nos va a dar lo mismo.
                for (let i = 0; i < keys.length; i++) {// Cada propiedad
                    let strKey = keys[i];
                    // A09100501:   Permitir que las propiedades tengan un tipo button.
                    // Si es un botón nos los saltamos directamente...
                    strTmp = this.FieldPropertyValue(strKey, "type");
                    if (!StringUtils.IsEmptyString(strTmp)) {// Tiene tipo
                        if (strTmp.equals("B"))
                            continue;
                    }// Tiene tipo
                    // F11010304:	Para grabar hay que recorrer las propiedades modificadas, no las normales.
                    // Ahora buscamos el valor usando la clave de la lista de normales...
                    // Como la sacamos de la lista de modificadas, bUpdate en principio es TRUE.
                    bUpdate = true;
                    if (!bStringKey) // Esta comprobación para autonuméricos
                        if (!bMultipleKey && (strKey.equals(strPK)))
                            bUpdate = false;
                    if (bUpdate) {// Solo si es actualizable
                        if (!StringUtils.IsEmptyString(this.getObjectName())) {// Tabla de actualización diferente
                            if (strKey.startsWith("MAP_"))
                                bUpdate = false;
                            if (strKey.charAt(0) == '$' || strKey.charAt(0) == '#')
                                bUpdate = false;
                            //
                            // El ROWID tampoco lo ponemos...
                            if (strKey.equals(this.m_owner.getConnection().getRowIdFieldName()) && !StringUtils.IsEmptyString(strChkID) && !strChkID.equals("NULL"))
                                bUpdate = false;
                        }// Tabla de actualización diferente
                    }// Solo si es actualizable
                    // El campo puede ser excluible si está marcado como read-only
                    if (bUpdate) {// Ver si es de solo lectura
                        str = this.FieldPropertyValue(strKey, "readonly");
                        if (StringUtils.ParseBoolValue(str, false))
                            bUpdate = false;
                    }// Ver si es de solo lectura
                    // Si finalmente no es un campo excluible, se puede
                    // actualizar la tabla completa
                    if (bUpdate) {// No se puede insertar el ID
                        // Comprobar si la tabla para actualizar es diferente
                        // de la tabla de origen
                        // Si es el campo ROWID, marcar que ya no se necesita
                        if (strKey.equals(this.m_owner.getConnection().getRowIdFieldName()))
                            bNeedRowID = false;	// No necesita ROWID...
                        // Si tiene alias, usarlo
                        strTmp = strKey;
                        if (!StringUtils.IsEmptyString(str = this.FieldPropertyValue(strTmp, "alias"))) {// Tiene alias
                            strFields = str;
                        }// Tiene alias
                        else {// No tiene alias
                            // Decir que está mapeando para B.D.
                            strFields = this.MapField(strTmp, true);
                        }// No tiene alias
                        // Adicionar el valor en dependencia del tipo
                        // F11010304:	Para grabar hay que recorrer las propiedades modificadas, no las normales.
                        // Sacamos el nombre de la lista de normal. Si no está es NULL.
                        let vl = null;
                        if (this.m_lstNormalProperties.containsKey(strKey))
                            vl = this.m_lstNormalProperties.get(strKey);
                        if (!StringUtils.IsEmptyString(str = this.FieldPropertyValue(strKey, "mapcol"))) {// Es un ID, no puede ser 0
                            if (vl != null) {// No nulo
                                //  Esta conversión puede explotarse porque en el campo pueden haber puesto
                                //  cualquier guarrería, por lo que hay que cerciorarse que si pasa algo malo
                                //  caigamos en una situación controlada, en la que asignemos un valor nulo al
                                //  ID que estamos tratando de recolocar.
                                //  Buscar el tipo de datos del campo para comprobar los valores vacíos correctos
                                if (ObjUtils.IsNumeric(vl)) {// Numéricos
                                    let l = NumberUtils.SafeToLong(vl);
                                    if (0 == l)
                                        vl = null;
                                    else
                                        vl = l;
                                }// Numéricos
                                else {// Cadena
                                    str = vl.toString();
                                    if (StringUtils.IsEmptyString(str))
                                        vl = null;
                                    else
                                        vl = str;
                                }// Cadena
                            }// No nulo
                        }// Es un ID, no puede ser 0
                        str = this.DevelopObjectValue(vl);
                        if (vl instanceof String) {// Solo para valores de cadena
                            if (!StringUtils.IsEmptyString(str) && str.length > 2 && this.m_owner.getConnection().HasEscapeChars()) {// Solo para las cadenas
                                str = this.m_owner.getConnection().EscapeString(str.substring(1, str.length - 2));
                                str = "'" + str + "'";
                            }// Solo para las cadenas
                        }// Solo para valores de cadena
                        // Adicionar el valor al parser
                        // K11092602: Modificaciones para trabajar con conexiones online puras.
                        // Resolver el nombre real del campo, si la colección es indexada
                        let resolve = this.reverseResolveFieldName(strFields);
                        sentence.SetFieldValue(resolve, str);
                        if (StringUtils.ParseBoolValue(this.FieldPropertyValue(strKey, Utils.PROP_ATTR_EMBED), false))
                            sentence.SetFieldType(resolve, 1);
                    }// No se puede insertar el ID
                }// Cada propiedad
                if (sentence.GetFields().length > 0) {// Hay modificaciones
                    // F11070701: Modificaciones para solucionar los enlaces y grabación con claves no numéricas.
                    // Utilizar nuestra marca para saber si es nuevo o no
                    ////if (!StringUtils.IsEmptyString(strChkID) && !strChkID.equals("NULL"))
                    if (!this.IsNew()) {// El objeto tiene ID
                        // Objeto existente
                        bNewObj = false;
                        if (!bMultipleKey)
                            sentence.SetWhereSentence("WHERE " + strPK + "=" + strIDString);
                        else
                            sentence.SetWhereSentence(this.GetMultipleKeyString(true));
                        sentence.SetSqlType(SqlType.SQLTYPE_UPDATE);
                        sentence.SetFieldValue(strPK, this.getId());
                        sentence.addkey(strPK);
                        strCmd = sentence.RegenerateSql();
                        sentence.SetSqlType(SqlType.SQLTYPE_UPDATE);
                        // K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
                        // A12042503: Mecanismo para registrar un modo debug global en la aplicación.
                        if (this.m_owner.getIsDebugging() || this.m_owner.getOwnerApp().isDebugMode())
                            Utils.DebugLog(Utils.TAG_DATABASE_LOG, strCmd);
                        if (await this.m_owner.getConnection().ExecuteSqlStringAsync(null, sentence, false, this.m_owner.getName(), { [strPK]: strIDString }) == null) {
                            return false;
                        }
                        if (this.m_owner.getMultipleKey())
                            str = " WHERE " + this.GetMultipleKeyString(true);
                        else
                            str = " WHERE " + this.getIdFieldName() + "=" + this.GetObjectIdString(true);
                    }// El objeto tiene ID
                    else {// Es un objeto nuevo
                        // Objeto nuevo
                        bNewObj = true;
                        strRowID = this.getRowId();
                        if (this.m_owner.getConnection().getIsReplicating() || (!bStringKey && !bMultipleKey)) {// El ROWID solo si hace falta
                            // En principio, si se trata de datos remotos, la verificación se puede
                            // hacer arriba
                            // La existencia de campos de tipo unique-filter implica que se deben
                            // chequear las reglas indicadas por las fórmulas de unicidad antes de
                            // grabar el objeto. Si alguna de las reglas de unicidad falla, falla
                            // la grabación incluso antes de generar el RowID.
                            // TODO: Luis
                            // if (!CheckUniqueFields())
                            //     return false;
                            // Incluir la banderilla en esta condición para inhabilitar la generación de varios ROWID
                            // innecesariamente...
                            if (bNeedRowID) {// Solo si hace falta generarlo
                                while (true) {// Comprobar unicidad
                                    strRowID = this.m_owner.GenerateRowId();
                                    if (this.m_owner.IsUniqueRowId(strRowID))
                                        break;
                                }// Comprobar unicidad
                                str = this.DevelopObjectValue(strRowID);
                                sentence.SetFieldValue(this.m_owner.getConnection().getRowIdFieldName(), str);
                                sentence.addkey(this.m_owner.getConnection().getRowIdFieldName());
                                // F13022203: Proeblemas a la hora de grabar objetos cuando ha fallado una grabación previa.
                                // Malo
                                str = strRowID;
                            }// Solo si hace falta generarlo
                            else {// Preparar las condiciones para usar el mismo ROWID
                                // Como se puede haber borrado en la anterior, reutilizar el mismo ROWID
                                let idval = this.GetRawPropertyValue(this.m_owner.getConnection().getRowIdFieldName());
                                if (idval != null) {// Solo si tiene valor
                                    strRowID = idval.toString();
                                    str = "'" + strRowID + "'";
                                }// Solo si tiene valor
                            }// Preparar las condiciones para usar el mismo ROWID
                            sentence.SetSqlType(SqlType.SQLTYPE_INSERT);
                        }// El ROWID solo si hace falta
                        // Crear el INSERT y ejecutarlo
                        strCmd = sentence.RegenerateSql();
                        // A11092606: Si la colección depura, mostrar los SQL de grabación también.
                        // A12042503: Mecanismo para registrar un modo debug global en la aplicación.
                        if (this.m_owner.getIsDebugging() || this.m_owner.getOwnerApp().isDebugMode()) {// Traza
                            Utils.DebugLog(Utils.TAG_DATABASE_LOG, strCmd);
                            this.getOwnerApp().writeConsoleString(strCmd);
                        }// Traza
                        // La generación de objetos a nivel central implica que las claves no se comprueban
                        // Pasar la conexión
                        // A10090601:	Incluir el mecanismo de réplica selectiva por colecciones a la maquinaria.
                        // Programar aquí el soporte para la réplica selectiva por colecciones
                        let bReplicate = false;
                        // TODO: Luis por ahora esto no replica
                        // if (conn.getIsReplicating ())
                        // {// Si está replicando, podría ser selectiva
                        //     bReplicate =true;
                        //     if (this.m_owner.getOwnerApp().getSelectiveReplication())
                        //     {// Ver si la colección replica
                        //         if (!this.m_owner.getReplicate ())
                        //             bReplicate =false;
                        //     }// Ver si la colección replica
                        // }// Si está replicando, podría ser selectiva
                        // A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
                        // Buscar si la conexión soporta transacciones e iniciar una...
                        if (bTransacted)
                            bTransacted = conn.supportsTransactions();
                        if (bTransacted)
                            conn.beginTrans();
                        let b: any = false;
                        if (conn.acceptsParsedSentences())
                            b = await conn.ExecuteParsedSqlAsync(null, sentence, bReplicate, this.m_owner.getName());
                        else
                            b = await conn.ExecuteSqlStringAsync(null, strCmd, bReplicate, this.m_owner.getName());
                        // A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
                        // Comprobar el resultado y si hay transacciones echarlas patrás...
                        if (b instanceof Boolean && !(b as boolean)) {// Error
                            if (bTransacted)
                                conn.rollBack();
                            return false;
                        }// Error
                        // K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
                        // un popvalue del objeto que se load dependiendo del atributo save-result="NOMBRECOLECCION"
                        if (b.hasOwnProperty("isResultset")) {// Si es un RS, ver si tenemos que crear un objeto
                            let save_result = this.m_owner.CollPropertyValue("save-result");
                            if (!TextUtils.isEmpty(save_result)) {// Tenemos colección de resultados
                                let coll = await this.m_owner.getOwnerApp().getCollection(save_result);
                                if (coll != null) {// Y además existe, di tú
                                    let obj = await coll.CreateObject();
                                    coll.addItem(obj);
                                    await obj.Load(b);
                                    this.m_owner.getOwnerApp().pushValue(obj);
                                }// Y además existe, di tú
                            }// Tenemos colección de resultados
                        }// Si es un RS, ver si tenemos que crear un objeto
                        if (!bStringKey && !bMultipleKey) {// Obtener el ID si es autonumérico solamente
                            // A11092601: Modificaciones para mejorar el soporte de conexiones remotas y demás.
                            // Si la conexión soporta la obtención del valor de ID autonumérico, que lo haga ella.
                            if (conn.retrievesAutonumericKeys()) {// Se lo pide a la conexión
                                try {
                                    //TODO ADD TAG Juan Carlos. RetrieveNumericKey() espera la SQL ya con comillas.
                                    let sRowIDQuoted = str;
                                    if (!sRowIDQuoted.startsWith("'")) {
                                        sRowIDQuoted = "'" + sRowIDQuoted;
                                    }
                                    if (!sRowIDQuoted.endsWith("'")) {
                                        sRowIDQuoted = sRowIDQuoted + "'";
                                    }
                                    lPossibleKey = conn.RetrieveNumericKey(this.getFixedObjectName(), strPK, this.m_owner.getRowIdFieldName(), sRowIDQuoted);


                                }
                                catch (e2) {
                                    lPossibleKey = -1;
                                }
                            }// Se lo pide a la conexión
                            else {// Como siempre, por lo que sea
                                strCmd = "SELECT " + strPK + " FROM " + this.getFixedObjectName() + " WHERE " + this.m_owner.getRowIdFieldName() + "=" + str;
                                // A12042503: Mecanismo para registrar un modo debug global en la aplicación.
                                if (this.m_owner.getIsDebugging() || this.m_owner.getOwnerApp().isDebugMode())
                                    Utils.DebugLog(Utils.TAG_DATABASE_LOG, "Save Find Record: " + strCmd);
                                if (null == (rs = this.m_owner.getConnection().CreateRecordset(strCmd))) {// Error
                                    // Eliminar este objeto de la base de datos... algo anduvo mal
                                    strCmd = "DELETE FROM " + this.m_owner.getFixedObjectName() + " WHERE " + this.m_owner.getRowIdFieldName() + "=" + str;
                                    // Este no importa que falle... estamos haciendo lo que se puede
                                    this.m_owner.getConnection().ExecuteSqlString(strCmd);
                                    // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                                    let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_SAVEFAIL, "{0} failed. Cannot get Object ID.");
                                    sb = sb.replace("{0}", FunctionName);
                                    // A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
                                    // Estoooo, si hay transacciones, chungo
                                    if (bTransacted)
                                        conn.rollBack();
                                    throw new XoneGenericException(-1999, sb);
                                }// Error
                                if (await rs.next()) {// Existe
                                    if (!bStringKey) {// Actualizar
                                        let idval = null;
                                        // M10052401:	Modificaciones en la forma de acceso a bases de datos SQLite.
                                        // K11010501:	Modificaciones para la versión 1.5 de Android.
                                        idval = rs.getValue(strPK, this.m_owner.getIdFieldType());
                                        // Comprobar si viene vacío o no
                                        lPossibleKey = NumberUtils.SafeToLong(idval);
                                        if (lPossibleKey == 0) {// Error
                                            // A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
                                            // Si está transaccionado, simplemente rollback
                                            if (bTransacted)
                                                conn.rollBack();
                                            else {// Como toda la vida
                                                // Primero lo primero... borremos este objeto en la base de datos...
                                                strCmd = "DELETE FROM " + this.m_owner.getFixedObjectName() + " WHERE " + this.m_owner.getRowIdFieldName() + "='" + strRowID + "'";
                                                if (this.m_owner.getConnection().ExecuteSqlString(strCmd) == null)
                                                    return false;
                                                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                                                let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_SAVEFAIL_02, "{0} failed. Field '{1}' seems to be non-identity or it is not being updated.");
                                                sb = sb.replace("{0}", FunctionName);
                                                sb = sb.replace("{1}", strPK);
                                                throw new XoneGenericException(-19899, sb);
                                            }// Como toda la vida
                                        }// Error
                                    }// Actualizar
                                    else
                                        lPossibleKey = -1;	// Al menos indicar que se grabó
                                }// Existe
                                rs.close();
                                rs = null;
                            }// Como siempre, por lo que sea
                        }// Obtener el ID si es autonumérico solamente
                        // F11070701: Modificaciones para solucionar los enlaces y grabación con claves no numéricas.
                        // Si hemos grabado, indicar que el objeto no es nuevo
                        this.m_bNewObject = false;
                    }// Es un objeto nuevo
                }// Hay modificaciones
            }// Hay que grabar
            // Ahora debe asignar el valor al ID interno del objeto
            // porque ha llegado aquí si y solo si se ha ejecutado
            // correctamente...
            if (lPossibleKey > 0) {// Solo si ha grabado algo
                if (StringUtils.IsEmptyString(strRowID))
                    strRowID = this.getRowId();
                if (!bStringKey) {// Entero
                    this.put(strPK, lPossibleKey);
                    this.m_strObjectId = String.format("%s", lPossibleKey);
                }// Entero
                this.m_lObjectId = lPossibleKey;
                // Almacenar el ROWID
                // Esto solo hará falta si se ha generado un nuevo ROWID, de lo contrario no
                if (bNeedRowID) {// Se ha generado uno nuevo
                    this.put(this.m_owner.getRowIdFieldName(), sentence.GetRowId());
                }// Se ha generado uno nuevo
            }// Solo si ha grabado algo
            let bResult = true;
            // F10052701:	El mecanismo de recuperación al fallar Save() tiene problemas.
            // Relocalizarse en la colección propietaria
            ////m_owner.RelocateObject (this);
            // Puede ser que las colecciones que contiene el objeto
            // tengan datos que grabar, así que grabarlos
            // TODO: Luis ver esto
            // let bResult = false;
            // try
            // {
            //     bResult = OnSave();
            // }
            // catch (e)
            // {
            //     UndoSave(bNewObj, bTransacted);
            //     throw e;
            // }
            // if (!bResult)
            // {// Error
            //     UndoSave(bNewObj, bTransacted);
            //     return false;
            // }// Error
            // Trata de resolver los enlaces pendientes si es que los hay
            // Aquí se ha cambiado algo en el orden de ejecución de las cosas
            // TODO: Luis ver esto
            // bResult = true;
            // if (this.m_lstLinkItems.length > 0)
            // {// Enlazar
            //     try
            //     {
            //         bResult = LinkItems();
            //     }
            //     catch ( e)
            //     {
            //         if (lPossibleKey > 0)
            //             UndoSave(bNewObj, bTransacted);
            //         throw e;
            //     }
            // }// Enlazar
            // if (bResult)
            // {
            //     try
            //     {
            //         bResult = SaveContents();
            //     }
            //     catch (e)
            //     {
            //         if (lPossibleKey > 0)
            //             UndoSave(bNewObj, bTransacted);
            //         throw e;
            //     }
            // }
            // // F10052701:	El mecanismo de recuperación al fallar Save() tiene problemas.
            // // Intentar efectuar los enlaces nuevamente aquí y no donde se hacía antes...
            // if (bResult)
            // {// Si aún quedan enlaces, intentarlo de nuevo
            //     if (this.m_lstLinkItems.size() > 0)
            //     {// Enlazar
            //         try
            //         {
            //             bResult = LinkItems();
            //         }
            //         catch (e)
            //         {
            //             if (lPossibleKey > 0)
            //                 UndoSave(bNewObj, bTransacted);
            //             throw e;
            //         }
            //     }// Enlazar
            // }// Si aún quedan enlaces, intentarlo de nuevo
            // // Grabar aquellos contenidos que se lo merezcan
            // if (bResult)
            // {// Solo ahora marca las propiedades como limpias
            // 	// A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
            // 	// Si tenemos transacciones, hacer commit
            // 	let obj=conn.commit();
            // 	// K11092601: ExecuteSqlString y similares deben devolver un objeto, que puede ser ResultSet.
            // 	if (obj!=null)
            // 	{// Tiene resultado
            // 		if (obj instanceof ResultSet)
            // 		{// Es un resultset
            // 			let rsResult=obj as IResultSet;
            //         	let save_result=this.m_owner.CollPropertyValue("save-result");
            //         	if (!TextUtils.isEmpty(save_result))
            //         	{// Tiene definida una colección
            //         		let coll=this.m_owner.getOwnerApp().getCollection(save_result);
            //         		if (coll !=null)
            //         		{// Existe la colección
            //         			let objData=coll.CreateObject();
            //         			coll.addItem(objData);
            //         			if (rsResult.next())
            //         				objData.Load(rsResult);
            //         			this.m_owner.getOwnerApp().PushValue(objData);
            //         		}// Existe la colección
            //         	}// Tiene definida una colección
            //         }// Es un resultset
            // 	}// Tiene resultado
            //     // Marcar todas las propiedades como listas, solamente si la grabación
            //     // ha resultado exitosa. Esto permite que si falla la grabación, no se queden
            //     // marcadas las propiedades como grabadas cuando en realidad no lo están
            //     SetDirty(false);
            //     // F10052701:	El mecanismo de recuperación al fallar Save() tiene problemas.
            //     // Registrar el objeto aquí si todo ha ido bien...
            //     // Las llamadas a RelocateObject y demás hay que hacerlas después de grabar del todo
            //     // así que las quitamos de aquí...
            //     if (bRegister)
            //         this.m_owner.RegisterObjectKey(this);
            // }// Solo ahora marca las propiedades como limpias
            // else
            // {// To patrás
            //     if (lPossibleKey > 0)
            //     {// Solo si ha grabado algo
            //         // Borra el objeto de la base de datos con todo lo que se haya grabado de él
            //         UndoSave(bNewObj, bTransacted);
            //     }// Solo si ha grabado algo
            //     else
            //     {// Por si las moscas
            //     	if (bTransacted)
            //     		conn.rollBack();
            //     }// Por si las moscas
            // }// To patrás
            return bResult;
        }
        catch (e) {
            if (rs != null) {// Tiene recordset
                try {
                    rs.close();
                }
                catch (se) {
                    // Ignorar esta excepción
                }
                rs = null;
            }// Tiene recordset
            // A11092603: Permitir que la maquinaria utilice transacciones si la conexión lo permite.
            if (bTransacted)
                conn.rollBack();
            throw e;
        }
    }
    //#endregion

    //#region Borrar el objeto de la base de datos
    /**
     * Elimina este objeto de la base de datos
     * @return		Devuelve TRUE si el objeto se puede eliminar correctamente.
     * @throws XoneGenericException
     */
    public async deleteObject(): Promise<boolean> {
        let strCmd = "";
        let bDelete = false;
        let bDeleteActions = false;
        let FunctionName = "CXoneDataObject::DeleteObject";

        try {
            // O12050301: Si la colección es especial no hay que borrar el objeto de base de datos.
            // Si es un objeto de una colección especial, entonces tururú
            if (this.m_owner.getSpecial())
                return true;
            // Un objeto solo se tiene que borrar de la base de datos
            // si es que por alguna razón ha llegado a ella, claro está
            if (!this.m_bFollowRules)
                bDelete = true;
            else
                bDelete = this.CanDelete();

            if (bDelete) {// Si se puede borrar
                if (!this.m_owner.getSpecial())
                    bDeleteActions = true;		// Puede tratarse de una colección especial
            }// Si se puede borrar
            // Primero ejecutar las acciones de borrado
            // contenidas
            if (bDeleteActions) {// Ejecutar las acciones
                if (!this.ExecuteDeleteActions()) {// Acciones de eliminación
                    this.m_bFollowRules = true;
                    // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                    let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_DELETEOBJFAIL_01, "{0} failed. Pre-delete actions have not been executed.");
                    sb = sb.replace("{0}", FunctionName);
                    throw new XoneGenericException(-6000, sb);
                }// Acciones de eliminación
            }// Ejecutar las acciones
            // Ahora sí se verifica si el objeto está grabado o no, pues no tiene sentido lanzar
            // un DELETE contra una base de datos si no están almacenados los datos en ella, digo yo...
            if (!StringUtils.IsEmptyString(this.getObjectName()) && !this.GetObjectIdString().equals("NULL")) {// Este objeto debe tener datos
                if (bDelete) {// Si se puede borrar
                    // Ahora eliminar el objeto de la base de datos...
                    strCmd = "DELETE FROM " + this.getFixedObjectName() + " WHERE " + this.getIdFieldName() + "=" + this.GetObjectIdString(true);
                    // TODO: Luis
                    if (this.m_owner.getIsDebugging() || this.m_owner.getOwnerApp().isDebugMode())
                        Utils.DebugLog(Utils.TAG_DATABASE_LOG, strCmd);
                    // F10090306:	No se registra el error cuando falla DeleteObject.
                    // El resultado no importa porque lanza una excepción.
                    let sRowidName = this.m_owner.getConnection().getRowIdFieldName();
                    let sentence = new SqlParser(sRowidName);
                    sentence.ParseSqlString(strCmd);
                    sentence.SetFieldValue(this.getIdFieldName(), this.getId());
                    sentence.addkey(this.getIdFieldName());
                    await this.m_owner.getConnection().ExecuteSqlStringAsync(null, sentence, false, this.m_owner.getName());
                }// Si se puede borrar
                else {// No se puede borrar
                    this.m_bFollowRules = true;
                    return false;	// No se puede...
                }// No se puede borrar
            }// Este objeto debe tener datos
            // Este código también se ha sacado de la condición, ya que aunque el objeto no se borre
            // de la base de datos, las acciones post-eliminación sí deberían analizarse, ya que pueden
            // referirse a objetos que no necesariamente existan en el disco, sino en la memoria...
            if (bDelete && bDeleteActions) {// Ejecuta las acciones posteriores
                if (!this.ExecuteDeleteActions(true)) {// Ver qué ha pasado
                    this.m_bFollowRules = true;
                    // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                    let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_DELETEOBJFAIL_02, "{0} failed. Could not execute post-delete actions.");
                    sb = sb.replace("{0}", FunctionName);
                    throw new XoneGenericException(-6001, sb);
                }// Ver qué ha pasado
            }// Ejecuta las acciones posteriores
            this.m_bFollowRules = true;
            return true;
        }
        catch (ex) {
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            let sMessage = ex.message;
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
            sb = sb.replace("{0}", FunctionName);
            if (!TextUtils.isEmpty(sMessage)) {
                sb = sb.concat(sMessage);
            }
            throw new XoneGenericException(-1001, ex, sb);
        }
    }

    /**
     * @return		Devuelve TRUE si se cumplen todas las reglas de borrado.
     * @throws Exception
     */
    protected CanDelete(): boolean {
        let nodeList: XmlNodeList;
        let strIDString = this.GetObjectIdString(true);
        let coll: XoneDataCollection;
        //  En principio si este objeto no tiene ID todavía
        //  es seguro borrarlo. De todas maneras esta función es
        //  virtual, así que si no está usted de acuerdo redefínala
        //  y al carajo...
        try {
            if (StringUtils.IsEmptyString(strIDString))
                return true;
            //  Para evaluar reglas del tipo rule se debe tener colección
            //  propietaria.
            coll = this.m_owner;
            do {
                nodeList = null;
                let deleteNode = coll.GetNode("delete");
                if (deleteNode != null) {// Tiene delete
                    nodeList = deleteNode.SelectNodes("rule");
                    if (nodeList.count() > 0)
                        break;
                }// Tiene delete
                coll = coll.getParentCollection();
            } while (coll != null);
            // Si tenemos lista de reglas, usarla
            if (nodeList != null) {// Ahora analizar lo que haya encontrado...
                for (let i = 0; i < nodeList.count(); i++) {// Evaluar cada una de las reglas
                    let node = nodeList.get(i);
                    if (!this.EvaluateRule(node))
                        return false;	// Adquirir el mensaje de error
                }// Evaluar cada una de las reglas
            }// Ahora analizar lo que haya encontrado...
            // Antes de comprobar las reglas, ver si la colección propietaria es de solo lectura
            if (StringUtils.ParseBoolValue(/*str = */this.m_owner.CollPropertyValue("readonly"), false)) {// Read Only
                // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
                let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_OBJ_CANNOTDELETE, "Cannot delete object. Collection '{0}' is read-only.");
                sb = sb.replace("{0}", this.m_owner.getName());
                throw new XoneGenericException(-8101, sb);
            }// Read Only
            return true;
        }
        catch (ex) {
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            let sMessage = ex.message;
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
            sb = sb.replace("{0}", "CXoneDataObject::CanDelete");
            if (!TextUtils.isEmpty(sMessage)) {
                sb = sb.concat(sMessage);
            }
            throw new XoneGenericException(-10151, ex, sb);
        }
    }

    /**
     * Ejecuta las acciones de eliminación del objeto. EN función del parámetro ejecuta las anteriores o las posteriores.
     * @param AfterActions		TRUE para ejecutar las acciones posteriores a la eliminación del objeto.
     * @return					Devuelve TRUE si todas las acciones se han ejecutado correctamente.
     * @throws Exception
     */
    public async ExecuteDeleteActions(AfterActions: boolean = false): Promise<boolean> {
        let nodeList: XmlNodeList;
        let strIDString = this.GetObjectIdString(true);
        let coll: XoneDataCollection;
        //  En principio si este objeto no tiene ID todavía
        //  es seguro borrarlo. De todas maneras esta función es
        //  virtual, así que si no está usted de acuerdo redefínala
        //  y al carajo...
        try {
            if (StringUtils.IsEmptyString(strIDString))
                return true;
            //  Para evaluar reglas del tipo rule se debe tener colección
            //  propietaria. A partir de ahí se ejecutan todas las reglas
            //  heredadas de los padres...
            coll = this.m_owner;
            do {// Tiene colección propietaria
                // Primero tenemos que buscar el nodo delete
                let deleteNode = coll.GetNode("delete");
                if (deleteNode == null)
                    return true;		// No tiene nodo delete
                // Ahora buscar dentro del nodo aquellas acciones que nos interesen.
                // F11081106: El nodo del que se sacan las acciones de borrado no es el correcto.
                nodeList = deleteNode.SelectNodes(AfterActions ? "afteraction" : "action");
                for (let i = 0; i < nodeList.count(); i++) {// Ejecutar las acciones
                    let node = nodeList.get(i);
                    if (!this.EvaluateNodeRules(node))
                        continue;
                    // A12081701: Mecanismo para pasar parámetros a un nodo de acción.
                    // Esto no lleva argumentos... por ahora.
                    if (!await this.ExecuteNodeAction(null, node, 2, null))
                        return false;
                }// Ejecutar las acciones
                coll = coll.getParentCollection();
            } while (coll != null);

            return true;
        }
        catch (ex) {
            // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
            let sMessage = ex.message;
            let sb = this.m_messages.GetMessage(XoneMessageKeys.SYS_MSG_GENERALFAIL, "{0} failed. ");
            sb = sb.replace("{0}", "CXoneDataObject::ExecuteDeleteActions");
            if (!TextUtils.isEmpty(sMessage)) {
                sb = sb.concat(sMessage);
            }
            throw new XoneGenericException(-10171, ex, sb);
        }
    }
    //#endregion
    //#region ROWID
    /**
     * Devuelve o asigna valor al ROWID del objeto. Respeta los nombres de campos y demás cosas.
     * @return		Devuelve el valor del ROWID de este objeto.
     * @throws Exception
     */
    public getRowId(): string {
        return StringUtils.SafeToString(this.get(this.m_owner.getConnection().getRowIdFieldName()));
    }

    /**
     * Asigna valor al ROWID del objeto, colocándolo en su campo correspondiente.
     * @param value		Nuevo valor del ROWID (sin comillas)
     * @throws Exception
     */
    public setRowId(value: string): void {
        this.put(this.m_owner.getConnection().getRowIdFieldName(), value);
    }
    //#endregion


    /**
     * Asigna valor a la bandera que indica si el objeto debe cumplir las reglas de borrado o no cuando se llama
     * a DeleteObject para eliminarlo de la base de datos.
     * @param value		TRUE para indicar que se sigan las reglas (valor por defecto) FALSE para que no se cumplan.
     */
    public setFollowRules (value:boolean): void
    {
        this.m_bFollowRules = value;
    }
}