import IXmlDocument from "../../Interfaces/IXmlDocument";
import IXmlNode from "../../Interfaces/IXmlNode";
import DotNetJSONDocument from "./DotNetJSONDocument";
import SaxDocument from "../SAXImpl/SaxDocument";
import XmlNode from "./XmlNode";

export default class XmlDocument implements IXmlDocument {
    static getInstance():IXmlDocument {
        return new SaxDocument();
        //return new DotNetJSONDocument();
    }

    /**
     *
     */
    constructor() {
        
    }
    
    Deserialize(context: any, sPath: string, Source: any, useTranslation: boolean) {
        throw new Error("Method not implemented.");
    }
    getRootNode(): XmlNode {
        throw new Error("Method not implemented.");
    }
    createNode(node: XmlNode, strName: string) {
        throw new Error("Method not implemented.");
    }

}