
import { parse, resolve } from "path";
import Hashtable from "../../Collections/HashMap/Hashtable";
import { Exception } from "../../Exceptions/Exception";
import IConnection from "../../Interfaces/IConnection";
import IDataOptions from "../../Interfaces/IDataOptions";
import IResultSet from "../../Interfaces/IResultSet";
import { SqlParser } from "../../Parsers/SQL/SqlParser";
import ConnectionStringUtils from "../../Utils/ConnectionStringUtils";
import NumberUtils from "../../Utils/NUmberUtils";
import SqlType from "../../Utils/SqlType";
import StringBuilder from "../../Utils/StringBuilder";
import StringUtils from "../../Utils/StringUtils";
import TextUtils from "../../Utils/TextUtils";
import { Utils } from "../../Utils/Utils";
import XoneJSONConnection from "../JSONConnection/XoneJSONConnection";
import XoneWebCoreConnectionData from "./XoneWebCoreConnectionData";
import XoneWebCoreResulset from "./XoneWebCoreResulset";
//import fetch from "node-fetch";

interface Token {
    "access_token": string,
    "expires_in": Date,
    "token_type": string,
    "scope": string
};

export default class XoneWebCoreConnection implements IConnection {

    // Cliente para conectar con LogonTest
    // private m_clientId = "XIdsAPIdUQMB9bzf5";
    // private m_clientSecret = "q5d007Ma19GM1jN0IMhl6uqjKt7PoO";

    // Cliente para conectar con AllByxone
    // private m_clientId = "XIdsAPIXmITgixqqj";
    // private m_clientSecret = "5M3c9Oae5A7ewzTDU2Z3xh1B9P0Z79";

    // // Prod
    // private m_url = "https://web.xonedev.cloud/api/itf/DoAction";
    // local
    //private m_url = "http://localhost:1854/api/itf/DoAction";

    private m_idsUrl;



    private m_token: Token;
    private m_data: any;
    private m_resulset: XoneWebCoreResulset;
    private bIsLoginCall: boolean;
    private bIsJWTCall: boolean;
    private sToken: string;
    private nFlags: number;
    private nTimeout: number;
    private nSecurityLevel: number;
    private sUrlAddress: string;
    private sMethod: string;
    private sUsername: string;
    private sPassword: string;
    private sEndpoint: string;
    private sAction: string;
    private sAuthAction: string;
    private sContentType: string;
    private bIsSqlQuery: boolean;
    private bPostUrlEncode: boolean;
    private bIsCertificatePinningEnabled: boolean;
    private sLocalCertPath: string;
    private bAuthenticate: boolean;
    private sAuthenticationUsername: string;
    private sAuthenticationPassword: string;
    private bUseRemoteBroker: boolean;
    private sRemoteMapped: string;
    private sRemoteDest: string;
    private bAllowUnsafeCertificates: boolean;

    /**
     *
     */
    constructor(sConnectionString: string) {
        this.m_token = null;
        // Unos valore por defecto
        this.m_idsUrl = "https://ids.xonedev.cloud/connect/token";
        this.sUrlAddress = "https://web.xonedev.cloud/api/itf/DoAction";
        this.parseConnectionString(sConnectionString);
    }

    executeUpdateAsync(Sentence: any): Promise<number> {
        throw new Error("Method not implemented.");
    }

    async executeNonQuery(data: any): Promise<any> {
        var token = await this.readBearerTokenAsync();
        if (TextUtils.isEmpty(token))
            throw new Exception("No Auth");
        // var parser=new SqlParser("ROWID");
        // parser.ParseSqlString(Sentence as string);
        // var data= {
        //     coll: args[0],
        //     action: "select",
        //     where: parser.GetWhereSentence()
        // };
        const response = await fetch(this.sUrlAddress, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            //mode: 'no-cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'omit', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer "+token
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            //redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        const result = await response.json();
        if (result.hasError===true)
            throw new Exception(result.err);
        return 1;
    }

    private getDataFromParser(parser: SqlParser): any {
        return parser.GetFields().toJSON();
        // const data = [];
        // data.push(parser.GetFields().toJSON());
        // return data;
    }

    private formatOnlineData(parser: SqlParser, collName: string, keys: any) {
        if (parser.GetSqlType() == SqlType.SQLTYPE_SELECT) {
            return {
                coll: collName,
                action: "select",
                where: parser.GetWhereSentence()
            };
        }
        if (parser.GetSqlType() == SqlType.SQLTYPE_UPDATE || parser.GetSqlType() == SqlType.SQLTYPE_INSERT) {
            return {
                coll: collName,
                action: "write",
                data: this.getDataFromParser(parser),
                keys: parser.getKeys(),
                where: parser.GetWhereSentence()
            };
        }
        if (parser.GetSqlType() == SqlType.SQLTYPE_DELETE) {
            return {
                coll: collName,
                action: "drop",
                data: this.getDataFromParser(parser),
                keys: parser.getKeys(),
                where: null // SqlParser.getWhereOnlyFields(parser.GetWhereSentence())
            };
        }
        return {};
        // if (parser.GetSqlType() == SqlType.SQLTYPE_INSERT) {
        //     return {
        //         coll: collName,
        //         action: "write",
        //         data: this.getDataFromParser(parser),
        //         keys: parser.getKeys(),
        //         data: this.getDataFromParser(parser)
        //     };
        // }
    }

    async executeAsync(Sentence: any, MaxRows: number, ...args): Promise<any> {
        if (typeof Sentence == 'string') {
            let tmp = new SqlParser("ROWID");
            tmp.ParseSqlString(Sentence);
            Sentence = tmp;
        }
        return await this.executeNonQuery(this.formatOnlineData(Sentence, args[0], args[1]));
    }

    private getAuthData(): string {
        let bld = new StringBuilder("client_id=");
        bld.append(this.sUsername);
        bld.append("&client_secret=");
        bld.append(this.sPassword);
        bld.append("&grant_type=client_credentials");
        return bld.toString();

    }

    private async readBearerTokenAsync(): Promise<string> {
        if (this.m_token != null) {
            if (this.m_token.expires_in > new Date())
                return this.m_token.access_token;
        }
        const response = await fetch(this.m_idsUrl, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            //mode: "no-cors",
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                //'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            //redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: this.getAuthData() // body data type must match "Content-Type" header
        });
        let token = await response.json();
        if (token.error) {
            console.error(token.error);
            return this.m_token = null;
        }
        var expire = new Date();
        expire.setSeconds(expire.getSeconds() + token.expires_in);
        this.m_token = token;
        this.m_token.expires_in = expire;
        return token.access_token;

        //   return response.then((value)=> {
        //     return value.json().then((token)=> {
        //         if (token.error) {
        //             console.error(token.error);
        //             return this.m_token=null;
        //         }
        //         var expire=new Date();
        //         expire.setSeconds(expire.getSeconds()+token.expires_in);
        //         this.m_token=token;
        //         this.m_token.expires_in=expire;
        //         return token.access_token;
        //     })
        //     .catch((reason)=>{
        //         console.error(reason);
        //         return null;
        //     }); // parses JSON response into native JavaScript objects
        //   })
        //   .catch((reason)=> {
        //       console.error(reason);
        //       throw new Exception(reason);
        //   })

    }

    executeQuery(Sentence: any): IResultSet {
        return new XoneWebCoreResulset();
    }

    async fetchDataAsync(Sentence: any, data: any): Promise<any> {
        var token = await this.readBearerTokenAsync();
        if (TextUtils.isEmpty(token))
            throw new Exception("No Auth");
        // var parser = new SqlParser("ROWID");
        // parser.ParseSqlString(Sentence as string);
        // var data = {
        //     //count:args.length>0?args[0]:false,
        //     //coll: args.length>1?args[1]:"",
        //     action: "select",
        //     where: parser.GetWhereSentence(),
        //     sort: parser.getOrderBySentence()
        //     //page: args.length>2?args[2]:{}
        // };
        // data = { ...data, ...options };
        const response = await fetch(this.sUrlAddress, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            //mode: 'no-cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'omit', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            //redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return await response.json();
    }

    async executeQueryAsync(Sentence: any, ...args): Promise<IResultSet> {
        var parser = new SqlParser("ROWID");
        parser.ParseSqlString(Sentence as string);
        var data: IDataOptions = {
            action: "select",
            where: parser.GetWhereSentence(),
            sort: parser.getOrderBySentence()
            //page: args.length>2?args[2]:{}
        };
        data = { ...data, ...args[0] };
        var rs=new XoneWebCoreResulset(this, Sentence, data);
        return await rs.populate();
    }

    // async executeQueryAsyncOld(Sentence: any, ...args): Promise<IResultSet> {
    //     var token = await this.readBearerTokenAsync();
    //     if (TextUtils.isEmpty(token))
    //         throw new Exception("No Auth");
    //     var parser = new SqlParser("ROWID");
    //     parser.ParseSqlString(Sentence as string);
    //     var data = {
    //         //count:args.length>0?args[0]:false,
    //         //coll: args.length>1?args[1]:"",
    //         action: "select",
    //         where: parser.GetWhereSentence(),
    //         sort: parser.getOrderBySentence()
    //         //page: args.length>2?args[2]:{}
    //     };
    //     this.m_data = { ...data, ...args[0] };
    //     const response = await fetch(this.m_url, {
    //         method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //         //mode: 'no-cors', // no-cors, *cors, same-origin
    //         // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //         //credentials: 'omit', // include, *same-origin, omit
    //         headers: {
    //             'Content-Type': 'application/json',
    //             "Authorization": "Bearer " + token
    //             // 'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         //redirect: 'follow', // manual, *follow, error
    //         // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //         body: JSON.stringify(this.m_data) // body data type must match "Content-Type" header
    //     });
    //     const result = await response.json();
    //     return this.m_resulset = new XoneWebCoreResulset(result.data);
    //     // return this.readBearerTokenAsync().then((token) => {
    //     //     if (TextUtils.isEmpty(token))
    //     //         throw new Exception("No Auth");
    //     //     var parser=new SqlParser("ROWID");
    //     //     parser.ParseSqlString(Sentence as string);
    //     //     var data= {
    //     //         coll: args[0],
    //     //         action: "select",
    //     //         where: parser.GetWhereSentence()
    //     //     };
    //     //     const response = fetch(this.m_url, {
    //     //         method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //     //         mode: 'no-cors', // no-cors, *cors, same-origin
    //     //         // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //     //         //credentials: 'omit', // include, *same-origin, omit
    //     //         headers: {
    //     //         'Content-Type': 'application/json',
    //     //         "Authorization": "Bearer "+token
    //     //         // 'Content-Type': 'application/x-www-form-urlencoded',
    //     //         },
    //     //         //redirect: 'follow', // manual, *follow, error
    //     //         // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //     //         body: JSON.stringify(data) // body data type must match "Content-Type" header
    //     //     });
    //     //     return response.then((value)=> 
    //     //             value.json().then((result)=> 
    //     //                 new XoneWebCoreResulset(result.data)))
    //     //             .catch((reason)=> {
    //     //                 console.error(reason);
    //     //                 return null;
    //     //             });
    //     // });
    // }

    async countAsync(Sentence: any, ...args): Promise<IResultSet> {
        // var token = await this.readBearerTokenAsync();
        // if (TextUtils.isEmpty(token))
        //     throw new Exception("No Auth");
        var parser = new SqlParser("ROWID");
        parser.ParseSqlString(Sentence as string);
        var data:IDataOptions = {
            action: "count",
            where: parser.GetWhereSentence(),
            sort: parser.getOrderBySentence()
        };
        data = { ...data, ...args[0] };
        var rs=new XoneWebCoreResulset(this, Sentence, data);
        return await rs.populate();
        // const response = await fetch(this.m_url, {
        //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //     //mode: 'no-cors', // no-cors, *cors, same-origin
        //     // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     //credentials: 'omit', // include, *same-origin, omit
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Authorization": "Bearer " + token
        //         // 'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        //     //redirect: 'follow', // manual, *follow, error
        //     // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //     body: JSON.stringify(this.m_data) // body data type must match "Content-Type" header
        // });
        // const result = await response.json();
        // return this.m_resulset = new XoneWebCoreResulset(result.data);
    }

    private parseConnectionString(sConnection: string): void {
        let parameters = ConnectionStringUtils.parseConnectionString(sConnection);
        this.bIsLoginCall = StringUtils.ParseBoolValue(parameters.get("logincall"));
        // TAG 20050201: Luis: Permitir una primera aproximacion a authorization por JWT
        // En el connstring debe ponerse jwtcall=true y el token que se tenga
        this.bIsJWTCall = StringUtils.ParseBoolValue(parameters.get("jwtcall"));
        this.sToken = StringUtils.SafeToString(parameters.get("token"), Utils.EMPTY_STRING);
        this.nFlags = this.nFlags | (StringUtils.ParseBoolValue(parameters.get("odataformat")) ? XoneJSONConnection.FLAG_XONEJSONODATA : XoneJSONConnection.FLAG_XONEJSONSQL);
        this.nFlags = this.nFlags | (StringUtils.ParseBoolValue(parameters.get("customformat")) ? XoneJSONConnection.FLAG_XONEJSONCUSTOM : XoneJSONConnection.FLAG_XONEJSONSQL);
        this.nTimeout = NumberUtils.SafeToInt(parameters.get("timeout"), 60);
        this.nSecurityLevel = NumberUtils.SafeToInt(parameters.get("security level"), 0);
        this.sUrlAddress = parameters.get("data source");
        if (this.sUrlAddress != null) {
            this.sUrlAddress = this.sUrlAddress.trim();
            if (this.sUrlAddress.endsWith("?")) {
                this.sUrlAddress = this.sUrlAddress.substring(0, this.sUrlAddress.length - 1);
            }
        } else {
            this.sUrlAddress = Utils.EMPTY_STRING;
        }
        this.m_idsUrl=parameters.get("ids") || this.m_idsUrl;
        //this.m_url=this.sUrlAddress;
        this.sMethod = parameters.get("method");
        if (this.sMethod == null) {
            this.sMethod = "POST";
        }
        this.sUsername = parameters.get("xoneuser");
        if (this.sUsername == null) {
            this.sUsername = Utils.EMPTY_STRING;
        }
        this.sPassword = parameters.get("xonepass");
        if (this.sPassword == null) {
            this.sPassword = Utils.EMPTY_STRING;
        }
        this.sEndpoint = parameters.get("endpoint");
        if (this.sEndpoint == null) {
            this.sEndpoint = Utils.EMPTY_STRING;
        }
        // TAG 20050208: Luis: Permitir customizar mas las colecciones online
        // en el connstring se puede poner Action=nombreaccion y AuthAction=nombreaccionauth
        this.sAction = StringUtils.SafeToString(parameters.get("action"), XoneJSONConnection.DEFAULT_ACTION_NAME);
        this.sAuthAction = StringUtils.SafeToString(parameters.get("authaction"), XoneJSONConnection.DEFAULT_AUTHACTION_NAME);
        this.sContentType = StringUtils.SafeToString(parameters.get("content-type"), XoneJSONConnection.DEFAULT_CONTENT_TYPE);
        this.bIsSqlQuery = StringUtils.ParseBoolValue(parameters.get("sqlquery"), true);
        // TAG: 0604201601 nuevo parametro en el connstring para poder codeficar el cupero del post en formato key=valeu&key=vale
        this.bPostUrlEncode = StringUtils.ParseBoolValue(parameters.get("postencode"), false);
        this.bIsCertificatePinningEnabled = StringUtils.ParseBoolValue(parameters.get("enablecertificatepinning"));
        this.sLocalCertPath = StringUtils.SafeToString(parameters.get("localcertificatepath"));
        // Para la autenticacion remota, puede ser por conexion o por llamada del script del metodo
        this.bAuthenticate = StringUtils.ParseBoolValue(parameters.get("auth"), false);
        if (this.bAuthenticate) {
            this.sAuthenticationUsername = parameters.get("user id");
            this.sAuthenticationPassword = parameters.get("password");
        }
        this.bUseRemoteBroker = StringUtils.ParseBoolValue(parameters.get("remote broker"), false);
        this.sRemoteMapped = StringUtils.SafeToString(parameters.get("remote mapped"));
        this.sRemoteDest = StringUtils.SafeToString(parameters.get("remote destination"));
        this.bAllowUnsafeCertificates = StringUtils.ParseBoolValue(parameters.get("allowunsafecertificates"), false);
    }
    

    createStatement() {
        throw new Error("Method not implemented.");
    }
    isClosed(): boolean {
        throw new Error("Method not implemented.");
    }
    commit() {
        throw new Error("Method not implemented.");
    }
    beginTrans(): void {
        throw new Error("Method not implemented.");
    }
    rollback(): void {
        throw new Error("Method not implemented.");
    }
    execute(parameter: any, MaxRows: number) {
        throw new Error("Method not implemented.");
    }
    executeUpdate(sql: string): number;
    executeUpdate(Sentence: any): number;
    executeUpdate(Sentence: any): number {
        return 0;
    }
    executeOperation(OperType: number, tableName: string, values: any, whereClause: string): number {
        throw new Error("Method not implemented.");
    }
    getDBMS(): string {
        throw new Error("Method not implemented.");
    }
    outerJoinsSupported(): boolean {
        throw new Error("Method not implemented.");
    }
    acceptsParsedSentences(): boolean {
        throw new Error("Method not implemented.");
    }
    acceptsEmptyQueries(): boolean {
        throw new Error("Method not implemented.");
    }
    LastRowsAffected(): number {
        throw new Error("Method not implemented.");
    }
    LastInsertedRowId(): number {
        throw new Error("Method not implemented.");
    }
    cancelProcesses(flags: number): number {
        throw new Error("Method not implemented.");
    }
    setCryptoData(cryptoData: Hashtable<string, Object>): void {
        throw new Error("Method not implemented.");
    }
    getCryptoData(): Hashtable<string, Object> {
        throw new Error("Method not implemented.");
    }
    cryptoSupported(): boolean {
        throw new Error("Method not implemented.");
    }
    getTokenFromAuth(login: string, pass: string): string {
        throw new Error("Method not implemented.");
    }

}