/**
 * @typedef {Object} AppSize
 * @property {number} width
 * @property {number} height
 */

/**
 * Handler App node Class
 */
class AppHandler {
  constructor(appData) {
    this.appData = appData;
    this.setAppNode();
  }

  // Set app node
  setAppNode() {
    this.appNode = this.appData.getAppNode();
  }

  // Get App width / height

  /**
   * Get App Size
   * @returns {AppSize}
   */
  getAppSize() {
    const width = this.appNode.getAttrValue("resolution-width");
    const height = this.appNode.getAttrValue("resolution-height");

    if (isNaN(width) || isNaN(height)) {
      console.error(
        "resolution-width and resolution-height must be numeric in <app></app>"
      );
      return { width: null, height: null };
    }
    return {
      width: Number(width),
      height: Number(height),
    };
  }

  /**
   * Set Visual Conditions / Visual Macros
   * @param {Number} width
   * @param {Number} height
   */
  setVisualConditionsMacros(width, height) {
    // Width / Height
    this.appData.setGlobalMacro(
      "##SCREEN_RESOLUTION_WIDTH##",
      width.toString()
    );
    this.appData.setGlobalMacro(
      "##SCREEN_RESOLUTION_HEIGHT##",
      width.toString()
    );
    // Orientation
    this.orientation = width > height ? "horizontal" : "vertical";
    this.appData.setGlobalMacro("##CURRENT_ORIENTATION##", this.orientation);
    // VisualConditions
    this.sizeConditions =
      width < 768 ? "xs" : width < 992 ? "s," : width < 1200 ? "md" : "lg";
    this.appData.setVisualConditions(`web:${this.sizeConditions}`, false);
  }

  /**
   * Generate a GUID as a DeviceId
   * @returns {string}
   */
  getDeviceId() {
    const _p8 = (s) => {
      var p = (Math.random().toString(16) + "000000000").substr(2, 8);
      return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    };

    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId) {
      deviceId = _p8() + _p8(true) + _p8(true) + _p8();
      localStorage.setItem("deviceId", deviceId);
    }
    return deviceId;
  }

  /**
   * Set appData inmutable Macros
   */
  setInmutableMacros() {
    const attributes = this.appNode.attrs;
    if (!attributes) {
      console.error("Not found <app></app>");
      return;
    }

    const deviceId = this.getDeviceId();
    const [language, countryCode] = navigator.language.split("-");
    const { platform, vendor } = navigator;

    this.appData.setGlobalMacro("##DEVICE_OS##", "web");
    this.appData.setGlobalMacro("##DEVICE_OSVERSION##", platform);
    this.appData.setGlobalMacro("##DEVICE_MODEL##", vendor);
    this.appData.setGlobalMacro("##VERSION##", attributes?.version ?? "1");
    this.appData.setGlobalMacro("##DEVICEID##", deviceId);
    this.appData.setGlobalMacro("##CURRENT_LANGUAGE##", language);
    this.appData.setGlobalMacro("##CURRENT_COUNTRY_CODE##", countryCode);
    this.appData.setGlobalMacro("##FRAME_VERSION##", "v1");
    // TODO: Revisar valores a enviar a las macros
    this.appData.setGlobalMacro("##LIVEUPDATE_VERSION##", "0");
    this.appData.setGlobalMacro("##DEVICE_MANUFACTURER##", "web");
    this.appData.setGlobalMacro("##DEVICE_TYPE##", "web");
    this.appData.setGlobalMacro("##MID##", "999");
    this.appData.setGlobalMacro("##DEVICE_OSVERSION##", "web");
    this.appData.setGlobalMacro("##CURRENT_DENSITY##", "1");
    this.appData.setGlobalMacro("##CURRENT_DENSITY_VALUE##", "1");
  }

  // getEntryPoint() {
  //   return this.getAppValueWithConditions("entry-point");
  // }

  // getLoginColl() {
  //   return this.getAppValueWithConditions("login-coll");
  // }

  /**
   * Get autologon attribute
   * @returns {boolean}
   */
  getAutoLogon() {
    let autoLogon = this.appNode.attrs.autologon;
    return autoLogon === "true";
  }

  // getAppValueWithConditions(attr) {
  //   try {
  //     // get login-coll from attribute
  //     let attribute = this.appNode.getAttrValue(attr);

  //     // get appNode
  //     const app = this.appNode.toJSON().app;

  //     // get login-coll node
  //     const attributeNode = app.childs.find((e) => e[attr]);
  //     if (!attributeNode || !attributeNode[attr]) return attribute;

  //     // login-coll items
  //     const items = attributeNode[attr].childs
  //       .map((e) => e.item)
  //       .filter((e) => {
  //         const conditions = e.attributes.conditions;
  //         return conditions === "" || conditions.includes("web");
  //       });

  //     // filter size conditions
  //     let filteredItems = items.filter((e) => {
  //       const conditions = e.attributes.conditions.split(":");
  //       return conditions.includes(this.sizeConditions);
  //     });

  //     // filter orientation
  //     filteredItems = (filteredItems.length !== 0 || items).filter((e) => {
  //       const conditions = e.attributes.conditions.split(":");
  //       return conditions.includes(this.orientation);
  //     });

  //     return (filteredItems.length !== 0 || items)[0].attributes.name;
  //   } catch (ex) {
  //     console.error(`Error in getAppValueWithConditions: ${ex}`);
  //     return attribute;
  //   }
  // }
}

export default AppHandler;
