import "../Extensions/Extensions";
import { XoneDataCollection } from "./XoneDataCollection";
import Hashtable from "../Collections/HashMap/Hashtable";
import TextUtils from "../Utils/TextUtils";
import { Utils } from "../Utils/Utils";
import { IFileManager } from "../Interfaces/IFileManager";
import IMessageHolder from "../Interfaces/IMessageHolder";
import XoneMessageHolder from "./Localization/XoneMessageHolder";
import IXoneObject from "../Interfaces/IXoneObject";
import IXoneAttributes from "../Interfaces/IXoneAttributes";
import IXoneApp from "../Interfaces/IXoneApp";
import XoneConnectionData from "../Connection/XoneConnectionData";
import ObjectDeveloper from "../Utils/ObjectDeveloper";
import StringBuilder from "../Utils/StringBuilder";
import StringUtils from "../Utils/StringUtils";
import { XoneDataObject } from "./XoneDataObject";
import IXmlDocument from "../Interfaces/IXmlDocument";
//import DotNetJSONDocument from "../Xml/JSONImpl/DotNetJSONDocument";
import ConnectionStringUtils from "../Utils/ConnectionStringUtils";
import IllegalArgumentException from "../Exceptions/IllegalArgumentException";
import { Exception } from "../Exceptions/Exception";
import IllegalStateException from "../Exceptions/IllegalStateException";
import XmlUtils from "../Utils/XmlUtils";
import XOneJavascript from "../XoneScripts/XOneJavascript";
import ScriptContext from "../XoneScripts/ScriptContext";
import { XoneMessageKeys } from "../Exceptions/XoneMessageKeys";
import IUserCredentialValidator from "../Interfaces/IUserCredentialValidator";
import XoneUser from "./XoneUser";
import XmlNode from "../Xml/JSONImpl/XmlNode";
import XoneGenericException from "../Exceptions/XoneGenericException";
import { createVerify } from "crypto";
import NullPointerException from "../Exceptions/NullPointerException";
import XoneCssParser from "./CSS/XoneCssParser";
import XoneCssRule from "./CSS/XoneCssRule";
import XmlDocument from "../Xml/JSONImpl/XmlDocument";
import NumberUtils from "../Utils/NUmberUtils";
import IXoneFileLoader from "../Interfaces/IXoneFileLoader";
import FileLoaderNodeJS from "../Manager/FileLoaderNodeJS";
import FileLoaderBrowser from "../Manager/FileLoaderBrowser";
import Vector from "../Collections/Vector";
import { XoneError } from "./XoneError";
import XoneWebCoreConnectionData from "../Connection/WebCore/XoneWebCoreConnectionData";
import { connect } from "http2";

export class XoneApplication implements IXoneApp {
  // Solo para identificar que una instancia es la aplicacion
  public get isApplication(): boolean {
    return true;
  }

  private _objectID: symbol;
  private m_PushExit: boolean;
  private m_lstConnections: Hashtable<string, XoneConnectionData>;
  private m_objectDeveloper: ObjectDeveloper;
  private m_lstSysCollNames: Hashtable<string, string>;
  private m_strAppName: string;
  private m_lstQualifyCaches: Hashtable<string, Hashtable<string, string>>;
  private m_strConsole: StringBuilder;
  private m_lstParamStack: any[];
  private m_lstGlobalMacros: Hashtable<string, any>;
  private m_nDebugPort: number;
  private m_dbgLocker: Object;
  private m_lstCssList: Array<XoneCssParser>;
  private m_lstCollPropUndefinedValues: Hashtable<string, string[]>;
  private m_lstConditionCssList: Hashtable<string, Array<XoneCssParser>>;
  private m_strFixedVisualCondition: string;
  private m_strVisualConditions: string;
  private bIsCharWidthCompatibility: boolean;
  private bIsImageValuePriorized: boolean;
  private m_lstOpenColls: XoneDataCollection[];
  private m_bLoadLayouts: boolean;
  private m_bSelectiveReplication: boolean;
  private m_lstVisualCondDescriptors: Hashtable<string, string[]>;
  private m_lstCachedScripts: Hashtable<string, StringBuilder>;
  private m_globalItems: Hashtable<string, any>;
  private m_user: XoneDataObject;
  private m_lstCollections: Hashtable<
    string,
    XoneDataCollection
  > = new Hashtable<string, XoneDataCollection>();
  private m_lstCollPropValueCaches = new Hashtable<
    string,
    Hashtable<string, Hashtable<string, Hashtable<string, string>>>
  >();
  /**
   * K12050901: Permitir activar o desactivar las caches de valores de atributos.
   */
  private m_bCacheAttrValues = true; // TRUE si se cachean los atributos...
  /**
   * O13080801: Mecanismo de optimización para la gestión de controles visuales y atributos.
   * Lista con los atributos que varían porque tienen macros.
   */
  protected m_lstEvaluatedAttributes: Hashtable<string, Array<string>>;

  private m_jsonConfigFile;
  private m_strAppPath: string;
  private m_fileManager: IFileManager;
  private m_messages: IMessageHolder;
  protected m_company: IXoneObject;
  private m_strEntIdOwner: string;
  private m_strUserIdColl: string;
  private m_strEntIdLevel: string;
  private m_strEntIdColl: string;
  private m_currency: IXoneObject;
  private m_strFilesPath: string;
  private m_nVersionMapping: number;
  private m_xmlConfigFile: XmlNode;
  private m_context: any;
  private m_nMasterMid: number;
  private m_strDatemask: string;
  private m_bIsReplicating: boolean;
  private m_strPlatform: string;
  private m_nOperIdLength: number;
  private m_nRowIdLength: number;
  private mapLoginCollections: Hashtable<string, string>;
  private mapEntryPointCollections: Hashtable<string, string>;
  private sOverridenEntryPointCollection: string;
  private m_strEntTable: any;
  private m_strEntPk: any;
  private m_nEntPkType: number;
  private m_ui: any;
  private m_bHasStylesheets: boolean;
  private m_xmlAppNode: XmlNode;
  private m_strDefaultLanguage: string;
  private m_nScriptOptimizationLevel: number;
  private m_bLogMemoryUsage: boolean;
  private m_bSqlProfilerMode: boolean;
  private m_bDebug: boolean;
  private mIsEncryptFiles: any;
  private m_loader: IXoneFileLoader;
  private m_lstCollExceptions: Array<string>;
  private m_lstPropExceptions: Array<string>;
  private m_error: Array<XoneError>;
  private __SCRIPT_WRAPPERASYNC: any;
  private m_bExternalEntIdColl: boolean;
  private m_bExternalEntIdLevel: any;
  private m_bExternalEntIdOwner: any;

  /**
   *
   */
  constructor() {
    Utils.DebugLog(Utils.TAG_FRAMEWORK, "A new AppData is being instantiated.");
    this.m_strPlatform = "web";
    // En principio sería el que se pase desde fuera... si no viene ninguno seremos nosotros
    this._objectID = Symbol("XoneApplication");
    // if (null ==(m_loader =FileLoader))
    // 	m_loader =this;
    // m_context=context;
    // A13080702: Adicionar el PushValueAndExit de la mollejada para propagar a los demás.
    this.m_PushExit = false;
    // A11081001: Implementación de CreateObject y su infraestructura para crear objetos en scripts.
    // m_factory =Factory;
    // Y ahora inicializar el resto de variables.
    // Por defecto donde está la DLL...
    //////m_strAppPath = Path.GetDirectoryName(Assembly.GetExecutingAssembly().CodeBase).Replace("file:\\", "");
    this.m_strAppName = "XOne Android  Application";
    // La lista de conexiones
    this.m_lstConnections = new Hashtable<string, XoneConnectionData>();
    // Por defecto tenemos todas las colecciones de la aplicación
    //////m_strCollectionFilter = "//mp:collprops/mp:coll";
    // Crear la lista de colecciones
    this.m_lstCollections = new Hashtable<string, XoneDataCollection>();
    // Crear la lista de nombres de colecciones de sistema
    this.m_lstSysCollNames = new Hashtable<string, string>();
    // Crear la lista de caches de cualificación de campos
    this.m_lstQualifyCaches = new Hashtable<
      string,
      Hashtable<string, string>
    >();
    // Crear el desarrollador de objetos
    this.m_objectDeveloper = new ObjectDeveloper();
    // Datemask por defecto
    // TODO SACAR EL DATEMASK DEL DISPOSITIVO O COGER UNO POR DEFECTO
    //////m_strDatemask = CultureInfo.CurrentCulture.DateTimeFormat.ShortDatePattern.ToLower();
    // Inicializa la consola con una cadena vacía
    // O11042501: Utilizar un StringBuilder para la consola de AppData.
    // En realidad usamos un string builder vacío.
    this.m_strConsole = new StringBuilder();
    // Inicializa el stack de parámetros pra PushValue y PopValue
    this.m_lstParamStack = new Array();
    // TODO COGER EL DATEMASK DEL SISTEMA
    //////m_strSysDatemask = StringUtils.NormalizeDateMask(CultureInfo.CurrentCulture.DateTimeFormat.ShortDatePattern);
    // Crear el error para mantener la compatibilidad con versiones anteriores
    //m_error = new ThreadLocal<>();
    //m_currentContext = new ThreadLocal<>();
    //
    // Implementación de la maquinaria de scripting
    // Objetos propios usados para que esta clase se comporte como un scripting host.
    //m_classTypeInfoList = new Hashtable<>();
    // M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
    // Macros globales para sustituir en los SQLs y demás
    this.m_lstGlobalMacros = new Hashtable<string, any>();
    // A10051901:   Componentes de depuración y modificación de maquinaria XOne para depurar.
    // Inicializar el puerto de depuración con un valor por defecto.
    this.m_nDebugPort = 7797;
    // Por defecto la maquinaria se estará ejecutando, claro.
    // m_smState =new Semaphore(1);
    // K12060701: Modificaciones para permitir multithreading en los scripts y depuración.
    // Ahora esto es a nivel de threads
    //////m_state = EngineDebugState.Running;
    this.m_dbgLocker = new Object(); // Esto lo vamos a usar para sincronizar los threads.
    // m_lstDebugContexts = new ArrayList<>();
    // m_debugCtx = new ThreadLocal<>();
    // m_debugCtx.set(new CXoneDebugContext(CXoneDebugContext.MAIN_THREAD_NAME));
    // getDebugContext().setState(EngineDebugState.Running);
    // m_lstDebugContexts.add(getDebugContext());
    // // Crea la lista con las definiciones de puntos de ruptura.
    // m_lstBreakpointLists = new ArrayList<>();
    // // F11051803: Modificaciones para solucionar problemas de depuración en la maquinaria.
    // // Con dos cojines.. inicializamos a cero...
    // m_aiScriptDepth =new AtomicInteger(0);
    // ////m_aiScriptDepth.set(0);
    // // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
    this.m_messages = new XoneMessageHolder("EN");
    // A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
    // Crear la lista de CSS y demás cositas necesarias para que funcione.
    this.m_lstCssList = new Array<any>();
    this.m_lstCollPropUndefinedValues = new Hashtable<string, Array<string>>();
    // M11072901: Modificaciones al mecanismo de CSS para adaptarse a condiciones visuales.
    // Lista para almacenar las hojas por condiciones de ejecución.
    this.m_lstConditionCssList = new Hashtable<string, Array<XoneCssParser>>();
    this.m_strFixedVisualCondition = this.m_strVisualConditions = "";
    // M11111701: Protección de colecciones abiertas por error en scripts.
    this.m_lstOpenColls = new Array<XoneDataCollection>();
    // Para ala compatibilidad con las otras plataformas
    this.bIsCharWidthCompatibility = false;
    this.bIsImageValuePriorized = false;
    // O13030601: Optimizaciones en la búsqueda de CSS para atributos.
    // Crear la lista de condiciones visuales parseadas
    this.m_lstVisualCondDescriptors = new Hashtable<string, Array<string>>();
    // O13061901: Cachear los scripts que no están en ficheros include.
    // Lista de scripts parseados que no son includes
    this.m_lstCachedScripts = new Hashtable<string, StringBuilder>();
    // A13080701: Permitir objetos globales de script adicionados desde fuera.
    this.m_globalItems = new Hashtable<string, any>();
    // TODO ADD TAG
    // Por defecto mantenemos esta banderilla a true porque asumimos que la va a usar
    // un framework que la necesita. Se puede poner a false a nivel de aplicación.
    this.m_bLoadLayouts = true;
    //this.m_lstRunningThreads.clear();
    this.m_nOperIdLength = 150;
    this.m_nRowIdLength = 100;
    this.m_lstCollExceptions = XoneApplication.getCollExceptions();
    this.m_lstPropExceptions = XoneApplication.getPropExceptions();
    this.m_error = new Array<XoneError>();
    this.m_isBrowser = !(
      typeof global !== "undefined" &&
      {}.toString.call(global) == "[object global]"
    );
    if (this.isBrowser) {
      this.m_loader = new FileLoaderBrowser();
    } else {
      this.m_loader = new FileLoaderNodeJS();
    }
    // if (this.m_isBrowser)
    //     Object.defineProperty(window,"appData", {
    //         value:this
    //     });
    // else
    //     Object.defineProperty(global,"appData", {
    //         value:this
    //     });
  }

  private static getCollExceptions(): Array<string> {
    return Array.from([
      "connection",
      "objname",
      "updateobj",
      "progid",
      "stringkey",
      "loadall",
      "withopen",
      "idfieldname",
      "useextdata",
    ]);
  }

  private static getPropExceptions(): Array<string> {
    return Array.from([
      "mapcol",
      "mapfld",
      "bit",
      "formula",
      "name",
      "group",
      "frame",
      "type",
      "linkedto",
      "linkedfield",
    ]);
  }

  getSysDatemask(): string {
    return "ymd";
  }

  isDebugMode(): boolean {
    return false;
  }

  addEvaluatedAttributesList(sCollectionName: any, sKey: string) {
    if (this.m_lstEvaluatedAttributes == null) {
      this.m_lstEvaluatedAttributes = new Hashtable<string, Array<string>>();
    }
    if (!this.m_lstEvaluatedAttributes.containsKey(sCollectionName)) {
      this.m_lstEvaluatedAttributes.put(sCollectionName, new Array<string>());
    }
    this.m_lstEvaluatedAttributes.get(sCollectionName).push(sKey);
  }

  GetWeekDayName(n: number): any {
    throw new Error("Method not implemented.");
  }

  currentContext(): any {
    throw new Error("Method not implemented.");
  }

  private m_isBrowser: boolean;

  public get isBrowser(): boolean {
    return this.m_isBrowser;
  }

  // M11111701: Protección de colecciones abiertas por error en scripts.
  // Una nueva colección abierta
  public AddOpenColl(Collection: XoneDataCollection): void {
    if (!this.m_lstOpenColls.contains(Collection))
      this.m_lstOpenColls.push(Collection);
  }

  // M11111701: Protección de colecciones abiertas por error en scripts.
  // Elimina la colección de la lista de colecciones abiertas
  public RemoveOpenColl(Collection: XoneDataCollection): void {
    if (this.m_lstOpenColls.contains(Collection))
      this.m_lstOpenColls.remove(Collection);
  }

  public exports = <any>{};

  // public RunScript(strFunctionName: any, strLang: string, strScript: string, ctx: ScriptContext,...Arguments): any {
  //     __SCRIPT_WRAPPER(this,this.m_ui,ctx.getObjectHost(),strScript, Arguments);
  //     // Object.defineProperties(global, {
  //     //     "self" : {
  //     //         value : ctx.getObjectHost(),
  //     //         writable : false,
  //     //         enumerable : false,
  //     //         configurable : true
  //     //     },
  //     //     "selfColl" : {
  //     //         value : ctx.getCollectionHost(),
  //     //         writable : false,
  //     //         enumerable : false,
  //     //         configurable : true
  //     //     }
  //     // });
  //     // require("../Code/LoginColl.js");
  //     // // let obj=Object.setPrototypeOf(this.exports.LoginColl_JSWRAPPER,this.exports.__XONE_ISOLATE);
  //     // // let b=Object.create(obj);
  //     // this.__extends(this.exports.LoginColl_JSWRAPPER,this.exports.__XONE_ISOLATE);
  //     // let execContext=new this.exports.__XONE_ISOLATE(ctx.getObjectHost());
  //     // let a=execContext.calcular();
  //     // this.exports["LoginColl_JSWRAPPER"]["create"].call(ctx.getObjectHost());
  // }

  public async RunScriptAsync(
    strFunctionName: any,
    strLang: string,
    strScript: string,
    ctx: ScriptContext,
    ...Arguments
  ): Promise<any> {
    // let f=require("../xone/source/scripts/__LoginColl__.js");
    // let g=require("../xone/source/scripts/__SCRIPT_WRAPPER__.js");
    // f={...f,...g};
    // var c=new f.__SCRIPT_WRAPPERASYNC(this, this.m_ui, ctx.getObjectHost(), "LoginColl_create_1", Arguments);
    // f["LoginColl_create_1"]();
    let s = "(async () => {\n" + strScript + "\n})()";
    console.log("FunctionCall: ", strFunctionName);
    console.log("Script: ", s);
    await this.__SCRIPT_WRAPPERASYNC(
      this,
      this.m_ui,
      ctx.getObjectHost(),
      ctx.getCollectionHost(),
      this.getUser(),
      s,
      Arguments
    );
    return true;
    // Object.defineProperties(global, {
    //     "self" : {
    //         value : ctx.getObjectHost(),
    //         writable : false,
    //         enumerable : false,
    //         configurable : true
    //     },
    //     "selfColl" : {
    //         value : ctx.getCollectionHost(),
    //         writable : false,
    //         enumerable : false,
    //         configurable : true
    //     }
    // });
    // require("../Code/LoginColl.js");
    // // let obj=Object.setPrototypeOf(this.exports.LoginColl_JSWRAPPER,this.exports.__XONE_ISOLATE);
    // // let b=Object.create(obj);
    // this.__extends(this.exports.LoginColl_JSWRAPPER,this.exports.__XONE_ISOLATE);
    // let execContext=new this.exports.__XONE_ISOLATE(ctx.getObjectHost());
    // let a=execContext.calcular();
    // this.exports["LoginColl_JSWRAPPER"]["create"].call(ctx.getObjectHost());
  }

  private __extendStatics(d, b) {
    return (
      Object.setPrototypeOf ||
      ({ __proto__: [] } instanceof Array &&
        function (d, b) {
          d.__proto__ = b;
        }) ||
      function (d, b) {
        for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    );
  }

  private __extends(d, b) {
    Object.setPrototypeOf(d, b);
    // function __() { this.constructor = d; }
    // d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  }
  // let __extends = (this && this.__extends) || (function () {
  //     var extendStatics = function (d, b) {
  //         extendStatics = Object.setPrototypeOf ||
  //             ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
  //             function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
  //         return extendStatics(d, b);
  //     };
  //     return function (d, b) {
  //         extendStatics(d, b);
  //         function __() { this.constructor = d; }
  //         d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  //     };
  // })();

  getDefaultLanguage(): string {
    return "javascript";
  }
  m_bIsReady: boolean;
  m_strObjectPrefix: string;
  getPlatform(): string {
    return this.m_strPlatform;
  }
  GetClassName(strProgId: any): string {
    return strProgId;
  }
  CheckClassName(strClassName: string): string {
    return strClassName;
  }

  getIdColl(): string {
    throw new Error("Method not implemented.");
  }
  getCurrentCompany(): XoneDataObject {
    return this.getCurrentEnterprise() as XoneDataObject;
  }

  public getUserIdColl(): string {
    return this.m_strUserIdColl;
  }

  public getUser(): IXoneObject {
    return this.m_user;
  }

  public getCurrency(): IXoneObject {
    return this.m_currency;
  }

  public getEntIdLevel(): string {
    return this.m_strEntIdLevel;
  }

  public getEntIdColl(): string {
    return this.m_strEntIdColl;
  }

  public getEntIdOwner(): string {
    return this.m_strEntIdOwner;
  }

  public getUserInterface() {
    // Devolver el UI
    return this.m_ui;
  }

  /**
   * Asigna valor al objeto que se usará para gestionar el UI de la aplicación.
   * @param value		Nuevo objeto de UI de la aplicación.
   * @throws XoneGenericException
   */
  public setUserInterface(value: any) {
    this.m_ui = value;
  }

  /**
   * Adiciona una conexión de datos a la lista interna de conexiones
   * @param Connection			Conexión que se quiere adicionar a la lista.
   * @return						TRUE si la conexión se ha añadido correctamente.
   */
  private AddConnection(Connection: XoneConnectionData): boolean {
    // Adicionar la conexión a la lista
    // F10080902: Cuando se adiciona una conexión, verificar si es nula.
    // Aquí habrá que comprobar si es nula, claro
    if (Connection != null)
      this.m_lstConnections.put(Connection.getName(), Connection);
    return true;
  }

  /**
   * Crea una conexión de datos. Las conexiones pueden ser de base de datos, remotas, etc.
   * @param NodeData				Nodo XML para construir la conexión, la cual saca de aquí sus datos. Si se pasa NULL se crea una conexión principal.
   * @return						Devuelve la nueva conexión recién creada o NULL si algo falla.
   */
  private CreateConnection(NodeData: XmlNode): XoneConnectionData {
    let strName: string = null;
    let cn: XoneConnectionData = null;
    //
    // Si no viene nodo, estamos creando la central
    if (NodeData != null) strName = NodeData.getAttrValue(Utils.PROP_ATTR_NAME);
    if (StringUtils.IsEmptyString(strName))
      strName = XoneConnectionData.MAIN_CONNECTION;
    // Buscar qué conexión es la que tenemos que crear, para ello posiblemente haya
    // que parsear la cadena de conexión para sacar las características de la conexión
    if (NodeData == null) {
      cn = new XoneWebCoreConnectionData(this.m_context, strName, this); // TODO: Esto es el real XoneDbConnectionData(m_context, strName, this);

      /*
       * TODO ADD TAG 04/05/2015 Juan Carlos:
       * Aplicando estos nuevos atributos del license.ini.
       */
      if (strName.localeCompare(XoneConnectionData.MAIN_CONNECTION) == 0) {
        cn.setOperIdLength(this.m_nOperIdLength);
        cn.setRowIdLength(this.m_nRowIdLength);
      }
    } else {
      // Tenemos que averiguar qué tipo de conexión es
      // A11022301:	Funciones, clases y demás necesarias para trabajar con conexiones remotas.
      // Analizar la cadena de conexión.
      const strConnString = NodeData.getAttrValue("connstring");

      //Juan Carlos. Errores en el parseo de la cadena de conexión. Lo cambio.
      const parsedConnectionString = ConnectionStringUtils.parseConnectionString(
        strConnString
      );
      let strProvider = parsedConnectionString.get("provider");
      if (!TextUtils.isEmpty(strProvider)) {
        if (
          strProvider.compareToIgnoreCase("cgsoft remote provider") == 0 ||
          strProvider.compareToIgnoreCase("xone remote provider") == 0
        ) {
          let strProgID = parsedConnectionString.get("progid");
          if (strProgID.compareToIgnoreCase("CGSSocketCE.ConnectCE") == 0) {
            let GPSConnectionData = require("../Connection/GPSConnection/XoneGPSConnectionData");
            cn = new GPSConnectionData.default(this.m_context, strName, this);
          } else if (strProgID.compareToIgnoreCase("CGSRSS.CProxy") == 0) {
            //cn = new CXoneRSSConnectionData(strName, this,this.m_context);
          } else if (strProgID.compareToIgnoreCase("CGSProxy.CProxy") == 0) {
            //cn = new CXoneRemoteConnectionData(strName, this);
          } else if (strProgID.indexOf("JSONConnection") >= 0) {
            let JSONConnectionData = require("../Connection/JSONConnection/XoneJSONConnectionData");
            cn = new JSONConnectionData.default(this.m_context, strName, this);
          } else {
            //cn = new CXoneReflectionConnectionData(strProgID,strName, this, this.m_context);
            try {
              if (cn != null && this.m_user != null) {
                cn.addExtendedProperty(
                  "xoneuser",
                  this.m_user.GetRawStringField("LOGIN")
                );
                cn.addExtendedProperty(
                  "xonepass",
                  this.m_user.GetRawStringField("PWD")
                );
              }
            } catch (ex) {
              ex.printStackTrace();
            }
          }
        }
        if (cn == null) {
          cn = new XoneWebCoreConnectionData(this.m_context, strName, this); // TODO: Esto es el real new CXoneRemoteConnectionData (strName, this);
        }
      }

      // 12/01/2018 Juan Carlos
      // Alguien se olvidó de leer y asignar el atributo datemask del <connection>.
      if (cn != null) {
        const sDateMask = NodeData.getAttrValue("datemask");
        if (!TextUtils.isEmpty(sDateMask)) {
          cn.setDatemask(sDateMask);
        }
        // Tag 23041801 Luis Para poder agregar en la conexion a traves de atributos el rowidfieldname y el sqlfieldname
        const sRowidFieldName = NodeData.getAttrValue("rowidfieldname");
        if (!TextUtils.isEmpty(sRowidFieldName)) {
          cn.setRowIdFieldName(sRowidFieldName);
        }
        const sSqlFieldName = NodeData.getAttrValue("sqlfieldname");
        if (!TextUtils.isEmpty(sSqlFieldName)) {
          cn.setSqlFieldName(sSqlFieldName);
        }
      }
      // Si no hemos creado ninguna, la creamos ahora...
      if (cn == null)
        cn = new XoneWebCoreConnectionData(this.m_context, strName, this); // TODO: Esto es el real XoneDbConnectionData(this.m_context, strName, this);
    } // Tenemos que averiguar qué tipo de conexión es
    // Pasar el nodo de datos a la conexión para que se busque la vida
    // A11111101: Soportar plataformas a nivel de nodos de conexión.
    // Asignar la plataforma
    cn.SetPlatform(this.m_strPlatform);
    cn.setNodeData(NodeData);
    // Si es la conexión principal, le pasamos el IsReplicating de la aplicación
    if (cn.getName().equals(XoneConnectionData.MAIN_CONNECTION)) {
      // Pasarle las cosas que históricamente estaban a nivel de aplicación
      cn.setIsReplicating(this.m_bIsReplicating);
      cn.setDatemask(this.m_strDatemask);
    } // Pasarle las cosas que históricamente estaban a nivel de aplicación
    // TODO ADD TAG
    // Verificar que tenga datemask...
    if (StringUtils.IsEmptyString(cn.getDatemask())) {
      // Poner la de la aplicación
      cn.setDatemask(this.m_strDatemask);
    } // Poner la de la aplicación
    // Si es la conexión principal, comprobar si hay InitMasterData anterior
    if (cn.getName().equals(XoneConnectionData.MAIN_CONNECTION)) {
      // Setear los datos
      if (this.m_nMasterMid > 0) {
        // Tiene valores
        cn.setMID(this.m_nMasterMid);
        cn.setDBID(this.m_nMasterMid);
        cn.setIsReplicating(true);
      } // Tiene valores
    } // Setear los datos
    return cn;
  }

  LoadConnection(Data: XmlNode): boolean {
    const cn = this.CreateConnection(Data);
    if (cn == null) {
      return false;
    }
    return this.AddConnection(cn);
  }

  public getConnection(ConnectionName: string = null): XoneConnectionData {
    let strName = ConnectionName;
    if (StringUtils.IsEmptyString(ConnectionName))
      strName = XoneConnectionData.MAIN_CONNECTION;
    // Buscar la conexión en la lista
    if (!this.m_lstConnections.containsKey(strName)) return null;
    // Devolverla
    return this.m_lstConnections.get(strName);
  }

  public async login(...FunctionParams: any): Promise<XoneDataObject> {
    const sFunctionName = "Login";
    Utils.CheckNullParameters(sFunctionName, FunctionParams);
    Utils.CheckIncorrectParamCount(sFunctionName, FunctionParams, 1);
    const jsObject = FunctionParams[0];
    const sUserName = StringUtils.SafeToString(jsObject["userName"], null);
    const sPassword = StringUtils.SafeToString(jsObject["password"], null);
    const bIsGoogleSignOn = StringUtils.ParseBoolValue(
      jsObject["isGoogleSignOn"],
      false
    );
    const sEntryPointCollection = StringUtils.SafeToString(
      jsObject["entryPoint"],
      this.getEntryPointCollection(this.getCurrentVisualConditions())
    );
    const onLoginSuccessful = jsObject["onLoginSuccessful"];
    const onLoginFailed = jsObject["onLoginFailed"];
    const onLoginCallResults = jsObject["onLoginCallResults"];
    if (!bIsGoogleSignOn) {
      if (sUserName == null) {
        throw new IllegalArgumentException(
          sFunctionName + "(): Null user name parameter"
        );
      }
      if (sPassword == null) {
        throw new IllegalArgumentException(
          sFunctionName + "(): Null password parameter"
        );
      }
    }
    if (TextUtils.isEmpty(sEntryPointCollection)) {
      throw new IllegalArgumentException(
        sFunctionName + "(): Empty entry point parameter"
      );
    }
    const dataCollEntryPoint = await this.getCollection(sEntryPointCollection);
    if (dataCollEntryPoint == null) {
      throw new IllegalArgumentException(
        sFunctionName +
          "(): Entry point collection " +
          sEntryPointCollection +
          " not found"
      );
    }
    this.setOverridenEntryPoint(sEntryPointCollection);
    const user = this.getUser();
    if (user != null) {
      Utils.DebugLog(
        Utils.TAG_FRAMEWORK,
        sFunctionName + "(): User is already logged in"
      );
    }
    // if (bIsGoogleSignOn) {
    //     IStartActivityForResult lastEditView = app.getLastEditView();
    //     if (lastEditView == null) {
    //         throw new IllegalStateException(sFunctionName + "(): No edit view is visible, cannot perform Google Sign In");
    //     }
    //     GoogleSignInTask gsoTask = new GoogleSignInTask(lastEditView);
    //     GoogleSignInAccount result = gsoTask.get();
    //     sUserName = result.getEmail();
    //     if (onLoginCallResults != null) {
    //         HashMap<String, Object> mapRuntimeObjects = getXOneScriptObjects();
    //         XOneJavascript.run(m_context, onLoginCallResults, mapRuntimeObjects, gsoTask);
    //         return;
    //     }
    // }
    let ctx = new ScriptContext(null);
    const mapRuntimeObjects = await this.getXOneScriptObjects(ctx);
    try {
      let loggedUser = await this.logonUser(
        sUserName,
        sPassword,
        Utils.EMPTY_STRING
      );
      // ca.then(async (loggedUser)=>{
      let loginException: Exception = null;
      if (loggedUser != null) {
        Utils.setLastAppExecutedField(
          this.m_context,
          sUserName,
          this.m_strAppName + "##" + Utils.LAST_EXECUTED_USER_FIELD_NAME
        );
        if (onLoginSuccessful != null) {
          XOneJavascript.run(this, onLoginSuccessful, ctx, mapRuntimeObjects);
        }
        this.m_ui.launchEntryPoint();
      } else if (onLoginFailed != null) {
        let sMessage;
        if (loginException != null) {
          sMessage = Utils.getThrowableMessage(loginException);
        } else {
          sMessage = "Login failed";
        }
        //XOneJavascript.run(onLoginFailed, sMessage);
        await XOneJavascript.runAsync(
          this,
          onLoginFailed,
          ctx,
          mapRuntimeObjects,
          null,
          sMessage
        );
      }
      return loggedUser;
    } catch (ex) {
      console.error(ex);
      if (onLoginFailed)
        XOneJavascript.run(this, onLoginFailed, ctx, mapRuntimeObjects);
      return null;
    }
    // let loginException: Exception = null;
    // let loggedUser:XoneDataObject;
    // try {
    //     loggedUser =await this.logonUser(sUserName, sPassword, Utils.EMPTY_STRING);
    // } catch (ex) {
    //     loginException = ex;
    //     loggedUser = null;
    // }
    // return loggedUser;
  }

  private async getXOneScriptObjects(
    scriptContext?: ScriptContext
  ): Promise<Hashtable<String, Object>> {
    const mapRuntimeObjects = new Hashtable<String, Object>();
    mapRuntimeObjects.put("appData", this);
    let injectJavaObject = this.getUserInterface();
    if (injectJavaObject != null) {
      mapRuntimeObjects.put("ui", injectJavaObject);
    }
    if (this.m_user != null) {
      mapRuntimeObjects.put("user", this.m_user);
    }
    //mapRuntimeObjects.put("err", this.getError());
    if (scriptContext != null) {
      injectJavaObject = scriptContext.getObjectHost();
      if (injectJavaObject != null) {
        mapRuntimeObjects.put("self", injectJavaObject);
      }
      injectJavaObject = await scriptContext.getCollectionHost();
      if (injectJavaObject != null) {
        mapRuntimeObjects.put("selfDataColl", injectJavaObject);
      }
      let params = scriptContext.GetParams();
      if (params != null) {
        let paramsNames = null;
        while ((paramsNames = params.next()) != null) {
          mapRuntimeObjects.put(paramsNames.value[0], paramsNames.value[1]);
        }
      }
    }
    return mapRuntimeObjects;
  }

  public async logonUser(
    UserName: string,
    Password: string,
    errorMessage: string,
    silentMode: boolean = false
  ): Promise<XoneDataObject> {
    // Primero buscamos la colección de usuarios... el nombre se puede cambiar
    // en el nodo de inicialización
    let usercol = await this.getSysCollection("Usuarios");
    // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
    if (usercol == null)
      ////throw new Exception("No se encuentra la colección de usuarios para buscar el usuario que se ha pedido conectar.");
      // A12080601: Incluir un mensaje de error personalizado en LogonUser.
      throw new Exception(
        this.m_messages.GetMessage(
          XoneMessageKeys.SYS_MSG_APP_LOGONNOUSERCOLL,
          errorMessage
        )
      );
    // TODO: Luis Esto es lo unico que se me ocurre para que no espere Authentication las llamadas online
    // if (usercol.getConnection() instanceof CXoneReflectionConnectionData)
    // 	usercol.getConnection().addExtendedProperty("logincall", "true");
    // Buscamos el usuario que nos piden
    const objuser = (await usercol.get("LOGIN", UserName)) as XoneUser;
    usercol.getConnection().removeExtendedProperty("logincall");
    if (objuser == null) {
      // Error
      // M11051201: Mecanismo para soporte multilenguaje en los componentes y demás cosas.
      ////throw new Exception("No se encuentra el usuario con LOGIN='" + UserName + "' para cargarlo.");
      // A12080601: Incluir un mensaje de error personalizado en LogonUser.
      let s = this.m_messages.GetMessage(
        XoneMessageKeys.SYS_MSG_APP_LOGONUSERNOTFOUND,
        errorMessage
      );
      s = s.replace("{0}", UserName);
      throw new Exception(s);
    } // Error
    // Si el objeto implementa la interfaz adecuada, usarla
    if (!silentMode) {
      // TODO: Luis Para modo silencioso, sin pass
      let validator: IUserCredentialValidator = null;
      if (
        Object.prototype.hasOwnProperty.call(objuser, "ValidateUserCredentials")
      )
        validator = objuser;
      if (validator != null) {
        // Efectuar la validación
        let objparams = [Password];
        if (!validator.ValidateUserCredentials(objparams)) return null;
      } // Efectuar la validación
      else {
        // Verificar la clave... en esta implementación es comparar y nada mais
        let strUserPwd: string = objuser.get("PWD") as string;
        if (StringUtils.IsEmptyString(Password)) {
          // La del usuario debería ser vacía también
          if (!StringUtils.IsEmptyString(strUserPwd)) return null; // Error
        } // La del usuario debería ser vacía también
        else {
          // Comparar
          if (!Password.equals(strUserPwd)) return null;
        } // Comparar
      } // Verificar la clave... en esta implementación es comparar y nada mais
    }
    // Al menos el usuario está bien... ahora buscar la empresa...
    this.m_user = objuser;
    // if (usercol.getConnection() instanceof CXoneReflectionConnectionData) {
    // 	try {
    // 		if (this.m_user!=null) {
    // 			usercol.getConnection().addExtendedProperty("xoneuser",this.m_user.GetRawStringField("LOGIN"));
    // 			usercol.getConnection().addExtendedProperty("xonepass",this.m_user.GetRawStringField("PWD"));
    // 		}
    // 	} catch (e) {
    // 		e.printStackTrace();
    // 	}
    // }
    let objcomp = await objuser.ObjectItem("IDEMPRESA");
    if (objcomp == null) return objuser;
    // Cargar la empresa
    this.LoadCompanyData(objcomp);
    return objuser;
  }

  protected GetSysCollName(SysName: string): string {
    // Si no está en lista, es que no se ha traducido, luego es el que se ha pasado
    if (!this.m_lstSysCollNames.containsKey(SysName)) return SysName;
    // De lo contrario, devolvemos el traducido
    return this.m_lstSysCollNames.get(SysName);
  }

  public async getSysCollection(CollName: string): Promise<XoneDataCollection> {
    return await this.getCollection(this.GetSysCollName(CollName));
  }

  public addLoginCollection(
    loginCollection: XmlNode,
    loginCollectionDefault: string
  ): void {
    if (this.mapLoginCollections == null) {
      this.mapLoginCollections = new Hashtable<string, string>();
    }
    XmlUtils.getValuesByConditions(
      this.mapLoginCollections,
      loginCollection,
      loginCollectionDefault
    );
  }

  public getLoginCollectionName(condition: string): string {
    if (!StringUtils.IsEmptyString(condition)) {
      let lst = this.getCurrentVisualConditionsDescriptors();
      for (let s in lst) {
        if (this.mapLoginCollections.containsKey(s)) {
          return this.mapLoginCollections.get(s);
        }
      }
    }
    /// ALEJANDRO FG - APRENDIENDO - 22/02/2017
    // He cambiado el orden para que si esta definido el nuevo modo , pues este prevalezca sobre el viejo,
    // tal y como hace el entrypoint
    if (this.mapLoginCollections.containsKey(Utils.MACRO_DEFAULT)) {
      let sLoginColl = this.mapLoginCollections.get(Utils.MACRO_DEFAULT);
      if (!TextUtils.isEmpty(sLoginColl)) {
        return sLoginColl;
      }
    }

    let loginCollNode = this.getConfigFile()
      .SelectSingleNode(Utils.COLL_COLLPROPS)
      .SelectSingleNode(
        Utils.COLL_COLL,
        Utils.COLL_LOGIN_COLL,
        Utils.TRUE_VALUE
      );
    if (loginCollNode != null) {
      return loginCollNode.getAttrValue(Utils.PROP_ATTR_NAME);
    }
    return null;
  }

  // ADD Tag Luis Ahora puede haber varios entrypoint en dependencia de las condiciones como en el style

  public addEntryPointCollection(
    entryPointCollection: XmlNode,
    entryPointDefault: string
  ): void {
    if (this.mapEntryPointCollections == null) {
      this.mapEntryPointCollections = new Hashtable<string, string>();
    }
    XmlUtils.getValuesByConditions(
      this.mapEntryPointCollections,
      entryPointCollection,
      entryPointDefault
    );
  }

  public getEntryPointCollection(sCondition: string) {
    if (!TextUtils.isEmpty(this.sOverridenEntryPointCollection)) {
      return this.sOverridenEntryPointCollection;
    }
    if (!StringUtils.IsEmptyString(sCondition)) {
      let lstVisualConditions = this.getCurrentVisualConditionsDescriptors();
      for (let sVisualCondition in lstVisualConditions) {
        if (this.mapEntryPointCollections.containsKey(sVisualCondition)) {
          return this.mapEntryPointCollections.get(sVisualCondition);
        }
      }
    }
    if (this.mapEntryPointCollections.containsKey(Utils.MACRO_DEFAULT)) {
      return this.mapEntryPointCollections.get(Utils.MACRO_DEFAULT);
    }
    return null;
  }

  public setOverridenEntryPoint(sEntryPointCollection: string): void {
    this.sOverridenEntryPointCollection = sEntryPointCollection;
  }

  public logout(...FunctionParams): void {
    const sFunctionName = "Logout";
    const user = this.getUser();
    if (user == null) {
      throw new IllegalStateException(
        sFunctionName + "(): User is not logged in"
      );
    }
    const userCollection = user.getOwnerCollection();
    if (userCollection != null) {
      userCollection.Clear();
    }
    this.m_user = null;
    this.clearCompanyData();
    // TODO: ver como se hace esto
    // IXoneAndroidApp app = getAndroidApp();
    // if (app != null) {
    //     app.returnToLogin();
    // }
  }

  async IniciarApp(
    codePath: string,
    scriptWrapper: any,
    fileManager: IFileManager,
    connString?: string
  ): Promise<void> {
    this.setAppPath(codePath);
    this.__SCRIPT_WRAPPERASYNC = scriptWrapper;
    this.m_fileManager = fileManager;
    const appXml = await fileManager.readFileAsync(
      this.m_strAppPath + "/" + Utils.TAG_APPNODE
    );
    this.m_jsonConfigFile = await fileManager.readFileAsync(
      this.m_strAppPath + "/" + Utils.TAG_MAPPINGFILE + ".xne"
    );
    this.LoadConfigFile(
      this.m_strAppPath,
      appXml,
      this.m_jsonConfigFile,
      true,
      2
    );
    this.Initialize(connString);
  }

  /**
   * Inicializa la aplicacion. Se le pasa una cadena de conexion para pasarle a la conexion principal de la aplicacion. Si la conexion principal no existe, la crea.
   * @param ConnString			Cadena de conexion para pasarle a la conexion principal. Si ya tenoa una voa nodo APP, se sustituye por esta.
   * @return						Devuelve TRUE si la inicializacion es correcta. FALSE si falla algo que no dispare excepciones.
   * @throws Exception
   */
  public Initialize(ConnString: string = Utils.EMPTY_STRING): boolean {
    // Crear un wrapper de UI si no lo han puesto ya
    if (this.m_ui == null)
      this.m_ui = {
        startReplica: function () {},
        showToast: function (...args) {},
        launchEntryPoint: function (...args) {},
        msgBox: function (...args) {},
        startGps: function (...args) {},
      }; // new XoneUserInterface (this);

    // Buscamos la conexion principal
    let mainConn: XoneConnectionData = this.getConnection();

    if (mainConn == null) {
      // Adicionar la nueva
      // M11051201: Mecanismo para soporte multilenguaje en los componentes y demos cosas.
      if (null == (mainConn = this.CreateConnection(null)))
        ////throw new Exception("No se puede crear la conexion principal. Posiblemente haya un problema de memoria.");
        throw new XoneGenericException(
          -9000,
          this.m_messages.GetMessage(
            XoneMessageKeys.SYS_MSG_APP_CANNOTCREATEMAINCONN,
            "Cannot create main connection. Possible cause: memory error."
          )
        );
      mainConn.setConnString(ConnString);
      // Si hay prefijo definido, lo ponemos ahora
      if (!StringUtils.IsEmptyString(this.m_strObjectPrefix))
        mainConn.setPrefix(this.m_strObjectPrefix);
      this.AddConnection(mainConn);
    } // Adicionar la nueva
    else {
      // Si no tiene cadena de conexion, ponerla
      if (StringUtils.IsEmptyString(mainConn.getConnString()))
        mainConn.setConnString(ConnString);
    } // Si no tiene cadena de conexion, ponerla
    // Solamente pedir una conexion fosica... si falla es que hay problemas de inicializacion
    let conn = mainConn.GetNewConnection(true);
    if (conn == null) {
      // Error
      // M11051201: Mecanismo para soporte multilenguaje en los componentes y demos cosas.
      ////throw new Exception("No se puede abrir una conexion para '" + mainConn.getConnString() + "'");
      let s = this.m_messages.GetMessage(
        XoneMessageKeys.SYS_MSG_APP_OPENCONNERROR,
        "Cannot open connection. ConnString='{0}'"
      );
      s = s.replace("{0}", mainConn.getConnString());
      throw new XoneGenericException(-9001, s);
    } // Error
    // Cerrar la conexion
    //////conn.close();
    // TODO ADD TAG Moviendo el loadinclude
    // Inicializacion completada...
    return (this.m_bIsReady = true);
  }

  public LoadConfigFile(
    executionPath: string,
    appSource: any,
    Source: any,
    useTranslation: boolean,
    version: number
  ): IXmlDocument {
    // A14102301: Incroporación de un mecanismo de control de integridad para desarrollos.
    // Si estamos en este modo, el mappings tiene que cuadrar... si no, pal carajo...
    // Solo si la maquinaria se ha activado
    // if (m_checker != null && !m_checker.checkFileIntegrity(executionPath, m_strAppPath)) {
    //     return null;
    // }
    // Ahora se debe guardar la version con la que se esta trabajando que se usara en las colecciones al cargarla
    this.m_nVersionMapping = version;
    let doc = XmlDocument.getInstance();
    doc.Deserialize(null, executionPath, Source, useTranslation);
    this.m_xmlConfigFile = doc.getRootNode();
    //require("../Code/scripts/funciones.js");
    // Luis: El nodo app, en el mundo nuevo
    // puede venir en un archivo externo
    // En ese caso lo mas seguro es que ya lo tengamos cargado
    if (appSource != null) {
      if (!this.LoadAppFile(executionPath, appSource, useTranslation)) {
        return null;
      }
    } else if (
      !this.LoadAppNode(this.m_xmlConfigFile.SelectSingleNode("app"))
    ) {
      return null;
    }
    // // Los include ahora aqui
    // // Cargar los ficheros include que están a nivel global (a nivel de colección) y
    // // almacenarlos en las listas correspondientes...
    // if (m_lstIncludeFiles == null) {
    //     if (!LoadIncludeFiles(m_xmlAppNode)) {
    //         throw new XoneGenericException(-11015, "Cannot load script files. Check include node.");
    //     }
    // }
    // LoadSecurityConfigFiles(m_xmlAppNode, executionPath, useTranslation);
    // // 20/09/2019
    // LoadAppMachineLearningFile();
    // Completo
    return doc;
  }

  /**
   * Carga el fichero app.xml que es el nuevo formato de integrar las cosas de la app
   * @param Source				Stream del cual se leerán los datos del fichero mappings. El framework tendrá que buscarse la vida para abrirlo.
   * @return						Devuelve TRUE si la carga del mappings ha sido correcta.
   * @throws Exception
   */
  public LoadAppFile(
    executionPath: string,
    Source: any,
    useTranslation: boolean
  ): boolean {
    // A14102301: Incorporación de un mecanismo de control de integridad para desarrollos.
    // Si estamos en este modo, el mappings tiene que cuadrar... si no, pal carajo...
    // TODO: Luis para mas adelante
    // if (m_checker !=null)
    // {// Solo si la maquinaria se ha activado
    //     if (!m_checker.checkFileIntegrity(executionPath, m_strAppPath))
    //         return false;
    // }// Solo si la maquinaria se ha activado
    //LittleEndianDataInputStream s =new LittleEndianDataInputStream(Source);
    //XmlDocument doc =new XmlDocument();
    let xmlDocument: IXmlDocument = XmlDocument.getInstance();
    xmlDocument.Deserialize(null, executionPath, Source, useTranslation);

    /*
     * 26/02/2018 Juan Carlos
     * Al convertir algunos proyectos con el Cloud Studio, el nodo app puede ser la raíz
     * directamente. Eso no lo veo mal pero esto no lo tenía en cuenta.
     */
    let rootNode = xmlDocument.getRootNode();
    this.m_xmlAppNode = rootNode.SelectSingleNode("app");
    if (this.m_xmlAppNode == null) {
      if (rootNode.getName().equals("app")) {
        this.m_xmlAppNode = rootNode;
      }
    }

    // Luis: El nodo app, en el mundo nuevo
    // puede venir en un archivo externo
    // En ese caso lo mas seguro es que ya lo tengamos cargado
    return this.LoadAppNode(this.m_xmlAppNode);
  }

  /**
   *  Busca el nodo app dentro del fichero mappings y carga su contenido
   * @throws Exception
   */
  private LoadAppNode(xapp: XmlNode): boolean {
    // De entrada si no hay mappings, no podemos hacer nada
    //if (m_xmlConfigFile == null)
    //    return false;
    //XmlNode xapp = m_xmlConfigFile.SelectSingleNode("app");
    if (xapp == null) return true; // No tenemos nodo app
    // A10120301:	Modificaciones para guardar y exponer el nodo app para uso del framework.
    // Guardamos aquí el nodo por lo que pueda ser...
    this.m_xmlAppNode = xapp;
    this.m_xmlAppNode.getAttrValue("version");
    // Buscar el nombre de la aplicación si es que viene
    let str = XmlUtils.getNodeAttr(xapp, "name");
    if (!StringUtils.IsEmptyString(str)) this.m_strAppName = str;

    this.m_strDefaultLanguage = StringUtils.SafeToString(
      XmlUtils.getNodeAttr(xapp, "default-language"),
      "VBScript"
    );

    // A12042503: Mecanismo para registrar un modo debug global en la aplicación.
    this.m_bDebug = StringUtils.ParseBoolValue(
      XmlUtils.getNodeAttr(xapp, "debug"),
      false
    );

    // 26/06/2018
    this.m_bSqlProfilerMode = StringUtils.ParseBoolValue(
      XmlUtils.getNodeAttr(xapp, "sql-profiler"),
      false
    );

    /*
     * 05/07/2017
     * Dejar esto permanentemente ralentizaba un cojón algunas aplicaciones en el framework
     * debug, le hago un atributo y lo desactivo por defecto.
     */
    this.m_bLogMemoryUsage = StringUtils.ParseBoolValue(
      XmlUtils.getNodeAttr(xapp, "log-memory-usage"),
      false
    );

    let sScriptOptimizationLevel = XmlUtils.getNodeAttr(
      xapp,
      "script-optimization-level"
    );
    if (TextUtils.isEmpty(sScriptOptimizationLevel)) {
      if (Utils.isDebuggable(this.m_context)) {
        this.m_nScriptOptimizationLevel = -1;
      } else {
        this.m_nScriptOptimizationLevel = 9;
      }
    } else {
      this.m_nScriptOptimizationLevel = NumberUtils.SafeToInt(
        sScriptOptimizationLevel,
        9
      );
    }

    // Filtramos valores inválidos
    if (this.m_nScriptOptimizationLevel < -1) {
      this.m_nScriptOptimizationLevel = -1;
    } else if (this.m_nScriptOptimizationLevel > 13) {
      this.m_nScriptOptimizationLevel = 13;
    }

    // TODO: Luis esto en web no tiene sentido, solo lo dejo como referencia
    // let sMaxDexFiles = XmlUtils.getNodeAttr(xapp, "max-dex-files");
    // this.m_nMaxDexFiles = NumberUtils.SafeToInt(sMaxDexFiles);

    // M09072901:	Permitir que se puedan traducir los joins a formato where en blackberry
    // Leer aquí si hay que traducir los joins o no
    // En principio en BB esto hay que hacerlo siempre, por lo que el valor por defecto es TRUE
    // TODO SI ESTO HACE FALTA LO USAREMOS ALGÚN DÍA
    ////str =XmlUtils.GetNodeAttr(xapp, "xlat-joins");
    ////if (!StringUtils.IsEmptyString(str))
    ////	m_bTranslateJoins =StringUtils.ParseBoolValue(str, true);
    // Buscar las conexiones que haya declaradas dentro del nodo de aplicación
    // F13022106: Sacar la versión de la aplicación del nodo app al cargarlo. Setear macro global.
    // Versión de la aplicación
    if (
      StringUtils.IsEmptyString((str = XmlUtils.getNodeAttr(xapp, "version")))
    )
      str = "1.0";
    this.setGlobalMacro("##VERSION##", str);
    // Buscar las conexiones que haya declaradas dentro del nodo de aplicación
    let xcns = xapp.SelectNodes("connection");
    let cn: XoneConnectionData;
    for (let i = 0; i < xcns.count(); i++) {
      // Cada definición
      let xc = xcns.get(i);
      cn = this.CreateConnection(xc);
      if (cn != null) this.AddConnection(cn);
    } // Cada definición
    if (
      !StringUtils.IsEmptyString((str = XmlUtils.getNodeAttr(xapp, "prefix")))
    ) {
      // Ponerle prefijo a la conexión principal
      if (null == (cn = this.getConnection())) {
        // No existe la principal, crearla
        if (null == (cn = this.CreateConnection(null))) return false;
        this.AddConnection(cn);
      } // No existe la principal, crearla
      // Ponerle el prefijo
      cn.setPrefix(str);
    } // Ponerle prefijo a la conexión principal
    // A12021601: Permitir que una conexión incluya el selective-replication en su declaración.
    // SelectiveReplication a nivel de nodo app para iniciar la aplicación.
    // TODO: Luis Ver si esto es necesario mas adelante
    //setSelectiveReplication (StringUtils.ParseBoolValue (xapp.getAttrValue ("selective-replication"), false));
    // Cargar los nombres de colecciones de sistema que se cambien
    xcns = xapp.SelectNodes("syscoll-name");
    for (let i = 0; i < xcns.count(); i++) {
      // Los nodos indican sustituciones de nombres de colecciones de sistema
      let xn = xcns.get(i);
      let strSysName = XmlUtils.getNodeAttr(xn, "system-name");
      let strCollName = XmlUtils.getNodeAttr(xn, "collection-name");
      if (
        !StringUtils.IsEmptyString(strSysName) &&
        !StringUtils.IsEmptyString(strCollName)
      )
        this.m_lstSysCollNames.put(strSysName, strCollName);
    } // Los nodos indican sustituciones de nombres de colecciones de sistema
    // A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
    // Cargar los CSS que haya definidos en la aplicación
    xcns = xapp.SelectNodes("style");
    for (let i = 0; i < xcns.count(); i++) {
      // Cada CSS lo adicionamos a la lista
      let xn = xcns.get(i);

      //TODO ADD TAG Juan Carlos, respetar el atributo encoding en los CSS.
      let strCharsetEncoding = xn.getAttrValue("encoding");

      let strName = xn.getAttrValue("url");
      // M11072901: Modificaciones al mecanismo de CSS para adaptarse a condiciones visuales.
      // La condición de carga.
      let strCondition = xn.getAttrValue("conditions");

      /*
       * 27/07/2018
       * Nuevo atributo strict-mode que obliga a tener un CSS bien formado. Sirve para avisar
       * de errores gordos como la falta de un punto y coma (esto provocaba que se le dieran
       * valores erróneos al framework). Se deja por defecto a false porque hay muchas apps
       * que ya dependen del comportamiento antiguo, que dejaba pasar CSSs rotos.
       *
       * Usar este atributo también requiere escapear como en la web los dos puntos si se
       * usan como valor de un atributo en CSS, pues se usa como separador.
       * Por ejemplo, title:Hola:Mundo; debe escribirse como title:Hola\:Mundo;
       */
      let bStrictMode = StringUtils.ParseBoolValue(
        xn.getAttrValue("strict-mode"),
        false
      );
      // A14102301: Incorporación de un mecanismo de control de integridad para desarrollos.
      // Verificar el fichero si es que vale la cosa
      // TODO: Luis ver si esto es necesario
      // if (m_checker !=null)
      // {// Verificar
      //     if (!m_checker.checkFileIntegrity(strName, this.m_strAppPath))
      //         return false;
      // }// Verificar
      let css = this.LoadAndSaveNewCssFile(
        strName,
        strCharsetEncoding,
        bStrictMode
      );
      // TODO: Luis ver si esto del cacheado aqui tiene sentido
      // File fUnparsedCss = new File(m_loader.getIncludeFilePath(strName, mIsEncryptFiles));
      // File fCachedCss = new File(GetCachedCssDirectory(), strName);
      // XoneCssParser css;
      // if (!fCachedCss.exists()) {
      //     // No hay CSS serializado en disco, parsearlo por primera vez y guardarlo.
      //     css = LoadAndSaveNewCssFile(fUnparsedCss, fCachedCss, strName, strCharsetEncoding, bStrictMode);
      // } else {
      //     try {
      //         // Restauramos el CSS.
      //         PersistableBundleCompat bundle = PersistableBundleCompat.restoreFromBinaryFile(fCachedCss, XoneCssParser.PARCELABLE_VERSION);
      //         String sCachedCssCrc = bundle.getString(XoneCssParser.BUNDLE_KEY_CRC);
      //         String sUnparsedCssCrc = Utils.getFileChecksum(fUnparsedCss, "crc", false);
      //         if (TextUtils.equals(sCachedCssCrc, sUnparsedCssCrc)) {
      //             // El checksum del original no ha cambiado. Devolverlo tal cual.
      //             css = bundle.getParcelable(XoneCssParser.BUNDLE_KEY_DATA);
      //             if (css.isStrictMode() != bStrictMode) {
      //                 /*
      //                  * El cacheado tenía strict-mode pero el nodo dice que no o viceversa.
      //                  * Volver a parsear.
      //                  */
      //                 css = LoadAndSaveNewCssFile(fUnparsedCss, fCachedCss, strName, strCharsetEncoding, bStrictMode);
      //             }
      //         } else {
      //             // El checksum del original es distinto. Parsearlo de nuevo y guardarlo.
      //             css = LoadAndSaveNewCssFile(fUnparsedCss, fCachedCss, strName, strCharsetEncoding, bStrictMode);
      //         }
      //     } catch (CssParseException ex) {
      //         throw ex;
      //     } catch (Exception ex) {
      //         /*
      //          * Error al deserializar el fichero. La versión del fichero que espera este
      //          * framework es distinta a la guardada. Borramos el cacheado y reintentamos
      //          * parseo. Podría ocurrir también si se ha actualizado el SO. Eso en particular
      //          * nunca lo he visto ocurrir.
      //          */
      //         ex.printStackTrace();
      //         if (!fCachedCss.delete()) {
      //             throw new IOException("Cannot delete cached CSS file!");
      //         }
      //         css = LoadAndSaveNewCssFile(fUnparsedCss, fCachedCss, strName, strCharsetEncoding, bStrictMode);
      //     }
      // }
      // Adicionar a la lista (si aplica, claro)
      if (StringUtils.IsEmptyString(strCondition)) {
        this.m_lstCssList.push(css);
      } else {
        // De lo contrario tiene condición
        // M11072901: Modificaciones al mecanismo de CSS para adaptarse a condiciones visuales.
        let list = this.m_lstConditionCssList.get(strCondition);
        if (list == null) {
          // Crear y adicionar
          list = new Array<XoneCssParser>();
          this.m_lstConditionCssList.put(strCondition, list);
        }
        list.push(css);
      }
      // En cualquiera de los casos tenemos CSS
      this.m_bHasStylesheets = true;
    }
    this.addEntryPointCollection(
      this.m_xmlAppNode.selectSingleNode("entry-point"),
      this.m_xmlAppNode.getAttrValue("entry-point")
    );
    this.addLoginCollection(
      this.m_xmlAppNode.selectSingleNode("login-coll"),
      this.m_xmlAppNode.getAttrValue("login-coll")
    );
    // Completo
    return true;
  }

  private LoadAndSaveNewCssFile(
    strName: string,
    strCharsetEncoding: string,
    bStrictMode: boolean
  ): XoneCssParser {
    return this.LoadNewCssFile(strName, strCharsetEncoding, bStrictMode);
    // PersistableBundleCompat bundle = new PersistableBundleCompat();
    // String sUnparsedCssCrc = Utils.getFileChecksum(fUnparsedCss, "crc", false);
    // bundle.putString(XoneCssParser.BUNDLE_KEY_CRC, sUnparsedCssCrc);
    // bundle.putParcelable(XoneCssParser.BUNDLE_KEY_DATA, css);
    // bundle.saveToBinaryFile(fCachedCss, XoneCssParser.PARCELABLE_VERSION);
    // return css;
  }

  private LoadNewCssFile(
    strName: string,
    strCharsetEncoding: string,
    bStrictMode: boolean
  ): XoneCssParser {
    let is = this.m_loader.LoadFile(
      this.getSourcePath(strName),
      this.mIsEncryptFiles
    );
    if (is == null) {
      throw new XoneGenericException(-100, "File not Found: " + strName);
    }
    try {
      return new XoneCssParser(strName, is, strCharsetEncoding, bStrictMode);
    } catch (e) {
      /*
       * Juan Carlos
       * Cambio el mensaje. Esto puede no ser un error de sintaxis, y si no lo es puede
       * confundir al programador.
       */
      let sMessage = Utils.getThrowableMessage(e);
      throw new XoneGenericException(
        -100,
        e,
        "Error loading CSS file: " + strName + "\r\n" + sMessage
      );
    }
  }

  public async getCollection(name: string): Promise<XoneDataCollection> {
    if (TextUtils.isEmpty(name)) {
      //throw new IllegalArgumentException("Empty collection name argument in appData.getCollection()");
    }
    //let a=import(CollName)
    // Si la tiene en lista, la devuelve tal cual...
    if (this.m_lstCollections.containsKey(name)) {
      let dataCollection = this.m_lstCollections.get(name);
      if (dataCollection == null) {
        Utils.DebugLog(
          Utils.TAG_FRAMEWORK,
          "AppData.GetCollection(): Collection list contains a key for " +
            name +
            " but it is null. AppData hashCode: " +
            this.hashCode()
        );
      }
      return dataCollection;
    }
    // Si no la tiene, intenta cargarla
    return await this.LoadCollection(name);
  }

  public async LoadCollection(CollName: string): Promise<XoneDataCollection> {
    // Si tiene definido un filtro de collprops, aplicarlo para buscar la coleccion en el mappings
    let xc = null;
    // Dentro de la lista de colecciones, buscar la que nos interesa
    // Buscar el collprops
    // F11081101: Si se pide GetCollection sin haber cargado el mappings hay mooa.
    // Si el mappings es null entonces nos explotamos...
    if (this.m_xmlConfigFile == null) {
      if (TextUtils.isEmpty(CollName)) {
        Utils.DebugLog(
          Utils.TAG_FRAMEWORK,
          "AppData.LoadCollection(): Error loading collection, empty collection name parameter, mappings xml object is null. AppData hashCode: "
        );
      } else {
        Utils.DebugLog(
          Utils.TAG_FRAMEWORK,
          "AppData.LoadCollection(): Error loading collection " +
            CollName +
            ", mappings xml object is null. AppData hashCode: " +
            this.hashCode()
        );
      }
      return null;
    }
    // const collProps = this.m_xmlConfigFile.SelectSingleNode("collprops"); // this.m_jsonConfigFile.xml?.collprops;
    // if (collProps !== null)
    //     xc = collProps.coll?.find( x=> x["@name"]===CollName);

    const collProps = this.m_xmlConfigFile.SelectSingleNode("collprops");
    if (collProps != null)
      xc = collProps.SelectSingleNode("coll", "name", CollName);
    let result = null;
    let version = 2;
    if (xc == null) {
      // No se encuentra la coleccion
      // F11051802: No se pueden incluir referencias nulas en un hashtable.
      // this.m_lstCollections.put(CollName, null);
      // A12062601: Modificar el picaoto para no tener que incluir la coleccion en el mappings.
      // Tratamos de buscarla fuera como Collname.xml
      result = await this.LoadExternalCollection(CollName);
      if (null == result) {
        Utils.DebugLog(
          Utils.TAG_FRAMEWORK,
          "AppData.LoadCollection(): Collection " +
            CollName +
            " not found as an external file. AppData hashCode: " +
            this.hashCode()
        );
        return null;
      }
      xc = result.second;
      version = result.first;
    } // No se encuentra la coleccion
    // Ahora tenemos que construir la coleccion
    if (this.m_lstCollections.containsKey(CollName))
      return this.m_lstCollections.get(CollName);
    const coll = new XoneDataCollection(this, xc, version);
    // Incluirla en la lista y devolverla
    this.m_lstCollections.put(CollName, coll);
    // Si no se pueden cargar los datos bosicos, nos largamos
    if (!(await coll.Load())) {
      this.m_lstCollections.delete(CollName);
      Utils.DebugLog(
        Utils.TAG_FRAMEWORK,
        "AppData.LoadCollection(): Collection " +
          CollName +
          " found but DataCollection.Load() failed. AppData hashCode: " +
          this.hashCode()
      );
      return null;
    }
    return coll;
  }

  private async LoadExternalCollection(name: string): Promise<any> {
    var file = await this.m_fileManager.readFileAsync(
      this.m_strAppPath + "/" + name + ".xne"
    );
    const doc = XmlDocument.getInstance();
    doc.Deserialize(null, this.m_strAppPath, file, true);
    let node;
    if (!(node = doc.getRootNode())?.getName().equals("coll"))
      node = doc.getRootNode().SelectSingleNode("coll");
    return { first: 2, second: node }; //JSON.parse(file.toString())};
  }

  public hashCode(): string {
    return this._objectID.toString();
  }

  public getCompany(): IXoneObject {
    return this.m_company;
  }

  public GetCollPropValueCache(
    CollName: string,
    NodeType: string = "coll",
    NodeName: string = "values"
  ): Hashtable<string, any> {
    if (!this.m_bCacheAttrValues) return null;
    let collCache: Hashtable<
      string,
      Hashtable<string, Hashtable<string, string>>
    >;

    // F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
    // Esto puede ser problemático si hay múltiples accesos
    //synchronized (this.m_lstCollPropValueCaches)
    {
      if (TextUtils.isEmpty(CollName)) {
        /*
         * 09/07/2018 Juan Carlos
         * Esto puede ocurrir por accidente y no es recuperable, así que mejor lanzar una
         * excepción clara.
         */
        throw new Exception(
          "Empty collection name parameter passed to GetCollPropValueCache"
        );
      }
      if (!this.m_lstCollPropValueCaches.containsKey(CollName)) {
        // No existe
        collCache = new Hashtable<
          string,
          Hashtable<string, Hashtable<string, string>>
        >();
        this.m_lstCollPropValueCaches.put(CollName, collCache);
      } // No existe
      // F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
      // Quitamos el else y cogemos siempre el que haya.... fuera nuestro o no...
      collCache = this.m_lstCollPropValueCaches.get(CollName);
    }
    // Ahora buscamos la colección de tipos de nodo
    let nodeTypeCache: Hashtable<string, Hashtable<string, string>>;
    // F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
    //synchronized (collCache)
    {
      if (!collCache.containsKey(NodeType)) {
        // No existe
        nodeTypeCache = new Hashtable<string, Hashtable<string, string>>();
        collCache.put(NodeType, nodeTypeCache);
      } // No existe
      // F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
      // Quitamos el else y cogemos el que haya... que haber tiene que haber...
      nodeTypeCache = collCache.get(NodeType);
    }
    // Ahora el nombre del nodo
    let nodeNameCache: Hashtable<string, string>;
    // F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
    //synchronized (nodeTypeCache)
    {
      if (TextUtils.isEmpty(NodeName)) {
        /*
         * 09/07/2018 Juan Carlos
         * Esto puede ocurrir por accidente y no es recuperable, así que mejor lanzar una
         * excepción clara.
         */
        throw new NullPointerException(
          "Empty node name parameter passed to GetCollPropValueCache"
        );
      }
      if (!nodeTypeCache.containsKey(NodeName)) {
        // No existe
        nodeNameCache = new Hashtable<string, string>();
        nodeTypeCache.put(NodeName, nodeNameCache);
      } // No existe
      // F13022103: La gestión de caches de atributos debe tener en cuenta la concurrencia.
      // Quitamos el else y cogemos el que haya sea de quien sea
      nodeNameCache = nodeTypeCache.get(NodeName);
    }
    // Finalmente devolvemos lo que sea
    return nodeNameCache;
  }

  GetCollPropUndefinedValues(CollName: string) {
    // K12050901: Permitir activar o desactivar las caches de valores de atributos.
    if (!this.m_bCacheAttrValues) return null;
    if (this.m_lstCollPropUndefinedValues == null) {
      return null;
    }
    if (this.m_lstCollPropUndefinedValues.containsKey(CollName))
      return this.m_lstCollPropUndefinedValues.get(CollName);
    let cache = new Array<string>();
    this.m_lstCollPropUndefinedValues.put(CollName, cache);
    return cache;
  }

  public getMessageHolder(): IMessageHolder {
    return this.m_messages;
  }

  public getConfigFile(): XmlNode {
    return this.m_xmlConfigFile;
  }
  /**
   * O13030601: Optimizaciones en la búsqueda de CSS para atributos.
   * Pica la cadenilla de condiciones visuales y le hace un trabajillo de acondicionamiento para
   * que no haya que parsear las cadenas en cada llamada.
   */
  private ParseVisualConditions(): void {
    // Lo primero es ver si ya esta condición está seteada
    const strTmp = this.m_strVisualConditions.trim();
    if (StringUtils.IsEmptyString(strTmp)) {
      return;
    }
    if (this.m_lstVisualCondDescriptors.containsKey(strTmp)) {
      return;
    }
    // De lo contrario hay que parsear...
    const parts = this.m_strVisualConditions.split(":");
    // Y ahora con esto hay que hacer combinaciones válidas...
    let nStart = 0,
      nEnd = parts.length - 1;
    const lstCombinations = new Array<string>();
    while (nStart <= nEnd) {
      this.CreateVisualCombinations(parts, nStart++, nEnd--, lstCombinations);
    }
    this.m_lstVisualCondDescriptors.put(strTmp, lstCombinations);
  }

  /// <summary>
  /// O13030601: Optimizaciones en la búsqueda de CSS para atributos.
  /// Dada una lista de partes de condiciones visuales arma combinaciones válidas para buscar CSS dentro de las listas
  /// de condiciones visuales que se cargaron al inicio de la aplicación.
  /// </summary>
  /// <param name="Parts">Arreglo de cadenas con los pedazos que forman la combinación a analizar.</param>
  /// <param name="StartIndex">Indice (0-based) en el cual se comienza a armar la combinación.</param>
  /// <param name="EndIndex">Indice (0-based) en el que termina la combinación.</param>
  /// <param name="Destination">Lista en la que se colocan las combinaciones.</param>
  private CreateVisualCombinations(
    Parts: string[],
    StartIndex: number,
    EndIndex: number,
    Destination: Array<string>
  ) {
    // Lo primero es quitar por el final
    let sb: StringBuilder;
    // Caso especial... si son iguales es solo ese
    if (StartIndex == EndIndex) {
      // Uno y nos vamos
      if (!Destination.contains(Parts[StartIndex]))
        Destination.push(Parts[StartIndex]);
      return;
    } // Uno y nos vamos
    let nStart = StartIndex,
      nEnd = EndIndex;
    // La segunda vuelta la damos de atrás palantre...
    while (nEnd >= StartIndex) {
      // Primera vuelta
      sb = new StringBuilder();
      for (let i = nEnd; i >= StartIndex; i--) {
        // Adicionar
        if (!sb.isEmpty()) sb.insert(0, ":");
        sb.insert(0, Parts[i]);
      } // Adicionar
      let sTmp = Utils.EMPTY_STRING;
      if (!Destination.contains((sTmp = sb.toString()))) Destination.push(sTmp);
      nEnd--;
    } // Primera vuelta
    while (nStart <= EndIndex) {
      // Segunda vuelta
      sb = new StringBuilder();
      for (let i = nStart; i <= EndIndex; i++) {
        // Componer
        if (!sb.isEmpty()) sb.append(":");
        sb.append(Parts[i]);
      } // Componer
      let sTmp = Utils.EMPTY_STRING;
      if (!Destination.contains((sTmp = sb.toString()))) Destination.push(sTmp);
      nStart++;
    } // Segunda vuelta
  }

  public setVisualConditions(Value: string, IsFixed: boolean): void {
    const strValue: string = Value || Utils.EMPTY_STRING;

    if (IsFixed)
      this.m_strFixedVisualCondition = this.m_strVisualConditions = strValue;
    else
      this.m_strVisualConditions =
        this.m_strFixedVisualCondition + ":" + strValue;
    // Limpiar las caches de atributillos
    //this.m_lstCollPropValueCaches.clear();
    this.m_lstCollPropUndefinedValues.clear();
    // F13061907: Limpieza de caches de atributos a nivel de objeto en caso de tenerlas.
    // Limpiar las caches locales de los objetos.
    this.m_lstCollections.values().forEach((coll) => {
      // TODO: Limpiar la cache de cadaobjeto
      // obj.clearCaches();
    });
    // while (enm.hasMoreElements())
    // {// Recorrer y limpiar
    // 	String key =enm.nextElement();
    //     XoneDataCollection c =this.m_lstCollections.get(key);
    //     for (long i = 0; i < c.getCount(); i++)
    //         c.get(i).ClearCaches();
    // }// Recorrer y limpiar
    // O13030601: Optimizaciones en la búsqueda de CSS para atributos.
    // Ahora nos parseamos las condiciones visuales que haya para usarlas así.
    this.ParseVisualConditions();
  }

  /**
   * ADD TAG Luis
   * Leer el visual conditions
   *
   * @return
   */
  public getCurrentVisualConditions(): string {
    return this.m_strVisualConditions;
  }

  public getVisualConditionsDescriptors(): Hashtable<String, Array<String>> {
    return this.m_lstVisualCondDescriptors;
  }

  public getCurrentVisualConditionsDescriptors(): Array<string> {
    if (
      this.m_lstVisualCondDescriptors.containsKey(this.m_strVisualConditions)
    ) {
      return this.m_lstVisualCondDescriptors.get(this.m_strVisualConditions);
    }
    return null;
  }

  public getAppPath(): string {
    return this.m_strAppPath;
  }

  public getSourcePath(FileName: string): string {
    return this.m_strAppPath + "/" + FileName;
  }

  public setAppPath(value: string): void {
    this.m_strAppPath = this.m_strFilesPath = value;
    // A12051002: Agregar FilesPath a la aplicación para manejarlo en los scripts.
    // Calcular FilePath...
    if (!this.m_strFilesPath.endsWith("/")) this.m_strFilesPath += "/";
    this.m_strFilesPath += Utils.DEFAUT_FILES_PATH;
  }

  public setApplicationName(value: string): void {
    this.m_strAppName = value;
  }

  // M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
  // Funciones para obtener y setear una macro global
  public setGlobalMacro(MacroName: string, MacroValue: any) {
    if (this.m_lstGlobalMacros == null)
      this.m_lstGlobalMacros = new Hashtable<string, any>();
    this.m_lstGlobalMacros.put(MacroName, MacroValue);
  }

  /// M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
  // Devuelve el valor de una macro global
  public getGlobalMacro(MacroName: string): any {
    if (this.m_lstGlobalMacros == null) return null;
    // Si no está en lista, NULL patrás
    if (!this.m_lstGlobalMacros.containsKey(MacroName)) return null;
    return this.m_lstGlobalMacros.get(MacroName);
  }

  public GetAllGlobalMacros(): Hashtable<string, any> {
    let mapCopy = new Hashtable<string, any>();
    if (this.m_lstGlobalMacros == null || this.m_lstGlobalMacros.length <= 0) {
      return mapCopy;
    }
    this.m_lstGlobalMacros
      .entrySet()
      .forEach((entry) => mapCopy.put(entry[0], entry[1]));
    // Set<Entry<String, String>> entries = m_lstGlobalMacros.entrySet();
    // for (Entry<String, String> entry : entries) {
    //     String sKey = entry.getKey();
    //     String sValue = entry.getValue();
    //     mapCopy.put(sKey, sValue);
    // }
    return mapCopy;
  }

  /**
   *  Carga los datos de la empresa actual
   *  @param Company				Empresa que se quiere setear como activa.
   *  @return						Devuelve TRUE si los datos de la empresa se cargan correctamente.
   */
  protected LoadCompanyData(Company: IXoneObject): void {
    //
    // Estas acciones solamente tienen sentido en caso de que el manejo sea interno
    // Si el programador lo esto manipulando desde fuera, no deben tocarse sus datos
    // Por eso son los dos IFES que vienen debajo...
    // Si haboa otra empresa tenemos que soltarla. Ya se vero mos
    // alante por quo...
    if (!this.m_bExternalEntIdColl) this.m_strEntIdColl = null;

    if (!this.m_bExternalEntIdLevel) this.m_strEntIdLevel = null; // Cadena de empresas por encima y al nivel de esta
    //
    if (!this.m_bExternalEntIdOwner) this.m_strEntIdOwner = null; // Empresa propietaria

    if ((this.m_company = Company) != null) {
      // Algo tiene
      this.m_strEntTable = Company.getObjectName();
      this.m_strEntPk = Company.getIdFieldName();
      // K11010501:	Modificaciones para la version 1.5 de Android.
      this.m_nEntPkType = StringUtils.ParseBoolValue(
        Company.getOwnerCollection().CollPropertyValue("stringkey"),
        false
      )
        ? 1
        : 0;
      //
      // Prepara la cadenilla con las empresas que conforman el
      // grupo de esta empresa y sus hijos
      // Generar las cadenas que se usaron para los filtros
      if (!this.m_bExternalEntIdColl)
        this.m_strEntIdColl = this.GenerateEntIdColl(
          this.m_company.GetObjectIdString(true)
        );
      //
      // Prepara la cadena con las empresas que eston al mismo nivel que esta
      // y las que eston por encima
      if (!this.m_bExternalEntIdLevel)
        this.m_strEntIdLevel = this.GenerateEntIdLevel(
          this.m_company.GetObjectIdString(true)
        );
      //
      if (!this.m_bExternalEntIdOwner)
        this.m_strEntIdOwner = this.GenerateEntIdOwner();
    } // Algo tiene
    // Almacenar la empresa
    this.m_company = Company;
    // Sacar la moneda de la empresa
    //this.m_currency = this.m_company.ObjectItem("IDMONEDA");
    // Seoalar en la empresa el ID del usuario a la forma antigua...
    if (this.m_user != null)
      this.m_company.put("MAP_IDCURRENTUSER", this.m_user.getId());
    // Completo
  }
  GenerateEntIdOwner(): string {
    return Utils.EMPTY_STRING;
  }
  GenerateEntIdLevel(arg0: string): string {
    return this.m_company.get("ID");
  }
  GenerateEntIdColl(arg0: string): string {
    return this.m_company.get("ID");
  }

  /**
   * Elimina la empresa actual.
   */
  private clearCompanyData() {
    this.m_strEntIdColl = null;
    this.m_strEntIdLevel = null;
    this.m_strEntIdOwner = null;
    this.m_company = null;
    this.m_strEntTable = null;
    this.m_strEntPk = null;
    this.m_nEntPkType = 0;
    this.m_currency = null;
  }

  // M11011001:	Incluir macros globales y evaluación de dichas macros para IMEI y demás.
  // Para sustituir macros globales
  public PrepareSqlString(Sentence: string): string {
    // Pos eso... si no tiene macros, pa qué revisar
    if (!Sentence.contains("##")) return Sentence;
    this.m_lstGlobalMacros.entries.forEach(
      (value, key) => (Sentence = Sentence.replace(key, value))
    );
    // Enumeration<String> enm =this.m_lstGlobalMacros.keys();
    // String strTmp =Sentence;
    // while (enm.hasMoreElements())
    // {// Revisar cada una
    // 	String strName;
    // 	String strValue;
    // 	strName =enm.nextElement();
    // 	strValue =m_lstGlobalMacros.get(strName);
    // 	strTmp =strTmp.replaceAll(strName, strValue);
    // }// Revisar cada una
    // Una vez terminado, devolver lo que sea
    // De lo contrario, la cadena con las sustituciones
    return this.PrepareUserMacrosSqlString(Sentence);
  }

  // TODO: Luis
  public PrepareUserMacrosSqlString(Sentence: string): string {
    // Pos eso... si no tiene macros, pa qué revisar
    if (!Sentence.contains("##USER_")) return Sentence;
    try {
      return this.m_user.ReplaceFieldValueMacros(Sentence, "##USER_");
    } catch (e) {
      return Sentence;
    }
  }

  /**
   *
   * @param FileName
   * @param ClassName
   * @return
   */
  public FindStylesheetByFileAndClassName(
    FileName: string,
    ClassName: string
  ): XoneCssRule {
    let lstCssParsers = new Array<XoneCssParser>();
    this.m_lstCssList.forEach((cssParser) => {
      let sName = cssParser.getName();
      if (FileName.compareTo(sName) == 0) {
        lstCssParsers.push(cssParser);
      }
    });
    // for (let cssParser in this.m_lstCssList) {
    //     let sName = cssParser.getName();
    //     if (FileName.compareTo(sName) == 0) {
    //         lstCssParsers.add(cssParser);
    //     }
    // }
    return this.FindStylesheetByClassNameList(ClassName, lstCssParsers);
  }

  /**
   * A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
   * Obtiene una hoja de estilo basado en el nombre de clase que se pasa. Las hojas de estilo últimas
   * redefinen a las primeras, por lo que la búsqueda empieza de atrás hacia adelante.
   * Opera recursivamente para aquellos estilos que tengan herencia.
   *
   * @param ClassName		Selector de clase para buscar el estilo
   * @param AttrName		Nombre del atributo cuyo valor se quiere obtener.
   * @return				Devuelve un estilo que contenga este atributo o NULL.
   */
  public FindStylesheetByClassName(
    ClassName: string,
    AttrName: string
  ): XoneCssRule {
    // O12060701: Excepciones en la gestión de atributos para no buscarlos en los CSS.
    // Las excepciones no las buscamos
    if (ClassName.startsWith("coll.") || ClassName.equals("coll")) {
      // Colección
      if (this.m_lstCollExceptions.contains(AttrName)) {
        return null;
      }
    } else if (ClassName.startsWith("prop.") || ClassName.equals("prop")) {
      // Prop
      if (this.m_lstPropExceptions.contains(AttrName)) {
        return null;
      }
    }
    let lstCssParsers: Array<XoneCssParser>;
    let css: XoneCssRule;
    // M11072901: Modificaciones al mecanismo de CSS para adaptarse a condiciones visuales.
    // Lo primero que tenemos que hacer es ver si tenemos condiciones visuales. En este
    // caso tendremos que buscar primero en la lista apropiada.
    if (!StringUtils.IsEmptyString(this.m_strVisualConditions)) {
      // Buscar en esta lista
      if (
        this.m_lstConditionCssList != null &&
        this.m_lstConditionCssList.length > 0
      ) {
        let str = this.m_strVisualConditions.trim();
        let lstDescriptors = this.m_lstVisualCondDescriptors.get(str);
        if (lstDescriptors == null) {
          return null;
        }
        for (let index = 0; index < lstDescriptors.length; index++) {
          const sDescriptor = lstDescriptors[index];
          if (this.m_lstConditionCssList.containsKey(sDescriptor)) {
            // Si encontramos lo que sea, nos vamos
            lstCssParsers = this.m_lstConditionCssList.get(sDescriptor);
            if (
              null !=
              (css = this.FindStylesheetByClassAttrList(
                ClassName,
                AttrName,
                lstCssParsers
              ))
            ) {
              return css;
            }
          }
        }
      }
    }
    // Si llegamos aquí es que no hay condiciones visuales o que no han redefinido nada
    css = this.FindStylesheetByClassAttrList(
      ClassName,
      AttrName,
      this.m_lstCssList
    );
    return css;
  }

  protected FindStylesheetByClassAttrList(
    ClassName: string,
    AttrName: string,
    listCss: Array<XoneCssParser>
  ): XoneCssRule {
    if (listCss == null) {
      return null;
    }
    let k = listCss.length;
    while (k > 0) {
      // Empezamos por el finalll
      k--;
      let css = listCss[k];
      let rule = css.getRuleBySelector(ClassName);
      if (rule != null) {
        // Bueno... al menos existe
        if (rule.contains(AttrName)) return rule;
        // De lo contrario puede que sea hija de otro que sí lo implemente
        if (rule.contains("extends")) {
          // Ver cuál es la base
          let strBase = rule.getRuleValue("extends");
          let base = this.FindStylesheetByClassName(strBase, AttrName);
          if (base != null) return base;
        } // Ver cuál es la base
      } // Bueno... al menos existe
    } // Empezamos por el finalll
    // Estoooo no lo tenemos o no hay stylesheets.
    return null;
  }

  protected FindStylesheetByClassNameList(
    ClassName: string,
    CssList: Array<XoneCssParser>
  ): XoneCssRule {
    let k = CssList.length;
    while (k > 0) {
      k--;
      let css = CssList[k];
      let rule = css.getRuleBySelector(ClassName);
      if (rule != null) {
        return rule;
      }
    }
    return null;
  }

  /**
   * A11070701: Introducción de los CSS en la maquinaria para poder parametrizar el mappings.
   *
   * @return		TRUE si en la aplicación hay al menos un CSS cargado.
   */
  public hasStylesheets(): boolean {
    // M11072901: Modificaciones al mecanismo de CSS para adaptarse a condiciones visuales.
    // Ahora tenemos el valor cacheado.
    return this.m_bHasStylesheets;
  }

  /**
   * Devuelve la lista de cualificación de campos de la colección cuyo nombre se pasa como parámetro.
   * @param CollName		Nombre de la colección cuya lista de cualificación se quiere obtener.
   * @return				Devuelve una tabla de cache para la colección cuyo nombre se pasa como parámetro.
   */
  public GetQualifyCache(CollName: string): Hashtable<string, string> {
    // Si ya la tiene, devolverla
    if (this.m_lstQualifyCaches.containsKey(CollName))
      return this.m_lstQualifyCaches.get(CollName);
    // De lo contrario crearla
    let cache = new Hashtable<string, string>();
    this.m_lstQualifyCaches.put(CollName, cache);
    return cache;
  }

  /**
   * F09052004:	Modificación de las interfaces para implementar manejo de errores.
   * Implementación de la función para registrar el error de ejecución a nivel de script.
   * Se utiliza la variable de error XOne para almacenar estos datos.
   */
  public RegisterError(Code: number, Description: string): void {
    // K12060701: Modificaciones para permitir multithreading en los scripts y depuración.
    // Gestionar las variables de error a nivel de thread
    let error = this.getError();
    if (error == null) {
      return;
    }
    error.setNumber(Code);
    error.setDescription(Description);
    error.setFailedSql(null);
  }

  /**
   * @return			Expone el objeto de error de esta aplicación
   */
  public getError(): XoneError {
    // K12060701: Modificaciones para permitir multithreading en los scripts y depuración.
    // Para gestionar esto de manera que sea independiente para cada thread.
    if (this.m_error == null) {
      return null;
    }
    let error = this.m_error.pop();
    if (error == null) {
      // Lo creamos ahora
      error = new XoneError();
      this.m_error.push(error);
    } // Lo creamos ahora
    return error;
  }

  public ContainsError(): boolean {
    if (this.m_error == null) {
      return false;
    }
    return this.m_error.length > 0;
  }

  public getAppNode(): XmlNode {
    return this.m_xmlAppNode;
  }

  bind() {}

  createCollection(Name: string, SourceNode: XmlNode = null, version: number) {
    let strCollName = Name;
    if (StringUtils.IsEmptyString(strCollName) && SourceNode)
      strCollName = SourceNode.getAttrValue(Utils.PROP_ATTR_NAME);
    if (StringUtils.IsEmptyString(strCollName)) return null;
    // Crearla siempre... si ya hay otra habrá que machacarla
    let coll: XoneDataCollection;
    if (this.m_lstCollections.containsKey(strCollName)) {
      // Existe
      coll = this.m_lstCollections.get(strCollName);
      this.m_lstCollections.delete(strCollName);
    } // Existe
    let collProps = this.m_xmlConfigFile.SelectSingleNode(Utils.COLL_COLLPROPS);
    if (collProps == null) return null;
    // Buscamos el nodo en cuestión, si existe tendremos que reemplazarlo...
    let oldNode = collProps.SelectSingleNode(
      Utils.COLL_COLL,
      Utils.PROP_ATTR_NAME,
      strCollName
    );
    if (oldNode != null) collProps.replaceChild(oldNode, SourceNode);
    else
      collProps.addChild(
        SourceNode,
        SourceNode.getName(),
        Utils.PROP_ATTR_NAME,
        strCollName
      );
    // Ahora tenemos que construir la colección
    coll = new XoneDataCollection(this, SourceNode, version);
    // Si no se pueden cargar los datos básicos, nos largamos
    if (!coll.Load()) return null;
    // Incluirla en la lista y devolverla
    this.m_lstCollections.put(strCollName, coll);
    return coll;
  }

  /**
   * @return			Devuelve TRUE si la aplicación tiene activa la réplica selectiva (solo modifican la cola las colecciones marcadas)
   */
  public getSelectiveReplication(): boolean {
    return this.m_bSelectiveReplication;
  }

  /**
   * Asigna valor a la bandera que indica si la aplicación tiene activa la réplica selectiva.
   * @param value		TRUE para habilitar la réplica selectiva, FALSE para usar réplica normal (todo replica)
   */
  public setSelectiveReplication(value: boolean) {
    this.m_bSelectiveReplication = value;
  }

  createObject(sClassId: string) {
    // TODO: Luis esto son los creadores de objetos
    return {};
  }

  decryptString(value: string) {}

  encryptString(value: string) {}

  error() {}

  executeSql(sql: string) {}

  failWithMessage(Code: number, Message: string) {
    /// K12060701: Modificaciones para permitir multithreading en los scripts y depuración.
    // Las variables de error van por threads.
    switch (Message) {
      case Utils.FAIL_EXITAPP:
        this.getUserInterface().exitApp();
        break;
      case Utils.FAIL_EXIT:
        this.getUserInterface().exit();
        break;
      case Utils.FAIL_LOGIN:
        this.getUserInterface().login();
        break;
      case Utils.MACRO_STARTREPLICA:
        this.getUserInterface().startReplica();
        break;
      default:
        let error = this.getError();
        error.setNumber(Code);
        error.setDescription(Message);
        break;
    }
  }

  getAllowedEnterprises() {}

  getAllowedUsers() {}

  public getCollectionCount(): number {
    return this.m_lstCollections.length;
  }

  getConnString() {}

  public getCurrentEnterprise(): IXoneObject {
    return this.m_company;
  }

  getFilesPath() {
    return this.m_strFilesPath;
  }

  getReplicationId() {}

  getReservedObject() {}

  isReplicating() {}

  popValue() {}

  pushValue(objData: object) {
    this.m_ui.openEditView(objData);
  }

  pushValueAndExit(objData: object) {}

  registerPush() {}

  safeRound(value: number) {}

  setConnString(param0) {}

  setIsReplicating(booleano) {}

  variantToString(param0) {}

  writeConsoleString(param0) {}
}
