import Hashtable from "../../Collections/HashMap/Hashtable";
import Vector from "../../Collections/Vector";
import BufferedReader from "../../Helpers/BufferedReader";
import StringBuilder from "../../Utils/StringBuilder";
import TextUtils from "../../Utils/TextUtils";
import { Utils } from "../../Utils/Utils";
import CssParseException from "./CssParseException";
import XoneCssRule from "./XoneCssRule";

enum ParseStates {
    ExpectingSelector,
    ExpectingCommentOpenComplete,
    GettingComment,
    ExpectingCommentClose,
    GettingSelector,
    ExpectingOpenBrace,
    ExpectingRuleKey,
    GettingRuleKey,
    ExpectingRuleValue,
    GettingRuleValue,
};

export default class XoneCssParser {

    public static PARCELABLE_VERSION = 2;
    public static  BUNDLE_KEY_DATA = "data";
    public static  BUNDLE_KEY_CRC = "crc";
    private static  m_strValidChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.-_";

    private m_strName="";
    private m_bundleRules: Hashtable<string,XoneCssRule>;
    private m_bStrictMode=false;

    /**
     * Constructor de la clase para crearnos las cositas que nos hacen falta.
     */
    constructor(sName:string, is?: any, sEncoding?: string, bStrictMode?:boolean) { 
       this.m_strName = sName;
        this.m_bStrictMode = bStrictMode;
        this.m_bundleRules = new Hashtable<string,XoneCssRule>();
        this.parse(new BufferedReader(is), sEncoding);
    }

    public getName():string {
        return this.m_strName;
    }

    public isStrictMode() {
        return this.m_bStrictMode;
    }

    /**
     * F11092607: Tener en cuenta los separadores entre clave y valor en los CSS.
     * Función para moverse al siguiente token ignorando separadores.
     *
     * @param line
     * @param br
     * @param startPos
     * @return
     * @throws IOException
     */
    private moveToNextToken(line:string, br: BufferedReader, startPos: number): string {
        let k = startPos;
        let ch = "";
        while (null != line) {
            let n = line.length;
            while (k < n) {
                ch = line.charAt(k);
                switch (ch) {
                    case '\t':
                    case ' ':
                        k++;
                        break;
                    default:
                        // Error
                        return ch;
                }
            }
            line = br.readLine();
            k = 0;
            ch = "";
        }
        return ch;
    }

    /**
     * Carga el fichero que se le pasa como parámetro y parsea su contenido.
     * 27/07/2018 Mejoras para lanzar errores de parseo descriptivos
     *
     * @param is        Stream origen de los datos, que en teoría deberían proceder de un fichero
     *                  CSS o de un nodo XML. Al terminar o lanzar una excepción será cerrado.
     * @param sEncoding El charset usado para leer el fichero. Para evitar problemas, debería ser
     *                  UTF-8 como indica el estándar
     * @throws IOException
     */
    // private parse(is:any, sEncoding:string):void{
    //     // Juan Carlos, respetar el atributo encoding en los CSS.
    //     if (TextUtils.isEmpty(sEncoding)) {
    //         sEncoding = "ISO8859_1";
    //     }
    //     InputStreamReader isr = null;
    //     BufferedReader br = null;
    //     try {
    //         isr = new InputStreamReader(is, sEncoding);
    //         br = new BufferedReader(isr);
    //         parse(br);
    //     } finally {
    //         Utils.closeSafely(br, isr, is);
    //     }
    // }

    private parse(br: BufferedReader, sEncoding?: string):void {
        let strLine="";
        let parseState = ParseStates.ExpectingSelector;
        let states = new Vector<ParseStates>();
        let strSelector = Utils.EMPTY_STRING;
        let strRuleKey = Utils.EMPTY_STRING;
        let strRuleValue = Utils.EMPTY_STRING;
        let cssRule:XoneCssRule = null;
        let nLine = 0;
        while (null != (strLine = br.readLine())) {
            let nPosition = 0;
            let nLineLength = strLine.length;
            nLine++;
            while (nPosition < nLineLength) {
                let sCurrentCharacter = strLine.charAt(nPosition);
                switch (parseState) {
                    case ParseStates.ExpectingSelector:
                        // No hay selector iniciado
                        if (XoneCssParser.m_strValidChars.indexOf(sCurrentCharacter) != -1) {
                            // Caracter válido para iniciar selector
                            parseState = ParseStates.GettingSelector;
                            break;
                        }
                        // Si no hay selector iniciado podría ser un espacio
                        switch (sCurrentCharacter) {
                            case '\t':
                            case ' ':
                                nPosition++;
                                break;
                            case '/':
                                nPosition++;
                                states.push(parseState);
                                parseState = ParseStates.ExpectingCommentOpenComplete;
                                break;
                            default:
                                // Error
                                throw new CssParseException(this.getName(), nLine, strLine, "Expected selector, got \"" + sCurrentCharacter + "\"");
                        }
                        break;
                    case ParseStates.ExpectingCommentOpenComplete:
                        // Solo esperamos asterisco
                        if (sCurrentCharacter != '*') {
                            throw new CssParseException(this.getName(), nLine, strLine, "Expected open comment asterisk, got \"" + sCurrentCharacter + "\"");
                        }
                        nPosition++;
                        parseState = ParseStates.GettingComment;
                        break;
                    case ParseStates.GettingComment:
                        // Lo que viene es comentario
                        if (sCurrentCharacter == '*') {
                            parseState = ParseStates.ExpectingCommentClose;
                        }
                        // Nos cagamos en el comentario
                        nPosition++;
                        break;
                    case ParseStates.ExpectingCommentClose:
                        // Ver si termina el comentario
                        if (sCurrentCharacter == '/') {
                            // Comentario completo
                            parseState = states.pop();
                            nPosition++;
                        } else {
                            // De lo contrario el comentario no ha terminado
                            parseState = ParseStates.GettingComment;
                        }
                        break;
                    case ParseStates.GettingSelector:
                        // Selector iniciado
                        if (XoneCssParser.m_strValidChars.indexOf(sCurrentCharacter) != -1) {
                            // Sumamos este
                            strSelector += sCurrentCharacter;
                            nPosition++;
                            break;
                        }
                        switch (sCurrentCharacter) {
                            case '\t':
                            case ' ':
                                // Selector terminado
                                nPosition++;
                                parseState = ParseStates.ExpectingOpenBrace;
                                break;
                            case '{':
                                // Selector terminado, regla iniciada
                                nPosition++;
                                parseState = ParseStates.ExpectingRuleKey;
                                break;
                            case '/':
                                // Podría ser un comentario
                                parseState = ParseStates.ExpectingOpenBrace;
                                states.push(parseState);
                                parseState = ParseStates.ExpectingCommentOpenComplete;
                                nPosition++;
                                break;
                            case ':':
                                // A12042502: Los selectores de CSS pueden estar tipificados.
                                // Este caracter es válido en los selectores...
                                strSelector += sCurrentCharacter;
                                nPosition++;
                                break;
                            default:
                                // Error
                                throw new CssParseException(this.getName(), nLine, strLine, "Expected selector, got \"" + sCurrentCharacter + "\"");
                        }
                        break;
                    case ParseStates.ExpectingOpenBrace:
                        // Esperando una llave
                        switch (sCurrentCharacter) {
                            case ' ':
                            case '\t':
                                nPosition++;
                                break;
                            case '{':
                                // Regla iniciada
                                nPosition++;
                                parseState = ParseStates.ExpectingRuleKey;
                                break;
                            case '/':
                                // Podría ser un comentario
                                parseState = ParseStates.ExpectingOpenBrace;
                                states.push(parseState);
                                parseState = ParseStates.ExpectingCommentOpenComplete;
                                nPosition++;
                                break;
                            default:
                                throw new CssParseException(this.getName(), nLine, strLine, "Expected open brace character, got \"" + sCurrentCharacter + "\"");
                        }
                        break;
                    case ParseStates.ExpectingRuleKey:
                        // Estamos esperando un inicio de clave de regla
                        if (XoneCssParser.m_strValidChars.indexOf(sCurrentCharacter) != -1) {
                            // Inicio de clave válido
                            strRuleKey += sCurrentCharacter;
                            nPosition++;
                            parseState = ParseStates.GettingRuleKey;
                            break;
                        }
                        switch (sCurrentCharacter) {
                            case '}':
                                // Regla terminada?
                                if (cssRule != null) {
                                    this.m_bundleRules.put(strSelector, cssRule);
                                    cssRule = null;
                                }
                                strSelector = strRuleKey = strRuleValue = "";
                                nPosition++;
                                // Estado esperando nueva definición
                                parseState = ParseStates.ExpectingSelector;
                                break;
                            case ' ':
                            case '\t':
                                nPosition++;
                                break;
                            case '/':
                                states.push(parseState);
                                parseState = ParseStates.ExpectingCommentOpenComplete;
                                nPosition++;
                                break;
                            default:
                                throw new CssParseException(this.getName(), nLine, strLine, "Expected new rule key character, got \"" + sCurrentCharacter + "\"");
                        }
                        break;
                    case ParseStates.GettingRuleKey:
                        // Obteniendo clave de regla
                        if (XoneCssParser.m_strValidChars.indexOf(sCurrentCharacter) != -1 && sCurrentCharacter != '.') {
                            // Contamos
                            strRuleKey += sCurrentCharacter;
                            nPosition++;
                            break;
                        }
                        // F11092607: Tener en cuenta los separadores entre clave y valor en los CSS.
                        // Moverse al siguiente token ignorando separadores.
                        sCurrentCharacter = this.moveToNextToken(strLine, br, nPosition);
                        if (sCurrentCharacter != ':') {
                            throw new CssParseException(this.getName(), nLine, strLine, "Unexpected token while getting rule key, got\"" + sCurrentCharacter + "\"");
                        }
                        // Terminada la clave
                        parseState = ParseStates.ExpectingRuleValue;
                        nPosition++;
                        break;
                    case ParseStates.ExpectingRuleValue:
                        // Aquí tiene que empezar el valor ya
                        switch (sCurrentCharacter) {
                            case ';':
                                throw new CssParseException(this.getName(), nLine, strLine, "Unexpected blank value");
                            case '}':
                                // 11/04/2019 Mostrar errores de CSS rotos
                                throw new CssParseException(this.getName(), nLine, strLine, "Unexpected end bracket value");
                        }
                        // De lo contrario empieza el valor
                        strRuleValue += sCurrentCharacter;
                        nPosition++;
                        parseState = ParseStates.GettingRuleValue;
                        break;
                    case ParseStates.GettingRuleValue:
                        // Cualquier cosa es un valor
                        if (sCurrentCharacter == ';') {
                            // Valor completo
                            if (cssRule == null) {
                                cssRule = new XoneCssRule(strSelector);
                            }
                            cssRule.addRule(strRuleKey, strRuleValue);
                            nPosition++;
                            parseState = ParseStates.ExpectingRuleKey;
                            strRuleKey = strRuleValue = "";
                            break;
                        } else if (sCurrentCharacter == '\\') {
                            /*
                             * Carácter de escape. Si es algo que reconozcamos, como los dos puntos,
                             * sumamos el valor y nos saltamos 2 posiciones, no 1.
                             */
                            let sNextCharacter = strLine.charAt(nPosition + 1);
                            if (sNextCharacter == ':') {
                                strRuleValue += sNextCharacter;
                                nPosition = nPosition + 2;
                                break;
                            }
                            strRuleValue += sCurrentCharacter;
                            nPosition++;
                            break;
                        } else if (sCurrentCharacter == ':') {
                            if (this.m_bStrictMode) {
                                let sbMessage = new StringBuilder("Missing semicolon value terminator.");
                                if (!TextUtils.isEmpty(strSelector)) {
                                    sbMessage.append("\nIn selector: ");
                                    sbMessage.append(strSelector);
                                }
                                if (!TextUtils.isEmpty(strRuleKey)) {
                                    sbMessage.append("\nIn CSS rule: ");
                                    sbMessage.append(strRuleKey);
                                }
                                throw new CssParseException(this.getName(), nLine, strLine, sbMessage.toString());
                            }
                        } else if (sCurrentCharacter == '}') {
                            // 11/04/2019 Mostrar errores de CSS rotos
                            if (this.m_bStrictMode) {
                                let sbMessage = new StringBuilder("Unexpected end bracket found while getting rule value. Missing semicolon value terminator?");
                                if (!TextUtils.isEmpty(strSelector)) {
                                    sbMessage.append("\nIn selector: ");
                                    sbMessage.append(strSelector);
                                }
                                if (!TextUtils.isEmpty(strRuleKey)) {
                                    sbMessage.append("\nIn CSS rule: ");
                                    sbMessage.append(strRuleKey);
                                }
                                throw new CssParseException(this.getName(), nLine, strLine, sbMessage.toString());
                            }
                        }
                        // Cualquier otra cosa, sumamos al valor
                        strRuleValue += sCurrentCharacter;
                        nPosition++;
                        break;
                    default:
                        throw new CssParseException(this.getName(), nLine, strLine, "Unexpected parse state: " + parseState);
                }
            }
        }
    }

    /**
     * Dado el nombre de un selector devuelve la regla CSS que responde a dicho selector o NULL si
     * no existe.
     *
     * @param strSelector
     * @return
     */
    public  getRuleBySelector(strSelector:string):XoneCssRule {
        return this.m_bundleRules.get(strSelector);
    }

    public addRule(strSelector:string, cssRule: XoneCssRule ) {
        this.m_bundleRules.put(strSelector, cssRule);
    }

    // /**
    //  * 26/07/2018 Juan Carlos
    //  * Implementación de parcelable para serializar este objeto a disco si queremos.
    //  */
    // public static final Creator<XoneCssParser> CREATOR = new this.Creator<XoneCssParser>() {
    //     @NonNull
    //     @Override
    //     public XoneCssParser createFromParcel(Parcel source) {
    //         return new XoneCssParser(source);
    //     }

    //     @NonNull
    //     @Override
    //     public XoneCssParser[] newArray(int nSize) {
    //         return new XoneCssParser[nSize];
    //     }
    // };

    // protected XoneCssParser(Parcel source) {
    //     m_strName = source.readString();
    //     m_bundleRules = source.readBundle(getClass().getClassLoader());
    //     m_bStrictMode = source.readByte() != 0;
    // }

    // @Override
    // public int describeContents() {
    //     return 0;
    // }

    // @Override
    // public void writeToParcel(Parcel destination, int nFlags) {
    //     destination.writeString(m_strName);
    //     destination.writeBundle(m_bundleRules);
    //     destination.writeByte((byte) (m_bStrictMode ? 1 : 0));
    // }
}