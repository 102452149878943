import IXoneApp from "../Interfaces/IXoneApp.js";
import { __SCRIPT_WRAPPER,__SCRIPT_WRAPPERASYNC } from "../xone/source/scripts/__SCRIPT_WRAPPER__.js";
import ScriptContext from "./ScriptContext.js";

export default class XOneJavascript {
    public static run(appData: IXoneApp, script: any, ctx: ScriptContext, mapRuntimeObjects: any,Arguments?:any, sMessage: string="") {
        __SCRIPT_WRAPPER(appData,appData.getUserInterface(),ctx.getObjectHost(),script,Arguments);
    }

    public static async runAsync(appData: IXoneApp, script: any, ctx: ScriptContext, mapRuntimeObjects: any,Arguments?:any, sMessage: string="") {
        return __SCRIPT_WRAPPERASYNC(appData,appData.getUserInterface(),ctx.getObjectHost(),script,Arguments);
    }

}