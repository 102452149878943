export class SwipeHandler {
  xThreshhold = 75; // slide this.xThreshhold
  allowedTime = 500; // ms allowed to swipe
  yThreshhold = 75; // slide this.yThreshhold

  /**
   * Constructor
   * @param {Function} callbackFunction
   */
  constructor(callbackFunction) {
    this.callbackFunction = callbackFunction;
  }

  /**
   * Touch Start
   * @param {*} e
   */
  touchStart(e) {
    if (
      e.srcElement !== this.touchElement &&
      e.srcElement.getAttribute("swipe") === "false"
    )
      return e.stopPropagation();
    this.touchobj = e.changedTouches[0];
    this.dist = 0;
    this.startX = this.touchobj.pageX;
    this.startY = this.touchobj.pageY;
    // get start time
    this.startTime = new Date().getTime();
  }

  /**
   * Touch End
   * @param {*} e
   */
  touchEnd(e) {
    if (
      e.srcElement !== this.touchElement &&
      e.srcElement.getAttribute("swipe") === "false"
    )
      return e.stopPropagation();
    this.touchobj = e.changedTouches[0];
    this.dist = this.touchobj.pageX - this.startX;
    // get swipe time
    this.elapsedTime = new Date().getTime() - this.startTime;
    if (
      this.elapsedTime > this.allowedTime ||
      Math.abs(this.dist) < this.xThreshhold ||
      Math.abs(this.touchobj.pageY - this.startY) > this.yThreshhold
    )
      return;

    this.callbackFunction(this.dist < 0 ? 1 : -1);
  }

  init(touchElement) {
    this.touchElement = touchElement;
    this.touchElement.addEventListener(
      "touchstart",
      this.touchStart.bind(this),
      { passive: true }
    );
    this.touchElement.addEventListener("touchend", this.touchEnd.bind(this), {
      passive: true,
    });
  }

  clear() {
    // clear events
    this.touchElement.removeEventListener("touchstart", this.touchStart);
    this.touchElement.removeEventListener("touchend", this.touchEnd);
  }
}
