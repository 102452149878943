/**
 * get Image Path
 * @param {string} imgPath
 * @param {string} [initialPath]
 * @param {boolean} [isFirstCheck]
 * @returns {Promise<string|null>}
 */
export const getImagePath = async (
  imgPath,
  initialPath = "icons",
  isFirstCheck = true
) => {
  const image = new Image();
  let finalPath = imgPath;
  try {
    await new Promise((resolve, reject) => {
      if (!finalPath.includes("icons/") && !finalPath.includes("files/"))
        finalPath = `/${initialPath}/${finalPath}`;

      image.src = `.${finalPath}`;

      image.onload = function() {
        resolve();
      };
      image.onerror = () => reject();
    });
  } catch {
    if (!isFirstCheck) return null;
    if (finalPath.includes("icons/"))
      return getImagePath(finalPath.replace("icons/", "files/"), "", false);
    return getImagePath(finalPath.replace("files/", "icons/"), "", false);
  }

  return finalPath;
};

export const getImagePathAndSize = async (
  imgPath,
  initialPath = "icons",
  isFirstCheck = true
) => {
  const image = new Image();
  let finalPath = imgPath;
  let imgSize = { width: 0, height: 0 };
  try {
    imgSize = await new Promise((resolve, reject) => {
      if (!finalPath.includes("icons/") && !finalPath.includes("files/"))
        finalPath = `/${initialPath}/${finalPath}`;

      image.src = `.${finalPath}`;

      image.onload = function() {
        // @ts-ignore
        resolve({ width: this.width, height: this.height });
      };
      image.onerror = () => reject();
    });
  } catch {
    if (!isFirstCheck) return null;
    if (finalPath.includes("icons/"))
      return getImagePathAndSize(
        finalPath.replace("icons/", "files/"),
        "",
        false
      );
    return getImagePathAndSize(
      finalPath.replace("files/", "icons/"),
      "",
      false
    );
  }

  return { path: finalPath, imgSize };
};
