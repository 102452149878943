import { XoneDataObject } from "./appData/core/XoneDataObject";

class XoneViewsHandler {
  /**
   * _instance
   * @type {XoneViewsHandler}
   */
  static _instance;

  /**
   * views
   * @type {WeakMap<XoneDataObject, XoneView>}
   */
  viewsMap = new Map();

  constructor() {
    if (XoneViewsHandler._instance) return XoneViewsHandler._instance;
    XoneViewsHandler._instance = this;
  }

  /**
   * Add View
   * @param {XoneDataObject} xoneDataObject
   * @param {XoneView} xoneView
   */
  addView(xoneDataObject, xoneView) {
    this.viewsMap.set(xoneDataObject["XoneHashId"], xoneView);
  }

  /**
   * Get View
   * @param {XoneDataObject} xoneDataObject
   * @returns {XoneView}
   */
  getView(xoneDataObject) {
    return this.viewsMap.has(xoneDataObject["XoneHashId"])
      ? this.viewsMap.get(xoneDataObject["XoneHashId"])
      : null;
  }
}

/**
 * @type {XoneViewsHandler}
 */
export const xoneViewsHandler = new XoneViewsHandler();

export class XoneView {
  /**
   * xoneDataObject
   * @type {XoneDataObject}
   */
  xoneDataObject;

  /**
   * bindedEvents
   * @type {Array<object>}
   */
  bindedEvents = [];

  /**
   * constructor
   * @param {XoneDataObject} xoneDataObject
   */
  constructor(xoneDataObject) {
    const xoneViewsHandler = new XoneViewsHandler();
    xoneViewsHandler.addView(xoneDataObject, this);
  }

  /**
   * addControl
   * @param {XoneControl} control
   */
  addControl(control) {
    this[control.name] = control;
  }

  /**
   * Bind event
   * @param  {...any} Args
   */
  bind(...Args) {
    if (!Args || Args.length < 3)
      return console.error(
        "Error calling bind method. You need 3 arguments at least."
      );

    let bindedEvent = this.bindedEvents.find(
      (e) =>
        e.field === Args[0] && e.eventName === Args[1].toString().toLowerCase()
    );

    if (!bindedEvent)
      bindedEvent = {
        fieldName: Args[0],
        eventName: Args[1].toString().toLowerCase(),
      };

    if (Args.length === 3) bindedEvent.action = Args[2];
    else {
      bindedEvent.params = Args[2];
      bindedEvent.action = Args[3];
    }

    // Add event bind
    this.bindedEvents.push(bindedEvent);
  }

  /**
   * Refresh
   * @param  {...any} Props
   */
  refresh(...Props) {
    const props = Props.length === 1 ? Props[0].toString().split(",") : Props;
    props.forEach((e) => {
      if (this[e]?.refresh) this[e].refresh();
    });
    if (Props.length === 0)
      Object.values(this).forEach((e) => {
        if (!e) return;
        if (e.isContents && e.refresh) e.refresh();
      });
  }

  /**
   * Refresh All
   */
  refreshAll() {
    this.refresh();
  }
}

export class XoneControl {
  refresh = () => {};

  constructor(name, isContents = false) {
    this.name = name;
    this.isContents = isContents;
  }
}
