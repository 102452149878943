<template>
  <teleport to="body">
    <div v-if="isLoaderVisible" class="xone-loader">
      <div></div>
    </div>
  </teleport>
</template>

<script>
import { ref } from "vue";
import XoneUI from "../../composables/XoneUI";
export default {
  setup() {
    const isLoaderVisible = ref(false);

    XoneUI.setShowLoaderCallback(() => (isLoaderVisible.value = true));
    XoneUI.setHideLoaderCallback(() => (isLoaderVisible.value = false));

    return { isLoaderVisible };
  },
};
</script>

<style scoped>
.xone-loader {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s;
  z-index: 999;
}

.xone-loader div:first-child {
  border-radius: 50%;
  border: 8px solid white;
  border-left: 8px solid transparent;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>