import Hashtable from "../../Collections/HashMap/Hashtable";

export default class XoneCssRule {

    private m_strName;
    private m_bundleRules:Hashtable<string,string>;

    constructor(sSelectorName:string) {
        this.m_strName = sSelectorName;
        this.m_bundleRules = new Hashtable<string,string>();
    }

    public getName() {
        return this.m_strName;
    }

    /**
     * El método String.intern() nos ahorra bastante memoria aquí.
     * TODO 26/07/2018 Juan Carlos
     * Esto de internar las cadenas es horriblemente lento en algunos teléfonos. Revisar.
     * <p>
     * Tag: Luis 1604201802
     * Corregido un error en los valores de los css, que no pueden tener espacio. Se le hace trim()
     *
     * @param sRuleKey
     * @param sRuleValue
     */
    public addRule(sRuleKey: string, sRuleValue:string) {
        this.m_bundleRules.put(sRuleKey, sRuleValue.trim());
    }

    public getRuleValue(sRuleKey:string):string {
        if (this.m_bundleRules.containsKey(sRuleKey)) {
            return this.m_bundleRules.get(sRuleKey);
        }
        // No lo conejo
        return null;
    }

    public deleteRule(sRuleKey: string):void {
        if (this.m_bundleRules.containsKey(sRuleKey)) {
            this.m_bundleRules.delete(sRuleKey);
        }
    }

    public contains(sRuleKey:string): boolean {
        return this.m_bundleRules.containsKey(sRuleKey);
    }

    // /**
    //  * TODO 26/07/2018 Juan Carlos
    //  * Implementación de parcelable para serializar este objeto a disco si queremos.
    //  */
    // public static final Creator<XoneCssRule> CREATOR = new Creator<XoneCssRule>() {
    //     @Override
    //     public XoneCssRule createFromParcel(@NonNull Parcel source) {
    //         return new XoneCssRule(source);
    //     }

    //     @Override
    //     public XoneCssRule[] newArray(int nSize) {
    //         return new XoneCssRule[nSize];
    //     }
    // };

    // protected XoneCssRule(@NonNull Parcel source) {
    //     m_strName = source.readString();
    //     m_bundleRules = source.readBundle(getClass().getClassLoader());
    // }

    // @Override
    // public int describeContents() {
    //     return 0;
    // }

    // @Override
    // public void writeToParcel(Parcel destination, int nFlags) {
    //     destination.writeString(m_strName);
    //     destination.writeBundle(m_bundleRules);
    // }

}