<template>
  <!-- Image -->
  <img
    ref="imgElement"
    v-if="attributes.readOnly"
    :src="imgPath"
    :class="{ 'keep-aspect-ratio': attributes.keepAspectRatio }"
  />
  <!-- Signature -->
  <canvas
    v-if="!attributes.readOnly"
    ref="sign"
    :width="controlWidth && controlWidth"
    :height="controlHeight && controlHeight"
    class="xone-signature"
  ></canvas>
  <div v-if="!attributes.readOnly" class="xone-clear-signature">
    <button @click="clearCanvas()">X</button>
  </div>
</template>

<script>
import {
  computed,
  inject,
  onMounted,
  PropType,
  provide,
  ref,
  Ref,
  watch,
  watchEffect,
} from "vue";
import {
  PropAttributes,
  xoneAttributesHandler,
} from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import SignaturePad from "signature_pad";
import { getImagePath } from "../../composables/helperFunctions/ImageHelper";
export default {
  props: {
    /** xoneDataObject
     @type {PropType<XoneDataObject>} 
     */
    xoneDataObject: { type: Object, required: true },
    /** attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    /**
     * prop model value
     * @type {Ref<any>}
     */
    let propValue = ref();

    // Get appData model value
    watchEffect(() => {
      if (props.xoneDataObject?.model)
        propValue.value = props.xoneDataObject?.model[props.attributes.name];
    });

    const imgPath = ref();

    watchEffect(async () => {
      if (propValue.value) {
        imgPath.value = `/files/${propValue.value}`;
        imgPath.value = await getImagePath(propValue.value, "files");
      }
      if (props.attributes.image && props.attributes.image !== "") {
        imgPath.value = `/icons/${props.attributes.image}`;
        imgPath.value = await getImagePath(props.attributes.image, "icons");
      }
    });

    /**
     * sign
     * @type {Ref<HTMLElement>}
     */
    const sign = ref();

    /**
     * signaturePad
     * @type {SignaturePad}
     */
    let signaturePad;

    onMounted(() => {
      if (!sign.value) return;
      sign.value.setAttribute("swipe", false);
      signaturePad = new SignaturePad(sign.value);
    });

    const clearCanvas = () => {
      if (!signaturePad) return;
      signaturePad.clear();
    };

    const objectInfo = inject("objectInfo");

    /**
     * imgElement
     * @type {Ref<HTMLElement>}
     */
    const imgElement = ref();

    const fitSizeToContainer = (timeout = 0) => {
      xoneAttributesHandler.fitHeightToContainer(
        props.attributes,
        imgElement,
        timeout
      );
      xoneAttributesHandler.fitWidthToContainer(
        props.attributes,
        imgElement,
        timeout
      );
    };

    onMounted(() => fitSizeToContainer());

    /**
     * Last breadcumb in stack
     * @type {ComputedRef<string>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    // Provide breadcumbId info to child components
    const breadcumbId = inject("breadcumbId");

    watchEffect(() => {
      if (breadcumbId === lastBreadcumb.value?.id) fitSizeToContainer();
    });

    /**
     * Window Size
     * @type {{containerHeight: Ref<number>}}
     */
    const { containerHeight, containerWidth } = inject("containerSize");
    watch(
      () => containerHeight.value,
      () => fitSizeToContainer(500)
    );
    watch(
      () => containerWidth.value,
      () => fitSizeToContainer(500)
    );

    // if selected group is current group fit size to container
    /**
     * groupId
     * @type {number}
     */
    const groupId = inject("groupId");

    /**
     * activeGroup
     * @type {Ref<number>}
     */
    const { activeGroup } = inject("groupHandler");

    watch(
      () => activeGroup.value,
      (newValue) => {
        if (groupId !== newValue) return;
        fitSizeToContainer();
      }
    );

    return { imgElement, imgPath, sign, clearCanvas };
  },
};
</script>

<style scoped>
/* Image */
img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.3s;
}

.keep-aspect-ratio {
  object-fit: contain;
}

.xone-signature {
  display: block;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
}

.xone-clear-signature {
  position: absolute;
  top: 0;
  right: 0;
}

button {
  padding: 10px;
}
</style>