export class Exception {
    getClass(): any {
        return {getSimpleName:()=>"Exception"};
    }

    getMessage():string {
        return this.m_message;
    }
    m_message: string;
    /**
     *
     */
    constructor(message: string) {
        this.m_message=message;
        
    }
}